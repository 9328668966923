/* eslint-disable no-unused-vars */
import { takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { loadingLead, createLead, updateStep, updateLead, updateErr } from '../features/lead-slice';
import {
  createLeadApi,
  getAppoinmentAvailablityApi,
  getLeadWebData,
  getQuoteApi,
  getReferralDataApi,
  handlePaymentApi,
  updateLeadDataApi
} from '../../apis';
import {
  CREATE_LEAD,
  UPDATE_LEAD,
  UPDATE_STEP,
  GET_QUOTE,
  HANDLE_PAYMENT,
  GET_LEAD_DATA,
  GET_REFERRAL_DATA
} from '../action-types/lead-types';
import { allConstantVals, calculatePriceOfProduct } from '../../Constants';
import { updateLeadAction } from '../actions/lead-actions';

// const navigate = useNavigate();

const getLeadData = (state) => state.lead;

function* getLeadDataByInteraction(action) {
  try {
    yield put(loadingLead(true));
    const response = yield call(getLeadWebData, action.payload);
    // console.log();
    yield put(
      updateLead({
        loading: false,
        leadDetail: {
          data: response.result,
          interaction_id: action.payload,
          lead_id: response.result.lead_id
        },
        productData: {
          ...response.result.data,
          currentStep: response.result.data?.currentStep ? response.result.data?.currentStep : 2,
          property_postcode: response.result.property_postcode,
          u_show_online: response.result.u_show_online,
          quoteData: response.result.data?.quoteData,
          appointment: response.result.data?.appointment,
          callbackStatus: response.result.data?.callbackStatus,
          valuation: response.result.data?.valuation
        },

        accessDetail: {},
        reportDetail: {},
        errorType: null
      })
    );
  } catch (err) {
    yield put(loadingLead(false));
    const error = JSON.parse(err.message);
    yield put(updateErr({ msg: error.message ? error.message : 'Something went wrong' }));
  }
}

function* handleLead(action) {
  try {
    yield put(loadingLead(true));
    const response = yield call(createLeadApi, action.payload);
    if (response.result.postcode_coverage === false) {
      yield put(loadingLead(false));
      yield put(
        updateErr({
          msg: 'Unfortunately, we currently have no surveyor covering this postcode area.',
          btnOne: 'Okay'
        })
      );
      // NotificationManager.error("Sorry, We don't coverage this postcode");
    } else {
      const leadData = yield select(getLeadData);
      yield put(
        updateLead({
          ...leadData,
          loading: false,
          leadDetail: response.result,
          productData: {
            ...leadData.productData,
            currentStep: 2,
            property_postcode: action.payload.property_postcode,
            u_show_online: response.result?.data.u_show_online
          }
        })
      );
    }
  } catch (err) {
    yield put(loadingLead(false));
    const error = JSON.parse(err.message);
    // NotificationManager.error(error.message ? error.message : 'Something went wrong');
    yield put(updateErr({ msg: error.message ? error.message : 'Something went wrong' }));
  }
}

function* updateStepWithLocalStorage(action) {
  const leadData = yield select(getLeadData);
  // console.log(action.payload, 'step', leadData.productData);
  // switch (action.payload) {
  //   case 1:
  //     yield put(
  //       updateLead({
  //         ...leadData,
  //         currentStep: action.payload,
  //         productData: {}
  //       })
  //     );
  //     // yield put(updateStep(action.payload));
  //     break;
  //   case 2:
  //     yield put(
  //       updateLead({
  //         ...leadData,
  //         currentStep: action.payload,
  //         productData: {
  //           ...leadData.productData,
  //           property_postcode: '',
  //           property_address: '',
  //           property_type: '',
  //           bedrooms: '',
  //           asking_price: '',
  //           freehold_or_lease: '',
  //           years_left_on_lease: '',
  //           historic_timber_frame_or_beams: '',
  //           mordern_timber_frame: '',
  //           steel_frame: '',
  //           concrete_property: '',
  //           'ex-council_timber_frame': '',
  //           thatched_roof: '',
  //           conservatory: '',
  //           grade_2_listed: '',
  //           conservation_area: '',
  //           swimming_pool: '',
  //           cellar: '',
  //           more_than_5_rooms_ground_floor: '',
  //           land_excluding_gardens: ''
  //         }
  //       })
  //     );
  //     // yield put(updateStep(action.payload));
  //     break;
  //   case 3:
  //     yield put(
  //       updateLead({
  //         ...leadData,
  //         currentStep: action.payload,
  //         productData: {
  //           ...leadData.productData,
  //           historic_timber_frame_or_beams: '',
  //           mordern_timber_frame: '',
  //           steel_frame: '',
  //           concrete_property: '',
  //           'ex-council_timber_frame': '',
  //           thatched_roof: '',
  //           conservatory: '',
  //           grade_2_listed: '',
  //           conservation_area: '',
  //           swimming_pool: '',
  //           cellar: '',
  //           more_than_5_rooms_ground_floor: '',
  //           land_excluding_gardens: ''
  //         }
  //       })
  //     );
  //     // yield put(updateStep(action.payload));
  //     break;
  //   case 4:
  //     yield put(
  //       updateLead({
  //         ...leadData,
  //         currentStep: action.payload,
  //         productData: {
  //           ...leadData.productData,
  //           conservatory: '',
  //           grade_2_listed: '',
  //           conservation_area: '',
  //           swimming_pool: '',
  //           cellar: '',
  //           more_than_5_rooms_ground_floor: '',
  //           land_excluding_gardens: ''
  //         }
  //       })
  //     );
  //     // yield put(updateStep(action.payload));
  //     break;
  //   default:
  //     yield put(updateStep(action.payload));
  //     break;
  // }
  yield put(updateStep(action.payload));
  localStorage.setItem(
    'leadData',
    JSON.stringify({
      ...leadData,
      productData: { ...leadData.productData, currentStep: action.payload }
    })
  );
}

function* updateLeadWebData(action) {
  try {
    console.log(action.payload, 'frommmmmmmmm saga');
    yield put(loadingLead(true));
    // yield put(updateLead(action.payload));
    yield call(updateLeadDataApi, {
      interaction_id: action.payload.leadDetail.interaction_id,
      data: { ...action.payload.productData, version: 1 }
    });
    yield put(updateLead(action.payload));
    yield put(loadingLead(false));
  } catch (err) {
    yield put(loadingLead(false));
    const error = JSON.parse(err.message);
    NotificationManager.error(error.message ? error.message : 'Something went wrong');
  }
}

function* getQuoteFromProdData({ payload }) {
  try {
    yield put(loadingLead(true));
    const quoteResponse = yield call(getQuoteApi, {
      ...payload?.productData,
      interaction_id: payload.leadDetail.interaction_id,
      valuation: payload.productData?.valuation
    });
    console.log(payload, 'apppppppppppppppppppppp');
    const productPriceDetails = yield call(
      calculatePriceOfProduct,
      quoteResponse.result.base_price,
      1.2,
      quoteResponse.result.total_price,
      quoteResponse.result.discounted_price,
      quoteResponse.result.discount_percent
    );
    console.log(productPriceDetails, 'pricesssss');
    yield put(
      updateLead({
        ...payload,
        productData: {
          ...payload.productData,
          quoteData: { ...quoteResponse.result, productPriceDetails },
          appointment: true
        }
      })
    );
    const appointmentResponse = yield call(getAppoinmentAvailablityApi, {
      postcode: payload.productData?.property_postcode,
      // product_id: quoteResponse.result.product.id
      job_type:
        payload.productData?.job_type === allConstantVals.valuationJobType.value
          ? payload.productData?.job_type
          : payload.productData?.survey_type,
      valuation: payload.productData?.valuation
    });
    yield put(
      updateLead({
        ...payload,
        productData: {
          ...payload.productData,
          quoteData: {
            ...appointmentResponse.result,
            ...quoteResponse.result,
            productPriceDetails
          },
          appointment: true
        }
      })
    );
    yield put(loadingLead(false));
  } catch (err) {
    const error = JSON.parse(err.message);
    console.log(error.data, 'from saga');
    if (error.status === 404 && error.data.includes('No appointmet slot found')) {
      // NotificationManager.error('No appointment slot found');
      // yield put(loadingLead(false));
      // yield call(
      //   updateLeadWebData({ ...payload, currentStep: 8, loading: false, appointment: false })
      // );
      /// /////////////////////////////////
      // yield call(updateLeadWebData, {
      //   payload: {
      //     ...payload,
      //     currentStep: 8,
      //     appointment: false,
      //     web_stage: allConstantVals.stage_quote_no_slots
      //   }
      // });
      // yield put(
      //   updateLead({
      //     ...payload,
      //     // currentStep: 8,
      //     appointment: false,
      //     web_stage: allConstantVals.stage_quote_no_slots.value
      //   })
      // );
      console.log('hellllo');
      /// ////////////////////////////////////
      // yield call(updateLeadDataApi, {
      //   interaction_id: payload.leadDetail.interaction_id,
      //   data: payload.productData
      // });
    } else if (error.status === 404) {
      // NotificationManager.error('Product Not Found');
      yield put(loadingLead(false));
      console.log('Product Not Found');
    } else {
      yield put(loadingLead(false));
      NotificationManager.error(error.message ? error.message : 'Something went wrong');
    }
  }
}

function* handlePaymentAndCreateInstruction(action) {
  try {
    const { leadData, paymentDetail, currentStep } = action.payload;
    // const leadData = action.payload;
    yield put(loadingLead(true));
    const response = yield call(handlePaymentApi, { ...leadData, paymentDetail });
    // console.log(response, response.result.number, 'from handlepayments', paymentDetail);
    yield put(
      updateLead({
        ...leadData,
        productData: {
          ...leadData.productData,
          currentStep,
          quoteData: {
            ...leadData.productData?.quoteData,
            paymentDetail,
            jobNumber: response.result.number
          }
        }
      })
    );
  } catch (err) {
    console.log(err);
    yield put(loadingLead(false));
    const error = JSON.parse(err.message);
    NotificationManager.error(error.message ? error.message : 'Something went wrong');
  }
}

function* getReferralData({ payload }) {
  try {
    yield put(loadingLead(true));
    const response = yield call(getReferralDataApi, payload.productData.referId);
    console.log(response, 'getReferralDetails');
    yield put(
      updateLead({
        ...payload,
        productData: { ...payload.productData, partner_logo: response?.result?.ref_url }
      })
    );
  } catch (err) {
    yield put(loadingLead(false));
    const error = JSON.parse(err.message);
    // yield put(updateErr({ msg: error.message ? error.message : 'Something went wrong' }));
    console.log({ msg: error.message ? error.message : 'Something went wrong' });
  }
}

export default function* rootSaga() {
  yield takeLatest(CREATE_LEAD, handleLead);
  yield takeLatest(UPDATE_STEP, updateStepWithLocalStorage);
  yield takeLatest(UPDATE_LEAD, updateLeadWebData);
  yield takeLatest(GET_QUOTE, getQuoteFromProdData);
  yield takeLatest(HANDLE_PAYMENT, handlePaymentAndCreateInstruction);
  yield takeLatest(GET_LEAD_DATA, getLeadDataByInteraction);
  yield takeLatest(GET_REFERRAL_DATA, getReferralData);
}
