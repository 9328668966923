/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import Axios from 'axios';
import Container from '../Container';
import FormControl from '../FormControl';
import { updateLeadAction, updateStepAction } from '../../redux/actions/lead-actions';
import FormSubmitButtons from '../FormSubmitButtons';
import { addressBasedOnPostcode, checkPostcodeCoverage } from '../../apis';
import Button from '../Button';
import { loadingLead, updateErr, updateLead } from '../../redux/features/lead-slice';
import {
  allConstantVals,
  buildYear,
  propertyType,
  bedrooms,
  askingPrice,
  freeholdOrLease,
  yearsLeftOnLease,
  generalYesNo,
  ewsRating,
  commonFieldErr
} from '../../Constants';
import withHandleGetAddress from '../../HOC/HandleGetAddress';
import FieldsErr from '../FieldsErr';
import CautionComponent from '../CautionComponent';

function FormThree({ getAndUpdateAddress }) {
  const {
    valuationJobType,
    surveyJobType,
    level2Name,
    level3Name,
    flat,
    yes,
    no,
    leasehold,
    a3,
    b2,
    stage_fail_no_service,
    stage_quote,
    stage_property_non_standard,
    stage_property_additional
  } = allConstantVals;
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const [addressVals, setAddressVals] = useState([]);
  const [coordinates, setCoordinates] = useState({});
  const [postcodeErr, setPostcodeErr] = useState({ status: false, message: '' });

  // const getAndUpdateAddress = async (postcodeVal) => {
  //   try {
  //     dispatch(loadingLead(true));
  //     const addresses = await addressBasedOnPostcode(postcodeVal);
  //     dispatch(loadingLead(false));
  //     setPostcodeErr({ status: false, message: '' });
  //     setAddressVals([{ key: 'key', value: '', label: 'Select an option' }, ...addresses]);
  //   } catch (err) {
  //     dispatch(loadingLead(false));
  //     setPostcodeErr({ status: true, message: JSON.parse(err.message).message });
  //   }
  // };
  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    window.scrollTo(0, 0);
    getAndUpdateAddress(
      leadData.productData?.property_postcode,
      setPostcodeErr,
      setAddressVals,
      setCoordinates
    );
  }, []);

  const { productData } = leadData;
  const initialValues = {
    build_year_l3: productData?.build_year_l2
      ? productData?.build_year_l2
      : productData?.build_year_l3 || '',
    property_postcode: productData?.property_postcode || '',
    property_address: productData?.property_address || '',
    property_type: productData?.property_type || '',
    flat_4_storey_or_above: productData?.flat_4_storey_or_above || '',
    have_ews_form: productData?.have_ews_form || '',
    ews_rating: productData?.ews_rating || '',
    bedrooms: productData?.bedrooms || '',
    asking_price: productData?.asking_price || '',
    freehold_or_lease: productData?.freehold_or_lease || '',
    years_left_on_lease: productData?.years_left_on_lease || ''
  };

  const validationSchema = Yup.object({
    build_year_l3:
      !productData?.build_year_l2 && leadData?.productData?.job_type === surveyJobType.value
        ? // && leadData.productData?.survey_type === level3Name.value
          Yup.string().required(commonFieldErr)
        : Yup.string(),
    property_postcode: Yup.string().required('Property postcode cannot be empty'),
    property_address: Yup.string().required('please select the address'),
    property_type: Yup.string().required(commonFieldErr),
    flat_4_storey_or_above:
      leadData.productData?.job_type === valuationJobType.value
        ? Yup.string().when('property_type', {
            is: flat.value,
            then: Yup.string().required(commonFieldErr)
          })
        : Yup.string(),
    have_ews_form:
      leadData.productData?.job_type === valuationJobType.value
        ? Yup.string().when(['property_type', 'flat_4_storey_or_above'], {
            is: (property_type, flat_4_storey_or_above) =>
              property_type === flat.value && flat_4_storey_or_above === yes.value,
            then: Yup.string().required(commonFieldErr)
          })
        : Yup.string(),
    ews_rating:
      leadData.productData?.job_type === valuationJobType.value
        ? Yup.string().when(['flat_4_storey_or_above', 'have_ews_form'], {
            is: (flat_4_storey_or_above, have_ews_form) =>
              flat_4_storey_or_above === yes.value && have_ews_form === yes.value,
            then: Yup.string().required(commonFieldErr)
          })
        : Yup.string(),
    bedrooms: Yup.string().required(commonFieldErr),
    asking_price: Yup.string().required(commonFieldErr),
    freehold_or_lease: Yup.string().required(commonFieldErr),
    years_left_on_lease: Yup.string().when('freehold_or_lease', {
      is: leasehold.value,
      then: Yup.string().required(commonFieldErr)
    })
  });
  // const addressVals = [
  //   { key: 'Select an option', value: '' },
  //   { key: 'Option 1', value: 'option1' },
  //   { key: 'Option 2', value: 'option2' },
  //   { key: 'Option 3', value: 'option3' }
  // ];

  const fieldLabels = {
    build_year_l3: 'Approximate year of build',
    property_postcode: 'Property Postcode',
    property_address: 'Property address',
    property_type: 'Property type',
    flat_4_storey_or_above: 'Is the flat 4 storeys or above including ground floor?',
    have_ews_form: 'Do you have an EWS1 form?',
    ews_rating: 'EWS1 form Rating',
    bedrooms: 'Number of bedrooms as listed',
    asking_price:
      leadData.productData?.job_type === valuationJobType.value
        ? 'Estimated Value'
        : 'Agreed Price',
    freehold_or_lease: 'Is the property freehold or leasehold?',
    years_left_on_lease: 'How many years left on lease?'
  };

  const onSubmit = async (values) => {
    try {
      const {
        result: { coverage }
      } = await checkPostcodeCoverage(values.property_postcode);

      const updatedLeadData = {
        ...leadData,
        productData: { ...leadData.productData, ...values, coordinates, currentStep: 8 }
      };
      console.log(values, '22222222222');
      switch (leadData.productData?.job_type) {
        case valuationJobType.value:
          if (
            (values.flat_4_storey_or_above === yes.value && values.have_ews_form === no.value) ||
            (values.flat_4_storey_or_above === yes.value &&
              values.have_ews_form === yes.value &&
              (values.ews_rating === a3.value || values.ews_rating === b2.value)) ||
            (values.freehold_or_lease === leasehold.value &&
              values.years_left_on_lease === yearsLeftOnLease[2].value) ||
            values.asking_price === allConstantVals['2000000_or_more'].value ||
            values.bedrooms === allConstantVals['7'].value
          ) {
            dispatch(
              updateLeadAction({
                ...updatedLeadData,
                productData: {
                  ...updatedLeadData.productData,
                  web_stage: stage_fail_no_service.value,
                  currentStep: 8
                }
              })
            );
          } else {
            dispatch(
              updateLeadAction({
                ...updatedLeadData,
                productData: {
                  ...updatedLeadData.productData,
                  web_stage: stage_quote.value,
                  currentStep: 6
                }
              })
            );
          }
          break;
        case surveyJobType.value: {
          if (
            values.bedrooms === allConstantVals['7'].value ||
            values.asking_price === allConstantVals['2000000_or_more'].value ||
            (values.freehold_or_lease === leasehold.value &&
              values.years_left_on_lease === yearsLeftOnLease[2].value)
          ) {
            dispatch(
              updateLeadAction({
                ...updatedLeadData,
                productData: {
                  ...updatedLeadData.productData,
                  web_stage: stage_fail_no_service.value,
                  currentStep: 8
                }
              })
            );
            // TODO: uncomment this
            // } else if (leadData.productData.survey_type === level2Name.value) {
          } else if (
            leadData.productData.survey_type === level2Name.value ||
            leadData.productData.survey_type === level3Name.value
          ) {
            dispatch(
              updateLeadAction({
                ...updatedLeadData,
                productData: {
                  ...updatedLeadData.productData,
                  web_stage: stage_property_additional.value,
                  currentStep: 5
                }
              })
            );
          } else {
            dispatch(
              updateLeadAction({
                ...updatedLeadData,
                productData: {
                  ...updatedLeadData.productData,
                  web_stage: stage_property_non_standard.value,
                  currentStep: 4
                }
              })
            );
          }
          break;
        }
        default:
          break;
      }
    } catch (err) {
      const error = JSON.parse(err.message);
      if (error.status === 600) {
        dispatch(
          updateErr({
            msg: 'Please check your internet connection',
            btnOne: 'Okay'
          })
        );
      } else {
        dispatch(
          updateErr({
            msg: 'Unfortunately, we currently have no surveyor covering this postcode area.',
            btnOne: 'Okay'
          })
        );
      }
    }
  };
  return (
    <Container>
      <div className="flex justify-between items-center">
        <p className="font-bold text-3xl md:text-4xl">Property Details</p>
        {leadData.productData?.job_type === surveyJobType.value && (
          <div className="flex">
            <div className="w-4 h-4 border-2 border-primary-500 bg-primary-500 rounded-full mx-1" />
            {/* TODO: uncomment this */}
            {/* {leadData.productData?.job_type === surveyJobType.value &&
            leadData.productData.survey_type === level2Name.value ? (
              <div />
            ) : (
              <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
            )} */}
            <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
          </div>
        )}
      </div>
      <p>We need few more details about the property</p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ values, errors, touched }) => (
          <Form>
            {!productData?.build_year_l2 &&
              leadData?.productData?.job_type === surveyJobType.value && (
                // leadData?.productData?.survey_type === level3Name.value &&
                <FormControl
                  controlType="dropdown"
                  label={fieldLabels.build_year_l3}
                  name="build_year_l3"
                  optionVals={buildYear}
                  dropdownType="type1"
                />
              )}
            <div className="md:flex mt-5">
              <FormControl
                controlType="input"
                type="text"
                name="property_postcode"
                disabled
                label={fieldLabels.property_postcode}
                addressOpts="property_address"
              />
              {/* <Button customClass="p-3">find</Button> */}

              <FormControl
                controlType="dropdown"
                type="text"
                name="property_address"
                label={fieldLabels.property_address}
                dropdownType="type2"
                disabled={postcodeErr.status}
                optionVals={addressVals}
                // onClickHandler={() =>
                //   getAndUpdateAddress(values.property_postcode, setPostcodeErr, setAddressVals)
                // }
              />
            </div>
            {postcodeErr.status && <p className="text-red-500">{postcodeErr.message}</p>}
            <FormControl
              controlType="dropdown"
              type="text"
              name="property_type"
              label={fieldLabels.property_type}
              dropdownType="type1"
              optionVals={propertyType}
            />
            {leadData?.productData?.job_type === valuationJobType.value &&
              values.property_type === flat.value && (
                <FormControl
                  controlType="dropdown"
                  type="text"
                  name="flat_4_storey_or_above"
                  label={fieldLabels.flat_4_storey_or_above}
                  dropdownType="type1"
                  optionVals={generalYesNo}
                />
              )}
            {leadData?.productData?.job_type === valuationJobType.value &&
              values.property_type === flat.value &&
              values.flat_4_storey_or_above === yes.value && (
                <>
                  <FormControl
                    controlType="dropdown"
                    type="text"
                    name="have_ews_form"
                    label={fieldLabels.have_ews_form}
                    dropdownType="type1"
                    optionVals={generalYesNo}
                  />
                  {values.have_ews_form === no.value && (
                    <CautionComponent
                      type="info"
                      text="Sorry, we cannot value a property over 4 storeys without an EWS1 form."
                    />
                  )}
                </>
              )}
            {leadData?.productData?.job_type === valuationJobType.value &&
              values.property_type === flat.value &&
              values.flat_4_storey_or_above === yes.value &&
              values.have_ews_form === yes.value && (
                <>
                  <FormControl
                    controlType="dropdown"
                    type="text"
                    name="ews_rating"
                    label={fieldLabels.ews_rating}
                    dropdownType="type1"
                    optionVals={ewsRating}
                  />
                  {(values.ews_rating === a3.value || values.ews_rating === b2.value) && (
                    <CautionComponent
                      type="info"
                      text="Sorry, we cannot value a property with EWS1 form rating of A3 or B2."
                    />
                  )}
                </>
              )}
            <FormControl
              controlType="dropdown"
              type="text"
              name="bedrooms"
              label={fieldLabels.bedrooms}
              dropdownType="type1"
              small={+true}
              optionVals={bedrooms}
            />
            <FormControl
              controlType="dropdown"
              type="text"
              name="asking_price"
              label={fieldLabels.asking_price}
              dropdownType="type1"
              optionVals={askingPrice}
            />
            {values.asking_price === allConstantVals['2000000_or_more'].value && (
              <CautionComponent
                type="info"
                text="For properties with value above £2million, our team will review and get back to you."
              />
            )}
            <FormControl
              controlType="dropdown"
              type="text"
              name="freehold_or_lease"
              label={fieldLabels.freehold_or_lease}
              dropdownType="type1"
              optionVals={freeholdOrLease}
            />
            {values.freehold_or_lease === leasehold.value && (
              <>
                <FormControl
                  controlType="dropdown"
                  type="text"
                  name="years_left_on_lease"
                  label={fieldLabels.years_left_on_lease}
                  dropdownType="type1"
                  optionVals={yearsLeftOnLease}
                />
                {leadData?.productData?.job_type === valuationJobType.value &&
                  values.years_left_on_lease === allConstantVals['50_years_or_under'].value && (
                    <CautionComponent
                      type="info"
                      text="Sorry, we cannot value a property with outstanding lease of 50 years or under."
                    />
                  )}
              </>
            )}
            <FieldsErr fieldLabels={fieldLabels} errors={errors} touched={touched} />
            <FormSubmitButtons backBtnHandler={() => dispatch(updateStepAction(2))} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default withHandleGetAddress(FormThree);
