/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Route, Routes, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';

// COMPONENTS AND CONTAINERS
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Modal from './components/Modal';

import Home from './containers/Home';
import QuotePage from './containers/QuotePage';
import Product from './containers/Product';
import PaymentPage from './containers/PaymentPage';
import Confirmation from './containers/Confirmation';
import AccessDetail from './components/AccessDetail';
import ReportDetail from './components/ReportDetail';

// ACTIONS
import { updateErr, updateLead } from './redux/features/lead-slice';
import ErrComp from './components/ErrComp';
import { getLeadDataByInteraction, updateStepAction } from './redux/actions/lead-actions';

function App() {
  const [{ isPending }] = usePayPalScriptReducer();
  const { refId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const [searchParams] = useSearchParams();
  const { errorType } = leadData;
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    const localLeadData = localStorage.getItem('leadData');
    const localLeadDataParsed = JSON.parse(localLeadData);
    const interactionId = searchParams.get('interaction_id');
    if (localLeadData) {
      dispatch(updateLead(localLeadDataParsed));
    } else if (interactionId) {
      dispatch(getLeadDataByInteraction(interactionId));
    }
    // else if (refId) {
    //   console.log('@hello');
    //   navigate(`${refId}`);
    // } else {
    //   console.log('@hello2', refId);
    //   navigate('');
    // }
  }, []);
  useEffect(() => {
    if (leadData.productData?.currentStep === 7 && leadData.productData.u_show_online !== 'false') {
      window.onpopstate = (e) => {
        dispatch(updateStepAction(6));
      };
    } else if (
      leadData.productData?.currentStep === 9 &&
      leadData.productData.u_show_online !== 'false'
    ) {
      window.onpopstate = (e) => {
        dispatch(updateStepAction(9));
      };
    }
  });

  useEffect(() => {
    const body = document.querySelector('html');
    if (leadData.loading || isPending) {
      window.scrollTo(0, 0);
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }, [leadData.loading, isPending]);

  useEffect(() => {
    if (errorType) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [errorType]);

  // const errTypes = () => {
  //   switch (errorType.type) {
  //     case 'e1':
  //       return (
  //         <ErrComp>
  //           <p className="text-center pt-10 text-2xl">{errorType.msg}</p>
  //         </ErrComp>
  //       );
  //     case 'e2':
  //       return (
  //         <ErrComp>
  //           <p className="text-center pt-10 text-2xl">{errorType.msg}</p>
  //         </ErrComp>
  //       );
  //     default:
  //       return <div />;
  //   }
  // };

  return (
    <div className="relative">
      {(leadData.loading || isPending) && (
        <div className="absolute bg-white opacity-60 w-screen h-screen z-[102] flex items-center justify-center">
          <div>
            <div className="border-r-4 rounded-full w-20 h-20 border-primary-700 animate-spin" />
          </div>
        </div>
      )}
      {errorType && (
        <Modal open={modalOpen} onClose={() => dispatch(updateErr(null))}>
          <ErrComp
            type={errorType.type}
            yesBtnHandler={errorType.closeBtnHandler}
            btnOne={errorType.btnOne}
            btnTwo={errorType.btnTwo}>
            <p className="text-center pt-5 md:text-2xl text-xl">{errorType.msg}</p>
          </ErrComp>
        </Modal>
      )}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="product" element={<Product />} />
          <Route path="quote" element={<QuotePage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="confirmation" element={<Confirmation />} />
        </Route>
        <Route path="/.well-known" element={() => <div />} />
        <Route path="/:refId" element={<Home />}>
          <Route path="product" element={<Product />} />
          <Route path="quote" element={<QuotePage />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="confirmation" element={<Confirmation />} />
        </Route>
        {/* <Route exact path="/access-details" element={<AccessDetail />} />
        <Route exact path="/report-details" element={<ReportDetail />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
