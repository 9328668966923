/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

const ALPHABETS = 'abcdefghijklmnopqrstuvwxyz'.split('');
const TABLE_SEQ_TYPES = {
  alphabet_up: 'alphabet_up',
  number: 'number',
  alphabet_down: 'alphabet_down',
  roman: 'roman',
  number2: 'number2'
};
const TABLE_SEQ_TYPES_ARR = Object.values(TABLE_SEQ_TYPES);

function SURVEYTNCPAGE({ name, companyName }) {
  const toRoman = (num) => {
    const romanNumerals = [
      ['M', 1000],
      ['CM', 900],
      ['D', 500],
      ['CD', 400],
      ['C', 100],
      ['XC', 90],
      ['L', 50],
      ['XL', 40],
      ['X', 10],
      ['IX', 9],
      ['V', 5],
      ['IV', 4],
      ['I', 1]
    ];
    let result = '';

    for (const [roman, value] of romanNumerals) {
      while (num >= value) {
        result += roman;
        num -= value;
      }
    }
    return result;
  };

  const getFormattedSeqNum = (index, type) => {
    switch (type) {
      case TABLE_SEQ_TYPES.alphabet_up:
        return `${ALPHABETS[index].toUpperCase()}.`;
      case TABLE_SEQ_TYPES.number:
        return `${index + 1})`;
      case TABLE_SEQ_TYPES.alphabet_down:
        return `${ALPHABETS[index]})`;
      case TABLE_SEQ_TYPES.roman:
        return `${toRoman(index + 1)})`;
      case TABLE_SEQ_TYPES.number2:
        return `(${index + 1})`;
      default:
        return null;
    }
  };

  const renderUl = (data) => (
    <ul className="list-disc">
      {data.map((text) => (
        <li className="ml-3 whitespace-pre-wrap text-sm font-thin" key={text}>
          {text}
        </li>
      ))}
    </ul>
  );

  const renderContentList1 = (data) =>
    data.map(({ title, desc }) => (
      <div className="whitespace-pre-wrap text-justify text-sm" key={title}>
        <p className="!p-0 font-bold">{title}</p>
        <p className="!p-0 font-thin">{desc}</p>
      </div>
    ));

  const renderContentList2 = (data = [], type = TABLE_SEQ_TYPES.alphabet_up, level = 0) => {
    let html = '';

    data.forEach((obj, idx) => {
      const hasNestedData = obj.data && obj.data.length > 0;
      const hasNestedData2 = obj.data2 && obj.data2.length > 0;

      const formattedSeqNum = getFormattedSeqNum(idx, type);

      html += `<div style="display: flex; white-space: pre-wrap;"><div style="font-size: 14px; font-weight: 700; min-width: 45px ">${formattedSeqNum}</div>`;

      const textIndent = level === 0 ? 0 : 45;
      const fontWeight =
        ([TABLE_SEQ_TYPES.alphabet_down, TABLE_SEQ_TYPES.number2].includes(type) ||
          !hasNestedData) &&
        !obj.str2
          ? 100
          : 700;

      html += `<div>`;
      html += `<p style="font-size: 14px; text-align: justify; font-weight: ${fontWeight};">${obj.str}</p>`;

      if (obj.str2) {
        html += `<p style="font-size: 14px; text-align: justify; font-weight: 100;">${obj.str2}</p>`;
      }

      html += '</div></div>';

      if (hasNestedData) {
        html += `<div style="margin-left: ${textIndent}px; test="yes"">${renderContentList2(
          obj.data,
          TABLE_SEQ_TYPES_ARR[level + 1],
          level + 1
        )}</div>`;
      }

      if (obj.str3) {
        html += `<p style="margin-left: 45px; font-size: 14px; text-align: justify; font-weight: 100; white-space: pre-wrap;">${obj.str3}</p>`;
      }

      if (hasNestedData2) {
        html += `<div style="margin-left: ${textIndent}px;">${renderContentList2(
          obj.data2,
          TABLE_SEQ_TYPES_ARR[level + 1],
          level + 1
        )}</div>`;
      }

      if (obj.str_footer) {
        html += `<p style="margin-left: 45px; font-size: 14px; text-align: justify; font-weight: 100; white-space: pre-wrap;">${obj.str_footer}</p>`;
      }
    });

    return html;
  };

  const html = renderContentList2([
    {
      str: 'GENERAL TERMS\n\n',
      data: [
        {
          str: 'The Client wishes The Company to provide the Survey Report being an opinion of condition of The Subject Property interest as specified by the Client.\n\n'
        },
        {
          str: 'Subject to additional and agreed instructions to the effect, The Surveyor shall provide to The Client an opinion of value within The Survey Report based upon an inspection of The Subject Property by The Surveyor who shall be: a member of the Royal Institution of Chartered Surveyors (RICS), also a member of RICS Valuer Registration Scheme (VRS); and setting out the opinion of value of the relevant interest in the Property\n\n'
        },
        {
          str: 'Any Valuation within the Survey Report will be prepared in accordance with the RICS Valuation – Global Standards effective 31 January 2022, the RICS Valuation – Global Standard 2017: UK National Supplement effective 14 January 2019, and relevant UK VPGAs.\n\n'
        },
        {
          str: 'Introduction: These Terms of Engagement set out the conditions upon which we accept instructions, and will apply in all cases unless any other arrangement is confirmed in writing by The Company.\n\n'
        },
        {
          str: 'Appointment: The Client appoints The Surveyor to provide The Survey Report, exercising reasonable skill and care.\n\n'
        },
        {
          str: 'Performance Standards: The Surveyor confirms that the survey will be carried out in accordance with the best practice guidelines for RICS professionals carrying out a RICS Home Survey Level 2 Report service and the RICS Home Survey Level 3 Report service or other bespoke report.\n\n'
        },
        {
          str: 'Liability and Duty of Care. The Surveyor owes to the Client a duty to act with reasonable skill and care in providing The Survey Report and complying with the Client’s instructions where those instructions do not conflict with these terms or applicable laws and professional rules.\n\nThe Company and The Surveyor have no liability for the consequences, including delay in or failure to provide The Service or The Survey Report, of any failure by The Client or any agent of The Client: promptly to provide information or other material reasonably requested, or where that material is inaccurate or incomplete, or to follow our advice or recommendations.\n\nWe confirm that we hold professional indemnity insurance in respect of The Survey Report to be provided.\n\n\n'
        },
        {
          str: 'The Surveyor. The Survey Report is to be provided by an AssocRICS, MRICS or FRICS qualified member of the Royal Institution of Chartered Surveyors, who has the skills, knowledge and experience to survey and report on the Subject Property.\n\n'
        },
        {
          str: 'Report Delivery. Post inspection, We aim to deliver the report within 5 working days. In an exceptional scenario, the report may take longer. The report will be sent to the Client’s email address (or other agreed email address as applicable). The Company and The Surveyor cannot be held liable for any loss arising due to the delay in Report delivery.\n\n'
        },
        {
          str: 'The Client’s Obligations. The Client shall pay us through Houzecheck in respect of the fee including VAT as detailed in the Instruction Email. The fee is payable at the time of The Company being engaged to perform The Service. Please note that such fee will not be treated by RICS as Client money and will not be subject to the protection of the RICS Client Money Protection Scheme.\n\nThe Client will be responsible for the timely provision of all information and reasonable cooperation, including that from third parties engaged directly by the Client, required by the Company and the Surveyor to assist in the performance of The Service and delivery of The Survey Report. We will assume that the information provided is complete and correct.\n\n'
        }
      ]
    },
    {
      should_start_in_new_page: true,
      str: 'TERMS & CONDITIONS RELATING TO RICS HOME SURVEYS\n\n',
      data: [
        {
          str: 'Scope of the Inspection\n\n',
          data: [
            {
              str: 'Generally, The Surveyor will consider his or her advice carefully, but is not required to advise on any matter the significance of which in relation to The Subject Property is not apparent at the time of inspection from the inspection itself.\n\n'
            },
            {
              str: 'The Surveyor will inspect diligently, but is not required to undertake any action which would risk damage to the Property or injury to themselves.\n\n'
            },
            {
              str: 'The Surveyor will not undertake any structural or other similar calculations.\n\n',
              data: [
                {
                  str: 'Areas not Inspected\n',
                  str2: 'The Surveyor will identify any areas of The Subject Property which could not be adequately accessed or inspected during the inspection (relative to the RICS Home Survey Level 2 Report service and the RICS Home Survey Level 3 Report service standard scope of inspection specification), and as such these details are referred to in The Survey Report.\n\nIn the event that The Surveyor is requested to re-visit The Subject Property to inspect areas that The Surveyor was unable to inspect during the original visit, due to reasons beyond The Surveyor’s control, an additional fee will be payable.\n\n'
                },
                {
                  str: 'Flats or Maisonettes\n',
                  str2: 'Unless otherwise agreed, The Surveyor will inspect only the subject flat and garage (if any), the related internal and external common parts and the structure of the building or particular block in which the subject flat is situated. Other flats will not be inspected. The Surveyor will state in the report the limits of access and/or visibility in relation to the common parts and structure. The Surveyor will state whether he or she has seen a copy of the lease and, if not, the assumptions as to preparing obligations on which he or she is working.\n\n'
                },
                {
                  str: 'Environmental and Other Issues\n',
                  data: [
                    {
                      str: 'Particular noise and disturbance affecting The Subject Property will only be noted if it is significant at the time of the inspection or if specific investigation has been agreed between The Surveyor and The Client and confirmed in writing.\n\n'
                    },
                    {
                      str: 'The Surveyor will report on any obvious health and safety hazards to the extent that they are apparent from elements of The Subject Property considered as part of the inspection.\n\n'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          str: 'Ground Conditions\n',
          str2: 'The Surveyor will not be required to comment upon the possible existence of noxious substances, landfill or mineral extraction, or other forms of contamination.\n\n\n'
        },
        {
          str: 'Consents, Approvals and Searches\n\n',
          data: [
            {
              str: 'The Surveyor will be entitled to assume that The Subject Property is not subject to any unusual or onerous restrictions, obligations or covenants which apply to The Subject Property or affect the reasonable enjoyment of The Subject Property.\n\n'
            },
            {
              str: "The Surveyor will be entitled to assume that all planning, building regulations and other consents required in relation to the Property have been obtained. The Surveyor will not verify whether such consents have been obtained. Any enquiries should be made by the Client or the Client's legal advisers. Drawings and specifications will not be inspected by the Surveyor unless otherwise previously agreed.\n\n"
            },
            {
              str: 'The Surveyor will be entitled to assume that The Subject Property is unaffected by any matters which would be revealed by a Local Search and replies to the usual enquiries, or by a Statutory Notice, and that neither The Subject Property, nor its condition, its use or its intended use, is or will be unlawful.\n\n\n'
            }
          ]
        },
        {
          str: 'Content of the Survey Report\n',
          str2: 'The content of The Survey Report will be as per the RICS standard template for RICS Home Survey Level 2 Report service and the RICS Home Survey Level 3 Report service. The description of the service document “Description of the RICS Home Survey – Level 2 (survey only)”; “Description of the RICS Home Survey – Level 2 (survey and valuation)”; or “Description of the RICS Home Survey – Level 3” have been provided to you with our confirmation of instructions by electronic mail in The Instruction Email. For bespoke reports, the content of the report will be as discussed and agreed with the Surveyor.\n\n\n'
        },
        {
          str: '',
          data: [
            {
              str: 'Before the Inspection\n',
              str2: 'This period forms an important part of the relationship between the Surveyor and the Client. The commissioned Surveyor will use reasonable endeavours to contact the Client to understand particular concerns about the property and explain (where necessary) the extent and/or limitations of the inspection and report.\n\n'
            },
            {
              str: 'After the inspection\n',
              str2: 'The commissioned Surveyor will use reasonable endeavours to contact the Client after the inspection to discuss the major points and issues found. The content of this conversation will not in any way override the content of the report. The report will take precedence in all cases.\n\n'
            }
          ]
        },
        {
          str: 'Limitations\n',
          str2: 'Where advice or opinions are required outside the reasonable professional skill and expertise of the Surveyor, he or she will advise you on the need for further specialist involvement where we consider this to be necessary for the completion of your instruction(s).\n\n\n'
        },
        {
          str: 'Insurance Rebuilding Cost Assessment\n',
          str2: 'The Surveyor will provide an insurance rebuilding cost assessment only if this is agreed at the time of taking instructions. The Reinstatement Cost figure (If requested in the Client instruction) for the permanent buildings is provided for insurance purposes and is not directly related to the market value of the property. The reinstatement cost will be calculated by reference to the indices and guidance published by the Building Costs Information Service (BCIS). The figure stated will only include Value Added Tax on professional fees (not on building costs) and will not take into account other potential or consequential losses such as costs of demolition, site clearance, provision of services, alternative accommodation, personal items or any other contents or contingent costs.\n\n\n'
        },
        {
          str: 'Force Majeure\n',
          str2: 'Whilst every reasonable effort will be made to carry out the inspection at the date/time agreed, we cannot be held liable for any losses caused by matters outside our control, such as, but not exclusively: Surveyor illness; traffic/vehicle delay/breakdown; extreme weather conditions; or vendor unavailability.\n\n\n'
        },
        {
          str: 'Miscellaneous\n',
          data: [
            {
              str: 'Unless expressly provided, no term in the agreement between the Surveyor and the Client is enforceable under the Contracts (Rights of Third Parties) Act 1999 by any person other than the Surveyor or the Client.\n\n'
            },
            {
              str: 'The Surveyor shall, unless otherwise expressly agreed, rely upon information provided to the Company or the Surveyor by the Client or the Client’s legal or other professional advisers relating to relevant matters.\n\n'
            },
            {
              str: 'Any document resulting from a survey or other inspection of a building will not purport to express an opinion about or advise upon the condition of un-inspected or concealed parts and should not be taken as making any implied representation or statements about such parts.\n\n'
            },
            {
              str: 'Where the Client has instructed the Surveyor to make investigations which cause damage to the Subject Property on the basis that the Client has obtained the owner’s consent, the Client will indemnify the Surveyor against any loss or costs or liabilities arising.\n\n'
            },
            {
              str: 'The Client may only rely upon the Surveyor’s advice and report for purposes described in the Instruction Email, particulars of which have been communicated to the Surveyor in writing, and if the Client wishes to rely upon such advice and The Report for any other purpose, The Client may only do so with specific written consent of The Company and The Surveyor. These Terms and Conditions are based on the model Terms and Conditions of Engagement contained in the RICS guidance note.\n\n'
            },
            {
              str: 'External Cladding Panels and Fire Safety – Composite external panels which incorporate insulating material between inner and outer external wall faces such as aluminium composite material (ACM) panels have been extensively used in recent years as external cladding. Some panel cladding systems incorporate combustible insulation materials. The type of insulation and any fire checking of the panel system cannot be identified from a visual inspection. Where the existence of such panels has been reported, the type of insulation and installation is outside the scope of The Survey Report. Other elements of the Property and building will also have a bearing on overall fire safety, including, fire doors, fire stops in wall cavities, escape routes, alarms and fire suppression systems. Such matters are outside the scope of The Survey Report. For buildings of more than two storeys it is essential that the Fire Safety Certificate, (EWS1), is supplied. For buildings of two storey height or less the Surveyor will make the assumption that the cladding system is satisfactory. It is the responsibility of The Client to have any materials tested and if found to be not in compliance, The Client should make all necessary enquiries prior to any legal or financial commitment.\n\n'
            },
            {
              str: 'Asbestos and other hazardous materials: The construction of buildings, fixtures, fittings and plant or equipment may contain hazardous materials. Such materials are impossible to detect without specific tests and are beyond the scope of The Survey Report. No guarantee is given that such materials are not incorporated in any part of the property or fixtures. The service does not include an asbestos inspection and it falls outside The Control of Asbestos Regulations 2012.\n\n'
            }
          ]
        },
        {
          str: 'Limitation of Liability\n',
          str2: 'RICS recommends the use of liability caps to members to manage the risk in valuation and survey work. Our aggregate liability arising out of or in connection with this service, whether arising from negligence, breach of contract or any cause whatsoever, shall in no event exceed 15% of the Market Value of The Subject Property at the date of inspection, or the purchase price in relation to this report (whichever is the lesser). We will not be responsible for the first £2,000.00 of any claim. This clause shall not exclude liability for death or personal injury caused by our negligence.\n\nThe Survey Report is provided for The Clients use only, and we cannot accept responsibility if it is used or relied upon by anyone else, any other or third parties, unless this is expressly agreed by The Company and The Surveyor, in writing beforehand. None of the employees of The Company, partners or consultants, individually, has a contract with you, The Client, or owes you a duty of care or personal responsibility. You agree that you will not bring any claim against any such individuals personally in connection with the Services. For avoidance of doubt, the liability caps set out here also apply to valuations provided as an additional service.\n\n',
          data: [
            {
              str: 'The Company and/or The Surveyor shall under no circumstances whatsoever be liable to the Client, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for loss of profit or any indirect or consequential loss, of any failure by the Client or any agent of the Client: promptly to provide information or other material reasonably requested, or where that material is inaccurate or incomplete, or to follow our advice or recommendations.\n\n'
            },
            {
              str: 'None of the Company’s employees, officers, partners, affiliates or consultants individually has a contract with the Client or owes the Client or the prospective purchaser or vendor or any other party a duty of care or personal responsibility. The Client agrees not to bring any claim against any such individuals personally. To the extent that any part of this notification is a restriction of liability within the meaning of Section 1 of the Unfair Contract Terms Act 1977, it does not apply to death or personal injury resulting from negligence or fraudulent misrepresentation.\n\n'
            },
            {
              str: 'These Terms of Engagement do not include any warranties, conditions and other terms except as stated herein and as required by law. The Survey Report and any associated valuation will be provided after diligent consideration and research, but property values are subject to fluctuation and the valuation process is inexact and thus the Surveyor’s opinion is subject to a degree of tolerance depending on the property and the availability of comparable evidence. Valuations are provided to assist the Client in making a prudent decision; they are not provided as any form of guarantee or warranty of value.\n\n'
            },
            {
              str: 'Where loss is suffered by the Client for which the Company, the Surveyor and/or any other third party are jointly responsible, any recoverable loss by the Client from the Company will be proportionate to the Company’s relative contribution to the loss suffered and subject to the provisions of this clause 10.\n\n'
            },
            {
              str: "Where the Client is composed of more than one entity, then each such entity's liability shall be joint and several.\n\n"
            },
            {
              str: 'The Client acknowledges that the Surveyor shall have no liability for any inaccuracy in the Survey Report or service provision where this is due to an inaccuracy in the Information provided by the Client or third parties.\n\n'
            },
            {
              str: 'The terms implied by Sections 3 to 5 of the Supply of Goods and Services Act 1982 are, to the fullest extent permitted by law, excluded from this Contract and the Survey Report.\n\n'
            },
            {
              str: 'This Clause 10 shall survive termination of this contract.\n\n'
            },
            {
              str: 'The Client’s failure to follow the requirements set out in these Terms and Conditions will invalidate the Survey Report and service provision.\n\n'
            },
            {
              str: 'Nothing in these conditions shall limit or exclude the Company’s liability for:\n\n',
              data: [
                {
                  str: 'Death or personal injury caused by its negligence, or the negligence of its officers, employees, agents, subcontractors or consultants;\n\n'
                },
                { str: 'Fraud or fraudulent misrepresentation.\n\n' }
              ]
            },
            {
              str: 'If the Client has agreed to accept reimbursement of the fee paid, or the Company’s fee account has not been settled within two months of the date of the invoice, then it is agreed that the Client has placed no reliance on the Survey Report (including a valuation produced therein) and thus the Client or any permitted assignees will not be entitled to pursue any action for alleged negligence, breach of contract or breach of duty. This does not limit the Company’s entitlement to the agreed fee.\n\n'
            },
            {
              str: 'All telephone or verbal discussions are prone to misinterpretation or misunderstanding by either party, especially in the interpretation of instructions, legal, technical or descriptive matters; as such no reliance may be placed by the Client upon any such discussions unless agreed outcomes are formally confirmed in writing by an authorised officer of the Company.\n\n\n'
            }
          ]
        }
      ]
    },
    {
      str: 'VALUATIONS PROVIDED AS AN ADDITIONAL SERVICE\n\n',
      str2: 'Where a valuation is to be provided as an additional service, as confirmed with The Client in the Instruction Email, the following terms and conditions will also apply:\n\n',
      data: [
        {
          str: 'Purpose of the Report\n\n',
          data: [
            {
              str: 'The Client wishes the Surveyor to provide within the Survey Report an opinion of the value of the Freehold or Leasehold or Commonhold interest in The Subject Property as specified by the Client.\n\n'
            },
            {
              str: 'The Surveyor shall provide to the Client an opinion of value with the Survey Report (as per 1a above) based upon an inspection of the property by a named individual who shall be:\n\n',
              str_footer:
                'The Valuation within the Survey Report will be prepared in accordance with the RICS Valuation – Global Standards effective 31 January 2022, the RICS Valuation – Global Standard 2017: UK National Supplement effective 14 January 2019, and relevant UK VPGAs.\n\n',
              data: [
                {
                  str: 'a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the membership grades: AssocRICS, MRICS, or FRICS; and\n\n'
                },
                {
                  str: 'a member of the RICS Valuer Registration Scheme (VRS), setting out the Valuer’s opinion of the relevant interest in the property.\n\n'
                }
              ]
            },
            {
              str: 'Confidentiality: The Surveyor’s valuation is provided to the named addressee/s alone and solely for the purpose of the instruction to which it relates. The valuation may not, without The Company’s and Surveyor’s written consent, be used or relied upon by any other or third party, even if that third party pays all or part of the fees, or is permitted to see a copy of the Survey Report. If The Company and The Surveyor provides written consent to a third party relying on the valuation, any such third party is deemed to have accepted the terms of The Company’s engagement and the Surveyor’s engagement, and an additional fee may be payable. Neither the whole nor any part of the Survey Report or any reference to it may be included in any published document, circular or statement nor published in any way without The Company’s and The Surveyor’s written approval of the form and context in which it may appear.\n\n\n'
            },
            {
              str: 'The Surveyor shall have no known interest in The Subject Property or any other conflict that will prevent the Valuation from being undertaken in an independent, objective, and unbiased way.\n\n\n\n'
            },
            {
              str: 'The Market Value reported unless otherwise specifically agreed in writing, shall be in accordance with one or more of the following Valuation bases: Market Value (MV) or Market Rent (MR) detailed at the beginning of these conditions. (See “Definitions”.) The value shall be provided in pounds sterling.\n\n'
            },
            {
              str: 'The Valuation Methodology shall be the Comparable Basis of Methodology unless at the sole discretion of the Surveyor/Valuer and subject to the interest, bearing income, an Investment Methodology may be adopted which will be stated in the Valuation Report.\n\n'
            },
            {
              str: 'Flats – Building Insurance will be for the subject flat only and it will be assumed that the Client’s legal adviser will confirm that the Freeholder or Management company includes the flat in a block buildings insurance policy.\n\n'
            },
            {
              str: 'Access to the property shall be the responsibility of the Client who should inform the Surveyor of any delay or any special arrangements to be followed together with contact information for the occupier, estate agent or other key holder.\n\n'
            },
            {
              str: 'The Client will be informed of the inspection date and time and the report will be available normally within five working days of the inspection or otherwise as agreed with the Client and is subject to all necessary information being made available to the Surveyor or subject to further discovery. Any anticipated delay in gaining access or delay in the report will be notified to the Client.\n\n\n'
            }
          ]
        },
        {
          str: 'Reporting Assumptions and Limitations relating to provision of the Valuation within the Survey Report\n\n',
          data: [
            {
              str: 'The Surveyor will make reasonable assumptions which will be specified in the Survey Report. However, if these assumptions are found to be incorrect please advise us immediately as the valuation may require to be amended to take account of any newly disclosed, amended or discovered facts.\n\n'
            },
            {
              str: 'The Surveyor shall, unless otherwise expressly agreed, rely upon information from the Client or the Client’s legal or other professional advisers. It is the responsibility of the Client to ensure this information is accurate and to promptly advise the Surveyor if it is not.\n\n'
            },
            {
              str: 'External Cladding Panels and Fire Safety – Composite external panels which incorporate insulating material between inner and outer external wall faces such as aluminium composite material (ACM) panels have been extensively used in recent years as external cladding. Some panel cladding systems incorporate combustible insulation materials. The type of insulation and any fire checking of the panel system cannot be identified from a visual inspection. Where the existence of such panels has been reported, the type of insulation and installation is outside the scope of The Survey Report and the Valuation. Other elements of the Property and building will also have a bearing on overall fire safety, including, fire doors, fire stops in wall cavities, escape routes, alarms and fire suppression systems. Such matters are outside the scope of the Valuation. For buildings of more than two storeys it is essential that the Fire Safety Certificate, (EWS1), is supplied. Where the nature of the cladding is considered to be suspect, the Surveyor will report a Zero Value until the form EWS1 in Compliance is supplied. For buildings of two storey height or less the Surveyor will make the assumption that the cladding system is satisfactory. It is the responsibility of The Client to have any materials tested and if found to be not in compliance, the Valuation may then require to be adjusted. The Client should make all necessary enquiries prior to any legal or financial commitment.\n\n'
            },
            {
              str: 'Asbestos and other hazardous materials: The construction of buildings, fixtures, fittings and plant or equipment may contain hazardous materials. Such materials are impossible to detect without specific tests and are beyond the scope of the Survey Report. No guarantee is given that such materials are not incorporated in any part of the property or fixtures. The service does not include an asbestos inspection and it falls outside The Control of Asbestos Regulations 2012.\n\n'
            },
            {
              str: 'The Inspection will take into account gardens, grounds and permanent outbuildings, but will not include constructions or equipment with a specific or leisure purpose, including sauna, swimming pools or tennis courts and associated services and changing rooms. The Inspection will include the immediate gardens that belong to the property, but the Inspection specifically excludes any additional land such as pasture or amenity land.\n\n'
            },
            {
              str: 'Measurement – the Surveyor may measure the property during the inspection or take measurements or areas from plans provided by the Client or obtain such details from specialised database information available to the Surveyor or from publicly available information at the sole discretion of the Surveyor. Outbuildings will not be measured on site unless they are considered by the Surveyor to contribute significantly to the reported capital value or reinstatement cost (if reported).\n\n'
            },
            {
              str: 'The Following Additional Assumptions will be made by the Surveyor in respect of the Property:\n\n',
              data: [
                {
                  str: 'the ground is not affected by the existence of noxious or toxic substances or gases and has not been used for landfill or mineral extraction or other forms of contamination, and does not contain gypsum deposits;\n\n'
                },
                {
                  str: 'no high alumina cement concrete or calcium chloride additive or other potentially deleterious material including ‘Mundic material’ was used in the construction of the property or has since been incorporated;\n\n\n'
                },
                {
                  str: 'the Property is not susceptible to radon gas pollution, invasive or protected species, flood risk, high voltage equipment, cables or coastal erosion, unless specified in the report;\n\n'
                },
                {
                  str: 'the Property is not subject to any unusual or especially onerous restrictions, encumbrances or outgoings, easements or way leaves or rights of way over the property and that good title can be shown;\n\n'
                },
                {
                  str: 'the Property and its value are unaffected by any matters which would be revealed by local or environmental searches, and replies to the usual enquiries, or by any statutory notice, and that neither the property, nor its condition, nor its use, nor its intended use, is or will be unlawful;\n\n'
                },
                {
                  str: 'the inspection of those parts which have not been included in the Inspection in accordance with these Terms and Conditions would neither reveal material defects nor cause the Surveyor to alter the valuation materially;\n\n'
                },
                {
                  str: 'it is assumed that unless otherwise stated, roads, sewers and services outside the curtilage of the Property are the responsibility of the Local Authority or other statutory body. It is further assumed, unless otherwise stated, that all services including drainage are connected and access and roads are unfettered, and in the case of the public highway, maintained at the expense of the Local and Highways Authorities;\n\n'
                },
                {
                  str: 'the Valuation is based on the assumption that the Property is being valued with vacant possession and, unless otherwise stated, for owner occupation; if the property is tenanted the Client is under a duty to inform the Surveyor;\n\n'
                },
                {
                  str: 'that further investigation into Planning Permission, Building Regulations, Permitted Development Rights, extensions and alterations, hazardous materials, onerous restrictions or the like will not reveal anything sufficiently adverse as to materially affect the Valuation;\n\n'
                },
                {
                  str: 'in the case of Leasehold Tenure the Surveyor will assume that there remains a minimum of 85 years on the lease, and the Ground Rent and any Service Charges are reasonable unless otherwise detailed in the report;\n\n'
                },
                {
                  str: 'there are no archaeological remains, Scheduled Ancient Monument or liability to Chancel Repair and the property is not at risk of flooding or likely to suffer damage from unstable ground conditions;\n\n'
                },
                {
                  str: "any other assumptions will be clearly stated in the report; however, the Surveyor shall be under no duty to verify these assumptions and the Client should ensure the assumptions are correct. If any of these assumptions are incorrect then these should be discussed with the Valuer before any financial or legal commitment is made as the Valuer's opinion of value may change;\n\n"
                },
                {
                  str: 'if the Surveyor has not been provided, or cannot obtain, an up-to-date EPC rating for this property, for the purposes of the Energy Act 2011, its valuation will be based on the assumption that the subject property will meet the minimum requirements laid down by legislation and that there will be no adverse impact on value, rental ability and marketability;\n\n'
                },
                {
                  str: 'in the case of a newly constructed property, it has been built under a recognised builder’s warranty or insurance scheme, or has been supervised by a professional consultant capable of fully completing the UK Finance Professional Consultant Certificate;\n\n'
                },
                {
                  str: 'there are no ongoing insurance claims or neighbour disputes and the property is insurable under normal terms.\n\n'
                }
              ]
            },
            {
              str: 'Leasehold Properties\n',
              str2: 'Where The Subject Property is leasehold, data including unexpired lease term, ground rent and any further relevant information should be considered and reflected in the Valuation based upon data that can be sourced on the day of inspection or beforehand from either the vendor or the selling agent as applicable following reasonable investigation. However, the Surveyor clearly cannot give any absolute assurance that, if verbally provided, such data is reliable – verification will be a matter for your legal representatives as appropriate.\n\nWhere The Subject Property is leasehold, and it is not possible to inspect the lease or details have not been provided, the following assumptions will be made, unless instructed to the contrary:\n\n',
              str_footer:
                'For the avoidance of doubt, the Surveyor will not be under a duty to purchase data from third parties or to read the lease documentation. If further information is provided to the Surveyor after the valuation has been completed and submitted, the Surveyor may reconsider the valuation and, if necessary, issue an amended report. But again, for the avoidance of doubt, the Surveyor will not be under any obligation to read the lease document and would only be expected to base a revised valuation on further information provided to the Surveyor by your legal representatives.\n\n\n',
              data: [
                {
                  str: 'The unexpired term of the lease is assumed to be 85 years, and no action is being taken by any eligible party with a view to acquiring the freehold or extending the lease term. RICS Valuation – Global Standards 2022: UK national supplement UK VPGA 11 Valuation for residential mortgage purposes UK Valuation Practice Guidance Applications (UK VPGAs) RICS Valuation – Global Standards 2017: UK national supplement.\n\n'
                },
                {
                  str: 'There are no exceptionally onerous covenants upon the leaseholder.\n\n'
                },
                {
                  str: 'The lease cannot be determined, except on the grounds of a serious breach of covenant in the existing lease agreement.\n\n'
                },
                {
                  str: 'If there are separate freeholders, head and/or other subhead leaseholders, the terms and conditions of all the leases are in the same form and contain the same terms and conditions.\n\n'
                },
                {
                  str: 'The lease terms are mutually enforceable against all parties concerned.\n\n'
                },
                {
                  str: 'There are no breaches of covenant or disputes between the various interests concerned.\n\n'
                },
                {
                  str: 'The leases of all the properties in the building/development are materially the same.\n\n'
                },
                {
                  str: 'The ground rent stated, or assumed, is not subject to unreasonable review and is payable throughout the unexpired lease term.\n\n'
                },
                {
                  str: 'In the case of blocks of flats or maisonettes of over six dwellings, the freeholder manages the property directly, or there is an appropriate management structure in place.\n\n'
                },
                {
                  str: 'There is a duty holder, as defined in the Control of Asbestos Regulations 2012, and there are in place an asbestos register and an effective management plan, which does not require any immediate expenditure, pose a significant risk to health, or breach Health and Safety Executive (HSE) requirements.\n\n'
                },
                {
                  str: 'Where The Subject Property forms part of a mixed residential or commercially used block or development, there will be no significant changes in the existing pattern of use.\n\n'
                },
                {
                  str: 'Where The Subject Property forms part of a development containing separate blocks of dwellings, the lease terms of the property apply only to the block. There will be no requirement to contribute towards costs relating to other parts of the development, other than in respect of common roads, paths, communal grounds and services.\n\n'
                },
                {
                  str: 'Where The Subject Property forms part of a larger development whose ownership has since been divided, all necessary rights and reservations have been reserved.\n\n'
                },
                {
                  str: 'There are no unusual restrictions on assignment or subletting of the property for residential purposes.\n\n'
                },
                {
                  str: 'There are no outstanding claims or litigation concerning the lease of the subject property or any others within the same development.\n\n'
                },
                {
                  str: 'Where The Subject Property benefits from additional facilities within the development, the lease makes adequate provisions for the occupier to continue to enjoy them without exceptional restriction, for the facilities to be maintained adequately and for there being no charges over and above the service charge for such use and maintenance.\n\n'
                }
              ]
            },
            {
              should_start_in_new_page: true,
              str: 'Flats and Maisonettes\n\n',
              str2: 'Where The Subject Property is part of a building comprising flats or maisonettes, the following assumptions will also be made, unless instructed to the contrary: UK VPGA 11 Valuation for residential mortgage purposes, UK Valuation Practice Guidance Applications (UK VPGAs).\n\n\n',
              str3: 'In respect of insurance, the following assumptions will be made, unless instructed to the contrary:\n\n',
              data: [
                {
                  str: 'The costs of repairs and maintenance to the building and grounds are shared equitably between the flats and maisonettes.\n\n'
                },
                {
                  str: 'There are suitable, enforceable covenants between all leaseholds, or through the landlord or the owner.\n\n'
                },
                { str: 'There are no onerous liabilities outstanding.\n\n' },
                {
                  str: 'There are no substantial defects, or other matters requiring expenditure (in excess of the current amount or assumed amount of service charge payable on an annual basis), expected to result in charges to the leaseholder or owner of the subject property during the next five years, that are equivalent to 10% or more of the reported market value.\n\n'
                }
              ],
              data2: [
                {
                  str: 'the property can be insured under all-risks cover for the current reinstatement cost and is available on normal terms;\n\n'
                },
                { str: 'there are no outstanding claims or disputes;\n\n' },
                {
                  str: 'where individuals in a block make separate insurance arrangements, the leases make provision for mutual enforceability of insurance and repairing obligations and;\n\n'
                },
                {
                  str: 'any landlord responsible for insurance is required to rebuild the property with such alterations as may be necessary to comply with current Building Regulations and planning requirements.\n\n\n'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      str: 'COST ESTIMATES PROVIDED AS AN ADDITIONAL SERVICE\n\n',
      str2: 'We can provide cost estimates as an additional service to the Level 3 building survey service, subject to an additional fee.\n\nThese are indicative costs only and the purpose of providing them is to give initial guidance to help you in your purchasing decision. Actual costs may vary depending on economic factors at the time.\n\nCosts estimates may also be provided in bands as follows:\n\nBand A - £1,000 to £2,000\nBand B - £2,000 to £5,000\nBand C - £5,000 to £10,000\nBand D – 0ver £10,000\n\nItems below £1,000 will be deemed as not significant in the context of making a decision to purchase the property.\n\nYou must obtain your own cost estimates in writing from suitable building specialists and/or contractors before exchange of contracts so that you are aware of all costs before you make a legal commitment to purchase. Cost estimates must include VAT and any applicable professional fees. Should you exchange contracts before obtaining estimates from such contractors then you will proceed at your own risk. The Surveyor and Houzecheck Ltd will not be liable for any variation in costs between The Surveyor’s estimates and the actual costs provided by contractors or ultimately by way of expenditure in addressing the issues highlighted by the cost estimates provided.\n\n\n'
    },
    {
      str: 'BUILDINGS INSURANCE REINSTATMENT COST PROVIDED AS AN ADDITIONAL SERVICE\n\n',
      str2: 'The Surveyor can provide a building insurance reinstatement cost as an additional service to the Level 2 homebuyer service, subject to an additional fee.\n\nThe reinstatement cost is the cost of rebuilding a property including demolition of the original building, temporary support of adjoining buildings where applicable, materials, labour, professional fees and Local Authority charges. It includes sums for boundaries, drainage and significant outbuildings. The reinstatement cost will be reported as a single figure, not as a detailed schedule of all costs.\n\nSpecialist installations such as swimming pools are excluded.\n\nThe Surveyor cannot provide reinstatement costs for Listed Buildings as this requires a detailed specialist assessment, which is beyond the scope of the Level 2 service.\n\n'
    }
  ]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold text-center">
        SURVEYOR TERMS AND CONDITIONS OF ENGAGEMENT
      </h1>
      <br />
      <br />
      {renderContentList1([
        {
          title: 'Confirmation of Appointment\n\n',
          desc: 'We refer to your instruction received requiring The Surveyor to carry out and to prepare and deliver a Survey Report on the Subject Property. The Survey Report will be either a RICS Home Survey Level 2 Report (formerly known as an RICS HomeBuyer Report) or a RICS Home Survey Level 3 Report (formerly known as an RICS Building Survey) or a bespoke report as set out in the Instruction Email. The type of report will be specified in the separate Instruction Email for which you will have confirmed acceptance of the Terms of Engagement. We accept the instructions only on the basis that the scope, purpose, and conditions of the report are as set out in the Instruction Email and the Terms and Conditions for the Survey Report.\n\n\n'
        },
        {
          title: 'The Service\n\n',
          desc: 'These Terms and Conditions of Engagement should be referred to and used in conjunction with the Instruction Email, setting out the Client name and Subject Property address, instruction type, reason for instruction and fee/price agreed. The Surveyor details are included in the Instruction email and these Terms as per below.\n\nThe Survey Report requires disclosure of any previous business relationship we may have with you, your property, or your selling agent. If appropriate, disclosure has been made. The scope and extent of the inspection is detailed in the Description of Service, inclusive of the Terms and Conditions. The Survey Report will be prepared by a suitably qualified Surveyor in an objective way regarding the condition of the Property on the date of the inspection, and who is a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the membership grades: AssocRICS, MRICS, or FRICS.\n\nWe are required by RICS to seek formal confirmation from you that you are satisfied that the Survey Report Terms and Conditions meet your requirements, and these will be deemed as accepted by you upon making payment for this Service or services, as stated in the Instruction email by way of timed and date-verified email confirmation.\n\nComplaints: The Company operates a Complaints Handling Procedure in accordance with RICS guidelines. A copy is available upon request.\n\nAny reference to ‘valuation’ or ‘valuations’ in these Terms and Conditions relate to the Valuation element of an instructed Service and Survey Report where an optional additional valuation service has been selected by The Client and has agreed to be undertaken by The Surveyor, for which an additional fee has been agreed with The Client.\n\nThe Survey Report does not include:\n'
        }
      ])}
      {renderUl(['a valuation', 'buildings insurance reinstatement', 'cost estimates\n\n'])}
      <p className="whitespace-pre-wrap text-sm font-thin">
        {
          'These services can be provided as an additional service and are subject to additional terms and conditions and fees.\n\n\n'
        }
      </p>
      <p className="whitespace-pre-wrap text-sm font-bold">{'Definition\n\n'}</p>
      {renderContentList1([
        {
          title: 'The Company/we/us/our:',
          desc: `${companyName}\n\n`
        },
        {
          title: 'The Surveyor:',
          desc: `${name} of ${companyName}\n\n`
        },
        {
          title: 'Houzecheck:',
          desc: 'Our technology service provider.\n\n'
        },
        {
          title: 'The Client/you/your:',
          desc: 'The person or persons detailed in the Instruction email providing instructions for the Service and Survey Report to be provided for their own use. In this context, The Client and the customer have the same meaning.\n\n'
        },
        {
          title: 'Instruction Email:',
          desc: 'The type of report will be specified in the separate Instruction Email for which you will have confirmed acceptance of the Terms of Engagement. This is the email communication which we sent to you via Houzecheck, to set out the details of the Service and Survey Report to be undertaken. We accept the instructions only on the basis that the scope, purpose, and conditions of the report are as set out in the Instruction Email and the Terms and Conditions for the Survey Report.\n\n'
        },
        {
          title: 'Property:',
          desc: 'An interest in a self-contained domestic dwelling as defined by The Town and Country Planning (Use Classes) Order 1987 (as amended) being classified as Use Class C3 which may also include small HMOs within Use Class C4. In the context of the Service and the Property to be inspected, property is also referred to and defined as The Subject Property as specified in the Instruction Email.\n\n'
        },
        {
          title: 'Subject Property:',
          desc: 'The Property interest which is physically inspected in performing the Service and carrying out the Survey Report which is detailed in the Instruction Email.\n\n'
        },
        {
          title: 'Survey Report:',
          desc: 'The report undertaken by the Surveyor, the format of which will follow a template as set out in the attached documents “Description of the RICS Home Survey – Level 2 (survey only)”; “Description of the RICS Home Survey – Level 2 (survey and valuation)”; or “Description of the RICS Home Survey – Level 3”, as applicable. There is no set template for bespoke reports.\n\n'
        },
        {
          title: 'Description of Service:',
          desc: 'The Royal Institution of Chartered Surveyors (RICS) publication documents which describe the extent of service for the RICS Home Survey Level 2 Report service and the RICS Home Survey Level 3 Report service.\n\n'
        },
        {
          title: 'Valuation:',
          desc: 'Unless otherwise stated in the report, the valuation, where provided as an additional service, will be for the interest in The Subject Property, of its existing tenure and title, use and form. The Valuation will, as an additional service, therefore form part of the Survey Report.\n\n'
        },
        {
          title: 'Valuation Date:',
          desc: 'The Valuation Date shall be the Inspection Date of The Subject Property unless otherwise stipulated in the report.\n\n'
        },
        {
          title: 'The Service:',
          desc: 'The Service provided by the Company to provide The Client with a Survey Report which may include Valuation advice as an additional service.\n\n'
        },
        {
          title: 'Market Value (MV):',
          desc: 'The estimated amount for which an asset or liability should exchange on the valuation date between a willing buyer and a willing seller in an arm’s length transaction after proper marketing and where the parties had each acted knowledgeably, prudently and without compulsion.\n\n'
        },
        {
          title:
            'Statutory Definition of Market Value (capital gains tax, inheritance tax and stamp duty land tax).',
          desc: 'Summary definition derived from legislation: ‘The price which the property might reasonably be expected to fetch if sold in the open market at that time, but that price shall not be assumed to be reduced on the grounds that the whole property is to be placed on the market at one and the same time.’ (Source: section 272 Taxation and Chargeable Gains Act 1992. Section 160 Inheritance Tax Act 1984, Section 118 Finance Act 2003).\n\n'
        },
        {
          title: 'Market Rent (MR):',
          desc: 'The estimated amount for which an interest in real property should be leased on the valuation date between a willing lessor and a willing lessee on appropriate lease terms in an arm’s length transaction, after proper marketing and where the parties had each acted knowledgeably, prudently and without compulsion.\n\nOur assumptions of what are ‘appropriate lease terms’ for this property will be set out in our report.\n\n'
        },
        {
          title: 'RICS Monitoring:',
          desc: 'The Surveyor is regulated by the Royal Institution of Chartered Surveyors, (RICS).\n\nPlease note that our files may be subject to monitoring under the Institution’s conduct and disciplinary regulations and will be provided to RICS on request.\n\n\n'
        }
      ])}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}

export default SURVEYTNCPAGE;
