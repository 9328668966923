import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import reportWebVitals from './reportWebVitals';

// GLOBAL CSS
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-notifications/lib/notifications.css';

// REDUS STORE
import store from './redux/store';
import App from './App';

// eslint-disable-next-line no-unused-vars
const checkAndRunLocalStorageMigration = () => {
  const localStorageDataExists = localStorage.getItem('leadData') === null;
  if (localStorageDataExists) {
    return;
  }
  const currentLocalstorageVersion = 1;
  const oldLocalStorage = JSON.parse(localStorage.getItem('leadData'));
  console.log(oldLocalStorage.productData.version, 'versiooooooooooooooon', oldLocalStorage);
  if (oldLocalStorage.productData.version === currentLocalstorageVersion) {
    return;
  }
  console.log('helllllo');
  const updatedOldStorage = {
    loading: oldLocalStorage.loading,
    currentStep: oldLocalStorage.currentStep,
    leadDetail: oldLocalStorage.leadDetail,
    productData: {
      ...oldLocalStorage.productData,
      quoteData: oldLocalStorage.quoteData,
      appointment: oldLocalStorage.appointment,
      callbackStatus: oldLocalStorage.callbackStatus,
      valuation: oldLocalStorage.valuation,
      version: currentLocalstorageVersion
    },
    accessDetail: oldLocalStorage.accessDetail,
    reportDetail: oldLocalStorage.reportDetail,
    errorType: oldLocalStorage.errorType
  };
  localStorage.setItem('leadData', JSON.stringify(updatedOldStorage));
};
// checkAndRunLocalStorageMigration();
localStorage.removeItem('leadData');
// Main component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <React.StrictMode>
      <PayPalScriptProvider
        options={{
          'client-id':
            // 'AW6nl4v7Ld9CRK2HtcbL06-EDbt2stKRJ00bHigqEo15QM2UuipLVXg-w3g2sxYWid5XyeC1CFPhzGVG',
            process.env.REACT_APP_PAYPAL_CLIENTID,
          // 'buyer-country': 'GB',
          currency: 'GBP',
          'enable-funding': 'paylater',
          locale: 'en_GB'
        }}>
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </PayPalScriptProvider>
    </React.StrictMode>
    <NotificationContainer />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
