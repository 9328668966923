import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ThankYouPage from '../../components/ThankYouPage';
// import AccessDetail from '../../components/AccessDetail';
// import ReportDetail from '../../components/ReportDetail';

function Confirmation() {
  const navigate = useNavigate();
  const leadData = useSelector((state) => state.lead);
  const { refId } = useParams();
  const redirectPage = (route) => {
    if (refId) {
      navigate(`/${refId}${route}`);
    } else {
      navigate(route);
    }
  };
  useEffect(() => {
    if (leadData.productData?.currentStep === 1) {
      redirectPage('');
    } else if (
      leadData.productData?.currentStep === 2 ||
      leadData.productData?.currentStep === 3 ||
      leadData.productData?.currentStep === 4 ||
      leadData.productData?.currentStep === 5
    ) {
      navigate('product');
    } else if (leadData.productData?.currentStep === 6) {
      navigate('quote');
    } else if (leadData.productData?.currentStep === 7) {
      navigate('payment');
    } else if (leadData.productData?.currentStep === 9) {
      navigate('access-details');
    } else if (leadData.productData?.currentStep === 10) {
      navigate('report-details');
    }
  }, [leadData.productData?.currentStep]);
  return (
    <div>
      {leadData.productData?.currentStep === 8 && <ThankYouPage />}
      {/* {leadData.currentStep === 9 && <AccessDetail />} */}
      {/* {leadData.currentStep === 10 && <ReportDetail />} */}
      {leadData.productData?.currentStep === 11 && <ThankYouPage />}
    </div>
  );
}

export default Confirmation;
