/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../Container';
import FormControl from '../FormControl';
// import { updateLead } from '../../redux/features/lead-slice';
import FormSubmitButtons from '../FormSubmitButtons';
import { updateLeadAction, updateStepAction } from '../../redux/actions/lead-actions';
import {
  generalYesNoAndNotSure,
  commonFieldErr,
  allConstantVals,
  iDontKnowWaring
} from '../../Constants';
import FieldsErr from '../FieldsErr';
import CautionComponent from '../CautionComponent';

function FormFour() {
  const { i_dont_know, stage_property_additional } = allConstantVals;
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const { productData } = leadData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initialValues = {
    historic_timber_frame_or_beams: productData?.historic_timber_frame_or_beams || '',
    mordern_timber_frame: productData?.mordern_timber_frame || '',
    steel_frame: productData?.steel_frame || '',
    concrete_property: productData?.concrete_property || '',
    'ex-council_timber_frame': productData['ex-council_timber_frame'] || '',
    thatched_roof: productData?.thatched_roof || ''
  };
  const validationSchema = Yup.object({
    historic_timber_frame_or_beams: Yup.string().required(commonFieldErr),
    mordern_timber_frame: Yup.string().required(commonFieldErr),
    steel_frame: Yup.string().required(commonFieldErr),
    concrete_property: Yup.string().required(commonFieldErr),
    'ex-council_timber_frame': Yup.string().required(commonFieldErr),
    thatched_roof: Yup.string().required(commonFieldErr)
  });

  const fieldLabels = {
    historic_timber_frame_or_beams: 'Historic timber frame/beams?',
    mordern_timber_frame: 'Modern timber frame?',
    steel_frame: 'Steel frame?',
    concrete_property: 'Concrete Property?',
    'ex-council_timber_frame': 'Ex-council Timber Frame?',
    thatched_roof: 'Thatched Roof?'
  };
  const onSubmit = (values) => {
    const updatedLeadData = {
      ...leadData,
      productData: {
        ...leadData.productData,
        ...values,
        web_stage: stage_property_additional.value,
        currentStep: 5
      }
    };
    dispatch(updateLeadAction(updatedLeadData));
  };
  return (
    <Container>
      <div className="flex justify-between items-center">
        <p className="font-bold text-3xl md:text-4xl inline-block">Property details</p>

        <div className="flex">
          <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
          <div className="w-4 h-4 border-2 border-primary-500 bg-primary-500 rounded-full mx-1" />
          <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
        </div>
      </div>
      <p>Does the property has any of the following non-standard construction</p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ values, errors, touched }) => (
          <Form>
            <FormControl
              controlType="radio"
              label={fieldLabels.historic_timber_frame_or_beams}
              name="historic_timber_frame_or_beams"
              optionVals={generalYesNoAndNotSure}
              radioType="type3"
            />
            {values.historic_timber_frame_or_beams === i_dont_know.value && (
              <CautionComponent type="info" text={iDontKnowWaring} />
            )}
            <FormControl
              controlType="radio"
              label={fieldLabels.mordern_timber_frame}
              name="mordern_timber_frame"
              optionVals={generalYesNoAndNotSure}
              radioType="type3"
            />
            {values.mordern_timber_frame === i_dont_know.value && (
              <CautionComponent type="info" text={iDontKnowWaring} />
            )}
            <FormControl
              controlType="radio"
              label={fieldLabels.steel_frame}
              name="steel_frame"
              optionVals={generalYesNoAndNotSure}
              radioType="type3"
            />
            {values.steel_frame === i_dont_know.value && (
              <CautionComponent type="info" text={iDontKnowWaring} />
            )}
            <FormControl
              controlType="radio"
              label={fieldLabels.concrete_property}
              name="concrete_property"
              optionVals={generalYesNoAndNotSure}
              radioType="type3"
            />
            {values.concrete_property === i_dont_know.value && (
              <CautionComponent type="info" text={iDontKnowWaring} />
            )}
            <FormControl
              controlType="radio"
              label={fieldLabels['ex-council_timber_frame']}
              name="ex-council_timber_frame"
              optionVals={generalYesNoAndNotSure}
              radioType="type3"
            />
            {values['ex-council_timber_frame'] === i_dont_know.value && (
              <CautionComponent type="info" text={iDontKnowWaring} />
            )}
            <FormControl
              controlType="radio"
              label={fieldLabels.thatched_roof}
              name="thatched_roof"
              optionVals={generalYesNoAndNotSure}
              radioType="type3"
            />
            {values.thatched_roof === i_dont_know.value && (
              <CautionComponent type="info" text={iDontKnowWaring} />
            )}
            <FieldsErr fieldLabels={fieldLabels} errors={errors} touched={touched} />
            <FormSubmitButtons backBtnHandler={() => dispatch(updateStepAction(3))} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default FormFour;
