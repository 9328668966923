/* eslint-disable camelcase */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import Button from '../Button';
import Container from '../Container';

import { updateStepAction } from '../../redux/actions/lead-actions';
import FormSubmitButtons from '../FormSubmitButtons';
import { allConstantVals, yearsLeftOnLease } from '../../Constants';

function ThankyouPage() {
  const {
    valuationJobType,
    otherJobType,
    surveyJobType,
    level2Name,
    // matrimonial,
    before_1850,
    market_valuation,
    any_other_reason,
    leasehold,
    yes,
    no,
    b2,
    a3
  } = allConstantVals;
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const {
    productData: { quoteData }
  } = leadData;
  // const { jobNumber = '', dateSelected = '' } = quoteData;
  const conditionOne =
    leadData?.productData?.job_type === otherJobType.value ||
    (leadData?.productData?.job_type === valuationJobType.value &&
      leadData?.productData?.val_reason === any_other_reason.value) ||
    ((leadData?.productData?.val_reason === market_valuation.value ||
      leadData?.productData?.val_reason === any_other_reason.value) &&
      (leadData?.productData?.val_for_lending === yes.value ||
        leadData?.productData?.requirement_expert_witness === yes.value));
  const valConditn =
    (leadData?.productData?.freehold_or_lease === leasehold.value &&
      leadData?.productData?.years_left_on_lease === yearsLeftOnLease[2].value) ||
    leadData?.productData.appointment === false ||
    leadData?.productData?.job_type === otherJobType.value ||
    (leadData?.productData?.job_type === valuationJobType.value &&
      leadData?.productData?.val_reason === any_other_reason.value) ||
    (leadData?.productData?.flat_4_storey_or_above === yes.value &&
      leadData?.productData?.have_ews_form === no.value) ||
    (leadData?.productData?.flat_4_storey_or_above === yes.value &&
      leadData?.productData?.have_ews_form === yes.value &&
      (leadData?.productData?.ews_rating === b2.value ||
        leadData?.productData?.ews_rating === a3.value)) ||
    ((leadData?.productData?.val_reason === market_valuation.value ||
      leadData?.productData?.val_reason === any_other_reason.value) &&
      (leadData?.productData?.val_for_lending === yes.value ||
        leadData?.productData?.requirement_expert_witness === yes.value));

  const conditnTwo =
    leadData?.productData?.callbackStatus === true ||
    (leadData?.productData?.job_type === surveyJobType.value &&
      leadData?.productData?.survey_type === level2Name.value &&
      (leadData?.productData?.build_year_l2 === before_1850.value ||
        leadData?.productData?.non_standard_prop === yes.value ||
        leadData?.productData?.had_alter_in_prop === yes.value ||
        leadData?.productData?.had_prop_extented === yes.value ||
        leadData?.productData?.plan_alter_in_prop === yes.value ||
        leadData?.productData?.is_prop_condtn_poor === yes.value));
  // return valConditn ? (
  //   <Container noback>
  //     <p className="text-center text-4xl font-bold">Thanks for your enquiry.</p>
  //     {/* <p className="text-center">One of our advisors will be in touch shortly.</p> */}
  //     <p className="mt-8 text-xl text-center">
  //       Unfortunately, we don&apos;t currently offer the service you are looking for
  //     </p>
  //     <div className="w-full md:w-11/12 mx-auto bg-primary-50 px-1 md:px-7 rounded-2xl mt-8 pb-8">
  //       <p className="py-8 text-primary-800 font-bold text-center">
  //         Currently, we offer the following services:
  //       </p>
  //       <div className="flex flex-col mx-8 pl-6">
  //         <div className="relative">
  //           <div className="pb-8 px-2 md:px-4">
  //             <p className="font-bold text-xl">RICS Surveys</p>
  //             <p>RICS Level 2 (Homebuyer Survey) and RICS Level 3 (Building Survey).</p>
  //             {/* {leadData.currentStep === 8 && (
  //             <Button customClass="mt-4" onClickHandler={() => dispatch(updateStepAction(9))}>
  //               Add access details
  //             </Button>
  //           )} */}
  //           </div>
  //         </div>
  //         <div className="relative">
  //           <div className="pb-8 px-2 md:px-4">
  //             <p className="font-bold text-xl">RICS Valuations</p>
  //             <p>
  //               Help-to-Buy, Shared Ownership, Probate , Capital Gain, Right-to-buy and any other
  //               market valuation for non-lending purposes that do not require to appear as expert
  //               witness.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <p className="mt-8 text-xl text-center">
  //       Please call us on <strong>0330 113 1133</strong> in case you require any further assistance.
  //     </p>
  //     <FormSubmitButtons backBtnHandler={() => dispatch(updateStepAction(2))} nocontinue />
  //   </Container>
  // ) : (
  //   <Container noback>
  //     <p className="text-center text-4xl font-bold">Thanks for your order</p>
  //     <p className="text-center">You’ve successfully booked a Houzecheck surveyor</p>
  //     <p className="mt-8 text-xl text-center">
  //       Your booking id is <span className="font-bold">{jobNumber}</span>.
  //       <br />
  //       We’ll send you a confirmation email soon.
  //     </p>
  //     <div className="w-full md:w-11/12 mx-auto bg-primary-50 px-1 md:px-7 rounded-2xl mt-8 pb-8">
  //       <p className="py-8 text-primary-800 font-bold text-center">What&apos;s next?</p>
  //       <div className="flex flex-col mx-8 border-l-2 border-primary-500 pl-6">
  //         <div className="relative">
  //           <div className="absolute left-[-42px] text-white border border-primary-500 w-8 h-8 bg-primary-500 text-center rounded-full">
  //             <p
  //               className={`absolute top-[15%] ${
  //                 leadData.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
  //               }`}>
  //               {leadData.currentStep === 8 ? '1' : '✔'}
  //             </p>
  //           </div>
  //           <div className="pb-8 px-2 md:px-4">
  //             <p className="font-bold text-xl">Add access details</p>
  //             <p>
  //               We need a some important information to access the property. Please add them so
  //               surveyor can visit your property
  //             </p>
  //             {leadData.currentStep === 8 && (
  //               <Button customClass="mt-4" onClickHandler={() => dispatch(updateStepAction(9))}>
  //                 Add access details
  //               </Button>
  //             )}
  //           </div>
  //         </div>
  //         <div className="relative">
  //           <div
  //             className={`absolute left-[-42px] ${
  //               leadData.currentStep === 8
  //                 ? 'text-primary-500 bg-white'
  //                 : 'bg-primary-500 text-white'
  //             } border border-primary-500 w-8 h-8 text-center rounded-full`}>
  //             <p
  //               className={`absolute top-[15%] ${
  //                 leadData.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
  //               }`}>
  //               {leadData.currentStep === 8 ? '2' : '✔'}
  //             </p>
  //           </div>
  //           <div className="pb-8 px-2 md:px-4">
  //             <p className="font-bold text-xl">Surveyor visit</p>
  //             <p>
  //               The surveyor is booked to inspect the property on {dateSelected}. Where applicable,
  //               the inspection date may change subject to confirmation of property’s access with the
  //               concerned party.
  //             </p>
  //           </div>
  //         </div>
  //         <div className="relative">
  //           <div
  //             className={`absolute left-[-42px] ${
  //               leadData.currentStep === 8
  //                 ? 'text-primary-500 bg-white'
  //                 : 'bg-primary-500 text-white'
  //             } border border-primary-500 w-8 h-8 text-center rounded-full`}>
  //             <p
  //               className={`absolute top-[15%] ${
  //                 leadData.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
  //               }`}>
  //               {leadData.currentStep === 8 ? '3' : '✔'}
  //             </p>
  //           </div>
  //           <div className="px-2 md:px-4">
  //             <p className="font-bold text-xl">Report sent</p>
  //             <p>
  //               Report should be delivered within 5 working days of the inspection date stated
  //               above. The report will be delivered by email provided at the time of booking.
  //               Majority of our reports are delivered before the scheduled date but may get delayed
  //               under exceptional circumstances.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </Container>
  // );
  if (conditionOne) {
    return (
      <Container noback>
        <p className="text-center text-4xl font-bold">Thank you for your enquiry. </p>
        <p className="text-center mt-4">
          One of our advisors will be in touch shortly to discuss further.
        </p>
        {/* <p className="mt-6 text-xl text-center"></p> */}
        <div className="w-full md:w-11/12 mx-auto bg-primary-50 px-1 md:px-7 rounded-2xl mt-8 pb-8">
          <p className="py-8 text-primary-800 font-bold text-center">What happens next?</p>
          <div className="flex flex-col mx-8 border-l-2 border-primary-500 pl-6">
            <div className="relative">
              <div className="absolute left-[-42px] text-white border border-primary-500 w-8 h-8 bg-primary-500 text-center rounded-full">
                <p
                  className={`absolute top-[15%] ${
                    leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                  }`}>
                  {leadData.productData?.currentStep === 8 ? '1' : '✔'}
                </p>
              </div>
              <div className="pb-8 px-2 md:px-4">
                <p className="font-bold text-xl">Confirmation</p>
                <p>
                  One of our advisors will call you within 2 working hours to confirm the cost and
                  surveyor availability.
                </p>
                {/* {leadData.currentStep === 8 && (
              <Button customClass="mt-4" onClickHandler={() => dispatch(updateStepAction(9))}>
                Add access details
              </Button>
            )} */}
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute left-[-42px] ${
                  leadData.productData?.currentStep === 8
                    ? 'text-primary-500 bg-white'
                    : 'bg-primary-500 text-white'
                } border border-primary-500 w-8 h-8 text-center rounded-full`}>
                <p
                  className={`absolute top-[15%] ${
                    leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                  }`}>
                  {leadData.productData?.currentStep === 8 ? '2' : '✔'}
                </p>
              </div>
              <div className="pb-8 px-2 md:px-4">
                <p className="font-bold text-xl">Surveyor Visit</p>
                <p>90% of our survey visits are carried out within 1 week of your enquiry.</p>
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute left-[-42px] ${
                  leadData.productData?.currentStep === 8
                    ? 'text-primary-500 bg-white'
                    : 'bg-primary-500 text-white'
                } border border-primary-500 w-8 h-8 text-center rounded-full`}>
                <p
                  className={`absolute top-[15%] ${
                    leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                  }`}>
                  {leadData.productData?.currentStep === 8 ? '3' : '✔'}
                </p>
              </div>
              <div className="px-2 md:px-4">
                <p className="font-bold text-xl">Report</p>
                <p>
                  After the survey, 99% of our reports are sent within 5 working days, most of them
                  a lot sooner due to Houzecheck&apos;s cutting edge technology.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FormSubmitButtons
          backBtnHandler={() =>
            dispatch(updateStepAction(leadData.productData?.callbackStatus === true ? 6 : 2))
          }
          nocontinue
        />
      </Container>
    );
  }
  if (valConditn) {
    return (
      <Container noback showBottomTxt={false}>
        <p className="text-center text-4xl font-bold">Thanks for your enquiry.</p>
        {/* <p className="text-center">One of our advisors will be in touch shortly.</p> */}
        <p className="mt-8 text-xl text-center">
          {leadData?.productData.appointment === false
            ? `We have very limited availability in this postcode within next 2 weeks.`
            : `Unfortunately, we don't currently offer the service you are looking for`}
        </p>
        <div className="w-full md:w-11/12 mx-auto bg-primary-50 px-1 md:px-7 rounded-2xl mt-8 pb-8">
          {leadData?.productData.appointment === false ? (
            <p className="py-8 text-primary-800 font-bold mb-0">
              We always aim to book a job as soon as possible. It appears all our surveyors covering
              this postcode are either unavailable or working at the full capacity.
              <br />
              <br />
              One of our team members will call you to try book you a Surveyor as soon as possible.
            </p>
          ) : (
            <>
              <p className="py-8 text-primary-800 font-bold text-center">
                Currently, we offer the following services:
              </p>
              <div className="flex flex-col mx-8 pl-6">
                <div className="relative">
                  <div className="pb-8 px-2 md:px-4">
                    <p className="font-bold text-xl">RICS Surveys</p>
                    <p>RICS Level 2 (Homebuyer Survey) and RICS Level 3 (Building Survey).</p>
                    {/* {leadData.currentStep === 8 && (
              <Button customClass="mt-4" onClickHandler={() => dispatch(updateStepAction(9))}>
                Add access details
              </Button>
            )} */}
                  </div>
                </div>
                <div className="relative">
                  <div className="pb-8 px-2 md:px-4">
                    <p className="font-bold text-xl">RICS Valuations</p>
                    <p>
                      Help-to-Buy, Shared Ownership, Probate , Capital Gain, Right-to-buy and any
                      other market valuation for non-lending purposes that do not require to appear
                      as expert witness.
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <p className="mt-8 text-xl text-center">
          Please call us on{' '}
          <a href="tel:03301131133">
            <strong>0330 113 1133</strong>
          </a>{' '}
          if you require any further assistance.
        </p>
        <FormSubmitButtons backBtnHandler={() => dispatch(updateStepAction(2))} nocontinue />
      </Container>
    );
  }
  if (
    conditnTwo ||
    leadData?.productData?.asking_price === allConstantVals['2000000_or_more'].value ||
    leadData?.productData?.bedrooms === allConstantVals['7'].value
  ) {
    return (
      <Container noback>
        <p className="text-center text-4xl font-bold">Thank you for your enquiry. </p>
        <p className="text-center mt-4">
          One of our advisors will be in touch shortly to discuss further.
        </p>
        <p className="mt-6 text-xl text-center">
          {/* Your booking id is <span className="font-bold">{jobNumber}</span>. */}
        </p>
        <div className="w-full md:w-11/12 mx-auto bg-primary-50 px-1 md:px-7 rounded-2xl mt-8 pb-8">
          <p className="py-8 text-primary-800 font-bold text-center">What happens next?</p>
          <div className="flex flex-col mx-8 border-l-2 border-primary-500 pl-6">
            <div className="relative">
              <div className="absolute left-[-42px] text-white border border-primary-500 w-8 h-8 bg-primary-500 text-center rounded-full">
                <p
                  className={`absolute top-[15%] ${
                    leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                  }`}>
                  {leadData.productData?.currentStep === 8 ? '1' : '✔'}
                </p>
              </div>
              <div className="pb-8 px-2 md:px-4">
                <p className="font-bold text-xl">Confirmation</p>
                <p>
                  One of our advisors will call you within 2 working hours to confirm the cost and
                  surveyor availability.
                </p>
                {/* {leadData.currentStep === 8 && (
                <Button customClass="mt-4" onClickHandler={() => dispatch(updateStepAction(9))}>
                  Add access details
                </Button>
              )} */}
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute left-[-42px] ${
                  leadData.productData?.currentStep === 8
                    ? 'text-primary-500 bg-white'
                    : 'bg-primary-500 text-white'
                } border border-primary-500 w-8 h-8 text-center rounded-full`}>
                <p
                  className={`absolute top-[15%] ${
                    leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                  }`}>
                  {leadData.productData?.currentStep === 8 ? '2' : '✔'}
                </p>
              </div>
              <div className="pb-8 px-2 md:px-4">
                <p className="font-bold text-xl">Surveyor Visit</p>
                <p>90% of our survey visits are carried out within 1 week of your enquiry.</p>
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute left-[-42px] ${
                  leadData.productData?.currentStep === 8
                    ? 'text-primary-500 bg-white'
                    : 'bg-primary-500 text-white'
                } border border-primary-500 w-8 h-8 text-center rounded-full`}>
                <p
                  className={`absolute top-[15%] ${
                    leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                  }`}>
                  {leadData.productData?.currentStep === 8 ? '3' : '✔'}
                </p>
              </div>
              <div className="px-2 md:px-4">
                <p className="font-bold text-xl">Report</p>
                <p>
                  After the survey, 99% of our reports are sent within 5 working days, most of them
                  a lot sooner due to Houzecheck&apos;s cutting edge technology.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FormSubmitButtons
          backBtnHandler={() =>
            dispatch(updateStepAction(leadData.productData?.callbackStatus === true ? 6 : 2))
          }
          nocontinue
        />
      </Container>
    );
  }
  console.log('final else');
  return (
    <Container noback showBottomTxt={false}>
      {leadData.productData.u_show_online === 'false' ? (
        <>
          <p className="text-center text-4xl font-bold">Thank you for your enquiry.</p>
          <p className="text-center">One of our advisors will be in touch shortly.</p>
        </>
      ) : (
        <>
          {!leadData?.productData?.appointment ? (
            <p className="mt-8 text-xl text-center">
              We have very limited availability in this postcode within next 2 weeks.
            </p>
          ) : (
            <>
              <p className="text-center text-4xl font-bold">Thanks for your order</p>
              <p className="text-center">You’ve successfully booked a Houzecheck surveyor</p>
              <p className="mt-8 text-xl text-center">
                Your booking id is <span className="font-bold">{quoteData?.jobNumber}</span>.
                <br />
                We’ll send you a confirmation email soon.
              </p>
            </>
          )}
          <p className="hidden">.</p>
        </>
      )}
      <div className="w-full md:w-11/12 mx-auto bg-primary-50 px-1 md:px-7 rounded-2xl mt-8 pb-8">
        {leadData.productData.u_show_online === 'true' && !leadData?.productData.appointment ? (
          <p className="py-8 text-primary-800 font-bold mb-0">
            We always aim to book a job as soon as possible. It appears all our surveyors covering
            this postcode are either unavailable or working at the full capacity.
            <br />
            <br />
            One of our team members will call you to try book you a Surveyor as soon as possible.
          </p>
        ) : (
          <>
            <p className="py-8 text-primary-800 font-bold text-center">What&apos;s next?</p>
            <div className="flex flex-col mx-8 border-l-2 border-primary-500 pl-6">
              <div className="relative">
                <div className="absolute left-[-42px] text-white border border-primary-500 w-8 h-8 bg-primary-500 text-center rounded-full">
                  <p
                    className={`absolute top-[15%] ${
                      leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                    }`}>
                    {leadData.productData?.currentStep === 8 ? '1' : '✔'}
                  </p>
                </div>
                <div className="pb-8 px-2 md:px-4">
                  {leadData.productData.u_show_online === 'false' ? (
                    <>
                      <p className="font-bold text-xl">Confirmation</p>
                      <p>
                        One of our advisors will call you within 2 working hours to confirm the cost
                        and surveyor availability.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="font-bold text-xl">Add access details</p>
                      <p>
                        Our customer service team will coordinate with you, the estate agent and the
                        vendor to arrange access to the property.
                      </p>
                    </>
                  )}
                  {/* TODO: HERE */}
                  {/* {leadData.currentStep === 8 && (
                <Button customClass="mt-4" onClickHandler={() => dispatch(updateStepAction(9))}>
                  Add access details
                </Button>
              )} */}
                </div>
              </div>
              <div className="relative">
                <div
                  className={`absolute left-[-42px] ${
                    leadData.productData?.currentStep === 8
                      ? 'text-primary-500 bg-white'
                      : 'bg-primary-500 text-white'
                  } border border-primary-500 w-8 h-8 text-center rounded-full`}>
                  <p
                    className={`absolute top-[15%] ${
                      leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                    }`}>
                    {leadData.productData?.currentStep === 8 ? '2' : '✔'}
                  </p>
                </div>
                <div className="pb-8 px-2 md:px-4">
                  <p className="font-bold text-xl">Surveyor visit</p>
                  {leadData.productData.u_show_online === 'false' ? (
                    <p>90% of our survey visits are carried out within 1 week of your enquiry.</p>
                  ) : (
                    <p>
                      The surveyor is booked to inspect the property on {quoteData?.dateSelected}.
                      Where applicable, the inspection date may change subject to confirmation of
                      property’s access with the concerned party.
                    </p>
                  )}
                </div>
              </div>
              <div className="relative">
                <div
                  className={`absolute left-[-42px] ${
                    leadData.productData?.currentStep === 8
                      ? 'text-primary-500 bg-white'
                      : 'bg-primary-500 text-white'
                  } border border-primary-500 w-8 h-8 text-center rounded-full`}>
                  <p
                    className={`absolute top-[15%] ${
                      leadData.productData?.currentStep === 8 ? 'left-[11.5px]' : 'left-[8.5px]'
                    }`}>
                    {leadData.productData?.currentStep === 8 ? '3' : '✔'}
                  </p>
                </div>
                <div className="px-2 md:px-4">
                  <p className="font-bold text-xl">Report sent</p>
                  {leadData.productData.u_show_online === 'false' ? (
                    <p>
                      After the survey, 99% of our reports are sent within 5 working days, most of
                      them a lot sooner due to Houzecheck&apos;s cutting edge technology.
                    </p>
                  ) : (
                    <p>
                      Report should be delivered within 5 working days of the inspection date stated
                      above. The report will be delivered by email provided at the time of booking.
                      Majority of our reports are delivered before the scheduled date but may be
                      delayed under exceptional circumstances.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <p className="mt-8 text-xl text-center">
        Please call us on{' '}
        <a href="tel:03301131133">
          <strong>0330 113 1133</strong>
        </a>{' '}
        if you require any further assistance.
      </p>
    </Container>
  );
}

export default ThankyouPage;
