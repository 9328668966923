import React from 'react';

function Button({ btnType, submit, children, customClass, onClickHandler, loading }) {
  const btnTypeClass = {
    defaultFilledBtn: 'bg-primary-600 text-white py-4 px-8 rounded-xl',
    defaultOutlineBtn:
      'bg-transparent text-primary-600 border border-primary-600 py-4 px-8 rounded-xl'
  };
  return (
    <button
      className={`${btnTypeClass[btnType]} ${customClass} ${loading ? 'bg-secondary-400' : ''}`}
      type={submit ? 'submit' : 'button'}
      onClick={onClickHandler}
      disabled={loading}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  btnType: 'defaultFilledBtn',
  submit: false,
  customClass: ''
};

export default Button;
