import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';

function FormSubmitButtons({ backBtnHandler, noback, nocontinue, continueTxt }) {
  const navigate = useNavigate();
  const leadData = useSelector((state) => state.lead);

  const goBack = () => {
    if (backBtnHandler) {
      console.log('from back');
      backBtnHandler();
    } else {
      navigate(-1);
    }
  };

  return (
    // <div className="py-6 fixed md:static md:flex md:justify-between bottom-0 bg-white bg-opacity-70 w-full right-0 text-center">
    <div className="py-6 flex justify-between bottom-0 w-full right-0 text-center">
      {!noback && (
        <Button btnType="defaultOutlineBtn" customClass="mr-auto" onClickHandler={goBack}>
          Go back
        </Button>
      )}
      {!nocontinue && (
        <Button submit loading={leadData.loading} customClass="ml-auto">
          {leadData.loading ? 'Loading...' : continueTxt}
        </Button>
      )}
    </div>
  );
}

FormSubmitButtons.defaultProps = {
  continueTxt: 'Continue'
};
export default FormSubmitButtons;
