/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Navbar() {
  const leadData = useSelector((state) => state.lead);
  if (window.location.href.includes('partnerframe.houzecheck.com')) {
    return null;
  }
  return (
    <div className="container px-4 md:px-0 md:mx-auto mb-5">
      <nav className="flex items-center justify-between bg-primary-50 mt-4">
        <a
          href={
            leadData.productData?.partner_logo
              ? `${window.location.origin}/${leadData.productData?.referId}`
              : `${window.location.origin}`
          }>
          <img
            src={
              leadData.productData?.partner_logo
                ? leadData.productData?.partner_logo
                : '/images/houzecheck-logo.png'
            }
            alt="Houzecheck"
            className="w-2/5 md:w-1/5 lg:w-64"
          />
        </a>
        {leadData.productData?.partner_logo ? (
          // <img
          //   className="w-2/5 md:w-1/5 lg:w-64"
          //   src="/images/powered-by_houzecheck_logo.png"
          //   alt="Houzecheck"
          // />
          <div />
        ) : (
          <a href="tel:03301131133">
            <p className="font-bold text-xl flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                stroke="none"
                strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              0330 113 1133
            </p>
          </a>
        )}
      </nav>
      {leadData.productData?.currentStep !== 1 &&
        leadData.productData.u_show_online !== 'false' && <StatusBar />}
    </div>
  );
}

function StatusBar() {
  const [currStepName, setCurrStepName] = useState('');
  const currStep = useSelector((state) => state.lead.productData?.currentStep);
  const stepsDetail = [
    { step: 1, name: 'Personal details' },
    { step: 2, name: 'Survey/Valuation' },
    { step: 3, name: 'Property details' },
    { step: 4, name: 'Price and Availability' },
    { step: 5, name: 'Pay and Confirm' }
    // { step: 6, name: 'Confirmation' }
  ];
  return (
    <div className="relative max-w-4xl mx-auto my-12">
      <div className="flex justify-between items-center">
        <CurrSteps
          highlighted={currStep === 1}
          name={stepsDetail[0].name}
          step={stepsDetail[0].step}
          setCurrStepName={setCurrStepName}
        />
        <CurrSteps
          highlighted={currStep === 2}
          name={stepsDetail[1].name}
          step={stepsDetail[1].step}
          setCurrStepName={setCurrStepName}
        />
        <CurrSteps
          highlighted={currStep === 3 || currStep === 4 || currStep === 5}
          name={stepsDetail[2].name}
          step={stepsDetail[2].step}
          setCurrStepName={setCurrStepName}
        />
        <CurrSteps
          highlighted={currStep === 6}
          name={stepsDetail[3].name}
          step={stepsDetail[3].step}
          setCurrStepName={setCurrStepName}
        />
        <CurrSteps
          highlighted={
            currStep === 7 || currStep === 8 || currStep === 9 || currStep === 10 || currStep === 11
          }
          name={stepsDetail[4].name}
          step={stepsDetail[4].step}
          setCurrStepName={setCurrStepName}
        />
        {/* <CurrSteps
          highlighted={currStep === 8 || currStep === 9 || currStep === 10 || currStep === 11}
          name={stepsDetail[5].name}
          step={stepsDetail[5].step}
        /> */}
        <div className="border-b border-primary-300 absolute md:top-1/4 mx-auto left-0 right-0 w-[85%] md:w-4/5 z-0" />
      </div>

      <p className="w-full md:hidden block text-primary-500 font-semibold text-center mt-4">
        {currStepName}
      </p>
    </div>
  );
}
function CurrSteps({ highlighted, step, name, setCurrStepName }) {
  useEffect(() => {
    if (highlighted) {
      setCurrStepName(name);
    }
  }, [highlighted]);

  return (
    <div className="flex flex-1 text-center md:text-sm lg:text-base flex-col items-center z-10">
      <div
        className={`w-6 ${
          highlighted ? 'bg-primary-500 border-primary-500' : 'bg-white border-primary-300'
        } h-6 border rounded-full flex justify-center items-center`}>
        <div
          className={`relative ${
            highlighted ? 'text-white' : 'text-primary-300'
          } text-center align-middle`}>
          {step}
        </div>
      </div>
      <p
        className={`w-full hidden md:block ${
          highlighted ? 'text-primary-500' : 'text-primary-300'
        }`}>
        {name}
      </p>
    </div>
  );
}
export default Navbar;
