import React from 'react';

function Label({ name, text }) {
  return (
    <label htmlFor={name} className="text-primary-800 font-semibold text-sm md:text-base">
      {text}
    </label>
  );
}

export default Label;
