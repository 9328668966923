import React from 'react';
import { useDispatch } from 'react-redux';
import { addressBasedOnPostcode } from '../apis';
import { loadingLead } from '../redux/features/lead-slice';

function withHandleGetAddress(WrappedComponent) {
  return function WithHandleGetAddress() {
    const dispatch = useDispatch();
    const getAndUpdateAddress = async (
      postcodeVal,
      setPostcodeErr,
      setAddressVals,
      setCoordinates
    ) => {
      try {
        dispatch(loadingLead(true));
        const { addresses, coordinates } = await addressBasedOnPostcode(postcodeVal);
        dispatch(loadingLead(false));
        setPostcodeErr({ status: false, message: '' });
        setAddressVals([{ key: 'key', value: '', label: 'Select an option' }, ...addresses]);
        setCoordinates(coordinates);
      } catch (err) {
        dispatch(loadingLead(false));
        console.log(JSON.stringify(err.message), JSON.stringify(err), 'from hoc');
        setPostcodeErr({ status: true, message: JSON.parse(err.message).message });
      }
    };
    return <WrappedComponent getAndUpdateAddress={getAndUpdateAddress} />;
  };
}

export default withHandleGetAddress;
