import { createSlice } from '@reduxjs/toolkit';

const name = 'lead';

const initialState = {
  loading: false,
  leadDetail: {},
  productData: {
    currentStep: null,
    quoteData: {},
    // if appointment data is blank then this flag is set for thank you page.
    appointment: true,
    callbackStatus: null,
    valuation: false,
    version: 1
  },
  accessDetail: {},
  reportDetail: {},
  errorType: null
};

const reducers = {
  updateStep: (state, { payload }) => {
    state.productData.currentStep = payload;
  },
  updateErr: (state, { payload }) => {
    state.errorType = payload;
  },
  loadingLead: (state, { payload }) => {
    state.loading = payload;
  },
  createLead: (state, { payload: { result } }) => {
    state.loading = false;
    state.leadDetail = result;
  },
  handleLeadProduct: (state, { payload }) => {
    state.loading = false;
    state.productData = payload;
  },
  updateLead: (state, { payload }) => {
    console.log('from lead', payload);
    localStorage.setItem('leadData', JSON.stringify(payload));
    return payload;
  }
  // errorLead: (state, { payload }) => ({ ...state, loading: false, leadDetail: {}, error: payload })
};

const leadSlice = createSlice({ name, initialState, reducers });

export const {
  actions: { loadingLead, createLead, updateStep, updateLead, handleLeadProduct, updateErr },
  reducer: LeadReducer
} = leadSlice;
