import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Container from '../Container';
import FormControl from '../FormControl';
import Button from '../Button';
import Modal from '../Modal';
import withHandleGetAddress from '../../HOC/HandleGetAddress';
import { updateLead } from '../../redux/features/lead-slice';

function AccessDetail({ getAndUpdateAddress }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const [modalOpen, setModalOpen] = useState(false);
  const [addressVals, setAddressVals] = useState([]);
  const [coordinates, setCoordinates] = useState({});
  const [postcodeErr, setPostcodeErr] = useState({ status: false, message: '' });
  const [finalVal, setFinalVal] = useState({});

  useEffect(() => {
    if (leadData.productData?.currentStep === 1) {
      navigate('/');
    }
    if (
      leadData.productData?.currentStep === 2 ||
      leadData.productData?.currentStep === 3 ||
      leadData.productData?.currentStep === 4 ||
      leadData.productData?.currentStep === 5
    ) {
      navigate('/product');
    } else if (leadData.productData?.currentStep === 6) {
      navigate('/payment');
    } else if (leadData.productData?.currentStep === 7) {
      navigate('/payment');
    } else if (leadData.productData?.currentStep === 8) {
      navigate('/confirmation');
    } else if (leadData.productData?.currentStep === 10) {
      navigate('/report-details');
    } else if (leadData.productData?.currentStep === 11) {
      navigate('/confirmation');
    }
  }, [leadData.productData?.currentStep]);
  // const getAndUpdateAddress = async (postcodeVal) => {
  //   try {
  //     dispatch(loadingLead(true));
  //     const addresses = await addressBasedOnPostcode(postcodeVal);
  //     dispatch(loadingLead(false));
  //     setPostcodeErr({ status: false, message: '' });
  //     setAddressVals([{ key: 'key', value: '', label: 'Select an option' }, ...addresses]);
  //   } catch (err) {
  //     dispatch(loadingLead(false));
  //     setPostcodeErr({ status: true, message: JSON.parse(err.message).message });
  //   }
  // };
  const initialValues = {
    property_postcode: '',
    property_address: '',
    estate_agent_fname: '',
    estate_agent_lname: '',
    estate_agent_email: '',
    estate_agent_contact_number: ''
  };
  const validationSchema = Yup.object({
    property_postcode: Yup.string().required('Please select a Job Type'),
    property_address: Yup.string().required('please select the address'),
    property_type: Yup.string(),
    estate_agent_fname: Yup.string(),
    estate_agent_lname: Yup.string(),
    estate_agent_email: Yup.string().required('Please enter a valid email'),
    estate_agent_contact_number: Yup.string().required('Please enter a valid phone number')
  });
  const propertyType = [
    { key: 'key1', btnTitle: 'Estate Agent', value: 'estate_agent' },
    { key: 'key2', btnTitle: 'Client', value: 'client' },
    { key: 'key3', btnTitle: 'Third party', value: 'third_party' }
  ];
  // const addressVals = [
  //   { key: 'Select an option', value: '' },
  //   { key: 'Option 1', value: 'option1' },
  //   { key: 'Option 2', value: 'option2' },
  //   { key: 'Option 3', value: 'option3' }
  // ];
  // eslint-disable-next-line no-unused-vars
  const onSubmit = (values) => {
    setModalOpen(true);
    setFinalVal(values);
  };
  const goToReport = (values) => {
    const updatedLeadData = {
      ...leadData,
      currentStep: 10,
      accessDetail: { ...leadData.accessDetail, ...values, coordinates }
    };
    dispatch(updateLead(updatedLeadData));
  };
  return (
    <Container>
      <div className="flex justify-between items-center">
        <p className="font-bold text-3xl md:text-4xl">Access Details</p>
        <div className="flex">
          <div className="w-4 h-4 border-2 border-primary-500 bg-primary-500 rounded-full mx-1" />
          <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
        </div>
      </div>
      <p>Provide details to confirm the property access</p>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values }) => (
          <Form>
            <FormControl
              controlType="dropdown"
              type="text"
              name="property_type"
              label="Who will confirm surveyor’s access to the property?"
              dropdownType="type1"
              optionVals={propertyType}
            />
            <div className="md:flex flex-wrap">
              <div className="md:w-full">
                <div className="md:flex">
                  <FormControl
                    controlType="input"
                    type="text"
                    name="property_postcode"
                    disabled={postcodeErr.status}
                    label="Postcode"
                  />
                  <FormControl
                    controlType="dropdown"
                    type="text"
                    name="property_address"
                    label="Address"
                    disabled={postcodeErr.status}
                    dropdownType="type2"
                    optionVals={addressVals}
                    onClickHandler={() =>
                      getAndUpdateAddress(
                        values.property_postcode,
                        setPostcodeErr,
                        setAddressVals,
                        setCoordinates
                      )
                    }
                  />
                </div>
                {postcodeErr.status && <p className="text-red-500">{postcodeErr.message}</p>}
              </div>
              <FormControl
                controlType="input"
                type="text"
                name="estate_agent_fname"
                label="Estate First name"
              />
              <FormControl
                controlType="input"
                type="text"
                name="estate_agent_lname"
                label="Estate Last name"
              />
              <FormControl
                controlType="input"
                type="number"
                name="estate_agent_contact_number"
                label="Estate Agent Phone"
              />
              <FormControl
                controlType="input"
                type="email"
                name="estate_agent_email"
                label="Estate Agent Email"
              />
            </div>
            <Button submit>Proceed</Button>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="px-8">
          <p className="text-secondary-800 font-bold text-xl text-center">
            Covid terms and conditions
          </p>

          <img
            src="/images/covid.png"
            alt=""
            width={96}
            height={96}
            className="p-4 bg-primary-50 rounded-full mx-auto"
          />
          <p className="text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. At eget est faucibus odio
            tincidunt curabitur felis feugiat mattis.
          </p>
          <div className="mx-auto text-center">
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <Button
              submit
              btnType="defaultOutlineBtn"
              customClass="mt-5"
              onClickHandler={() => goToReport(finalVal)}>
              Okay, Thanks
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default withHandleGetAddress(AccessDetail);
