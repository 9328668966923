/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

// COMPONENTS AND CONTAINERS
import Container from '../Container';
import FormControl from '../FormControl';
import { updateErr } from '../../redux/features/lead-slice';
import { updateLeadAction, updateStepAction } from '../../redux/actions/lead-actions';
import FormSubmitButtons from '../FormSubmitButtons';
import {
  allConstantVals,
  jobType,
  surveyType,
  buildYear,
  valuationReasons,
  specificReasons,
  generalYesNoAndNotSure,
  commonFieldErr,
  nonStandardPropOptions,
  iDontKnowWaring
} from '../../Constants';
import CautionComponent from '../CautionComponent';
import FieldsErr from '../FieldsErr';

const {
  valuationJobType,
  surveyJobType,
  otherJobType,
  level2Name,
  level3Name,
  before_1850,
  help_to_buy,
  shared_ownership,
  market_valuation,
  matrimonial,
  any_other_reason,
  yes,
  no,
  i_dont_know,
  stage_fail_no_service,
  stage_lead_scoring,

  stage_fail_no_level_2,
  stage_property
} = allConstantVals;
function FormTwo() {
  const leadData = useSelector((state) => state.lead);
  const { productData } = leadData;
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initialValues = {
    job_type: productData?.job_type || '',
    survey_type: productData?.survey_type || '',
    other_service: productData.other_service || '',
    build_year_l2: productData?.build_year_l2 || '',
    non_standard_prop: productData?.non_standard_prop || '',
    had_alter_in_prop: productData?.had_alter_in_prop || '',
    had_prop_extented: productData?.had_prop_extented || '',
    plan_alter_in_prop: productData?.plan_alter_in_prop || '',
    is_prop_condtn_poor: productData?.is_prop_condtn_poor || '',

    // Valuation fields
    val_reason: productData?.val_reason || '',
    specific_reason: productData?.specific_reason || '',
    val_for_lending: productData?.val_for_lending || '',
    requirement_expert_witness: productData?.requirement_expert_witness || ''
  };
  const validationSchema = Yup.object({
    job_type: Yup.string().required(commonFieldErr)
  })
    .shape({
      survey_type: Yup.string().when('job_type', {
        is: surveyJobType.value,
        then: Yup.string().required(commonFieldErr).default('')
      })
      // build_year_l2: Yup.string().when(['job_type', 'survey_type'], {
      //   is: (job_type, survey_type) =>
      //     job_type === surveyJobType.value && survey_type === level2Name.value,
      //   then: Yup.string().required(commonFieldErr).default('')
      // }),
      // non_standard_prop: Yup.string().when(['job_type', 'survey_type'], {
      //   is: (job_type, survey_type) =>
      //     job_type === surveyJobType.value && survey_type === level2Name.value,
      //   then: Yup.string().required(commonFieldErr).default('')
      // }),
      // had_alter_in_prop: Yup.string().when(['job_type', 'survey_type'], {
      //   is: (job_type, survey_type) =>
      //     job_type === surveyJobType.value && survey_type === level2Name.value,
      //   then: Yup.string().required(commonFieldErr).default('')
      // }),
      // had_prop_extented: Yup.string().when(['job_type', 'survey_type'], {
      //   is: (job_type, survey_type) =>
      //     job_type === surveyJobType.value && survey_type === level2Name.value,
      //   then: Yup.string().required(commonFieldErr).default('')
      // }),
      // plan_alter_in_prop: Yup.string().when(['job_type', 'survey_type'], {
      //   is: (job_type, survey_type) =>
      //     job_type === surveyJobType.value && survey_type === level2Name.value,
      //   then: Yup.string().required(commonFieldErr).default('')
      // }),
      // is_prop_condtn_poor: Yup.string().when(['job_type', 'survey_type'], {
      //   is: (job_type, survey_type) =>
      //     job_type === surveyJobType.value && survey_type === level2Name.value,
      //   then: Yup.string().required(commonFieldErr).default('')
      // })
    })
    .shape({
      val_reason: Yup.string().when('job_type', {
        is: valuationJobType.value,
        then: Yup.string().required(commonFieldErr).default('')
      }),
      specific_reason: Yup.string().when(['job_type', 'val_reason'], {
        is: (job_type, val_reason) =>
          job_type === valuationJobType.value &&
          (val_reason === help_to_buy.value || val_reason === shared_ownership.value),
        then: Yup.string().required(commonFieldErr).default('')
      }),
      val_for_lending: Yup.string().when(['job_type', 'val_reason'], {
        is: (job_type, val_reason) =>
          job_type === valuationJobType.value &&
          (val_reason === market_valuation.value || val_reason === any_other_reason.value),
        then: Yup.string().required(commonFieldErr)
      }),
      requirement_expert_witness: Yup.string().when(['job_type', 'val_for_lending', 'val_reason'], {
        is: (job_type, val_for_lending, val_reason) =>
          job_type === valuationJobType.value &&
          (val_reason === market_valuation.value || val_reason === any_other_reason.value) &&
          (val_for_lending === no.value || val_for_lending === i_dont_know.value),
        then: Yup.string().required(commonFieldErr)
      })
    })
    .shape({
      other_service: Yup.string().when('job_type', {
        is: otherJobType.value,
        then: Yup.string().required(commonFieldErr).default('')
      })
    });

  // eslint-disable-next-line no-unused-vars
  const fieldLabels = {
    job_type: 'Which of the following services do you require?',
    survey_type: 'Please confirm which RICS survey do you require?',
    other_service: 'Please explain what you are looking for:',
    build_year_l2: 'Approximate year of build',
    non_standard_prop:
      'Is the property a standard built i.e. made of brick/stone with a tiled/slate roof',
    had_alter_in_prop: 'Has the property had any large extensions or major alterations?',
    had_prop_extented: 'Has the property been extended more than twice?',
    plan_alter_in_prop: 'Are you planning any extensions or major alterations?',
    is_prop_condtn_poor: 'Does the property need major repairs?',

    // Valuation fields
    val_reason: 'Please confirm the reason for the RICS Valuation?',
    specific_reason: 'Please confirm specific reason:',
    val_for_lending: 'Is the valuation required for lending purposes?',
    requirement_expert_witness: 'Is there a requirement to appear as expert witness?'
  };
  const onSubmit = async (values, { setFieldValue }) => {
    console.log(values);
    switch (values.job_type) {
      case valuationJobType.value:
        // if (values.val_for_lending === 'Yes') {
        //   dispatch(
        //     updateErr({
        //       msg: 'val_for_lending'
        //     })
        //   );
        // } else if (values.requirement_expert_witness === 'Yes') {
        //   dispatch(
        //     updateErr({
        //       msg: 'requirement_expert_witness'
        //     })
        //   );
        // } else {
        //   const updatedLeadData = {
        //     ...leadData,
        //     currentStep: 3,
        //     productData: { ...leadData.productData, ...values }
        //   };
        //   dispatch(updateLead(updatedLeadData));
        // }
        if (
          leadData.productData.u_show_online === 'false' ||
          values.val_reason === any_other_reason.value ||
          (values.val_reason === market_valuation.value &&
            (values.val_for_lending === yes.value ||
              values.requirement_expert_witness === yes.value))
        ) {
          const updatedLeadData = {
            ...leadData,
            productData: {
              ...leadData.productData,
              ...values,
              web_stage:
                leadData.productData?.u_show_online === 'false'
                  ? stage_lead_scoring.value
                  : stage_fail_no_service.value,
              currentStep: 8
            }
          };
          dispatch(updateLeadAction(updatedLeadData));
        } else {
          const updatedLeadData = {
            ...leadData,
            productData: {
              ...leadData.productData,
              ...values,
              web_stage: stage_property.value,
              currentStep: 3
            }
          };
          dispatch(updateLeadAction(updatedLeadData));
        }
        break;
      case surveyJobType.value: {
        console.log(1);
        const checkIfValid =
          values.survey_type === level2Name.value &&
          (values.build_year_l2 === before_1850.value ||
            values.non_standard_prop === yes.value ||
            values.had_alter_in_prop === yes.value ||
            values.had_prop_extented === yes.value ||
            values.plan_alter_in_prop === yes.value ||
            values.is_prop_condtn_poor === yes.value);
        if (leadData.productData.u_show_online === 'false') {
          const updatedLeadData = {
            ...leadData,
            productData: {
              ...leadData.productData,
              ...values,
              web_stage: stage_lead_scoring.value,
              currentStep: 8
            }
          };

          dispatch(updateLeadAction(updatedLeadData));
        } else if (checkIfValid) {
          const updatedLeadData = {
            ...leadData,
            productData: {
              ...leadData.productData,
              ...values,
              web_stage: stage_fail_no_level_2.value
            },
            errorType: {
              type: 1,
              msg: 'Based on your answers, RICS Level 2 (Homebuyer Survey) is not suitable for this property. Please select Level 3 (Building Survey) to proceed with the booking.',
              closeBtnHandler: () => setFieldValue('survey_type', level3Name.value),
              btnOne: 'Proceed with RICS Level 3',
              btnTwo: 'Request a callback'
            }
          };
          dispatch(updateLeadAction(updatedLeadData));
        } else {
          // dispatch(handleLeadProduct({ ...leadData.productData, ...values }))
          const updatedLeadData = {
            ...leadData,
            productData: {
              ...leadData.productData,
              ...values,
              web_stage: stage_property.value,
              currentStep: 3
            }
          };
          dispatch(updateLeadAction(updatedLeadData));
        }
        break;
      }
      case otherJobType.value:
        if (values.other_service) {
          const updatedLeadData = {
            ...leadData,
            productData: {
              ...leadData.productData,
              ...values,
              web_stage: stage_fail_no_service.value,
              currentStep: 8
            }
          };
          // dispatch(updateLead(updatedLeadData));
          dispatch(updateLeadAction(updatedLeadData));
        } else {
          dispatch(
            updateErr({
              type: 1,
              msg: 'Please explain or select the type of service'
            })
          );
        }
        break;
      default:
        console.log('from switch');
        break;
    }
  };

  return (
    <Container>
      <p className="font-bold text-4xl">Hello {leadData.leadDetail?.data?.first_name},</p>
      <p className="mb-6">
        So we can provide you with the best service, we need a little more information from you.
      </p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ values, errors, touched }) => (
          <Form>
            <FormControl
              controlType="radio"
              label={fieldLabels.job_type}
              name="job_type"
              optionVals={jobType}
              radioType="type1"
            />

            {values.job_type === otherJobType.value ? (
              <FormControl
                controlType="textarea"
                label={fieldLabels.other_service}
                name="other_service"
              />
            ) : (
              <>
                {values.job_type === surveyJobType.value && (
                  <JobTypeSurvey surveyTypeFormVals={values} fieldLabels={fieldLabels} />
                )}
                {values.job_type === valuationJobType.value && (
                  <JobTypeValuation formVals={values} fieldLabels={fieldLabels} />
                )}
              </>
            )}
            <FieldsErr fieldLabels={fieldLabels} errors={errors} touched={touched} />
            <FormSubmitButtons backBtnHandler={() => dispatch(updateStepAction(1))} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

function JobTypeSurvey({ surveyTypeFormVals, fieldLabels }) {
  return (
    <>
      <FormControl
        controlType="radio"
        label={fieldLabels.survey_type}
        name="survey_type"
        optionVals={surveyType}
        radioType="type2"
      />

      {/* {surveyTypeFormVals.survey_type === level2Name.value && (
        <>
          <CautionComponent
            text="Level 2 survey is not suitable for every property, so we just need know a bit more about the
                property before we can continue."
          />
          <FormControl
            controlType="dropdown"
            label={fieldLabels.build_year_l2}
            name="build_year_l2"
            optionVals={buildYear}
            dropdownType="type1"
          />

          <FormControl
            controlType="radio"
            label={fieldLabels.non_standard_prop}
            name="non_standard_prop"
            optionVals={nonStandardPropOptions}
            radioType="type3"
          />
          {surveyTypeFormVals.non_standard_prop === i_dont_know.value && (
            <CautionComponent type="info" text={iDontKnowWaring} />
          )}
          <FormControl
            controlType="radio"
            label={fieldLabels.had_alter_in_prop}
            name="had_alter_in_prop"
            optionVals={generalYesNoAndNotSure}
            radioType="type3"
          />
          {surveyTypeFormVals.had_alter_in_prop === i_dont_know.value && (
            <CautionComponent type="info" text={iDontKnowWaring} />
          )}
          <FormControl
            controlType="radio"
            label={fieldLabels.had_prop_extented}
            name="had_prop_extented"
            optionVals={generalYesNoAndNotSure}
            radioType="type3"
          />
          {surveyTypeFormVals.had_prop_extented === i_dont_know.value && (
            <CautionComponent type="info" text={iDontKnowWaring} />
          )}
          <FormControl
            controlType="radio"
            label={fieldLabels.plan_alter_in_prop}
            name="plan_alter_in_prop"
            optionVals={generalYesNoAndNotSure}
            radioType="type3"
          />
          {surveyTypeFormVals.plan_alter_in_prop === i_dont_know.value && (
            <CautionComponent type="info" text={iDontKnowWaring} />
          )}
          <FormControl
            controlType="radio"
            label={fieldLabels.is_prop_condtn_poor}
            name="is_prop_condtn_poor"
            optionVals={generalYesNoAndNotSure}
            radioType="type3"
          />
          {surveyTypeFormVals.is_prop_condtn_poor === i_dont_know.value && (
            <CautionComponent type="info" text={iDontKnowWaring} />
          )}
        </>
      )} */}
    </>
  );
}

function JobTypeValuation({ formVals, fieldLabels }) {
  return (
    <>
      <FormControl
        controlType="dropdown"
        label={fieldLabels.val_reason}
        name="val_reason"
        optionVals={valuationReasons}
        dropdownType="type1"
        updateVals={['specific_reason']}
        // onClickHandler={}
      />
      {(formVals.val_reason === help_to_buy.value ||
        formVals.val_reason === shared_ownership.value) && (
        <FormControl
          controlType="dropdown"
          label={fieldLabels.specific_reason}
          name="specific_reason"
          optionVals={specificReasons(formVals.val_reason)}
          dropdownType="type1"
        />
      )}
      {formVals.val_reason === any_other_reason.value && (
        <FormControl
          controlType="textarea"
          label={fieldLabels.specific_reason}
          name="specific_reason"
        />
      )}
      {(formVals.val_reason === market_valuation.value ||
        formVals.val_reason === any_other_reason.value) && (
        <FormControl
          controlType="dropdown"
          label={fieldLabels.val_for_lending}
          name="val_for_lending"
          optionVals={generalYesNoAndNotSure}
          dropdownType="type1"
        />
      )}
      {(formVals.val_reason === market_valuation.value ||
        formVals.val_reason === any_other_reason.value) &&
        (formVals.val_for_lending === no.value ||
          formVals.val_for_lending === i_dont_know.value) && (
          <FormControl
            controlType="dropdown"
            label={fieldLabels.requirement_expert_witness}
            name="requirement_expert_witness"
            optionVals={generalYesNoAndNotSure}
            dropdownType="type1"
          />
        )}
    </>
  );
}
export default FormTwo;
