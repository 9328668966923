import React from 'react';
import ReactDom from 'react-dom';

function Modal({ open, onClose, children }) {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 z-[101]"
        onClick={onClose}
        onKeyDown={onClose}
        role="button"
        tabIndex={0}>
        {' '}
      </div>
      <div className="fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-4/5 md:w-1/2 h-2/3 bg-white py-4 px-7 z-[101] rounded-2xl overflow-y-auto disable-scrollbars">
        <button type="button" className="block ml-auto text-secondary-400" onClick={onClose}>
          X
        </button>
        <div className="mt-8 md:mt-1 h-full">{children}</div>
      </div>
    </>,
    document.getElementById('portal')
  );
}

export default Modal;
