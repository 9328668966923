/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { NotificationManager } from 'react-notifications';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Swal from 'sweetalert2';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import { updateLead } from '../../redux/features/lead-slice';
import { handlePaymentAction, updateStepAction } from '../../redux/actions/lead-actions';
import FormSubmitButtons from '../../components/FormSubmitButtons';

import CheckoutForm from '../../components/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const leadData = useSelector((state) => state.lead);
  const {
    productData: { quoteData }
  } = leadData;
  const { productPriceDetails } = quoteData;
  const [modalOpen, setModalOpen] = useState(false);
  const { refId } = useParams();
  const redirectPage = (route) => {
    if (refId) {
      navigate(`/${refId}${route}`);
    } else {
      navigate(route);
    }
  };

  // useEffect(() => {
  //   if (!leadData?.quoteData?.timeSelected) {
  //     dispatch(updateStepAction(6));
  //   }
  // }, [leadData?.quoteData?.timeSelected]);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch('https://quote.houzecheck.com/api/checkout_sessions', {
      // fetch('http://localhost:3001/api/checkout_sessions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        items: [{ id: leadData?.leadDetail?.interaction_id }],
        amount: productPriceDetails?.finalPrice,
        customerInfo: {
          name: `${leadData?.leadDetail?.data?.first_name} ${leadData?.leadDetail?.data?.last_name}`,
          email: leadData?.leadDetail?.data?.email,
          postal_code: leadData?.leadDetail?.data?.property_postcode
        }
      })
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'flat'
  };
  const options = {
    clientSecret,
    appearance
  };

  useEffect(() => {
    // console.log(!leadData?.quoteData?.timeSelected, '2wwwssssssssssssssssssssssssssssss');
    if (leadData.productData?.currentStep === 1) {
      redirectPage('');
    } else if (
      leadData.productData?.currentStep === 2 ||
      leadData.productData?.currentStep === 3 ||
      leadData.productData?.currentStep === 4 ||
      leadData.productData?.currentStep === 5
    ) {
      navigate('product');
    } else if (leadData.productData?.currentStep === 6) {
      navigate('quote');
    } else if (leadData.productData?.currentStep === 8) {
      navigate('confirmation');
    }
    // else if (leadData.currentStep === 9) {
    //   navigate('/access-details');
    // } else if (leadData.currentStep === 10) {
    //   navigate('/report-details');
    // }
  }, [leadData.productData?.currentStep]);

  // Handle create order of paypal button
  const handleCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: productPriceDetails?.finalPrice
          }
        }
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    });
  };
  // Handle approval order of paypal button
  const onOrderApproval = (data, actions) => {
    try {
      setLoader(true);
      dispatch(
        handlePaymentAction({
          paymentDetail: {
            payment_id: clientSecret.split('_secret')[0],
            payment_type: 'Stripe'
          },
          leadData,
          currentStep: 8
        })
      );
      setLoader(false);
    } catch (e) {
      setLoader(false);
      Swal.fire({
        title: 'Error!',
        text: 'Payment failed!!!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  return (
    <Container backBtnHandler={() => dispatch(updateStepAction(6))}>
      <p className="text-3xl font-bold mt-3">Pay and confirm</p>
      <p>Please pay by Debit or credit card to complete the booking</p>
      <div className="flex flex-col md:flex-row mt-8 items-center">
        <div className="md:w-3/5 order-2 md:order-1 p-4 w-full">
          {/* <PayPalButtons
            style={{ layout: 'vertical', width: '100%' }}
            createOrder={handleCreateOrder}
            onApprove={onOrderApproval}
          /> */}
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm onOrderApproval={onOrderApproval} />
            </Elements>
          )}
        </div>
        <div className="border border-primary-200 px-4 py-6 rounded-2xl md:w-2/5 order-1 md:order-2">
          <p className="font-bold text-2xl mb-4 text-center">Order Summary</p>
          <div className="flex justify-between items-center">
            <p>Cost</p>
            <p>£{productPriceDetails?.baseAmt}</p>
          </div>
          <div className="flex justify-between items-center">
            <p>VAT @ 20%</p>
            <p>£{productPriceDetails?.vatAmt}</p>
          </div>
          {!!productPriceDetails?.discountPercentage && (
            <div className="flex justify-between items-center text-green-600">
              <p>{quoteData?.offer}</p>
              <p>-£{productPriceDetails?.discountAmt}</p>
            </div>
          )}
          <div className="flex justify-between items-center mt-8 text-xl">
            <p>Total Amount</p>
            <p className="font-semibold">£{productPriceDetails?.finalPrice}</p>
          </div>
          {/* <Button onClickHandler={() => setModalOpen(true)} customClass="w-full mt-5">
            Payment
          </Button> */}
          <p className="text-sm text-secondary-500 text-center">Powered by Stripe.</p>
        </div>
      </div>
      <FormSubmitButtons backBtnHandler={() => dispatch(updateStepAction(6))} nocontinue />
      {/* <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <p className="text-3xl font-bold">Privacy Policy</p>
        <p className="text-justify mt-3">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, hic asperiores. Beatae
          exercitationem fugit aliquam cumque ipsa eveniet atque quidem sequi nemo debitis esse iure
          sit ullam voluptatem, expedita modi saepe similique ipsum aliquid maiores neque vel et
          dicta quisquam! Illo earum facilis quis eum. Ducimus est eius tempore, illum magnam
          veritatis nesciunt eaque nemo perferendis quia maiores ratione pariatur corporis ipsam
          maxime esse ipsa. Id veritatis vitae doloremque quaerat quia impedit iure repudiandae
          saepe asperiores odit esse tenetur nisi rem voluptas, accusantium fugiat delectus quidem,
          in aspernatur! Commodi molestias fuga laboriosam adipisci porro deleniti iste non.
          Reiciendis quis nihil magni nulla suscipit nostrum nam reprehenderit id voluptatibus at
          illo harum, maiores sequi dolor mollitia aliquid officiis exercitationem natus, voluptate
          perspiciatis. Et quae maxime delectus! Tempora quod nesciunt at delectus officia unde
          repellendus magnam, minus modi repudiandae accusantium, esse quisquam sapiente. Corporis
          adipisci tempora dolorem reprehenderit repellendus consectetur praesentium? Voluptatum
          placeat laboriosam, recusandae ducimus ad officia. Quaerat ducimus debitis dignissimos
          culpa accusamus eligendi sint sapiente quos! Vel, cumque voluptatem quia saepe, eius
          nostrum reiciendis, ratione minima labore cupiditate autem. Aliquid qui, perferendis ab
          iste illo doloremque nostrum iusto saepe eaque laborum porro, nemo vitae in harum nam
          itaque? Maiores facilis porro inventore? Eum, fugit error, repellat at obcaecati
          accusantium labore, dicta perferendis asperiores magni quis excepturi veritatis vitae
          quibusdam a aspernatur unde deleniti fuga! Porro odio aliquam voluptates animi molestiae
          quas dolorum ipsam explicabo aut facere architecto autem illum reiciendis qui optio
          quibusdam quis eveniet, nobis minus perspiciatis nihil. Vitae exercitationem reprehenderit
          asperiores eius, dolorem corporis cum repudiandae pariatur id mollitia facere quae. Fugit
          incidunt quas cumque ab quo ipsum, odio ratione officiis distinctio adipisci, esse
          voluptatum accusantium iure asperiores reprehenderit dicta. Hic accusantium ipsum eos
          molestias rerum doloremque maiores ut voluptatem earum atque laboriosam aliquid
          perferendis quod amet, quaerat qui officiis ipsa quae deserunt, vel nulla veritatis sunt!
          Culpa expedita consequatur facilis odio cum. Aspernatur neque, voluptas expedita cumque
          enim dolorum velit laborum quod cupiditate, fuga quia accusantium eligendi similique error
          odio mollitia cum doloribus molestiae molestias numquam id. Ut repellendus magnam
          assumenda obcaecati sit quidem veniam labore. Assumenda voluptate facere debitis
          repudiandae officiis quo labore deleniti voluptatibus commodi distinctio perspiciatis, in
          aperiam tempore vel sapiente officia numquam enim blanditiis praesentium excepturi.
          Distinctio officiis officia, magni tempora magnam ipsa ad, asperiores rerum possimus ab
          veniam accusamus impedit ratione fuga maiores aperiam sequi quos ea repellat quam?
          Voluptatem expedita id consectetur obcaecati minima cupiditate eligendi tempore minus?
          Esse molestias sed soluta veniam possimus, a nobis animi non sequi harum reiciendis velit
          consectetur eum. Magnam ab vero soluta eius explicabo? Voluptas excepturi id ullam placeat
          quo quasi aspernatur maiores quos, dignissimos asperiores porro aperiam sequi dolores
          doloribus dolorem adipisci libero atque laudantium numquam ex pariatur minus illo
          doloremque sit. Ea nulla dolor cumque sit illo cum aliquam et exercitationem nemo nostrum
          consequatur, ex necessitatibus debitis aspernatur ratione magni dolorem modi soluta!
          Veritatis accusamus, quae tenetur vel esse neque sed alias nobis quisquam illum soluta
          maxime nulla cum temporibus similique omnis reiciendis!
        </p>
      </Modal> */}
    </Container>
  );
}

export default PaymentPage;
