import {
  CREATE_LEAD,
  UPDATE_STEP,
  UPDATE_LEAD,
  GET_QUOTE,
  HANDLE_PAYMENT,
  GET_LEAD_DATA,
  GET_REFERRAL_DATA
} from '../action-types/lead-types';

export const createLeadAction = (payload) => ({ type: CREATE_LEAD, payload });
export const updateStepAction = (payload) => ({ type: UPDATE_STEP, payload });
export const updateLeadAction = (payload) => ({ type: UPDATE_LEAD, payload });
export const getQuoteAction = (payload) => ({ type: GET_QUOTE, payload });
export const handlePaymentAction = (payload) => ({ type: HANDLE_PAYMENT, payload });
export const getLeadDataByInteraction = (payload) => ({ type: GET_LEAD_DATA, payload });
export const getReferralDataAction = (payload) => ({ type: GET_REFERRAL_DATA, payload });
