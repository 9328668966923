import React, { useRef, useEffect } from 'react';

function Trustbox() {
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="5ad08a9a4848ce0001ded658"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light">
      <a
        href="https://uk.trustpilot.com/review/houzecheck.com"
        target="_blank"
        rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
}

export default Trustbox;
