import React from 'react';

function CautionComponent({ text, type }) {
  switch (type) {
    case 'info':
      return (
        <div className="border-l-4 p-4 border-orange-500 bg-[#f0ece9] rounded-l rounded-r-lg w-full my-4 shadow-md">
          {text}
        </div>
      );
    case 'success':
      return (
        <div className="border-l-4 p-4 border-green-500 bg-[#e9f0ec] rounded-l rounded-r-lg w-full my-4 shadow-md">
          {text}
        </div>
      );

    default:
      return (
        <div className="border-l-4 p-4 border-secondary-900 bg-secondary-50 rounded-l rounded-r-lg w-full my-4">
          {text}
        </div>
      );
  }
}

export default CautionComponent;
