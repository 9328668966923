/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import FormControl from '../../components/FormControl';
import { createLeadAction, getReferralDataAction } from '../../redux/actions/lead-actions';
import { updateStep, updateLead } from '../../redux/features/lead-slice';

import './Home.css';
import Container from '../../components/Container';
import { isObjEmpty, changeStep, capitalizeFirstLetter } from '../../Constants';
import FormSubmitButtons from '../../components/FormSubmitButtons';

function Home({ match }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const [refID, setRefId] = useState('');
  const { refId } = useParams();
  useEffect(() => {
    if (refId) {
      dispatch(
        getReferralDataAction({
          ...leadData,
          productData: {
            ...leadData.productData,
            referId: refId,
            referralId: refId,
            web_url: window.location.href
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      leadData.productData?.currentStep === 2 ||
      leadData.productData?.currentStep === 3 ||
      leadData.productData?.currentStep === 4 ||
      leadData.productData?.currentStep === 5
    ) {
      navigate('product');
    } else if (leadData?.productData?.currentStep === 6) {
      navigate('quote');
    } else if (leadData?.productData?.currentStep === 7) {
      navigate('payment');
    } else if (leadData?.productData?.currentStep === 8) {
      navigate('confirmation');
    }
    // else if (leadData?.currentStep === 9) {
    //   navigate('/access-details');
    // } else if (leadData?.currentStep === 10) {
    //   navigate('/report-details');
    // }
    else {
      dispatch(updateStep(1));
    }
  }, [leadData.productData?.currentStep]);

  const {
    leadDetail: { data }
  } = leadData;
  const initialValues = {
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    email: data?.email || '',
    contact_number: data?.contact_number || '',
    property_postcode: data?.property_postcode || ''
  };
  const onSubmit = (values) => {
    console.log(refId, 'from submit');
    const updatedVals = {
      ...values,
      first_name: capitalizeFirstLetter(values.first_name),
      last_name: capitalizeFirstLetter(values.last_name),
      property_postcode: values.property_postcode.replace(/\s/g, ''), // removes whitespace
      referralId: leadData.productData?.referId,
      referId: leadData.productData?.referId,
      web_url: window.location.href
    };
    dispatch(createLeadAction(updatedVals));
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required('Please enter your first name'),
    last_name: Yup.string().required('Please enter your last name'),
    email: Yup.string().required('Please enter a valid email'),
    contact_number: Yup.string().required('Please enter a valid phone number'),
    property_postcode: Yup.string().required('Please enter a valid postcode')
  });

  if (leadData.productData?.currentStep !== 1) {
    return <Outlet />;
  }
  return (
    <Container noback showBottomTxt>
      <p className="font-bold text-4xl">Hi there!</p>
      <p>We just need to know a bit about you</p>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {() => (
          <Form>
            <div className="md:flex flex-wrap">
              <FormControl controlType="input" type="text" name="first_name" label="First name" />
              <FormControl controlType="input" type="text" name="last_name" label="Last name" />
              <FormControl controlType="input" type="email" name="email" label="Email" />
              <FormControl controlType="input" type="number" name="contact_number" label="Phone" />
              <FormControl
                controlType="input"
                type="text"
                name="property_postcode"
                label="Postcode of the property to be inspected"
              />
            </div>
            <FormSubmitButtons noback leadData={leadData} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default Home;
