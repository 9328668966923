import { ErrorMessage, Field } from 'formik';
import React from 'react';
import Label from '../Label';
import TextError from '../TextError';

function TextArea({ label, name, ...rest }) {
  return (
    <div className="md:pr-4 my-3">
      <Label name={name} text={label} />
      <Field
        as="textarea"
        className="bg-primary-50 block w-full p-3 border border-primary-200 outline-primary-200 rounded-xl mt-2"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default TextArea;
