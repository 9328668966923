import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unselected } from '../../Constants';
import { updateLead } from '../../redux/features/lead-slice';

import Button from '../Button';

function Accordian({ accordianQnA, title, step, toggleable }) {
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const [toggleAccordian, setToggleAccordian] = useState(true);
  return (
    <div className="bg-primary-50 rounded-2xl mb-4">
      <div className="flex items-center justify-between p-4">
        <p className="font-bold text-base md:text-xl w-1/2">{title}</p>
        <div>
          <Button
            btnType="defaultOutlineBtn"
            customClass="!py-2 text-primary-800 rounded-full !px-4 mr-3"
            onClickHandler={() =>
              dispatch(
                updateLead({
                  ...leadData,
                  productData: { ...leadData.productData, valuation: 'no', currentStep: step }
                })
              )
            }>
            Edit
          </Button>
          {toggleable && (
            <Button
              btnType="defaultOutlineBtn"
              customClass="!py-2 text-primary-200 border-primary-200 bg-white rounded-full px-3"
              onClickHandler={() => setToggleAccordian(!toggleAccordian)}>
              &#x25BC;
            </Button>
          )}
        </div>
      </div>
      <div className={`accordian-content ${toggleAccordian && 'accordian-show'}`}>
        {accordianQnA.map(
          (val) => val.answer !== unselected && <AccordianQnAFn val={val} key={val.key} />
        )}
      </div>
    </div>
  );
}

function AccordianQnAFn({ val: { question, answer } }) {
  return (
    <div className="flex justify-between p-3 border-t border-primary-200 text-sm md:text-base">
      <p className="w-1/2">{question}</p>
      <p className="font-bold text-right w-1/2">{answer}</p>
    </div>
  );
}
Accordian.defaultProps = {
  toggleable: true
};
export default Accordian;
