/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
export const changeStep = (val, navigate) => {
  //   switch (val) {
  //     case 1:
  //       navigate('/');
  //       break;
  //     case 2:
  //       navigate()
  //     default:
  //       navigate('/');
  //       break;
  //   }
  if (val === 1 || val === null) {
    navigate('/');
  } else if (val === 2 || val === 3) {
    navigate('/product');
  }
};

export const monthFullText = (val) => {
  switch (val) {
    case 'Jan':
      return 'January';
    case 'Feb':
      return 'February';
    case 'Mar':
      return 'March';
    case 'Apr':
      return 'April';
    case 'May':
      return 'May';
    case 'June':
      return 'June';
    case 'July':
      return 'July';
    case 'Aug':
      return 'August';
    case 'Sept':
      return 'September';
    case 'Oct':
      return 'October';
    case 'Nov':
      return 'November';
    case 'Dec':
      return 'December';
    default:
      return null;
  }
};

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
export const unselected = 'unselected';
export const allConstantVals = {
  valuationJobType: { label: 'RICS Valuation', value: 'Valuation' },
  surveyJobType: { label: 'RICS Survey', value: 'Survey' },
  otherJobType: { label: 'Other Service', value: 'Other' },

  level2Name: { label: 'RICS Level 2 (Homebuyer Survey)', value: 'Homebuyer - No Valuation' },
  level3Name: { label: 'RICS Level 3 (Building Survey)', value: 'Building Survey - No Valuation' },

  before_1850: { label: 'Before 1850', value: '10' },
  between_1850_1899: { label: 'Between 1850 - 1899', value: '15' },
  between_1900_1949: { label: 'Between 1900 - 1949', value: '20' },
  between_1950_1979: { label: 'Between 1950 - 1979', value: '25' },
  between_1980_2009: { label: 'Between 1980 – 2009', value: '30' },
  on_or_after_2010: { label: 'On or after 2010', value: '35' },

  help_to_buy: { label: 'Help to buy', value: 'Help to buy' },
  shared_ownership: { label: 'Shared Ownership', value: 'Shared Ownership' },
  probate: { label: 'Probate', value: 'Probate' },
  capital_gain: { label: 'Capital Gain', value: 'Capital Gain' },
  matrimonial: { label: 'Matrimonial', value: 'Matrimonial' },
  market_valuation: { label: 'Market Valuation', value: 'Market Valuation' },
  immigration: { label: 'Immigration', value: 'Immigration' },
  right_to_buy: { label: 'Right to buy', value: 'Right to buy' },
  buying_home: { label: 'Buying home', value: 'Buying home' },
  selling_home: { label: 'Selling home', value: 'Selling home' },
  any_other_reason: { label: 'Any other reason', value: 'Any other reason' },

  redeeming_loan: { label: 'Redeeming loan', value: 'Redeeming loan' },
  re_mortgaging: { label: 'Re-mortgaging', value: 'Re-mortgaging' },
  selling: { label: 'Selling', value: 'Selling' },
  staircasing: { label: 'Staircasing', value: 'Staircasing' },

  yes: { label: 'Yes', value: 'Yes' },
  no: { label: 'No', value: 'No' },
  i_dont_know: { label: "I don't know", value: 'Not Sure' },

  flat: { label: 'Flat', value: 'Flat' },
  maisonette: { label: 'Maisonette', value: 'Maisonette' },
  terraced: { label: 'Terraced', value: 'Terraced' },
  semi_detached: { label: 'Semi Detached', value: 'Semi Detached' },
  detached: { label: 'Detached', value: 'Detached' },
  bungalow: { label: 'Bungalow', value: 'Bunglow' },
  cottage: { label: 'Cottage', value: 'Cottage' },
  end_of_terrace: { label: 'End of Terrace', value: 'End of Terrace' },

  a1: { label: 'A1', value: 'A1' },
  a2: { label: 'A2', value: 'A2' },
  a3: { label: 'A3', value: 'A3' },
  b1: { label: 'B1', value: 'B1' },
  b2: { label: 'B2', value: 'B2' },

  1: { label: '1', value: '1' },
  2: { label: '2', value: '2' },
  3: { label: '3', value: '3' },
  4: { label: '4', value: '4' },
  5: { label: '5', value: '5' },
  6: { label: '6', value: '6' },
  7: { label: '7+', value: '7' },

  less_than_500000: { label: 'Less than £500,000', value: '10' },
  '500000_749999': { label: '£500,000 - £749,999', value: '15' },
  '750000_999999': { label: '£750,000 - £999,999', value: '20' },
  '1000000_1249999': { label: '£1,000,000 - £1,249,999', value: '25' },
  '1250000_1499999': { label: '£1,250,000 - £1,499,999', value: '30' },
  '1500000_1749999': { label: '£1,500,000 - £1,749,999', value: '35' },
  '1750000_1999999': { label: '£1,750,000 - £1,999,999', value: '40' },
  '2000000_or_more': { label: '£2,000,000 or more', value: '45' },

  freehold: { label: 'Freehold', value: 'Freehold' },
  leasehold: { label: 'Leasehold', value: 'Leasehold' },

  '85_years_and_over': { label: '85 years and over', value: '30' },
  '51_to_84_years': { label: '51 to 84 years', value: '20' },
  '50_years_or_under': { label: '50 years or under', value: '10' },

  stage_fail_no_service: { value: 'Fail - No Service' },
  stage_lead_scoring: { value: 'Lead Scoring' },
  stage_product: { value: 'Product' },
  stage_personal: { value: 'Personal' },
  stage_property: { value: 'Property' },
  stage_property_non_standard: { value: 'Property - Non-standard' },
  stage_property_additional: { value: 'Property - Additional' },
  stage_quote: { value: 'Quote' },
  stage_quote_no_slots: { value: 'Quote - No Slots' },
  stage_fail_no_level_2: { value: 'Fail - No Level 2' },
  stage_online_order: { value: 'Online Order' },
  stage_payment: { value: 'Payment' }
};
export const calculatePriceOfProduct = async (
  price,
  vat,
  total_price,
  discounted_price,
  discount = 0
) => {
  const baseAmt = (price / vat).toFixed(2);
  const vatAmt = (price - baseAmt).toFixed(2);
  // const discountAmt = (price * (discount / 100)).toFixed(2);
  const discountAmt = discounted_price;
  return {
    baseAmt,
    vatAmt,
    discountAmt,
    discountPercentage: Number(discount),
    // finalPrice: price - discountAmt
    finalPrice: total_price
  };
};
export const generalYesNo = [
  {
    key: 'key1',
    btnTitle: allConstantVals.yes.label,
    value: allConstantVals.yes.value
  },
  {
    key: 'key2',
    btnTitle: allConstantVals.no.label,
    value: allConstantVals.no.value
  }
];

export const generalYesNoAndNotSure = [
  { key: 'key1', btnTitle: allConstantVals.yes.label, value: allConstantVals.yes.value },
  { key: 'key2', btnTitle: allConstantVals.no.label, value: allConstantVals.no.value },
  {
    key: 'key3',
    btnTitle: allConstantVals.i_dont_know.label,
    value: allConstantVals.i_dont_know.value
  }
];
export const nonStandardPropOptions = [
  { key: 'key1', btnTitle: allConstantVals.yes.label, value: allConstantVals.no.value },
  { key: 'key2', btnTitle: allConstantVals.no.label, value: allConstantVals.yes.value },
  {
    key: 'key3',
    btnTitle: allConstantVals.i_dont_know.label,
    value: allConstantVals.i_dont_know.value
  }
];

// FORM2 OPTIONS DATA
export const jobType = [
  {
    key: 'key1',
    btnTitle: 'RICS Survey',
    btnSubTitle: 'Level 2 Homebuyer with or without valuation or Level 3 Building Survey',
    btnImg: '/images/job-type-survey.png',
    value: allConstantVals.surveyJobType.value
  },
  {
    key: 'key2',
    btnTitle: 'RICS Valuation',
    btnSubTitle:
      'Professional Valuation for purposes such as help to buy, shared ownership and probate',
    btnImg: '/images/job-type-val.png',
    value: allConstantVals.valuationJobType.value
  },
  {
    key: 'key3',
    btnTitle: 'Other Service',
    btnSubTitle: 'Any other RICS service such as land survey, party wall, defect analysis',
    btnImg: '/images/job-type-other.png',
    value: allConstantVals.otherJobType.value
  }
];
export const surveyType = [
  {
    key: 'key1',
    btnTitle: 'RICS Level 2 (Homebuyer Survey)',
    btnImg: '/images/level2.png',
    btnList: [
      'Most popular condition survey',
      'Concise easy to understand report',
      'Suitable for most standard properties'
    ],
    value: allConstantVals.level2Name.value
  },
  {
    key: 'key2',
    btnTitle: 'RICS Level 3 (Building Survey)',
    btnImg: '/images/level3.png',
    btnList: [
      'Most comprehensive condition survey',
      'Detailed construction and condition advice',
      'Ideal for larger, older or non-standard properties'
    ],
    value: allConstantVals.level3Name.value
  }
];
export const buildYear = [
  // BOTH FORM2 AND FORM3
  {
    key: 'key1',
    btnTitle: allConstantVals.before_1850.label,
    value: allConstantVals.before_1850.value
  },
  {
    key: 'key2',
    btnTitle: allConstantVals.between_1850_1899.label,
    value: allConstantVals.between_1850_1899.value
  },
  {
    key: 'key3',
    btnTitle: allConstantVals.between_1900_1949.label,
    value: allConstantVals.between_1900_1949.value
  },
  {
    key: 'key4',
    btnTitle: allConstantVals.between_1950_1979.label,
    value: allConstantVals.between_1950_1979.value
  },
  {
    key: 'key5',
    btnTitle: allConstantVals.between_1980_2009.label,
    value: allConstantVals.between_1980_2009.value
  },
  {
    key: 'key6',
    btnTitle: allConstantVals.on_or_after_2010.label,
    value: allConstantVals.on_or_after_2010.value
  },
  {
    key: 'key7',
    btnTitle: allConstantVals.i_dont_know.label,
    value: allConstantVals.i_dont_know.value
  }
];

export const valuationReasons = [
  {
    key: 'key1',
    btnTitle: allConstantVals.help_to_buy.label,
    value: allConstantVals.help_to_buy.value
  },
  {
    key: 'key2',
    btnTitle: allConstantVals.shared_ownership.label,
    value: allConstantVals.shared_ownership.value
  },
  { key: 'key3', btnTitle: allConstantVals.probate.label, value: allConstantVals.probate.value },
  {
    key: 'key4',
    btnTitle: allConstantVals.capital_gain.label,
    value: allConstantVals.capital_gain.value
  },
  {
    key: 'key5',
    btnTitle: allConstantVals.matrimonial.label,
    value: allConstantVals.matrimonial.value
  },
  {
    key: 'key6',
    btnTitle: allConstantVals.market_valuation.label,
    value: allConstantVals.market_valuation.value
  },
  {
    key: 'key7',
    btnTitle: allConstantVals.immigration.label,
    value: allConstantVals.immigration.value
  },
  {
    key: 'key8',
    btnTitle: allConstantVals.right_to_buy.label,
    value: allConstantVals.right_to_buy.value
  },
  {
    key: 'key9',
    btnTitle: allConstantVals.buying_home.label,
    value: allConstantVals.buying_home.value
  },
  {
    key: 'key10',
    btnTitle: allConstantVals.selling_home.label,
    value: allConstantVals.selling_home.value
  },
  {
    key: 'key11',
    btnTitle: allConstantVals.any_other_reason.label,
    value: allConstantVals.any_other_reason.value
  }
];
const specificReasonsHTB = [
  {
    key: 'key1',
    btnTitle: allConstantVals.redeeming_loan.label,
    value: allConstantVals.redeeming_loan.value
  },
  {
    key: 'key2',
    btnTitle: allConstantVals.re_mortgaging.label,
    value: allConstantVals.re_mortgaging.value
  }
];
const specificReasonsSO = [
  { key: 'key1', btnTitle: allConstantVals.selling.label, value: allConstantVals.selling.value },
  {
    key: 'key2',
    btnTitle: allConstantVals.staircasing.label,
    value: allConstantVals.staircasing.value
  },
  {
    key: 'key3',
    btnTitle: allConstantVals.re_mortgaging.label,
    value: allConstantVals.re_mortgaging.value
  }
];

export function specificReasons(valReason) {
  const { help_to_buy, shared_ownership } = allConstantVals;
  if (valReason === help_to_buy.value) {
    return specificReasonsHTB;
  }
  if (valReason === shared_ownership.value) {
    return specificReasonsSO;
  }
  return null;
}

export const nonStandardProp = [
  {
    key: 'key1',
    btnTitle: 'Yes',
    value: 'Yes'
  },
  {
    key: 'key2',
    btnTitle: 'No',
    value: 'No'
  }
];
export const hadAlterInProp = [
  {
    key: 'key1',
    btnTitle: 'Yes',
    value: 'Yes'
  },
  {
    key: 'key2',
    btnTitle: 'No',
    value: 'No'
  }
];
export const hadPropExtended = [
  {
    key: 'key1',
    btnTitle: 'Yes',
    value: 'Yes'
  },
  {
    key: 'key2',
    btnTitle: 'No',
    value: 'No'
  }
];
export const planAlterInProp = [
  {
    key: 'key1',
    btnTitle: 'Yes',
    value: 'Yes'
  },
  {
    key: 'key2',
    btnTitle: 'No',
    value: 'No'
  }
];

// FORM3 OPTIONS DATA
export const propertyType = [
  { key: 'key1', btnTitle: allConstantVals.flat.label, value: allConstantVals.flat.value },
  {
    key: 'key2',
    btnTitle: allConstantVals.maisonette.label,
    value: allConstantVals.maisonette.value
  },
  { key: 'key3', btnTitle: allConstantVals.terraced.label, value: allConstantVals.terraced.value },
  {
    key: 'key4',
    btnTitle: allConstantVals.semi_detached.label,
    value: allConstantVals.semi_detached.value
  },
  { key: 'key5', btnTitle: allConstantVals.detached.label, value: allConstantVals.detached.value },
  { key: 'key6', btnTitle: allConstantVals.bungalow.label, value: allConstantVals.bungalow.value },
  { key: 'key7', btnTitle: allConstantVals.cottage.label, value: allConstantVals.cottage.value },
  {
    key: 'key8',
    btnTitle: allConstantVals.end_of_terrace.label,
    value: allConstantVals.end_of_terrace.value
  }
];
export const commonFieldErr = 'Please answer this question in order to proceed';
export const iDontKnowWaring =
  "If you don't know the answer, one of our advisors will review the property. The advisor will call if this impacts the price or availability.";
export const ewsRating = [
  { key: 'key1', btnTitle: allConstantVals.a1.label, value: allConstantVals.a1.value },
  { key: 'key2', btnTitle: allConstantVals.a2.label, value: allConstantVals.a2.value },
  { key: 'key3', btnTitle: allConstantVals.a3.label, value: allConstantVals.a3.value },
  { key: 'key4', btnTitle: allConstantVals.b1.label, value: allConstantVals.b1.value },
  { key: 'key5', btnTitle: allConstantVals.b2.label, value: allConstantVals.b2.value }
];
export const bedrooms = [
  { key: 'key1', btnTitle: allConstantVals['1'].label, value: allConstantVals['1'].value },
  { key: 'key2', btnTitle: allConstantVals['2'].label, value: allConstantVals['2'].value },
  { key: 'key3', btnTitle: allConstantVals['3'].label, value: allConstantVals['3'].value },
  { key: 'key4', btnTitle: allConstantVals['4'].label, value: allConstantVals['4'].value },
  { key: 'key5', btnTitle: allConstantVals['5'].label, value: allConstantVals['5'].value },
  { key: 'key6', btnTitle: allConstantVals['6'].label, value: allConstantVals['6'].value },
  { key: 'key7', btnTitle: allConstantVals['7'].label, value: allConstantVals['7'].value }
];
export const askingPrice = [
  {
    key: 'key1',
    btnTitle: allConstantVals.less_than_500000.label,
    value: allConstantVals.less_than_500000.value
  },
  {
    key: 'key2',
    btnTitle: allConstantVals['500000_749999'].label,
    value: allConstantVals['500000_749999'].value
  },
  {
    key: 'key3',
    btnTitle: allConstantVals['750000_999999'].label,
    value: allConstantVals['750000_999999'].value
  },
  {
    key: 'key4',
    btnTitle: allConstantVals['1000000_1249999'].label,
    value: allConstantVals['1000000_1249999'].value
  },

  {
    key: 'key5',
    btnTitle: allConstantVals['1250000_1499999'].label,
    value: allConstantVals['1250000_1499999'].value
  },
  {
    key: 'key6',
    btnTitle: allConstantVals['1500000_1749999'].label,
    value: allConstantVals['1500000_1749999'].value
  },
  {
    key: 'key7',
    btnTitle: allConstantVals['1750000_1999999'].label,
    value: allConstantVals['1750000_1999999'].value
  },
  {
    key: 'key8',
    btnTitle: allConstantVals['2000000_or_more'].label,
    value: allConstantVals['2000000_or_more'].value
  }
];
export const freeholdOrLease = [
  { key: 'key1', btnTitle: allConstantVals.freehold.label, value: allConstantVals.freehold.value },
  { key: 'key2', btnTitle: allConstantVals.leasehold.label, value: allConstantVals.leasehold.value }
];
export const yearsLeftOnLease = [
  {
    key: 'key1',
    btnTitle: allConstantVals['85_years_and_over'].label,
    value: allConstantVals['85_years_and_over'].value
  },
  {
    key: 'key2',
    btnTitle: allConstantVals['51_to_84_years'].label,
    value: allConstantVals['51_to_84_years'].value
  },
  {
    key: 'key3',
    btnTitle: allConstantVals['50_years_or_under'].label,
    value: allConstantVals['50_years_or_under'].value
  }
];

export const sType1 = 's_type_1';
export const sType2 = 's_type_2';
export const sType3 = 's_type_3';
export const sType4 = 's_type_4';

const leaseLeftYearHandler = (val) => {
  switch (val) {
    case allConstantVals['50_years_or_under'].value:
      return allConstantVals['50_years_or_under'].label;
    case allConstantVals['51_to_84_years'].value:
      return allConstantVals['51_to_84_years'].label;
    case allConstantVals['85_years_and_over'].value:
      return allConstantVals['85_years_and_over'].label;
    default:
      return null;
  }
};

const askingPriceHandler = (val) => {
  switch (val) {
    case allConstantVals.less_than_500000.value:
      return allConstantVals.less_than_500000.label;
    case allConstantVals['500000_749999'].value:
      return allConstantVals['500000_749999'].label;
    case allConstantVals['750000_999999'].value:
      return allConstantVals['750000_999999'].label;
    case allConstantVals['1000000_1249999'].value:
      return allConstantVals['1000000_1249999'].label;
    case allConstantVals['1250000_1499999'].value:
      return allConstantVals['1250000_1499999'].label;
    case allConstantVals['1500000_1749999'].value:
      return allConstantVals['1500000_1749999'].label;
    case allConstantVals['1750000_1999999'].value:
      return allConstantVals['1750000_1999999'].label;
    case allConstantVals['2000000_or_more'].value:
      return allConstantVals['2000000_or_more'].label;
    default:
      return null;
  }
};

const buildYearHandler = (val) => {
  const {
    before_1850,
    between_1850_1899,
    between_1900_1949,
    between_1950_1979,
    between_1980_2009,
    on_or_after_2010,
    i_dont_know
  } = allConstantVals;

  switch (val) {
    case before_1850.value:
      return before_1850.label;
    case between_1850_1899.value:
      return between_1850_1899.label;
    case between_1900_1949.value:
      return between_1900_1949.label;
    case between_1950_1979.value:
      return between_1950_1979.label;
    case between_1980_2009.value:
      return between_1980_2009.label;
    case on_or_after_2010.value:
      return on_or_after_2010.label;
    case i_dont_know.value:
      return i_dont_know.label;
    default:
      return null;
  }
};

const nonStandardObj = {
  Yes: 'No',
  No: 'Yes',
  'Not Sure': 'Not Sure'
};

export function QuotePageQnAs(leadData) {
  const { productData } = leadData;

  const {
    valuationJobType,
    surveyJobType,
    level2Name,
    level3Name,
    flat,
    leasehold,
    help_to_buy,
    shared_ownership,
    market_valuation,
    any_other_reason,
    yes,
    no,
    i_dont_know
  } = allConstantVals;
  let qnaType = '';
  if (productData?.job_type === valuationJobType.value) {
    qnaType = valuationJobType.value;
  } else if (productData?.job_type === surveyJobType.value) {
    if (productData?.survey_type === level2Name.value) {
      qnaType = level2Name.value;
    } else if (productData?.survey_type === level3Name.value) {
      qnaType = level3Name.value;
    }
  }
  // const surveyAndLevel2QnA1 = [
  //   {
  //     key: 'key1',
  //     question: 'Survey or valuation',
  //     answer: leadData.productData?.job_type
  //   },
  //   {
  //     key: 'key2',
  //     question: 'Survey type',
  //     answer: leadData.productData?.survey_type
  //   },
  //   {
  //     key: 'key3',
  //     question: 'Year of build',
  //     answer: leadData.productData?.build_year
  //   },
  //   {
  //     key: 'key4',
  //     question: 'Is the property a non-standard built (e.g. timber, steel, concrete)?',
  //     answer: leadData.productData?.non_standard_prop
  //   },
  //   {
  //     key: 'key5',
  //     question: 'Has the property had any large extensions or major alterations?',
  //     answer: leadData.productData?.had_alter_in_prop
  //   },
  //   {
  //     key: 'key6',
  //     question: 'Has the property been extended more than twice?',
  //     answer: leadData.productData?.had_prop_extented
  //   },
  //   {
  //     key: 'key7',
  //     question: 'Are you planning any extensions or major alterations?',
  //     answer: leadData.productData?.plan_alter_in_prop
  //   },
  //   {
  //     key: 'key8',
  //     question: 'Is the property in poor condition?',
  //     answer: leadData.productData?.is_prop_condtn_poor
  //   }
  // ];
  // const surveyAndLevel2QnA2 = [
  //   {
  //     key: 'key1',
  //     question: 'Property Postcode',
  //     answer: leadData.productData?.property_postcode
  //   },
  //   {
  //     key: 'key2',
  //     question: 'Property address',
  //     answer: leadData.productData?.property_address
  //   },
  //   {
  //     key: 'key3',
  //     question: 'Property type',
  //     answer: leadData.productData?.property_type
  //   },
  //   {
  //     key: 'key4',
  //     question: 'Bedrooms',
  //     answer: leadData.productData?.bedrooms
  //   },
  //   {
  //     key: 'key5',
  //     question: 'Asking price',
  //     answer: leadData.productData?.asking_price
  //   },
  //   {
  //     key: 'key6',
  //     question: 'Is the property a freehold or leasehold?',
  //     answer: leadData.productData?.freehold_or_lease
  //   },
  //   {
  //     key: 'key7',
  //     question: 'If leasehold, how many years left on lease?',
  //     answer: leadData.productData?.years_left_on_lease
  //   }
  // ];
  // const surveyAndLevel2QnA3 = [
  //   {
  //     key: 'key1',
  //     question: 'Historic timber frame/beams?',
  //     answer: leadData.productData?.historic_timber_frame_or_beams
  //   },
  //   {
  //     key: 'key2',
  //     question: 'Modern timber frame?',
  //     answer: leadData.productData?.mordern_timber_frame
  //   },
  //   {
  //     key: 'key3',
  //     question: 'Steel frame?',
  //     answer: leadData.productData?.steel_frame
  //   },
  //   {
  //     key: 'key4',
  //     question: 'Concrete Property?',
  //     answer: leadData.productData?.concrete_property
  //   },
  //   {
  //     key: 'key5',
  //     question: 'Ex-council Timber Frame?',
  //     answer: leadData.productData['ex-council_timber_frame']
  //   },
  //   {
  //     key: 'key6',
  //     question: 'Thatched Roof?',
  //     answer: leadData.productData?.thatched_roof
  //   }
  // ];
  // const surveyAndLevel2QnA4 = [
  //   {
  //     key: 'key1',
  //     question: 'Conservatory?',
  //     answer: leadData.productData?.conservatory
  //   },
  //   {
  //     key: 'key2',
  //     question: 'Grade II listed?',
  //     answer: leadData.productData?.grade_2_listed
  //   },
  //   {
  //     key: 'key3',
  //     question: 'Conservation Area?',
  //     answer: leadData.productData?.conservation_area
  //   },
  //   {
  //     key: 'key4',
  //     question: 'Swimming Pool?',
  //     answer: leadData.productData?.swimming_pool
  //   },
  //   {
  //     key: 'key5',
  //     question: 'Cellar?',
  //     answer: leadData.productData?.cellar
  //   },
  //   {
  //     key: 'key6',
  //     question: 'More than 5 rooms on ground floor?',
  //     answer: leadData.productData?.more_than_5_rooms_ground_floor
  //   },
  //   {
  //     key: 'key7',
  //     question: 'Land (excluding gardens)?',
  //     answer: leadData.productData?.land_excluding_gardens
  //   }
  // ];
  // eslint-disable-next-line no-unused-vars
  const surveyAndLevel3QnA = [
    {
      key: 'key1',
      question: 'Survey or valuation',
      answer: productData?.job_type
    },
    {
      key: 'key2',
      question: 'Survey type',
      answer:
        leadData.productData?.valuation === 'yes'
          ? 'Building Survey - With Valuation'
          : productData?.survey_type
    },
    {
      key: 'key3',
      question: 'Approximate year of build',
      answer: buildYearHandler(productData?.build_year_l3)
    },
    {
      key: 'key4',
      question: 'Property Postcode',
      answer: productData?.property_postcode
    },
    {
      key: 'key5',
      question: 'Property address',
      answer: productData?.property_address
    },
    {
      key: 'key6',
      question: 'Property type',
      answer: productData?.property_type
    },
    {
      key: 'key7',
      question: 'Bedrooms',
      answer: productData?.bedrooms
    },
    {
      key: 'key8',
      question: 'Agreed Price',
      answer: askingPriceHandler(productData?.asking_price)
    },
    {
      key: 'key9',
      question: 'Is the property a freehold or leasehold?',
      answer: productData?.freehold_or_lease
    },
    {
      key: 'key10',
      question: 'How many years left on lease?',
      answer:
        productData?.freehold_or_lease === leasehold.value
          ? leaseLeftYearHandler(productData?.years_left_on_lease)
          : unselected
    },
    // TODO: uncomment this
    // {
    //   key: 'key11',
    //   question: 'Historic timber frame/beams?',
    //   answer: productData?.historic_timber_frame_or_beams
    // },
    // {
    //   key: 'key12',
    //   question: 'Modern timber frame?',
    //   answer: productData?.mordern_timber_frame
    // },
    // {
    //   key: 'key13',
    //   question: 'Steel frame?',
    //   answer: productData?.steel_frame
    // },
    // {
    //   key: 'key14',
    //   question: 'Concrete Property?',
    //   answer: productData?.concrete_property
    // },
    // {
    //   key: 'key15',
    //   question: 'Ex-council Timber Frame?',
    //   answer: productData['ex-council_timber_frame']
    // },
    // {
    //   key: 'key16',
    //   question: 'Thatched Roof?',
    //   answer: productData?.thatched_roof
    // },
    {
      key: 'key17',
      question: 'Conservatory?',
      answer: productData?.conservatory
    },
    // TODO: uncomment this
    // {
    //   key: 'key18',
    //   // question: 'Grade II listed?',
    //   question: 'Is it a listed building?',
    //   answer: productData?.grade_2_listed
    // },
    // {
    //   key: 'key19',
    //   question: 'Conservation Area?',
    //   answer: productData?.conservation_area
    // },
    {
      key: 'key20',
      question: 'Swimming Pool?',
      answer: productData?.swimming_pool
    },
    {
      key: 'key21',
      question: 'Cellar?',
      answer: productData?.cellar
    }
    // {
    //   key: 'key22',
    //   question: 'More than 5 rooms on ground floor?',
    //   answer: productData?.more_than_5_rooms_ground_floor
    // },
    // TODO: uncomment this
    // {
    //   key: 'key23',
    //   question: 'Land (excluding gardens)?',
    //   answer: productData?.land_excluding_gardens
    // }
  ];
  // eslint-disable-next-line no-unused-vars
  const surveyAndLevel2QnA = [
    {
      key: 'key1',
      question: 'Survey or valuation',
      answer: productData?.job_type
    },
    {
      key: 'key2',
      question: 'Survey type',
      answer:
        leadData.productData?.valuation === 'yes'
          ? 'Homebuyer - With Valuation'
          : productData?.survey_type
    },
    // TODO: Remove key3 json and uncomment below one
    {
      key: 'key3',
      question: 'Approximate year of build',
      answer: buildYearHandler(productData?.build_year_l3)
    },
    // {
    //   key: 'key3',
    //   question: 'Approximate year of build',
    //   answer: buildYearHandler(productData?.build_year_l2)
    // },
    // {
    //   key: 'key4',
    //   question:
    //     'Is the property a standard built i.e. made of brick/stone with a tiled/slate roof?',
    //   answer: nonStandardObj[productData?.non_standard_prop]

    //   // productData?.non_standard_prop === 'Yes'
    //   //   ? 'No'
    //   //   : productData?.non_standard_prop === 'No'
    //   //   ? 'Yes'
    //   //   : ''
    // },
    // {
    //   key: 'key5',
    //   question: 'Has the property had any large extensions or major alterations?',
    //   answer: productData?.had_alter_in_prop
    // },
    // {
    //   key: 'key6',
    //   question: 'Has the property been extended more than twice?',
    //   answer: productData?.had_prop_extented
    // },
    // {
    //   key: 'key7',
    //   question: 'Are you planning any extensions or major alterations?',
    //   answer: productData?.plan_alter_in_prop
    // },
    // {
    //   key: 'key8',
    //   question: 'Does the property need major repairs?',
    //   answer: productData?.is_prop_condtn_poor
    // },
    {
      key: 'key9',
      question: 'Property Postcode',
      answer: productData?.property_postcode
    },
    {
      key: 'key10',
      question: 'Property address',
      answer: productData?.property_address
    },
    {
      key: 'key11',
      question: 'Property type',
      answer: productData?.property_type
    },
    {
      key: 'key12',
      question: 'Bedrooms',
      answer: productData?.bedrooms
    },
    {
      key: 'key13',
      question: 'Agreed Price',
      answer: askingPriceHandler(productData?.asking_price)
    },
    {
      key: 'key14',
      question: 'Is the property a freehold or leasehold?',
      answer: productData?.freehold_or_lease
    },
    {
      key: 'key15',
      question: 'How many years left on lease?',
      answer:
        productData?.freehold_or_lease === leasehold.value
          ? leaseLeftYearHandler(productData?.years_left_on_lease)
          : unselected
    },
    {
      key: 'key16',
      question: 'Conservatory?',
      answer: productData?.conservatory
    },
    // TODO: uncomment this
    // {
    //   key: 'key17',
    //   question: 'Grade II listed?',
    //   answer: productData?.grade_2_listed
    // },
    // {
    //   key: 'key18',
    //   question: 'Conservation Area?',
    //   answer: productData?.conservation_area
    // },
    {
      key: 'key19',
      question: 'Swimming Pool?',
      answer: productData?.swimming_pool
    },
    {
      key: 'key20',
      question: 'Cellar?',
      answer: productData?.cellar
    }
    // {
    //   key: 'key21',
    //   question: 'More than 5 rooms on ground floor?',
    //   answer: productData?.more_than_5_rooms_ground_floor
    // },
    // TODO: uncomment this
    // {
    //   key: 'key22',
    //   question: 'Land (excluding gardens)?',
    //   answer: productData?.land_excluding_gardens
    // }
  ];
  // eslint-disable-next-line no-unused-vars
  const valuationQnA = [
    {
      key: 'key1',
      question: 'Survey or valuation',
      answer: productData?.job_type
    },
    {
      key: 'key2',
      question: 'Valuation Reason',
      answer: productData?.val_reason
    },
    {
      key: 'key3',
      question: 'Specific reason',
      answer:
        productData?.val_reason === help_to_buy.value ||
        productData?.val_reason === shared_ownership.value ||
        productData?.val_reason === any_other_reason.value
          ? productData?.specific_reason
          : unselected
    },
    {
      key: 'key4',
      question: 'Is the valuation required for lending purposes?',
      answer:
        productData?.val_reason === market_valuation.value ||
        productData?.val_reason === any_other_reason.value
          ? productData?.val_for_lending
          : unselected
    },
    {
      key: 'key5',
      question: 'Is there a requirement to appear as expert witness?',
      answer:
        productData?.job_type === valuationJobType.value &&
        (productData?.val_for_lending === no.value ||
          productData?.val_for_lending === i_dont_know.value)
          ? productData?.requirement_expert_witness
          : unselected
    },
    {
      key: 'key6',
      question: 'Property Postcode',
      answer: productData?.property_postcode
    },
    {
      key: 'key7',
      question: 'Property address',
      answer: productData?.property_address
    },
    {
      key: 'key8',
      question: 'Property type',
      answer: productData?.property_type
    },
    {
      key: 'key9',
      question: 'Is the flat 4 storeys or above including ground floor?',
      answer:
        productData?.property_type === flat.value ? productData?.flat_4_storey_or_above : unselected
    },
    {
      key: 'key10',
      question: 'Do have an EWS form?',
      answer:
        productData?.flat_4_storey_or_above === yes.value ? productData?.have_ews_form : unselected
    },
    {
      key: 'key11',
      question: 'EWS Rating',
      answer: productData?.have_ews_form === yes.value ? productData?.ews_rating : unselected
    },
    {
      key: 'key12',
      question: 'Bedrooms',
      answer: productData?.bedrooms
    },
    {
      key: 'key13',
      question: 'Estimated Value',
      answer: askingPriceHandler(productData?.asking_price)
    },
    {
      key: 'key14',
      question: 'Is the property a freehold or leasehold?',
      answer: productData?.freehold_or_lease
    },
    {
      key: 'key15',
      question: 'How many years left on lease?',
      answer:
        productData?.freehold_or_lease === leasehold.value
          ? leaseLeftYearHandler(productData?.years_left_on_lease)
          : unselected
    }
  ];

  switch (qnaType) {
    // case sType1:
    //   return surveyAndLevel2QnA1;
    // case sType2:
    //   return surveyAndLevel2QnA2;
    // case sType3:
    //   return surveyAndLevel2QnA3;
    // case sType4:
    //   return surveyAndLevel2QnA4;
    case valuationJobType.value:
      return valuationQnA;
    case level2Name.value:
      return surveyAndLevel2QnA;
    case level3Name.value:
      return surveyAndLevel3QnA;
    default:
      return [];
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
