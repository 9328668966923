import Axios from 'axios';
import { allConstantVals } from '../Constants';

const { valuationJobType } = allConstantVals;

const authData = {
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD
  }
};
export const createLeadApi = async (leadData) => {
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}lead`,
      { ...leadData, web_stage: 'Personal' },
      authData
    );
    console.log('from create lead', response.data);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};

export const addressBasedOnPostcode = async (postcode) => {
  try {
    const response = await Axios.get(
      `https://api.getAddress.io/find/${postcode}?api-key=${process.env.REACT_APP_POSTCODE_LOOKUP_API_KEY}`
    );

    const { addresses, latitude, longitude } = response.data;
    const addressesObj = addresses.map((val, i) => {
      // Remove the leading and trailing commas, and then replace multiple consecutive commas with space ", " by single comma
      const updatedVal = val.replace(/^,|,$|(, )+/g, '$1');
      return { key: `key${i}`, value: updatedVal, label: updatedVal };
    });
    return { addresses: addressesObj, coordinates: { latitude, longitude } };
  } catch (err) {
    const { status } = err.response;

    switch (status) {
      case 400:
        throw new Error(
          JSON.stringify({
            status: err.response.status,
            message: 'Invalid Postcode'
          })
        );
      case 404:
        throw new Error(
          JSON.stringify({
            status: err.response.status,
            message: 'Not found'
          })
        );
      case 500:
        throw new Error(
          JSON.stringify({
            status: err.response.status,
            message: 'Something went wrong'
          })
        );
      default:
        throw new Error(
          JSON.stringify({
            status: err.response.status,
            message: 'Something went wrong'
          })
        );
    }
  }
};

export const checkPostcodeCoverage = async (postcode) => {
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}checkpostcode`,
      {
        data: postcode.trim()
      },
      authData
    );
    return response?.data;
  } catch (err) {
    console.log(err);
    if (err?.response) {
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: `Sorry, currently we have no local surveyor covering "${postcode}". We are likely to expand in your area soon.`,
          message: `Sorry, currently we have no local surveyor covering "${postcode}". We are likely to expand in your area soon.`
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};

export const updateLeadDataApi = async (prodData) => {
  try {
    // const bodyData = { interaction_id: prodData?.interaction_id, data: prodData?.updatedLeadData?.prod };
    const response = await Axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}web_data`,
      prodData,
      authData
    );
    console.log('from update lead', response.data);
    return response.data;
  } catch (err) {
    console.log(err.response, err.response?.data.error.detail, err.response?.data);
    if (err.response) {
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};

export const getQuoteApi = async (prodData) => {
  try {
    // const bodyData = { interaction_id: prodData?.interaction_id, data: prodData?.updatedLeadData?.prod };
    const response = await Axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}product?interaction_id=${prodData.interaction_id}&valuation=${prodData.valuation}`,
      authData
    );
    console.log(response.data, 'from quoteapi');
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};

export const getAppoinmentAvailablityApi = async (prodData) => {
  try {
    // const bodyData = { interaction_id: prodData?.interaction_id, data: prodData?.updatedLeadData?.prod };
    const response = await Axios.get(
      // `https://houzecheck.service-now.com/api/houch/hzchek_web_app/appointmentslot?postcode=${prodData.postcode}&product_id=${prodData.product_id}`,
      `${process.env.REACT_APP_API_ENDPOINT}appointmentslot?postcode=${prodData.postcode}&job_type=${prodData.job_type}&valuation=${prodData.valuation}`,
      // `${process.env.REACT_APP_API_ENDPOINT}appointmentslot?postcode=${prodData.postcode}&product_id=${prodData.product_id}`,
      authData
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.log('from err', err.response.data.error.detail, err.response.data.error.message);
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};

export const handlePaymentApi = async (instructionData) => {
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}instruction`,
      {
        lead_id: instructionData.leadDetail?.lead_id,
        product_id: instructionData.productData.quoteData?.product.id,
        appointment_slot_id: instructionData.productData.quoteData.timeSelected,
        // property_postcode: 'wd187ne',
        // property_type: instructionData.productData?.property_type,
        purpose:
          instructionData.productData?.job_type === valuationJobType.value
            ? valuationJobType.value
            : instructionData.productData?.survey_type,
        paymentDetail: instructionData.paymentDetail,
        ...instructionData.productData
      },
      authData
    );
    console.log('from handlePayment api', response.data, '..........', instructionData.leadDetail);
    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};

export const getLeadWebData = async (interactionID) => {
  try {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}web_data?interaction_id=${interactionID}`,
      authData
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.log('from err', err.response.data.error.detail, err.response.data.error.message);
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};
export const getReferralDataApi = async (referralId) => {
  try {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}referrer?ref_id=${referralId}`,
      authData
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.log('from err', err.response.data.error.detail, err.response.data.error.message);
      throw new Error(
        JSON.stringify({
          status: err.response.status,
          data: err.response.data.error.detail,
          message: err.response.data.error.message
        })
      );
    } else if (err.request) {
      throw new Error(
        JSON.stringify({
          status: 600,
          data: `Connection error ${err.request}`,
          message: ''
        })
      );
    }
    throw new Error(
      JSON.stringify({
        status: 600,
        data: `Connection error`
      })
    );
  }
};
