/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../Container';
import FormControl from '../FormControl';
import { updateLeadAction, updateStepAction } from '../../redux/actions/lead-actions';
import FormSubmitButtons from '../FormSubmitButtons';
import { allConstantVals, generalYesNo, commonFieldErr } from '../../Constants';
import FieldsErr from '../FieldsErr';
// import { updateLead } from '../../redux/features/lead-slice';
function FormFive() {
  const { surveyJobType, level2Name, stage_quote } = allConstantVals;
  const leadData = useSelector((state) => state.lead);
  const dispatch = useDispatch();

  const { productData } = leadData;
  const initialValues = {
    conservatory: productData?.conservatory || '',
    grade_2_listed: productData?.grade_2_listed || '',
    conservation_area: productData?.conservation_area || '',
    swimming_pool: productData?.swimming_pool || '',
    cellar: productData?.cellar || '',
    // more_than_5_rooms_ground_floor: productData?.more_than_5_rooms_ground_floor || '',
    land_excluding_gardens: productData?.land_excluding_gardens || ''
  };
  const validationSchema = Yup.object({
    conservatory: Yup.string().required(commonFieldErr),
    // grade_2_listed: Yup.string().required(commonFieldErr),
    // conservation_area: Yup.string().required(commonFieldErr),
    swimming_pool: Yup.string().required(commonFieldErr),
    cellar: Yup.string().required(commonFieldErr)
    // more_than_5_rooms_ground_floor: Yup.string().required(commonFieldErr),
    // land_excluding_gardens: Yup.string().required(commonFieldErr)
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fieldLabels = {
    conservatory: 'Does it have a conservatory?',
    grade_2_listed: 'Is it a listed building?',
    conservation_area: 'Is it in a conservation area?',
    swimming_pool: 'Does it have a swimming pool?',
    cellar: 'Has it got a cellar?',
    // more_than_5_rooms_ground_floor: 'Are there more than 5 rooms on the ground floor?',
    land_excluding_gardens: 'Is there any additional land (excluding gardens)?'
  };
  const onSubmit = (values) => {
    const updatedLeadData = {
      ...leadData,
      productData: {
        ...leadData.productData,
        ...values,
        web_stage: stage_quote.value,
        currentStep: 6
      }
    };
    dispatch(updateLeadAction(updatedLeadData));
  };

  const backBtnHandler = () => {
    // TODO: uncomment this
    // if (
    //   leadData.productData.job_type === surveyJobType.value &&
    //   leadData.productData.survey_type === level2Name.value
    // ) {
    //   dispatch(updateStepAction(3));
    // } else {
    //   dispatch(updateStepAction(4));
    // }
    dispatch(updateStepAction(3));
  };

  return (
    <Container>
      <div className="flex justify-between items-center">
        {/* <div>
          <p className="font-bold text-3xl md:text-4xl inline-block">Property details: </p>
          <p className="md:inline-block font-bold text-lg md:text-xl md:ml-1">
            Additional Features
          </p>
        </div> */}
        <p className="font-bold text-3xl md:text-4xl">Property Details</p>
        <div className="flex">
          <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
          {/* TODO: uncomment this */}
          {/* {leadData.productData?.job_type === surveyJobType.value &&
          leadData.productData.survey_type === level2Name.value ? (
            <div />
          ) : (
            <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
          )} */}
          <div className="w-4 h-4 border-2 border-primary-500 bg-primary-500 rounded-full mx-1" />
        </div>
      </div>
      <p>Does the property has any of the following additional features</p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ errors, touched }) => (
          <Form>
            <FormControl
              controlType="radio"
              label={fieldLabels.conservatory}
              name="conservatory"
              optionVals={generalYesNo}
              radioType="type3"
            />
            {/* TODO: uncomment this */}
            {/* <FormControl
              controlType="radio"
              label={fieldLabels.grade_2_listed}
              name="grade_2_listed"
              optionVals={generalYesNo}
              radioType="type3"
            />
            <FormControl
              controlType="radio"
              label={fieldLabels.conservation_area}
              name="conservation_area"
              optionVals={generalYesNo}
              radioType="type3"
            /> */}
            <FormControl
              controlType="radio"
              label={fieldLabels.swimming_pool}
              name="swimming_pool"
              optionVals={generalYesNo}
              radioType="type3"
            />
            <FormControl
              controlType="radio"
              label={fieldLabels.cellar}
              name="cellar"
              optionVals={generalYesNo}
              radioType="type3"
            />
            {/* <FormControl
              controlType="radio"
              label={fieldLabels.more_than_5_rooms_ground_floor}
              name="more_than_5_rooms_ground_floor"
              optionVals={generalYesNo}
              radioType="type3"
            /> */}
            {/* TODO: uncomment this */}
            {/* <FormControl
              controlType="radio"
              label={fieldLabels.land_excluding_gardens}
              name="land_excluding_gardens"
              optionVals={generalYesNo}
              radioType="type3"
            /> */}
            <FieldsErr fieldLabels={fieldLabels} errors={errors} touched={touched} />
            <FormSubmitButtons backBtnHandler={backBtnHandler} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default FormFive;
