/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Container({ children, backBtnHandler, noback, showBottomTxt }) {
  const navigate = useNavigate();
  // const goBack = () => {
  //   if (backBtnHandler) {
  //     console.log('from back');
  //     backBtnHandler();
  //   } else {
  //     navigate(-1);
  //   }
  // };
  // max-w-4xl
  const handleNewQuote = () => {
    localStorage.clear();
    navigate(0);
  };
  if (window.location.href.includes('partnerframe.houzecheck.com')) {
    // if (window.location.href.includes('localhost:3000')) {
    return <div className="bg-white p-5">{children}</div>;
  }
  return (
    <div className="container max-w-4xl px-8 md:px-16 py-12 md:mx-auto bg-white rounded-3xl shadow-lg">
      {/* {!noback && (
        <div
          className="text-secondary-600 font-bold flex items-center"
          role="button"
          onClick={goBack}
          onKeyDown={goBack}
          tabIndex={0}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          <p className="ml-3">Back</p>
        </div>
      )} */}
      {children}

      {showBottomTxt && (
        <div className="text-center">
          <p className="mt-8 text-sm text-secondary-500">
            If you need any assistance, please call us on{' '}
            <a href="tel:03301131133">
              <strong>0330 113 1133</strong>
            </a>{' '}
            during Mon to Fri, 9AM to 5:30PM.
          </p>
        </div>
      )}
    </div>
  );
}
Container.defaultProps = {
  noback: false,
  showBottomTxt: true
};

export default Container;
