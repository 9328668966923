import React from 'react';
import { useSelector } from 'react-redux';
import Trustbox from '../TrustBox';

function Footer() {
  const leadData = useSelector((state) => state.lead);
  if (window.location.href.includes('partnerframe.houzecheck.com')) {
    return (
      <footer className="text-center">
        <hr className="mt-3" />
        <p className="p-4 text-secondary-600 text-sm">© Houzecheck 2023 </p>
      </footer>
    );
  }
  return (
    <footer className="text-center">
      {leadData.productData?.partner_logo && (
        <p className="my-7 px-2">
          The service is facilitated by Houzecheck Limited. Houzecheck is rated excellent on
          Trustpilot
          <br /> Houzecheck Surveying Limited is RICS regulated.
        </p>
      )}
      <div className="flex justify-center">
        <img src="/images/rics-logo.png" alt="RICS logo" className="w-40 h-auto" />
        <img src="/images/powered_by_hc.svg" alt="RICS logo" className="h-auto ml-5" />
      </div>
      <div className="hidden md:block">
        <Trustbox />
      </div>
      <hr className="mt-3" />
      <p className="p-4 text-secondary-600 text-sm">© Houzecheck 2023 </p>
    </footer>
  );
}

export default Footer;
