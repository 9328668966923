import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import Container from '../Container';
import FormControl from '../FormControl';
import FormSubmitButtons from '../FormSubmitButtons';
import { updateLeadAction } from '../../redux/actions/lead-actions';
import Button from '../Button';
import withHandleGetAddress from '../../HOC/HandleGetAddress';

function ReportDetail({ getAndUpdateAddress }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leadData = useSelector((state) => state.lead);
  const [addressVals, setAddressVals] = useState([]);
  const [postcodeErr, setPostcodeErr] = useState({ status: false, message: '' });
  const [addressVals2, setAddressVals2] = useState([]);
  const [postcodeErr2, setPostcodeErr2] = useState({ status: false, message: '' });
  const [addOneMoreDetail, setAddOneMoreDetail] = useState(false);

  useEffect(() => {
    if (leadData.productData?.currentStep === 1) {
      navigate('/');
    }
    if (
      leadData.productData?.currentStep === 2 ||
      leadData.productData?.currentStep === 3 ||
      leadData.productData?.currentStep === 4 ||
      leadData.productData?.currentStep === 5
    ) {
      navigate('/product');
    } else if (leadData.productData?.currentStep === 6) {
      navigate('/payment');
    } else if (leadData.productData?.currentStep === 7) {
      navigate('/payment');
    } else if (leadData.productData?.currentStep === 8) {
      navigate('/confirmation');
    } else if (leadData.productData?.currentStep === 9) {
      navigate('/access-details');
    } else if (leadData.productData?.currentStep === 11) {
      navigate('/confirmation');
    }
  }, [leadData.productData?.currentStep]);

  const initialValues = {
    property_postcode: '',
    property_address: '',
    client_fname: '',
    client_lname: '',
    client_email: '',
    client_contact_number: '',
    housing_assoc_or_scheme_provider: '',
    curr_share_owned: '',
    name_of_deceased: '',
    date_of_death: '',
    name_of_executor: '',
    property_postcode2: '',
    property_address2: '',
    client_fname2: '',
    client_lname2: '',
    client_email2: '',
    client_contact_number2: ''
  };
  const validationSchema = Yup.object({
    property_postcode: Yup.string(),
    property_address: Yup.string(),
    client_fname: Yup.string(),
    client_lname: Yup.string(),
    client_email: Yup.string(),
    client_contact_number: Yup.string(),
    housing_assoc_or_scheme_provider: Yup.string(),
    curr_share_owned: Yup.string(),
    name_of_deceased: Yup.string(),
    date_of_death: Yup.string(),
    name_of_executor: Yup.string(),
    property_postcode2: Yup.string(),
    property_address2: Yup.string(),
    client_fname2: Yup.string(),
    client_lname2: Yup.string(),
    client_email2: Yup.string(),
    client_contact_number2: Yup.string()
  });

  const handleAnotherClientButton = (val, handleValues) => {
    console.log(handleValues, 'lllll');
    setAddOneMoreDetail(val);
    if (val === false) {
      handleValues('property_postcode2', '');
      handleValues('property_address2', '');
      handleValues('client_fname2', '');
      handleValues('client_lname2', '');
      handleValues('client_email2', '');
      handleValues('client_contact_number2', '');
    }
  };
  const onSubmit = (values) => {
    const updatedLeadData = {
      ...leadData,
      currentStep: 11,
      reportDetail: { ...leadData.reportDetail, ...values }
    };
    dispatch(updateLeadAction(updatedLeadData));
  };
  return (
    <Container>
      <div className="flex justify-between items-center">
        <p className="font-bold text-3xl md:text-4xl">Report Details</p>
        <div className="flex">
          <div className="w-4 h-4 border-2 border-primary-500 bg-white rounded-full mx-1" />
          <div className="w-4 h-4 border-2 border-primary-500 bg-primary-500 rounded-full mx-1" />
        </div>
      </div>
      <p>Confirm report details</p>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="md:flex flex-wrap">
              <FormControl
                controlType="input"
                type="text"
                name="client_fname"
                label="Client First name"
              />
              <FormControl
                controlType="input"
                type="text"
                name="client_lname"
                label="Client Last name"
              />
              <FormControl
                controlType="input"
                type="number"
                name="client_contact_number"
                label="Client Phone"
              />
              <FormControl
                controlType="input"
                type="email"
                name="client_email"
                label="Client Email"
              />
              <div className="md:w-full">
                <div className="md:flex">
                  <FormControl
                    controlType="input"
                    type="text"
                    name="property_postcode"
                    label="Postcode"
                    disabled={postcodeErr.status}
                  />
                  <FormControl
                    controlType="dropdown"
                    type="text"
                    name="property_address"
                    disabled={postcodeErr.status}
                    label="Address"
                    dropdownType="type2"
                    optionVals={addressVals}
                    onClickHandler={() =>
                      getAndUpdateAddress(values.property_postcode, setPostcodeErr, setAddressVals)
                    }
                  />
                </div>
                {postcodeErr.status && <p className="text-red-500">{postcodeErr.message}</p>}
              </div>
              {(leadData.productData?.val_reason === 'help_to_buy' ||
                leadData.productData?.val_reason === 'shared_ownership') && (
                <>
                  <FormControl
                    controlType="input"
                    type="text"
                    name="housing_assoc_or_scheme_provider"
                    label="Housing association or scheme provider "
                  />
                  <FormControl
                    controlType="input"
                    type="text"
                    name="curr_share_owned"
                    label="How much of share is currently owned?"
                  />
                </>
              )}
              {leadData.productData?.val_reason === 'probate' && (
                <>
                  <FormControl
                    controlType="input"
                    type="text"
                    name="name_of_deceased"
                    label="Name of the deceased"
                  />
                  <FormControl
                    controlType="input"
                    type="text"
                    name="date_of_death"
                    label="Date of death"
                  />
                  <FormControl
                    controlType="input"
                    type="text"
                    name="name_of_executor"
                    label="Name of the executor"
                  />
                </>
              )}
            </div>
            <div className="w-full text-center">
              <Button
                btnType="defaultOutlineBtn"
                customClass="mt-5"
                onClickHandler={() => handleAnotherClientButton(!addOneMoreDetail, setFieldValue)}>
                {addOneMoreDetail ? '-' : '+'} another client
              </Button>
            </div>
            {addOneMoreDetail && (
              <div className="md:flex flex-wrap">
                <FormControl
                  controlType="input"
                  type="text"
                  name="client_fname2"
                  label="Client2 First name"
                />
                <FormControl
                  controlType="input"
                  type="text"
                  name="client_lname2"
                  label="Client2 Last name"
                />
                <FormControl
                  controlType="input"
                  type="number"
                  name="client_contact_number2"
                  label="CLient2 Phone"
                />
                <FormControl
                  controlType="input"
                  type="email"
                  name="client_email2"
                  label="CLient2 Email"
                />
                <div className="md:w-full">
                  <div className="md:flex">
                    <FormControl
                      controlType="input"
                      type="text"
                      name="property_postcode2"
                      disabled={postcodeErr.status}
                      label="Postcode2"
                    />
                    <FormControl
                      controlType="dropdown"
                      type="text"
                      name="property_address2"
                      label="Address2"
                      dropdownType="type2"
                      disabled={postcodeErr.status}
                      optionVals={addressVals2}
                      onClickHandler={() =>
                        getAndUpdateAddress(
                          values.property_postcode2,
                          setPostcodeErr2,
                          setAddressVals2
                        )
                      }
                    />
                  </div>
                  {postcodeErr2.status && <p className="text-red-500">{postcodeErr2.message}</p>}
                </div>
              </div>
            )}
            <FormSubmitButtons />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default withHandleGetAddress(ReportDetail);
