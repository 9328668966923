/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import FormTwo from '../../components/FormTwo';
import { updateLead } from '../../redux/features/lead-slice';
import FormThree from '../../components/FormThree';
import FormFour from '../../components/FormFour';
import FormFive from '../../components/FormFive';
// import QuotePage from '../../components/QuotePage';
// import PaymentPage from '../../components/PaymentPage';
// import ThankyouPage from '../../components/ThankYouPage';
// import AccessDetail from '../../components/AccessDetail';
// import ReportDetail from '../../components/ReportDetail';

function Product() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  const { refId } = useParams();
  const redirectPage = (route) => {
    if (refId) {
      navigate(`/${refId}${route}`);
    } else {
      navigate(route);
    }
  };
  useEffect(() => {
    // if (leadData.currentStep === 1) {
    //   navigate('/');
    // } else if (leadData.currentStep === 6) {
    //   navigate('/quote');
    // } else if (leadData.currentStep === 7) {
    //   navigate('/payment');
    // } else if (leadData.currentStep === 8) {
    //   navigate('/confirmation');
    // } else {
    //   navigate('/');
    // }
    switch (leadData.productData?.currentStep) {
      case 1:
        redirectPage('');
        break;
      case 6:
        navigate('quote');
        break;
      case 7:
        navigate('payment');
        break;
      case 8:
        navigate('confirmation');
        break;
      // case 9:
      //   navigate('/access-details');
      //   break;
      // case 10:
      //   navigate('/report-details');
      //   break;
      default:
        // redirectPage('');
        break;
    }
  }, [leadData.productData?.currentStep]);

  return (
    <>
      {leadData.productData?.currentStep === 2 && <FormTwo />}
      {leadData.productData?.currentStep === 3 && <FormThree />}
      {leadData.productData?.currentStep === 4 && <FormFour />}
      {leadData.productData?.currentStep === 5 && <FormFive />}

      {/* <QuotePage /> */}
      {/* <PaymentPage /> */}
      {/* <ThankyouPage /> */}
      {/* <AccessDetail /> */}
      {/* <ReportDetail /> */}
    </>
  );
}

export default Product;
