/* eslint-disable no-unused-vars */
import React from 'react';

import SURVEYTNCPAGE from './Survey';
import HCTNCPAGE from './Houzecheck';
import VALUATIONTNCPAGE from './Valuation';
import { allConstantVals } from '../../Constants';

const { valuationJobType, surveyJobType } = allConstantVals;
function TnC({ jobType, name, companyName }) {
  if (jobType === valuationJobType.value) {
    return <VALUATIONTNCPAGE name={name} companyName={companyName} />;
  }
  if (jobType === surveyJobType.value) {
    return <SURVEYTNCPAGE name={name} companyName={companyName} />;
  }
  return <HCTNCPAGE />;
}

function SurveyTnc({ name, companyName }) {
  return (
    <div className="text-justify">
      <p className="text-xl text-center">
        <strong>
          TERMS AND CONDITIONS OF ENGAGEMENT <br />
          RICS Home Survey Level 2 Report / HomeBuyer Report
          <br />
          RICS Home Survey Level 3 Report / Building Survey
        </strong>
      </p>
      <br />
      <br />
      <p>
        <strong> Confirmation of Appointment</strong>
      </p>
      <p>
        We refer to your instructions received requiring <strong> The Surveyor</strong> to carry out
        and to prepare and deliver a <strong> Survey Report</strong> on the{' '}
        <strong> Subject Property</strong>. The <strong> Survey Report</strong> will be either a
        RICS Home Survey Level 2 Report (formerly known as an RICS HomeBuyer Report) or a RICS Home
        Survey Level 3 Report (formerly known as an RICS Building Survey). The type of report will
        be specified in the separate<strong> Instruction Email</strong> for which you will confirm
        acceptance of the Terms of Engagement. We accept the instructions only on the basis that the
        scope, purpose, and conditions of the report are as set out in the{' '}
        <strong> Instruction Email</strong> and the Terms and Conditions for the{' '}
        <strong> Survey Report</strong>.
      </p>
      <br />
      <br />
      <p>
        <strong> The Service</strong>
      </p>
      <p>
        These Terms and Conditions of Engagement should be referred to and used in conjunction with
        the <strong> Instruction Email</strong>, setting out the Client name and address,
        instruction type, reason for instruction and fee/price agreed.{' '}
        <strong> The Surveyor</strong> details are included in the{' '}
        <strong> Instruction Email</strong> and these Terms as per below.
        <br />
        <br />
        The <strong> Survey Report</strong> requires disclosure of any previous business
        relationship we may have with you, your property, or your selling agent. If appropriate,
        disclosure has been made. The scope and extent of the inspection is detailed in the{' '}
        <strong> Description of Service</strong>, inclusive of the Terms and Conditions. The{' '}
        <strong> Survey Report</strong> will be prepared by a suitably qualified Surveyor in an
        objective way regarding the condition of the Property on the day of the inspection, and who
        is a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the
        membership grades: AssocRICS, MRICS, or FRICS.
        <br />
        <br />
        We are required by RICS to seek formal confirmation from you that you are satisfied that the
        <strong> Survey Report</strong> Terms and Conditions meet your requirements, and this will
        be deemed satisfied by you making payment for this <strong> Service</strong> or services, as
        stated in the Instruction Email by way of timed and dated verifiable email confirmation.
        <br />
        <br />
        Complaints: <strong> The Company</strong> operates a Complaints Handling Procedure in
        accordance with RICS guidelines. A copy is available upon request.
        <br />
        <br />
        Any reference to ‘valuation’ or ‘valuations’ in these Terms and Conditions relate to the
        <strong> Valuation</strong> element of an instructed <strong> Service</strong> and{' '}
        <strong> Survey Report</strong> where an optional additional valuation service has been
        selected by <strong> The Client</strong> and has agreed to be undertaken by{' '}
        <strong> The Surveyor</strong>, for which an additional fee has been agreed with{' '}
        <strong> The Client</strong>.
        <br />
        <br />
        The <strong> Survey Report</strong> does not include:
        <ul>
          <li>a valuation</li>
          <li>buildings insurance reinstatement</li>
          <li>cost estimates</li>
        </ul>
        <br />
        These services can be provided as an additional service and are subject to additional terms
        and conditions and fees.
      </p>
      <br />
      <br />
      <p>
        <strong> The Definitions</strong>
      </p>
      <p>
        <strong> The Company:</strong> Houzecheck Limited with company number 11223619 and
        registered office at 30-40 St. Albans Road, Watford, Hertfordshire, WD17 1RN.
        <br />
        <br />
        <strong> The Surveyor:</strong> {name} of {companyName}
        <br />
        <br />
        <strong> The Client:</strong> The person or persons detailed in the{' '}
        <strong> Instruction Email</strong> providing instructions for the <strong> Service</strong>{' '}
        and <strong> Survey Report</strong> to be provided for their own use. In this context,{' '}
        <strong> The Client</strong> and the customer have the same meaning.
        <br />
        <br />
        <strong> Instruction Email:</strong> The type of report will be specified in the separate
        <strong> Instruction Email</strong> for which you will confirm acceptance of the Terms of
        Engagement. This is the email communication which the company will send to you{' '}
        <strong> The Client</strong>, to set out the details of the <strong> Service</strong> and
        <strong> Survey Report</strong> to be undertaken. We accept the instructions only on the
        basis that the scope, purpose, and conditions of the report are as set out in the
        <strong> Instruction Email</strong> and the Terms and Conditions for the{' '}
        <strong> Survey Report.</strong>
        <br />
        <br />
        <strong> Property:</strong> An interest in a self-contained domestic dwelling as defined by
        The Town and Country Planning (Use Classes) Order 1987 (as amended) being classified as Use
        Class C3 which may also include small HMOs within Use Class C4. In the context of the
        <strong> Service</strong> and the <strong> Property</strong> to be inspected, property is
        also referred to and defined as <strong> The Subject Property</strong> as specified in the
        <strong> Instruction Email.</strong>
        <br />
        <br />
        <strong> Subject Property:</strong> The <strong> Property</strong> interest which is
        physically inspected in performing the <strong> Service</strong> and carrying out the{' '}
        <strong> Survey Report</strong> which is detailed in the{' '}
        <strong> Instruction Email.</strong>
        <br />
        <br />
        <strong> Survey Report:</strong> The report undertaken by the <strong> Surveyor</strong>,
        the format of which will follow a template as set out in the attached documents “Description
        of the RICS Home Survey – Level 2 (survey only)”; “Description of the RICS Home Survey –
        Level 2 (survey and valuation)”; or “Description of the RICS Home Survey – Level 3”, as
        applicable.
        <br />
        <br />
        <strong> Description of Service:</strong> The Royal Institution of Chartered Surveyors
        (RICS) publication documents which describe the extent of service for the RICS Home Survey
        Level 2 Report service and the RICS Home Survey Level 3 Report service.
        <br />
        <br />
        <strong> Valuation:</strong> Unless otherwise stated in the report, the valuation, where
        provided as an additional service, will be for the interest in{' '}
        <strong> The Subject Property</strong>, of its existing tenure and title, use and form. The
        <strong> Valuation</strong> will, as an additional service, therefore form part of the
        Survey Report.
        <br />
        <br />
        <strong> Valuation Date:</strong> Shall be the Inspection Date of{' '}
        <strong> The Subject Property</strong> unless otherwise stipulated in the report.
        <br />
        <br />
        <strong> The Service</strong>: <strong> The Service</strong> provided by the{' '}
        <strong> Company</strong> in arranging for <strong> The Surveyor</strong> to provide{' '}
        <strong> The Client</strong> with a <strong> Survey Report</strong> which may include
        Valuation advice as an additional service.
        <br />
        <br />
        <strong> Market Value (MV)</strong>: The estimated amount for which an asset or liability
        should exchange on the valuation date between a willing buyer and a willing seller in an
        arm’s length transaction after proper marketing and where the parties had each acted
        knowledgeably, prudently and without compulsion.
        <br />
        <br />
        <strong>
          Statutory Definition of Market Value (capital gains tax, inheritance tax and stamp duty
          land tax).
        </strong>{' '}
        Summary definition derived from legislation: ‘The price which the property might reasonably
        be expected to fetch if sold in the open market at that time, but that price shall not be
        assumed to be reduced on the grounds that the whole property is to be placed on the market
        at one and the same time.’ (Source: section 272 Taxation and Chargeable Gains Act 1992.
        Section 160 Inheritance Tax Act 1984, Section 118 Finance Act 2003).
        <br />
        <br />
        <strong> Market Rent (MR)</strong>: The estimated amount for which an interest in real
        property should be leased on the valuation date between a willing lessor and a willing
        lessee on appropriate lease terms in an arm’s length transaction, after proper marketing and
        where the parties had each acted knowledgeably, prudently and without compulsion.
        <br />
        <br />
        Our assumptions of what are ‘appropriate lease terms’ for this property will be set out in
        our report.
        <br />
        <br />
        <strong> RICS Monitoring: The Company</strong> and <strong> The Surveyor</strong> are
        regulated by the Royal Institution of Chartered Surveyors, (RICS).
        <br />
        <br />
        Please note that our files may be subject to monitoring under the Institution’s conduct and
        disciplinary regulations and will be provided to RICS on request.
      </p>
      <br />
      <br />
      <ol className="list-[upper-alpha]">
        <li>
          <strong> GENERAL TERMS</strong>
          <br />
          <ol>
            <li>
              <strong> The Client</strong> wishes <strong> The Company</strong> to arrange for{' '}
              <strong> The Surveyor</strong> to provide the <strong> Survey Report</strong> being an
              opinion of condition of <strong> The Subject Property</strong> interest as specified
              by the Client.
            </li>
            <li>
              Subject to additional and agreed instructions to the effect,{' '}
              <strong> The Surveyor</strong> shall provide to <strong> The Client</strong> an
              opinion of value within <strong> The Survey Report</strong> based upon an inspection
              of <strong> The Subject Property</strong> by <strong> The Surveyor</strong> who shall
              be: a member of the Royal Institution of Chartered Surveyors (RICS), also a member of
              RICS Valuer Registration Scheme (VRS); and setting out the opinion of value of the
              relevant interest in the <strong> Property</strong>.
            </li>
            <li>
              Any <strong> Valuation</strong> within the <strong> Survey Report</strong> will be
              prepared in accordance with the RICS Valuation &ndash; Global Standards effective 31
              January 2022, the RICS Valuation &ndash; Global Standard 2017: UK National Supplement
              effective 14 January 2019, and relevant UK VPGAs.
            </li>
            <li>
              <strong> Introduction. </strong>
              These Terms of Engagement set out the conditions upon which{' '}
              <strong> The Company</strong> and <strong> The Surveyor</strong> accept instructions,
              and will apply in all cases unless any other arrangement is confirmed in writing by
              <strong> The Company</strong>.
            </li>
            <li>
              <strong> Appointment. The Client</strong> appoints <strong> The Surveyor</strong> to
              provide <strong> The Survey Report</strong>, exercising reasonable skill and care.
            </li>
            <li>
              <strong> Performance Standards. </strong>The Surveyor confirms that the survey will be
              carried out in accordance with the best practice guidelines for RICS professionals
              carrying out a RICS Home Survey Level 2 Report service and the RICS Home Survey Level
              3 Report service.
            </li>
            <li>
              <strong> Liability and Duty of Care. The Surveyor</strong> owes to the{' '}
              <strong> Client</strong> a duty to act with reasonable skill and care in providing{' '}
              <strong> The Survey Report</strong> and complying with the <strong> Client’s</strong>{' '}
              instructions where those instructions do not conflict with these terms or applicable
              laws and professional rules.
              <br />
              <br />
              <strong> The Company</strong> and <strong> The Surveyor</strong> have no liability for
              the consequences, including delay in or failure to provide{' '}
              <strong> The Service</strong> or <strong> The Survey Report</strong>, of any failure
              by
              <strong> The Client</strong> or any agent of <strong> The Client</strong>: promptly to
              provide information or other material reasonably requested, or where that material is
              inaccurate or incomplete, or to follow our advice or recommendations.
              <br />
              <br />
              We confirm that <strong> The Surveyor</strong> holds professional indemnity insurance
              in respect of <strong> The Survey Report</strong> to be provided.
            </li>
            <li>
              <strong> The Surveyor. The Survey Report</strong> is to be provided by an AssocRICS,
              MRICS or FRICS qualified member of the Royal Institution of Chartered Surveyors, who
              has the skills, knowledge and experience to survey and report on the{' '}
              <strong> Subject Property.</strong>
            </li>
            <li>
              <strong> Report Delivery.</strong> Post inspection, <strong> The Company</strong> and{' '}
              <strong> The Surveyor</strong> aim to deliver the report within 5 working days. In an
              exceptional scenario, the report may take longer. The report will be sent to the
              Client’s email address (or other agreed email address as applicable).{' '}
              <strong> The Company</strong> and <strong> The Surveyor</strong> cannot be held liable
              for any loss arising due to delay in Report delivery.
            </li>
            <li>
              <strong> The Client’s Obligations. The Client</strong> shall pay{' '}
              <strong> The Company</strong> in respect of the fee including VAT, to be agreed
              between
              <strong> The Client</strong> and <strong> The Company</strong> and detailed in the
              <strong> Instruction Email</strong>. The fee is payable at the time of{' '}
              <strong> The Company</strong>
              being engaged to perform The Service. Please note that such fee will not be treated by
              RICS as <strong> Client</strong> money and will not be subject to the protection of
              the RICS Client Money Protection Scheme.
              <br />
              <br />
              The <strong> Client</strong> will be responsible for the timely provision of all
              information and reasonable cooperation, including that from third parties engaged
              directly by the <strong> Client</strong>, required by the <strong> Company</strong>{' '}
              and the <strong> Surveyor</strong> to assist in the performance of{' '}
              <strong> The Service</strong> and delivery of <strong> The Survey Report</strong>. We
              will assume that the information provided is complete and correct.
            </li>
            <li>
              <strong> Cancellation. The Client</strong> can cancel this contract by notifying{' '}
              <strong> The Company</strong> in writing (email or letter).{' '}
              <strong> The Client</strong> is liable to pay one of the following cancellation fees
              depending upon when the notice of cancellation is received. Where notice of
              cancellation received from <strong> The Client</strong>:
              <br />
              <ol className="list-[lower-alpha]">
                <li>
                  Before 2pm the day before scheduled appointment: £40.00 including VAT to cover
                  administrative costs.
                </li>
                <li>
                  After 2pm the day before scheduled appointment, but prior to inspection taking
                  place: 50% of the agreed fee.
                </li>
                <li>
                  After the inspection of the subject property: 100% of the agreed fee. And note:
                  <ol className="list-[lower-roman]">
                    <li>
                      <strong> The Company</strong> can cancel the contract if{' '}
                      <strong> The Surveyor</strong> after arriving at the{' '}
                      <strong> Property</strong>, concludes: that it is of a type of property he or
                      she has insufficient specialist knowledge to be able to provide the{' '}
                      <strong> Survey Report</strong> satisfactorily. In case of this cancellation
                      by <strong> The Surveyor</strong>, any money paid by{' '}
                      <strong> The Client</strong> for the Service and{' '}
                      <strong> The Survey Report</strong> will be refunded in full.
                    </li>
                    <li>
                      <strong> The Company</strong> can cancel the contract if{' '}
                      <strong> The Surveyor</strong> finds that{' '}
                      <strong> The Subject Property</strong> is demonstrably different from that
                      described by <strong> The Client</strong> at the time of providing
                      instructions for the service. In case of this cancellation by{' '}
                      <strong> The Company</strong> and/or <strong> The Surveyor</strong>, any money
                      paid by the <strong> Client</strong> for the service and{' '}
                      <strong> The Survey Report</strong> will be refunded, less a cancellation fee
                      of £40.00 including VAT to cover administrative costs. In the event of{' '}
                      <strong> The Surveyor</strong> having already attended the subject property, a
                      refund of any monies paid by the Client for the service and the Survey Report
                      will be reduced by 50% of the fee.
                    </li>
                    <li>
                      The Consumer Contracts (Information, Cancellation and Additional Charges)
                      Regulations 2013 shall not apply to this contract which cannot be cancelled
                      once <strong> The Subject Property</strong> has been inspected by{' '}
                      <strong> The Surveyor</strong>. Once we have provided you with our report, you
                      will lose the right to cancel during the 14 day cooling off period.
                    </li>
                    <li>
                      <strong> The Company</strong> also has the right to cancel this contract, if
                      for reasons beyond its control, it is unable to fulfil the contract. In this
                      event <strong> The Company</strong> will provide a full refund of any fees
                      paid.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong> No-shows and Re-scheduling.</strong> In circumstances where an appointment
              visit to access <strong> The Subject Property</strong> does not proceed due to factors
              beyond the control of <strong> The Company</strong> or <strong> The Surveyor</strong>{' '}
              and/or where the third-party appointee is not present or available, i.e. No-shows.{' '}
              <br />
              <br />
              Where <strong> The Surveyor</strong> fails to get access to the property for any
              reason, 50% of the agreed fee will be payable to <strong> The Company</strong> by{' '}
              <strong> The Client.</strong> <br />
              <br />
              <strong> The Client</strong> can re-schedule the agreed appointment by notifying{' '}
              <strong> The Company</strong> in writing (email or letter).{' '}
              <strong> The Client</strong> is liable to pay the following fees for late requests
              and/or no-shows:
              <ol className="list-[lower-alpha]">
                <li>
                  No fee: Rescheduling requests received by 2pm the day before scheduled
                  appointment.
                </li>
                <li>
                  25% of the agreed fee: request received after 2pm the day before scheduled
                  appointment, payable in addition to the agreed fee.
                </li>
                <li>
                  50% of the agreed fee: request received on day of scheduled appointment, where
                  this request is received by the <strong> Surveyor</strong> and{' '}
                  <strong> Company</strong> before the access inspection has commenced, in addition
                  to the agreed fee.
                </li>
              </ol>
            </li>
            <li>
              <strong> Late Payments and Non-Payments.</strong> All fees as required for the
              provision of <strong> The Service</strong> and <strong> The Survey Report</strong>{' '}
              will be paid by the Client to <strong> The Company</strong> prior to{' '}
              <strong> The Service</strong> being carried out.
              <br />
              <br />
              All fees that remain unpaid for 14 calendar days from the due date will incur an
              additional late payment fee of £50.00.
              <br />
              <br />
              Any payment outstanding for 28 calendar days after the due date will in addition be
              referred to a debt recovery process. Any additional costs incurred in employing such a
              process will be charged to the Client.
            </li>
            <li>
              <strong> Jurisdiction.</strong> English law shall apply in every respect in relation
              to the service and the agreement with the <strong> Client</strong> which shall be
              deemed to have been made in England. In the event of any dispute arising in connection
              with
              <strong> The Survey Report</strong> or <strong> The Valuation</strong>, including any
              third party using <strong> The Survey Report</strong> and/or{' '}
              <strong> Valuation</strong>, the parties will submit to the jurisdiction of the
              English courts only. Any dispute shall be subject to <strong> The Company’s</strong>{' '}
              Complaints Handling Procedure.
            </li>
            <li>
              <strong> Miscellaneous Provisions:</strong>
              <br />
              <ol className="list-[lower-alpha]">
                <li>
                  In the event of one of the Terms and Conditions herein being held to be
                  unenforceable, the remainder of the contract is not affected.
                </li>
                <li>
                  The <strong> Survey Report</strong> will be conveyed to the{' '}
                  <strong> Client</strong> electronically and in the event that a printed hard copy
                  report is required, the
                  <strong> Client</strong> undertakes to arrange this at the{' '}
                  <strong> Client&apos;s</strong> cost.
                </li>
                <li>
                  Failure to follow the requirements set down by these conditions will invalidate
                  <strong> The Survey Report</strong> and any additional valuation.
                </li>
                <li>
                  Dispute resolution: In the event that <strong> The Client</strong> has a complaint
                  regarding the standard of <strong> The Service</strong> and/or{' '}
                  <strong> The Survey Report</strong>, a formal Complaint Handling Procedure will be
                  followed. Copies of <strong> The Surveyor’s</strong> Complaint Handling Procedure
                  and <strong> The Company’s</strong> Complaint Handling Procedure are available
                  upon request. Using the Complaints Handling Procedures will not affect the
                  Client&apos;s legal rights.
                </li>
                <li>
                  <strong> Data Protection: The Company</strong> takes its responsibility under the
                  General Data Protection Regulations very seriously. Our Privacy Policy details how
                  we collect, use, maintain and disclose personal data. A copy of our Privacy Policy
                  is available upon request.
                </li>
                <li>
                  <strong> Intellectual Property:</strong> All intellectual property rights arising
                  out of <strong> The Survey Report</strong> and any valuation associated with it
                  shall be owned by <strong> The Company</strong>, excluding the personal Customer
                  Information.
                </li>
                <li>
                  <strong> Referral fees: The Company</strong> as a regulated firm and{' '}
                  <strong> The Surveyor</strong>
                  confirms that a payment has been or may be made, either individually or part of a
                  third-party commercial relationship. Both <strong> The Company</strong> and{' '}
                  <strong> The Surveyor</strong> are satisfied that this does not constitute a
                  conflict of interest.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong> TERMS & CONDITIONS RELATING TO RICS HOME SURVEYS</strong>
          <br />
          <br />
          <ol>
            <li>
              <strong> Scope of the Inspection</strong>
              <ol className="list-[lower-alpha]">
                <li>
                  Generally, <strong> The Surveyor</strong> will consider his or her advice
                  carefully, but is not required to advise on any matter the significance of which
                  in relation to <strong> The Subject Property</strong> is not apparent at the time
                  of inspection from the inspection itself.
                </li>
                <li>
                  <strong> The Surveyor</strong> will inspect diligently, but is not required to
                  undertake any action which would risk damage to the Property or injury to
                  themselves.
                </li>
                <li>
                  <strong> The Surveyor</strong> will not undertake any structural or other similar
                  calculations.
                  <ol className="list-[lower-roman]">
                    <li>
                      <strong> Areas not Inspected</strong>
                      <br />
                      <strong> The Surveyor</strong> will identify any areas of{' '}
                      <strong> The Subject Property</strong>
                      which could not be adequately accessed or inspected during the inspection
                      (relative to the RICS Home Survey Level 2 Report service and the RICS Home
                      Survey Level 3 Report service standard scope of inspection specification), and
                      as such these details are referred to in <strong> The Survey Report</strong>.
                      <br />
                      In the event that <strong> The Surveyor</strong> is requested to re-visit{' '}
                      <strong> The Subject Property</strong> to inspect areas that{' '}
                      <strong> The Surveyor</strong> was unable to inspect during the original
                      visit, due to reasons beyond <strong> The Surveyor’s</strong> control, an
                      additional fee will be payable.
                    </li>
                    <li>
                      <strong> Flats or Maisonettes</strong>
                      <br />
                      Unless otherwise agreed, <strong> The Surveyor</strong> will inspect only the
                      subject flat and garage (if any), the related internal and external common
                      parts and the structure of the building or particular block in which the
                      subject flat is situated. Other flats will not be inspected.{' '}
                      <strong> The Surveyor</strong> will state in the report the limits of access
                      and/or visibility in relation to the common parts and structure.{' '}
                      <strong> The Surveyor</strong> will state whether he or she has seen a copy of
                      the lease and, if not, the assumptions as to preparing obligations on which he
                      or she is working.
                    </li>
                    <li>
                      <strong> Environmental and Other Issues</strong>
                      <br />
                      <ol>
                        <li>
                          Particular noise and disturbance affecting{' '}
                          <strong> The Subject Property</strong> will only be noted if it is
                          significant at the time of the inspection or if specific investigation has
                          been agreed between <strong> The Surveyor</strong> and{' '}
                          <strong> The Client</strong> and confirmed in writing.
                        </li>
                        <li>
                          <strong> The Surveyor</strong> will report on any obvious health and
                          safety hazards to the extent that they are apparent from elements of{' '}
                          <strong> The Subject Property</strong> considered as part of the
                          inspection.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong> Ground Conditions</strong>
              <br />
              <strong> The Surveyor</strong> will not be required to comment upon the possible
              existence of noxious substances, landfill or mineral extraction, or other forms of
              contamination.
            </li>
            <li>
              <strong> Consents, Approvals and Searches</strong>
              <br />
              <ol className="list-[lower-alpha]">
                <li>
                  <strong> The Surveyor</strong> will be entitled to assume that{' '}
                  <strong> The Subject Property</strong> is not subject to any unusual or onerous
                  restrictions, obligations or covenants which apply to{' '}
                  <strong> The Subject Property</strong> or affect the reasonable enjoyment of{' '}
                  <strong> The Subject Property.</strong>
                </li>
                <li>
                  <strong> The Surveyor</strong> will be entitled to assume that all planning,
                  building regulations and other consents required in relation to the{' '}
                  <strong> Property</strong> have been obtained. <strong> The Surveyor</strong> will
                  not verify whether such consents have been obtained. Any enquiries should be made
                  by the <strong> Client</strong> or the <strong> Client&apos;s</strong> legal
                  advisers. Drawings and specifications will not be inspected by the{' '}
                  <strong> Surveyor</strong> unless otherwise previously agreed.
                </li>
                <li>
                  The <strong> Surveyor</strong> will be entitled to assume that{' '}
                  <strong> The Subject Property</strong> is unaffected by any matters which would be
                  revealed by a Local Search and replies to the usual enquiries, or by a Statutory
                  Notice, and that neither <strong> The Subject Property</strong>, nor its
                  condition, its use or its intended use, is or will be unlawful.
                </li>
              </ol>
            </li>
            <li>
              <strong> Content of the Survey Report</strong>
              <br />
              The content of <strong> The Survey Report</strong> will be as per the RICS standard
              template for RICS Home Survey Level 2 Report service and the RICS Home Survey Level 3
              Report service. The description of the service document “Description of the RICS Home
              Survey – Level 2 (survey only)”; “Description of the RICS Home Survey – Level 2
              (survey and valuation)”; or “Description of the RICS Home Survey – Level 3” have been
              provided to you with our confirmation of instructions by electronic mail in{' '}
              <strong> The Instruction Email.</strong>
            </li>
            <li>
              <ol className="list-[lower-alpha]">
                <li>
                  <strong> Before the Inspection</strong>
                  <br />
                  This period forms an important part of the relationship between the{' '}
                  <strong> Surveyor</strong> and the <strong> Client</strong>. The commissioned
                  <strong> Surveyor</strong> will use reasonable endeavours to contact the{' '}
                  <strong> Client</strong> to understand particular concerns about the property and
                  explain (where necessary) the extent and/or limitations of the inspection and
                  report.
                </li>
                <li>
                  <strong> After the inspection</strong>
                  <br />
                  The commissioned <strong> Surveyor</strong> will use reasonable endeavours to
                  contact the <strong> Client</strong> after the inspection to discuss the major
                  points and issues found. The content of this conversation will not in any way
                  override the content of the report. The report will take precedence in all cases.
                </li>
              </ol>
            </li>
            <li>
              <strong> Limitations</strong>
              <br />
              Where advice or opinions are required outside the reasonable professional skill and
              expertise of the <strong> Surveyor</strong>, he or she will advise you on the need for
              further specialist involvement where we consider this to be necessary for the
              completion of your instruction(s).
            </li>
            <li>
              <strong> Insurance Rebuilding Cost Assessment</strong>
              <br />
              <strong> The Surveyor</strong> will provide an insurance rebuilding cost assessment
              only if this is agreed at the time of taking instructions. The Reinstatement Cost
              figure{' '}
              <em>
                (If requested in <strong> the Client</strong> instruction)
              </em>{' '}
              for the permanent buildings is provided for insurance purposes and is not directly
              related to the market value of the property. The reinstatement cost will be calculated
              by reference to the indices and guidance published by the Building Costs Information
              Service (BCIS). The figure stated will only include Value Added Tax on professional
              fees (not on building costs) and will not take into account other potential or
              consequential losses such as costs of demolition, site clearance, provision of
              services, alternative accommodation, personal items or any other contents or
              contingent costs.
            </li>
            <li>
              <strong> Force Majeure</strong>
              <br />
              Whilst every reasonable effort will be made to carry out the inspection at the
              date/time agreed, we cannot be held liable for any losses caused by matters outside
              our control, such as, but not exclusively: Surveyor illness; traffic/vehicle
              delay/breakdown; extreme weather conditions; or vendor unavailability.
            </li>
            <li>
              <strong> Miscellaneous</strong>
              <br />
              <ol className="list-[lower-alpha]">
                <li>
                  Unless expressly provided, no term in the agreement between the{' '}
                  <strong> Surveyor</strong> and the <strong> Client</strong> is enforceable under
                  the Contracts (Rights of Third Parties) Act 1999 by any person other than the{' '}
                  <strong> Surveyor</strong> or the <strong> Client</strong>.
                </li>
                <li>
                  <strong> The Surveyor</strong> shall, unless otherwise expressly agreed, rely upon
                  information provided to the <strong> Company</strong> or the{' '}
                  <strong> Surveyor</strong> by the <strong> Client</strong> or the{' '}
                  <strong> Client’s</strong> legal or other professional advisers relating to
                  relevant matters.
                </li>
                <li>
                  Any document resulting from a survey or other inspection of a building will not
                  purport to express an opinion about or advise upon the condition of un-inspected
                  or concealed parts and should not be taken as making any implied representation or
                  statements about such parts.
                </li>
                <li>
                  Where the <strong> Client</strong> has instructed the <strong> Surveyor</strong>{' '}
                  to make investigations which cause damage to the{' '}
                  <strong> Subject Property</strong> on the basis that the <strong> Client</strong>{' '}
                  has obtained the owner’s consent, the
                  <strong> Client</strong> will indemnify the <strong> Surveyor</strong> against any
                  loss or costs or liabilities arising.
                </li>
                <li>
                  <strong> The Client</strong> may only rely upon the <strong> Surveyor’s</strong>{' '}
                  advice and report for purposes described in the{' '}
                  <strong> Instruction Email</strong>, particulars of which have been communicated
                  to the <strong> Surveyor</strong> in writing, and if the <strong> Client</strong>{' '}
                  wishes to rely upon such advice and
                  <strong> The Report</strong> for any other purpose, <strong> The Client</strong>{' '}
                  may only do so with specific written consent of <strong> The Company</strong> and{' '}
                  <strong> The Surveyor</strong>. These Terms and Conditions are based on the model
                  Terms and Conditions of Engagement contained in the RICS guidance note.
                </li>
                <li>
                  <strong> External Cladding Panels and Fire Safety</strong> – Composite external
                  panels which incorporate insulating material between inner and outer external wall
                  faces such as aluminium composite material (ACM) panels have been extensively used
                  in recent years as external cladding. Some panel cladding systems incorporate
                  combustible insulation materials. The type of insulation and any fire checking of
                  the panel system cannot be identified from a visual inspection. Where the
                  existence of such panels has been reported, the type of insulation and
                  installation{' '}
                  <strong>
                    <em>is outside the scope of</em> The Survey Report
                  </strong>
                  . Other elements of the <strong> Property</strong> and building will also have a
                  bearing on overall fire safety, including, fire doors, fire stops in wall
                  cavities, escape routes, alarms and fire suppression systems. Such matters{' '}
                  <strong>
                    <em>are outside the scope of</em> The Survey Report
                  </strong>
                  . For buildings of more than two storeys it is essential that the Fire Safety
                  Certificate, (EWS1), is supplied. For buildings of two storey height or less the
                  <strong> Surveyor</strong> will make the assumption that the cladding system is
                  satisfactory. It is the responsibility of <strong> The Client</strong> to have any
                  materials tested and if found to be not in compliance,{' '}
                  <strong> The Client</strong> should make all necessary enquiries prior to any
                  legal or financial commitment.
                </li>
                <li>
                  Asbestos and other hazardous materials: The construction of buildings, fixtures,
                  fittings and plant or equipment may contain hazardous materials. Such materials
                  are impossible to detect without specific tests and are beyond the scope{' '}
                  <strong> of The Survey Report</strong>. No guarantee is given that such materials
                  are not incorporated in any part of the property or fixtures. The service does not
                  include an asbestos inspection and it falls outside The Control of Asbestos
                  Regulations 2012.
                </li>
              </ol>
            </li>
            <li>
              <strong> Limitation of Liability</strong>
              <br />
              RICS recommends the use of liability caps to members to manage the risk in valuation
              and survey work. Our aggregate liability arising out of or in connection with this
              service, whether arising from negligence, breach of contract or any cause whatsoever,
              shall in no event exceed 15% of the Market Value of{' '}
              <strong> The Subject Property</strong> at the date of inspection, or the purchase
              price in relation to this report (whichever is the lesser). We will not be responsible
              for the first £2,000.00 of any claim. This clause shall not exclude liability for
              death or personal injury caused by our negligence.
              <br />
              <br />
              <strong> The Survey Report</strong> is provided for <strong> The Clients</strong> use
              only, and we cannot accept responsibility if it is used or relied upon by anyone else,
              any other or third parties, unless this is expressly agreed by{' '}
              <strong> The Company</strong> and <strong> The Surveyor</strong>, in writing
              beforehand. None of the employees of <strong> The Company</strong>, partners or
              consultants, individually, has a contract with you, <strong> The Client</strong>, or
              owes you a duty of care or personal responsibility. You agree that you will not bring
              any claim against any such individuals personally in connection with the Services. For
              avoidance of doubt, the liability caps set out here also apply to valuations provided
              as an additional service.
              <br />
              <ol className="list-[lower-alpha]">
                <li>
                  <strong> The Company</strong> and/or <strong> The Surveyor</strong> shall under no
                  circumstances whatsoever be liable to the <strong> Client</strong>, whether in
                  contract, tort (including negligence), breach of statutory duty, or otherwise, for
                  loss of profit or any indirect or consequential loss, of any failure by the{' '}
                  <strong> Client</strong> or any agent of the <strong> Client</strong>: promptly to
                  provide information or other material reasonably requested, or where that material
                  is inaccurate or incomplete, or to follow our advice or recommendations.
                </li>
                <li>
                  <strong> The Client</strong> acknowledges and agrees that the sole obligation of
                  the <strong> Company</strong> is to arrange for the <strong> Surveyor</strong> to
                  provide the <strong> Client</strong> with the <strong> Survey Report</strong>. The
                  <strong> Client</strong> hereby accepts that any claim for losses arising out of
                  any matter in connection with the <strong> Survey Report</strong> or any acts or
                  omissions of the <strong> Surveyor</strong> must be brought against the{' '}
                  <strong> Surveyor</strong>. <strong> The Client</strong> acknowledges, agrees and
                  accepts that the <strong> Company</strong> shall have no liability whatsoever for
                  or in relation to any such losses.
                </li>
                <li>
                  None of the <strong> Company’s</strong> employees, officers, partners, affiliates
                  or consultants individually has a contract with the <strong> Client</strong> or
                  owes the <strong> Client</strong> or the prospective purchaser or vendor or any
                  other party a duty of care or personal responsibility. The{' '}
                  <strong> Client</strong> agrees not to bring any claim against any such
                  individuals personally. To the extent that any part of this notification is a
                  restriction of liability within the meaning of Section 1 of the Unfair Contract
                  Terms Act 1977, it does not apply to death or personal injury resulting from
                  negligence or fraudulent misrepresentation.
                </li>
                <li>
                  These Terms of Engagement do not include any warranties, conditions and other
                  terms except as stated herein and as required by law.{' '}
                  <strong> The Survey Report</strong> and any associated valuation will be provided
                  after diligent consideration and research, but property values are subject to
                  fluctuation and the valuation process is inexact and thus the{' '}
                  <strong> Surveyor’s</strong> opinion is subject to a degree of tolerance depending
                  on the property and the availability of comparable evidence.{' '}
                  <strong> Valuations</strong> are provided to assist the <strong> Client</strong>{' '}
                  in making a prudent decision; they are not provided as any form of guarantee or
                  warranty of value.
                </li>
                <li>
                  Where loss is suffered by the <strong> Client</strong> for which the{' '}
                  <strong> Company</strong>, the Surveyor and/or any other third party are jointly
                  responsible, any recoverable loss by the <strong> Client</strong> from the{' '}
                  <strong> Company</strong> will be proportionate to the <strong> Company’s</strong>{' '}
                  relative contribution to the loss suffered and subject to the provisions of this
                  clause 10.
                </li>
                <li>
                  Where the <strong> Client</strong> is composed of more than one entity, then each
                  such entity&apos;s liability shall be joint and several.
                </li>
                <li>
                  The <strong> Client</strong> acknowledges that the <strong> Surveyor</strong>{' '}
                  shall have no liability for any inaccuracy in the <strong> Survey Report</strong>{' '}
                  or service provision where this is due to an inaccuracy in the Information
                  provided by the <strong> Client</strong> or third parties.
                </li>
                <li>
                  The terms implied by Sections 3 to 5 of the Supply of Goods and Services Act 1982
                  are, to the fullest extent permitted by law, excluded from this Contract and the
                  <strong> Survey Report</strong>.
                </li>
                <li>This Clause 10 shall survive termination of this contract.</li>
                <li>
                  The <strong> Client’s</strong> failure to follow the requirements set out in these
                  Terms and Conditions will invalidate the <strong> Survey Report</strong> and
                  service provision.
                </li>
                <li>
                  Nothing in these conditions shall limit or exclude the <strong> Company’s</strong>{' '}
                  liability for:
                  <br />
                  <br />
                  <ol className="list-[lower-roman]">
                    <li>
                      Death or personal injury caused by its negligence, or the negligence of its
                      officers, employees, agents, subcontractors or consultants;
                    </li>
                    <li>Fraud or fraudulent misrepresentation.</li>
                  </ol>
                </li>
                <li>
                  If the <strong> Client</strong> has agreed to accept reimbursement of the fee
                  paid, or the <strong> Company’s</strong> fee account has not been settled within
                  two months of the date of the invoice, then it is agreed that the{' '}
                  <strong> Client</strong> has placed no reliance on the{' '}
                  <strong> Survey Report</strong> (including a valuation produced therein) and thus
                  the <strong> Client</strong> or any permitted assignees will not be entitled to
                  pursue any action for alleged negligence, breach of contract or breach of duty.
                  This does not limit the <strong> Company’s</strong> entitlement to the agreed fee.
                </li>
                <li>
                  All telephone or verbal discussions are prone to misinterpretation or
                  misunderstanding by either party, especially in the interpretation of
                  instructions, legal, technical or descriptive matters; as such no reliance may be
                  placed by the <strong> Client</strong> upon any such discussions unless agreed
                  outcomes are formally confirmed in writing by an authorised officer of the
                  <strong> Company</strong>.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong> VALUATIONS PROVIDED AS AN ADDITIONAL SERVICE</strong>
          <br />
          Where a valuation is to be provided as an additional service, as confirmed with{' '}
          <strong> The Client</strong> in the <strong> Instruction Email</strong>, the following
          terms and conditions will also apply:
          <br />
          <br />
          <ol>
            <li>
              <strong> Purpose of the Report</strong>
              <br />
              <br />
              <ol className="list-[lower-alpha]">
                <li>
                  <strong> The Client</strong> wishes the <strong> Company</strong> to arrange for
                  the
                  <strong> Surveyor</strong> to provide within the <strong> Survey Report</strong>{' '}
                  an opinion of the value of the Freehold or Leasehold or Commonhold interest in The
                  Subject Property as specified by the <strong> Client</strong>.
                </li>
                <li>
                  <strong> The Surveyor</strong> shall provide to the <strong> Client</strong> an
                  opinion of value with the <strong> Survey Report</strong> (as per 1a above) based
                  upon an inspection of the property by a named individual who shall be:
                  <ol className="list-[lower-roman]">
                    <li>
                      a member of the Royal Institution of Chartered Surveyors (RICS) holding one of
                      the membership grades: AssocRICS, MRICS, or FRICS; and
                    </li>
                    <li>
                      a member of the RICS Valuer Registration Scheme (VRS), setting out the
                      Valuer’s opinion of the relevant interest in the property.
                    </li>
                  </ol>
                  <br />
                  <br />
                  The Valuation within the <strong> Survey Report</strong> will be prepared in
                  accordance with the RICS Valuation – Global Standards effective 31 January 2022,
                  the RICS Valuation – Global Standard 2017: UK National Supplement effective 14
                  January 2019, and relevant UK VPGAs.
                </li>
                <li>
                  <strong> Confidentiality: The Surveyor’s</strong> valuation is provided to the
                  named addressee/s alone and solely for the purpose of the instruction to which it
                  relates. The valuation may not, without <strong> The Company’s</strong> and
                  <strong> Surveyor’s</strong> written consent, be used or relied upon by any other
                  or third party, even if that third party pays all or part of the fees, or is
                  permitted to see a copy of the <strong> Survey Report. If The Company</strong> and
                  <strong> The Surveyor</strong> provides written consent to a third party relying
                  on the valuation, any such third party is deemed to have accepted the terms of The
                  <strong> Company’s</strong> engagement and the <strong> Surveyor’s</strong>{' '}
                  engagement, and an additional fee may be payable. Neither the whole nor any part
                  of the <strong> Survey Report</strong> or any reference to it may be included in
                  any published document, circular or statement nor published in any way without{' '}
                  <strong> The Company’s</strong> and <strong> The Surveyor’s</strong> written
                  approval of the form and context in which it may appear.
                </li>
                <li>
                  <strong> The Surveyor</strong> shall have no known interest in{' '}
                  <strong> The Subject Property</strong> or any other conflict that will prevent the
                  <strong> Valuation</strong> from being undertaken in an independent, objective,
                  and unbiased way.
                </li>
                <li>
                  The <strong> Market Value</strong> reported unless otherwise specifically agreed
                  in writing, shall be in accordance with one or more of the following Valuation
                  bases: Market Value (MV) or Market Rent (MR) detailed at the beginning of these
                  conditions. (See &ldquo;Definitions&rdquo;.) The value shall be provided in pounds
                  sterling.
                </li>
                <li>
                  The <strong> Valuation</strong> Methodology shall be the Comparable Basis of
                  Methodology unless at the sole discretion of the <strong> Surveyor</strong>/Valuer
                  and subject to the interest, bearing income, an Investment Methodology may be
                  adopted which will be stated in the Valuation Report.
                </li>
                <li>
                  <strong> Flats &ndash; Building Insurance</strong> will be for the subject flat
                  only and it will be assumed that the <strong> Client&rsquo;s</strong> legal
                  adviser will confirm that the Freeholder or Management company includes the flat
                  in a block buildings insurance policy.
                </li>
                <li>
                  <strong> Access</strong> to the property shall be the responsibility of the{' '}
                  <strong> Client</strong> who should inform the <strong> Surveyor</strong> of any
                  delay or any special arrangements to be followed together with contact information
                  for the occupier, estate agent or other key holder.
                </li>
                <li>
                  <strong> The Client</strong> will be informed of the inspection date and time and
                  the report will be available normally within five working days of the inspection
                  or otherwise as agreed with the <strong> Client</strong> and is subject to all
                  necessary information being made available to the <strong> Surveyor</strong> or
                  subject to further discovery. Any anticipated delay in gaining access or delay in
                  the report will be notified to the <strong> Client</strong>.
                </li>
              </ol>
            </li>
            <li>
              <strong>
                Reporting Assumptions and Limitations relating to provision of the Valuation within
                the Survey Report
              </strong>
              <ol className="list-[lower-alpha]">
                <li>
                  <strong> The Surveyor</strong> will make reasonable assumptions which will be
                  specified in the <strong> Survey Report</strong>. However, if these assumptions
                  are found to be incorrect please advise us immediately as the valuation may
                  require to be amended to take account of any newly disclosed, amended or
                  discovered facts.
                </li>
                <li>
                  <strong> The Surveyor</strong> shall, unless otherwise expressly agreed, rely upon
                  information from the <strong> Client</strong> or the <strong> Client’s</strong>{' '}
                  legal or other professional advisers. It is the responsibility of the{' '}
                  <strong> Client</strong> to ensure this information is accurate and to promptly
                  advise the <strong> Surveyor</strong> if it is not.
                </li>
                <li>
                  <strong> External Cladding Panels and Fire Safety</strong> – Composite external
                  panels which incorporate insulating material between inner and outer external wall
                  faces such as aluminium composite material (ACM) panels have been extensively used
                  in recent years as external cladding. Some panel cladding systems incorporate
                  combustible insulation materials. The type of insulation and any fire checking of
                  the panel system cannot be identified from a visual inspection. Where the
                  existence of such panels has been reported, the type of insulation and
                  installation{' '}
                  <strong>
                    <em>is outside the scope of</em> The Survey Report and the Valuation.
                  </strong>{' '}
                  Other elements of the <strong> Property</strong> and building will also have a
                  bearing on overall fire safety, including, fire doors, fire stops in wall
                  cavities, escape routes, alarms and fire suppression systems. Such matters{' '}
                  <strong>
                    <em>are outside the scope of the</em> Valuation
                  </strong>
                  . For buildings of more than two storeys it is essential that the Fire Safety
                  Certificate, (EWS1), is supplied. Where the nature of the cladding is considered
                  to be suspect, the <strong> Surveyor</strong> will report a Zero Value until the
                  form EWS1 in Compliance is supplied. For buildings of two storey height or less
                  the <strong> Surveyor</strong> will make the assumption that the cladding system
                  is satisfactory. It is the responsibility of <strong> The Client</strong> to have
                  any materials tested and if found to be not in compliance, the{' '}
                  <strong> Valuation</strong> may then require to be adjusted.{' '}
                  <strong> The Client</strong> should make all necessary enquiries prior to any
                  legal or financial commitment.
                </li>
                <li>
                  Asbestos and other hazardous materials: The construction of buildings, fixtures,
                  fittings and plant or equipment may contain hazardous materials. Such materials
                  are impossible to detect without specific tests and are beyond the scope of the
                  <strong> Survey Report</strong>. No guarantee is given that such materials are not
                  incorporated in any part of the property or fixtures. The service does not include
                  an asbestos inspection and it falls outside The Control of Asbestos Regulations
                  2012.
                </li>
                <li>
                  The Inspection will take into account gardens, grounds and permanent outbuildings,
                  but will not include constructions or equipment with a specific or leisure
                  purpose, including sauna, swimming pools or tennis courts and associated services
                  and changing rooms. The Inspection will include the immediate gardens that belong
                  to the property, but the Inspection specifically excludes any additional land such
                  as pasture or amenity land.
                </li>
                <li>
                  Measurement &ndash; the <strong> Surveyor</strong> may measure the property during
                  the inspection or take measurements or areas from plans provided by the{' '}
                  <strong> Client</strong> or obtain such details from specialised database
                  information available to the Surveyor or from publicly available information at
                  the sole discretion of the <strong> Surveyor</strong>. Outbuildings will not be
                  measured on site unless they are considered by the Surveyor to contribute
                  significantly to the reported capital value or reinstatement cost (if reported).
                </li>
                <li>
                  The Following Additional Assumptions will be made by the{' '}
                  <strong> Surveyor</strong> in respect of the <strong> Property</strong>:
                  <br />
                  <br />
                  <ol className="list-[lower-roman]">
                    <li>
                      the ground is not affected by the existence of noxious or toxic substances or
                      gases and has not been used for landfill or mineral extraction or other forms
                      of contamination, and does not contain gypsum deposits;
                    </li>
                    <li>
                      no high alumina cement concrete or calcium chloride additive or other
                      potentially deleterious material including &lsquo;Mundic material&rsquo; was
                      used in the construction of the property or has since been incorporated;
                    </li>
                    <li>
                      the <strong> Property</strong> is not susceptible to radon gas pollution,
                      invasive or protected species, flood risk, high voltage equipment, cables or
                      coastal erosion, unless specified in the report;
                    </li>
                    <li>
                      the <strong> Property</strong> is not subject to any unusual or especially
                      onerous restrictions, encumbrances or outgoings, easements or way leaves or
                      rights of way over the property and that good title can be shown;
                    </li>
                    <li>
                      the <strong> Property</strong> and its value are unaffected by any matters
                      which would be revealed by local or environmental searches, and replies to the
                      usual enquiries, or by any statutory notice, and that neither the property,
                      nor its condition, nor its use, nor its intended use, is or will be unlawful;
                    </li>
                    <li>
                      the inspection of those parts which have not been included in the Inspection
                      in accordance with these Terms and Conditions would neither reveal material
                      defects nor cause the <strong> Surveyor </strong>to alter the valuation
                      materially;
                    </li>
                    <li>
                      it is assumed that unless otherwise stated, roads, sewers and services outside
                      the curtilage of the <strong> Property </strong>are the responsibility of the
                      Local Authority or other statutory body. It is further assumed, unless
                      otherwise stated, that all services including drainage are connected and
                      access and roads are unfettered, and in the case of the public highway,
                      maintained at the expense of the Local and Highways Authorities;
                    </li>
                    <li>
                      the <strong> Valuation </strong>is based on the assumption that the Property
                      is being valued with vacant possession and, unless otherwise stated, for owner
                      occupation; if the property is tenanted the <strong> Client </strong>is under
                      a duty to inform the <strong> Surveyor</strong>;
                    </li>
                    <li>
                      that further investigation into Planning Permission, Building Regulations,
                      Permitted Development Rights, extensions and alterations, hazardous materials,
                      onerous restrictions or the like will not reveal anything sufficiently adverse
                      as to materially affect the <strong> Valuation</strong>;
                    </li>
                    <li>
                      in the case of Leasehold Tenure the <strong> Surveyor </strong>will assume
                      that there remains a minimum of 85 years on the lease, and the Ground Rent and
                      any Service Charges are reasonable unless otherwise detailed in the report;
                    </li>
                    <li>
                      there are no archaeological remains, Scheduled Ancient Monument or liability
                      to Chancel Repair and the property is not at risk of flooding or likely to
                      suffer damage from unstable ground conditions;
                    </li>
                    <li>
                      any other assumptions will be clearly stated in the report; however, the{' '}
                      <strong> Surveyor </strong>shall be under no duty to verify these assumptions
                      and the <strong> Client </strong>should ensure the assumptions are If any of
                      these assumptions are incorrect then these should be discussed with the Valuer
                      before any financial or legal commitment is made as the Valuer&apos;s opinion
                      of value may change;
                    </li>
                    <li>
                      if the <strong> Surveyor </strong>has not been provided, or cannot obtain, an
                      up-to-date EPC rating for this property, for the purposes of the Energy Act
                      2011, its valuation will be based on the assumption that the subject property
                      will meet the minimum requirements laid down by legislation and that there
                      will be no adverse impact on value, rental ability and marketability;
                    </li>
                    <li>
                      in the case of a newly constructed property, it has been built under a
                      recognised builder&rsquo;s warranty or insurance scheme, or has been
                      supervised by a professional consultant capable of fully completing the UK
                      Finance Professional Consultant Certificate;
                    </li>
                    <li>
                      there are no ongoing insurance claims or neighbour disputes and the property
                      is insurable under normal terms.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong> Leasehold Properties</strong>
                  <br />
                  Where <strong> The Subject Property </strong>is leasehold, data including
                  unexpired lease term, ground rent and any further relevant information should be
                  considered and reflected in the <strong> Valuation </strong>based upon data that
                  can be sourced on the day of inspection or beforehand from either the vendor or
                  the selling agent as applicable following reasonable investigation. However, the{' '}
                  <strong> Surveyor </strong>clearly cannot give any absolute assurance that, if
                  verbally provided, such data is reliable &ndash; verification will be a matter for
                  your legal representatives as appropriate.
                  <br />
                  <br />
                  Where <strong> The Subject Property </strong>is leasehold, and it is not possible
                  to inspect the lease or details have not been provided, the following assumptions
                  will be made, unless instructed to the contrary:
                  <ol className="list-[lower-roman]">
                    <li>
                      The unexpired term of the lease is assumed to be 85 years, and no action is
                      being taken by any eligible party with a view to acquiring the freehold or
                      extending the lease RICS Valuation &ndash; Global Standards 2022: UK national
                      supplement UK VPGA 11 Valuation for residential mortgage purposes UK Valuation
                      Practice Guidance Applications (UK VPGAs) RICS Valuation &ndash; Global
                      Standards 2017: UK national supplement.
                    </li>
                    <li>There are no exceptionally onerous covenants upon the</li>
                    <li>
                      The lease cannot be determined, except on the grounds of a serious breach of
                      covenant in the existing lease
                    </li>
                    <li>
                      If there are separate freeholders, head and/or other subhead leaseholders, the
                      terms and conditions of all the leases are in the same form and contain the
                      same terms and
                    </li>
                    <li>The lease terms are mutually enforceable against all parties</li>
                    <li>
                      There are no breaches of covenant or disputes between the various interests
                    </li>
                    <li>
                      The leases of all the properties in the building/development are materially
                      the
                    </li>
                    <li>
                      The ground rent stated, or assumed, is not subject to unreasonable review and
                      is payable throughout the unexpired lease
                    </li>
                    <li>
                      In the case of blocks of flats or maisonettes of over six dwellings, the
                      freeholder manages the property directly, or there is an appropriate
                      management structure in
                    </li>
                    <li>
                      There is a duty holder, as defined in the Control of Asbestos Regulations
                      2012, and there are in place an asbestos register and an effective management
                      plan, which does not require any immediate expenditure, pose a significant
                      risk to health, or breach Health and Safety Executive (HSE)
                    </li>
                    <li>
                      Where <strong> The Subject Property </strong>forms part of a mixed residential
                      or commercially used block or development, there will be no significant
                      changes in the existing pattern of
                    </li>
                    <li>
                      Where <strong> The Subject Property </strong>forms part of a development
                      containing separate blocks of dwellings, the lease terms of the property apply
                      only to the block. There will be no requirement to contribute towards costs
                      relating to other parts of the development, other than in respect of common
                      roads, paths, communal grounds and
                    </li>
                    <li>
                      Where <strong> The Subject Property </strong>forms part of a larger
                      development whose ownership has since been divided, all necessary rights and
                      reservations have been
                    </li>
                    <li>
                      There are no unusual restrictions on assignment or subletting of the property
                      for residential
                    </li>
                    <li>
                      There are no outstanding claims or litigation concerning the lease of the
                      subject property or any others within the same development.
                    </li>
                    <li>
                      Where <strong> The Subject Property</strong> benefits from additional
                      facilities within the development, the lease makes adequate provisions for the
                      occupier to continue to enjoy them without exceptional restriction, for the
                      facilities to be maintained adequately and for there being no charges over and
                      above the service charge for such use and maintenance.
                    </li>
                  </ol>
                  <br />
                  <br />
                  For the avoidance of doubt, the <strong> Surveyor </strong>
                  will not be under a duty to purchase data from third parties or to read the lease
                  documentation. If further information is provided to the{' '}
                  <strong> Surveyor </strong>after the valuation has been completed and submitted,
                  the <strong> Surveyor </strong>may reconsider the valuation and, if necessary,
                  issue an amended report. But again, for the avoidance of doubt, the{' '}
                  <strong> Surveyor </strong>will not be under any obligation to read the lease
                  document and would only be expected to base a revised valuation on further
                  information provided to the <strong> Surveyor </strong>by your legal
                  representatives.
                </li>
                <li>
                  <strong> Flats and Maisonettes</strong>
                  <br />
                  Where <strong> The Subject Property </strong>is part of a building comprising
                  flats or maisonettes, the following assumptions will also be made, unless
                  instructed to the contrary: UK VPGA 11 Valuation for residential mortgage
                  purposes, UK Valuation Practice Guidance Applications (UK VPGAs).
                  <ol className="list-[lower-roman]">
                    <li>
                      The costs of repairs and maintenance to the building and grounds are shared
                      equitably between the flats and maisonettes.
                    </li>
                    <li>
                      There are suitable, enforceable covenants between all leaseholds, or through
                      the landlord or the
                    </li>
                    <li>There are no onerous liabilities</li>
                    <li>
                      There are no substantial defects, or other matters requiring expenditure (in
                      excess of the current amount or assumed amount of service charge payable on an
                      annual basis), expected to result in charges to the leaseholder or owner of
                      the subject property during the next five years, that are equivalent to 10% or
                      more of the reported market value.
                    </li>
                  </ol>
                  <br />
                  In respect of insurance, the following assumptions will be made, unless instructed
                  to the contrary:
                  <ol className="list-[lower-roman]">
                    <li>
                      the property can be insured under all-risks cover for the current
                      reinstatement cost and is available on normal terms;
                    </li>
                    <li>there are no outstanding claims or disputes;</li>
                    <li>
                      where individuals in a block make separate insurance arrangements, the leases
                      make provision for mutual enforceability of insurance and repairing
                      obligations and;
                    </li>
                    <li>
                      any landlord responsible for insurance is required to rebuild the property
                      with such alterations as may be necessary to comply with current Building
                      Regulations and planning
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br />
        <br />
        <li>
          <strong> COST ESTIMATES PROVIDED AS AN ADDITIONAL SERVICE</strong>
          <br />
          We can provide cost estimates as an additional service to the Level 3 building survey
          service, subject to an additional fee.
          <br />
          <br />
          These are indicative costs only and the purpose of providing them is to give initial
          guidance to help you in your purchasing decision. Actual costs may vary depending on
          economic factors at the time.
          <br />
          <br />
          Costs estimates may also be provided in bands as follows:
          <br />
          <br />
          Band A - &pound;1,000 to &pound;2,000
          <br />
          Band B - &pound;2,000 to &pound;5,000
          <br />
          Band C - &pound;5,000 to &pound;10,000
          <br />
          Band D &ndash; 0ver &pound;10,000
          <br />
          <br />
          Items below &pound;1,000 will be deemed as not significant in the context of making a
          decision to purchase the property.
          <br />
          <br />
          You must obtain your own cost estimates in writing from suitable building specialists
          and/or contractors before exchange of contracts so that you are aware of all costs before
          you make a legal commitment to purchase. Cost estimates must include VAT and any
          applicable professional fees. Should you exchange contracts before obtaining estimates
          from such contractors then you will proceed at your own risk.{' '}
          <strong> The Surveyor</strong> and <strong> Houzecheck Ltd</strong> will not be liable for
          any variation in costs between <strong> The Surveyor&rsquo;s </strong>estimates and the
          actual costs provided by contractors or ultimately by way of expenditure in addressing the
          issues highlighted by the cost estimates provided.
        </li>
        <br />
        <br />
        <li>
          <strong> BUILDINGS INSURANCE REINSTATMENT COST PROVIDED AS AN ADDITIONAL SERVICE</strong>
          <br />
          <strong> The Surveyor</strong> can provide a building insurance reinstatement cost as an
          additional service to the Level 2 homebuyer service, subject to an additional fee.
          <br />
          <br />
          The reinstatement cost is not related to the capital value of the building and property.
          It is the cost of rebuilding from scratch were the building to be damaged beyond repair.
          <br />
          <br />
          The reinstatement cost is the cost of rebuilding a property including demolition of the
          original building, temporary support of adjoining buildings where applicable, materials,
          labour, professional fees and Local Authority charges. It includes sums for boundaries,
          drainage and significant outbuildings. The reinstatement cost will be reported as a single
          figure, not as a detailed schedule of all costs.
          <br />
          <br />
          Specialist installations such as swimming pools are excluded.
          <br />
          <br />
          The Surveyor cannot provide reinstatement costs for Listed Buildings as this requires a
          detailed specialist assessment, which is beyond the scope of the Level 2 service.
        </li>
      </ol>
    </div>
  );
}
function ValuationTnc({ name, companyName }) {
  return (
    <div className="text-justify">
      <p className="text-center text-xl">
        <strong> Terms and Conditions of Engagement for Valuations</strong>
      </p>
      <br />
      <br />
      <p>
        We refer to your instructions received requiring The Surveyor to carry out and to prepare
        and deliver a Valuation of Residential Property Report (Valuation Report) on the above
        property. We accept the instructions only on the basis that the scope, purpose, and
        conditions of the report are as set out in these Terms and Conditions for the Valuation
        Report. The Terms and Conditions are attached to and form part of the Valuation Report.
      </p>
      <br />
      <br />
      <p>
        The Terms and Conditions of Engagement should be referred to and used in conjunction with
        the Instruction Email, setting out the client’s name and address, instruction type, reason
        for instruction, subject property address and fee/price agreed. The Surveyor details are
        included in the Instruction email and these Terms as per below.
      </p>
      <br />
      <br />
      <p>
        The Valuation Report service requires disclosure of any previous business relationship we
        may have with you, your property, or your selling agent. If appropriate, disclosure has been
        made in accordance with Clause 1d of the Terms and Conditions. The scope and extent of the
        inspection is detailed in Clause 2a to 2h inclusive of the Terms and Conditions. The
        Valuation Report is prepared by a suitably qualified Surveyor, in an objective way having
        regard to the condition of the Property on the day of the inspection, and who is:
      </p>
      <br />
      <br />
      <ol>
        <li>
          a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the
          membership grades: AssocRICS, MRICS, or FRICS; and
        </li>
        <li>a member of the RICS Valuer Registration Scheme (VRS).</li>
      </ol>
      <br />
      <br />
      <p>
        We are required by RICS to seek formal confirmation from you that you are satisfied that the
        Valuation Report Terms and Conditions meet your requirements, and this will be deemed
        satisfied by you by making payment for this service, as stated in the Instruction Job
        Details email.
      </p>
      <br />
      <br />
      <p>
        Complaints: Houzecheck operates a Complaints Handling Procedure in accordance with RICS
        guidelines. A copy is available upon request.
      </p>
      <br />
      <br />
      <p>
        RICS Regulation and Monitoring: Houzecheck and the Surveyor/Valuer are registered with the
        RICS Valuer Registration scheme. Please note that our files may be subject to monitoring
        under the Institution’s conduct and disciplinary regulations.
      </p>
      <br />
      <br />
      <ol>
        <li>
          <p className="text-lg">
            <strong> Purpose of the Report</strong>
          </p>
          <ol className="list-[lower-alpha]">
            <li>
              <strong> The Client</strong> wishes the Company to arrange for a Surveyor to provide
              an opinion of the value of the Freehold or Leasehold or Commonhold interest in the
              Property as specified by the Client
            </li>
            <li>
              <strong> The Surveyor</strong> shall provide to the Client a report based upon an
              inspection of the property by a named individual who shall be: 1. a member of the
              Royal Institution of Chartered Surveyors (RICS) holding one of the membership grades:
              AssocRICS, MRICS, or FRICS; and 2. a member of the RICS Valuer Registration Scheme
              (VRS), setting out the Valuer’s opinion of the relevant interest in the property. The
              Valuation and Report will be prepared in accordance with the RICS Valuation Global
              Standards 2022, effective 31st January 2022, and the RICS Valuation Global Standard
              2017 UK National Supplement, effective 14th January 2019, known as the Red Book.
            </li>
            <li>
              <strong> Confidentiality:</strong> The Surveyor&apos;s valuation is provided to the
              named addressee/s alone and solely for the purpose of the instruction to which it
              relates. The valuation may not, without the Surveyor’s written consent, be used or
              relied upon by any other or third party, even if that third party pays all or part of
              the fees, or is permitted to see a copy of the Valuation Report. If the Surveyor
              provides written consent to a third party relying on the valuation, any such third
              party is deemed to have accepted the terms of our engagement and the Surveyor&apos;s
              engagement and an additional fee may be payable. Neither the whole nor any part of the
              report or any reference to it may be included in any published document, circular or
              statement nor published in any way without the Surveyor&apos;s written approval of the
              form and context in which it may appear.
            </li>
            <li>
              <strong> The Surveyor</strong> shall have no known interest in the Property or any
              other conflict that will prevent the Valuation Service from being undertaken in an
              independent, objective, and unbiased way.
            </li>
            <li>
              <strong> The Value</strong> reported unless otherwise specifically agreed in writing,
              shall be in accordance with one or more of the following Valuation bases: Market Value
              (MV) or Market Rent (MR), or a statutory basis of value as detailed at the end of
              these conditions. (See “Definitions”.) The value shall be provided in pounds sterling.
            </li>
            <li>
              <strong> The Valuation Approach</strong> shall be the Comparable Basis of Approach
              unless at the sole discretion of the Valuer and subject to the interest, bearing
              income, an Investment Methodology may be adopted which will be stated in the Valuation
              Report.
            </li>
            <li>
              <strong> The Reinstatement Cost figure</strong> (
              <em>If requested in the client instruction</em>) for the permanent buildings is
              provided for insurance purposes and is not directly related to the market value of the
              property. The reinstatement cost will be calculated by reference to the indices and
              guidance published by the Building Costs Information Service (BCIS). The figure stated
              will only include Value Added Tax on professional fees (not on building costs) and
              will not take into account other potential or consequential losses such as costs of
              demolition, site clearance, provision of services, alternative accommodation, personal
              items or any other contents or contingent costs.
            </li>
            <li>
              <strong> Flats &ndash; Building Insurance</strong> will be for the subject flat only
              and it will be assumed that the Client&apos;s legal adviser will confirm that the
              Freeholder or Management company includes the flat in a block buildings insurance
              policy.
            </li>
            <li>
              <strong> Access to the property</strong> shall be the responsibility of the Client who
              should inform the Valuer of any delay or any special arrangements to be followed
              together with contact information for the occupier, estate agent or other key holder.
            </li>
            <li>
              <strong> The Client</strong> will be informed of the inspection date and time of
              inspection and the report will be available normally within five working days of the
              inspection or otherwise as agreed with the Client and is subject to all necessary
              information being made available to the Surveyor or subject to further discovery. Any
              anticipated delay in gaining access or delay in the report will be notified to the
              Client.
            </li>
            <li>
              Where specifically requested, the report will also provide an estimated price that
              could hypothetically be achieved given a specific restriction that the property must
              achieve a sale and exchange contracts within 4 weeks (28 days) of the date that the
              report is provided. This estimated price is not a valuation, and any relationship to
              Market Value is coincidental. It is not described or used as a basis of value rather
              it is a figure that might be seen as a reflection of worth to the instructing client
              as at the date that the report is provided given the particular circumstances.
            </li>
          </ol>
        </li>
        <br />
        <br />
        <li>
          <strong> Reporting Assumptions and Limitations of Inspection</strong>
          <br />
          <ol className="list-[lower-alpha]">
            <li>
              <strong> The Surveyor</strong> will make reasonable assumptions which will be
              specified in the Valuation Report. However, if these assumptions are found to be
              incorrect please advise us immediately as the valuation may require to be amended to
              take account of any newly disclosed, amended or discovered facts.
            </li>
            <li>
              <strong> The Surveyor</strong> shall, unless otherwise expressly agreed, rely upon
              information from the Client or the Client’s legal or other professional advisers. It
              is the responsibility of the Client to ensure this information is accurate and to
              promptly advise the Valuer if it is not.
            </li>
            <li>
              <strong> The Client</strong> has requested a report on the value of the Property only.
              The Report is not a more thorough RICS Level 2 Report, Level 3 Report or other
              detailed survey report. The Surveyor will draw attention to the general condition of
              the property and any visible major defects but only where these may have an eû ect
              upon the Valuation.
            </li>
            <li>
              <strong> External Cladding Panels and Fire Safety</strong> &ndash; Composite external
              panels which incorporate insulating material between inner and outer external wall
              faces such as aluminium composite material (ACM) panels have been extensively used in
              recent years as external cladding. Some panel cladding systems incorporate combustible
              insulation materials. The type of insulation and any fire checking of the panel system
              cannot be identified from a visual inspection. Where the existence of such panels has
              been reported, the type of insulation and installation{' '}
              <strong>
                <em>is outside the scope of</em> The Survey Report and the Valuation and Valuation
                Report.
              </strong>{' '}
              Other elements of the <strong> Property</strong> and building will also have a bearing
              on overall fire safety, including, fire doors, fire stops in wall cavities, escape
              routes, alarms and fire suppression systems. Such matters{' '}
              <strong>
                <em>are outside the scope of the</em> Valuation and/Valuation Report.
              </strong>{' '}
              For buildings of more than two storeys it is essential that the Fire Safety
              Certificate, (EWS1) is supplied. Where the nature of the cladding is considered to be
              suspect, the <strong> Surveyor</strong> will report a Zero Value until the form EWS1
              in Compliance is supplied. For buildings of two storey height or less the Surveyor
              will make the assumption that the cladding system is satisfactory. It is the
              responsibility of <strong> The Client</strong> to have any materials tested and if
              found to be not in compliance, the <strong> Valuation</strong> may then require to be
              adjusted. <strong> The Client</strong> should make all necessary enquiries prior to
              any legal or financial commitment.
            </li>
            <li>
              <strong> Asbestos</strong> and other hazardous materials: The construction of
              buildings, fixtures, fittings and plant or equipment may contain hazardous materials.
              Such materials are impossible to detect without specific tests and are beyond the
              scope of the Valuation Report. No guarantee is given that such materials are not
              incorporated in any part of the property or fixtures.
            </li>
            <li>
              <strong> The Inspection</strong> will take into account gardens, grounds and permanent
              outbuildings, but will not include constructions or equipment with a specific or
              leisure purpose including sauna, swimming pools or tennis courts and associated
              services and changing rooms. The Inspection will include the immediate gardens that
              belong to the property, but the Inspection specifically excludes any additional land
              such as pasture or amenity land.
            </li>
            <li>
              <strong> Measurement &ndash;</strong> The Surveyor may measure the property during the
              inspection or take measurements or areas from plans provided by the client or obtain
              such details from specialised database information available to the Surveyor or from
              publicly available information at the sole discretion of the Surveyor. Outbuildings
              will not be measured on site unless they are considered by the Surveyor to contribute
              significantly to the reported capital value or reinstatement cost (if reported).
            </li>
            <li>
              <strong> The Following Additional Assumptions</strong> will be made by the Surveyor in
              respect of the Property:
              <ol className="list-[lower-roman]">
                <li>
                  {' '}
                  the ground is not affected by the existence of noxious or toxic substances or
                  gases and has not been used for landfill or mineral extraction or other forms of
                  contamination and does not contain gypsum deposits;
                </li>
                <li>
                  no high alumina cement concrete or calcium chloride additive or other potentially
                  deleterious material including “Mundic material” was used in the construction of
                  the property or has since been incorporated;
                </li>
                <li>
                  the Property is not susceptible to radon gas pollution, invasive or protected
                  species, flood risk, high voltage equipment, cables or coastal erosion unless
                  specified in the report;
                </li>
                <li>
                  the Property is not subject to any unusual or especially onerous restrictions,
                  encumbrances or outgoings, easements or way leaves or rights of way over the
                  property and that good title can be shown;
                </li>
                <li>
                  the Property and its value are unaffected by any matters which would be revealed
                  by local or environmental searches, and replies to the usual enquiries, or by any
                  statutory notice, and that neither the property, nor its condition, nor its use,
                  nor its intended use, is or will be unlawful;
                </li>
                <li>
                  the inspection of those parts which have not been included in the Inspection in
                  accordance with these Terms and Conditions would neither reveal material defects
                  nor cause the Valuer to alter the valuation materially;
                </li>
                <li>
                  it is assumed that unless otherwise stated, roads, sewers and services outside the
                  curtilage of the property are the responsibility of the Local Authority or other
                  statutory body. It is further assumed, unless otherwise stated, that all services
                  including drainage are connected and access and roads are unfettered and in the
                  case of the public highway maintained at the expense of the Local and Highways
                  Authorities;
                </li>
                <li>
                  the Valuation is based on the assumption that the Property is being valued with
                  vacant possession and, unless otherwise stated, for owner occupation; if the
                  property is tenanted the client is under a duty to inform the valuer;
                </li>
                <li>
                  that further investigation into Planning Permission, Building Regulation,
                  Permitted Development Rights, extensions and alterations, hazardous materials,
                  onerous restrictions or the like will not reveal anything sufficiently adverse as
                  to materially affect the Valuation;
                </li>
                <li>
                  in the case of Leasehold Tenure the Valuer will assume that there remains a
                  minimum of 85 years on the lease and the Ground Rent and any Service Charges are
                  reasonable unless otherwise detailed in the report.
                </li>
                <li>
                  there are no archaeological remains, Scheduled Ancient Monument or liability to
                  Chancel Repair and the property is not at risk of flooding or likely to suffer
                  damage from unstable ground conditions.
                </li>
                <li>
                  any other assumptions will be clearly stated in the report; however, the Valuer
                  shall be under no duty to verify these assumptions and the Client should ensure
                  the assumptions are correct. If any of these assumptions are incorrect then these
                  should be discussed with the Valuer before any financial or legal commitment is
                  made as the Valuer&apos;s opinion of value may change.
                </li>
                <li>
                  If the Valuer has not been provided, or cannot obtain, an up-to-date EPC rating
                  for this property, for the purposes of the Energy Act 2011, its valuation will be
                  based on the assumption that the subject property will meet the minimum
                  requirements laid down by legislation and that there will be no adverse impact on
                  value, rental ability and marketability.
                </li>
                <li>
                  In the case of a newly constructed property, it has been built under a recognised
                  builder’s warranty or insurance scheme, or has been supervised by a professional
                  consultant capable of fully completing the UK Finance Professional Consultant
                  Certificate.
                </li>

                <li>
                  There are no ongoing insurance claims or neighbour disputes and the property is
                  insurable under normal terms.
                </li>
              </ol>
            </li>
            <li>
              <strong> Leasehold Properties: </strong>
              <br />
              Where the dwelling is leasehold, data including unexpired lease term, ground rent and
              any further relevant information should be considered and reflected in the valuation
              based upon data that can be sourced on the day of inspection from either the client,
              vendor or selling agent as applicable following reasonable investigation. However, the
              surveyor clearly cannot give any absolute assurance that, if verbally provided, such
              data is reliable – verification will be a matter for your legal representatives as
              appropriate.
              <br />
              <br />
              Where the dwelling is leasehold, and it is not possible to inspect the lease or
              details have not been provided, the following assumptions will be made, unless
              instructed to the contrary:
              <ol className="list-[lower-roman]">
                <li>
                  The unexpired term of the lease is assumed to be 85 years, and no action is being
                  taken by any eligible party with a view to acquiring the freehold or extending the
                  lease term. (RICS Valuation - Global Standards 2022: UK national supplement, UK
                  VPGA 11, Valuation for residential mortgage purposes, UK Valuation Practice
                  Guidance Applications (UK VPGAs) RICS Valuation Global Standards 2022: UK national
                  supplement).
                </li>
                <li>There are no exceptionally onerous covenants upon the leaseholder.</li>
                <li>
                  The lease cannot be determined, except on the grounds of a serious breach of
                  covenant in the existing lease agreement.
                </li>
                <li>
                  If there are separate freeholders, head and/or other subhead leaseholders, the
                  terms and conditions of all the leases are in the same form and contain the same
                  terms and conditions.
                </li>
                <li>The lease terms are mutually enforceable against all parties concerned.</li>
                <li>
                  There are no breaches of covenant or disputes between the various interests
                  concerned.
                </li>
                <li>
                  The leases of all the properties in the building/ development are materially the
                  same.
                </li>
                <li>
                  The ground rent stated, or assumed, is not subject to unreasonable review and is
                  payable throughout the unexpired lease term.
                </li>
                <li>
                  In the case of blocks of flats or maisonettes of over six dwellings, the
                  freeholder manages the property directly, or there is an appropriate management
                  structure in place.
                </li>
                <li>
                  There is a duty holder, as defined in the Control of Asbestos Regulations 2012,
                  and there are in place an asbestos register and an effective management plan,
                  which does not require any immediate expenditure, pose a significant risk to
                  health, or breach Health and Safety Executive (HSE) requirements.
                </li>
                <li>
                  Where the subject property forms part of a mixed residential or commercially used
                  block or development, there will be no significant changes in the existing pattern
                  of use.
                </li>
                <li>
                  Where the property forms part of a development containing separate blocks of
                  dwellings, the lease terms of the property apply only to the block. There will be
                  no requirement to contribute towards costs relating to other parts of the
                  development, other than in respect of common roads, paths, communal grounds and
                  services.
                </li>
                <li>
                  Where the property forms part of a larger development whose ownership has since
                  been divided, all necessary rights and reservations have been reserved.
                </li>
                <li>
                  There are no unusual restrictions on assignment or subletting of the property for
                  residential purposes.
                </li>
                <li>
                  There are no outstanding claims or litigation concerning the lease of the subject
                  property or any others within the same development.
                </li>
                <li>
                  Where the property benefits from additional facilities within the development, the
                  lease makes adequate provisions for the occupier to continue to enjoy them without
                  exceptional restriction, for the facilities to be maintained adequately and for
                  there being no charges over and above the service charge for such use and
                  maintenance.
                </li>
              </ol>
            </li>
            <li>
              <strong> Flats and Maisonettes:</strong>
              <br />
              Where the proposed security is part of a building comprising flats or maisonettes, the
              following assumptions will also be made, unless instructed to the contrary: UK VPGA 11
              Valuation for residential mortgage purposes UK Valuation Practice Guidance
              Applications (UK VPGAs).
              <br />
              <br />
              <ol className="list-[lower-roman]">
                <li>
                  The costs of repairs and maintenance to the building and grounds are shared
                  equitably between the flats and maisonettes.
                </li>
                <li>
                  There are suitable, enforceable covenants between all leaseholds, or through the
                  landlord or the owner.
                </li>
                <li>There are no onerous liabilities outstanding.</li>
                <li>
                  There are no substantial defects, or other matters requiring expenditure (in
                  excess of the current amount or assumed amount of service charge payable on an
                  annual basis), expected to result in charges to the leaseholder or owner of the
                  subject property during the next five years, that are equivalent to 10% or more of
                  the reported market value.
                  <br />
                  <br />
                  For the avoidance of doubt, the surveyor will not be under a duty to purchase data
                  from third parties or to read the lease documentation. If further information is
                  provided to the surveyor after the valuation has been completed and submitted, the
                  valuer may reconsider the valuation and if necessary, issue an amended report. But
                  again, for the avoidance of doubt, the valuer will not be under any obligation to
                  read the lease document and would only be expected to base a revised valuation on
                  further information provided to the valuer by your legal representatives.
                  <br />
                  <br />
                  In respect of insurance, the following assumptions will be made, unless instructed
                  to the contrary:
                  <ol>
                    <li>
                      the property can be insured under all-risks cover for the current
                      reinstatement cost and is available on normal terms,
                    </li>
                    <li>there are no outstanding claims or disputes,</li>
                    <li>
                      where individuals in a block make separate insurance arrangements, the leases
                      make provision for mutual enforceability of insurance and repairing
                      obligations and,
                    </li>
                    <li>
                      any landlord responsible for insurance is required to rebuild the property
                      with such alterations as may be necessary to comply with current Building
                      Regulations and planning requirements.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong> Limitation of Liability</strong>
          <br />
          <ol className="list-[lower-alpha]">
            <li>
              the Company and/or the Surveyor shall under no circumstances whatsoever be liable to
              the Client, whether in contract, tort (including negligence), breach of statutory
              duty, or otherwise, for any loss of profit or any indirect or consequential loss, of
              any failure by the client or any agent of the client: promptly to provide information
              or other material reasonably requested, or where that material is inaccurate or
              incomplete, or to follow our advice or recommendations.
            </li>
            <li>
              the Client acknowledges and agrees that the sole obligation of the Company is to
              arrange for the Surveyor to provide the Client with the Valuation Report. The Client
              hereby accepts that any claim for losses arising out of any matter in connection with
              the Valuation Report or any acts or omissions of the Surveyor must be brought against
              the Surveyor. The Client acknowledges, agrees and accepts that the Company shall have
              no liability whatsoever for or in relation to any such losses.
            </li>
            <li>
              none of the Company’s employees, officers, partners, affiliates or consultants
              individually has a contract with the Client or owes the Client or the prospective
              purchaser or vendor or any other party a duty of care or personal responsibility. The
              Client agrees not to bring any claim against any such individuals personally. To the
              extent that any part of this notification is a restriction of liability within the
              meaning of Section 1 of the Unfair Contract Terms Act 1977, it does not apply to death
              or personal injury resulting from negligence or fraudulent misrepresentation.
            </li>
            <li>
              the Company&rsquo;s and Surveyors&rsquo; entire financial liability to the Client is
              hereby limited to an amount which is equivalent to twenty times the fee the Client has
              paid to the Company for the Valuation Service, and such amount shall be inclusive of
              legal costs, professional fees and disbursements, further investigations and testing
              and any other collateral costs incurred by the Client.
            </li>
            <li>
              these Terms of Engagement do not include any warranties, conditions and other terms
              except as stated herein and as required by law. The Valuation will be provided after
              diligent consideration and research but property values are subject to fluctuation and
              the valuation process is inexact and thus the Surveyor&rsquo;s opinion is subject to a
              degree of tolerance depending on the property and the availability of comparable
              evidence. Valuations are provided to assist the Client in making a prudent decision:
              they are not provided as any form of guarantee or warranty of value.
            </li>
            <li>
              where loss is suffered by the Client for which the Company, the Surveyor and/or any
              other third party are jointly responsible, any recoverable loss by the Client from the
              Company will be proportionate to the Company&rsquo;s relative contribution to the loss
              suffered and subject to the provisions of this clause 3.
            </li>
            <li>
              where the Client is composed of more than one entity, then each such entity&apos;s
              liability shall be joint and several.
            </li>
            <li>
              the Client acknowledges that the Surveyor shall have no liability for any inaccuracy
              in the Valuation Report or service provision where this is due to an inaccuracy in the
              Information provided by the client or third parties.
            </li>
            <li>
              the terms implied by Sections 3 to 5 of the Supply of Goods and Services Act 1982 are,
              to the fullest extent permitted by law, excluded from this Contract and the Valuation
              Report.
            </li>
            <li>this Clause 3 shall survive termination of this contract.</li>
            <li>
              the Client&rsquo;s failure to follow the requirements set out in these Terms and
              Conditions will invalidate the Valuation and service provision.
            </li>
            <li>
              nothing in these conditions shall limit or exclude the Company&rsquo;s liability for:
              <ol className="list-[lower-roman]">
                <li>
                  Death or personal injury caused by its negligence, or the negligence of its
                  officers, employees, agents, subcontractors or consultants;
                </li>
                <li>Fraud or fraudulent misrepresentation.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong> General Terms</strong>
          <br />
          <ol className="list-[lower-alpha]">
            <li>
              In the event of one of the Terms and Conditions herein being held to be unenforceable,
              the remainder of the contract is not affected.
            </li>
            <li>
              The Client shall pay the Company in respect of the fee including VAT, to be agreed
              between the Client and the Company and detailed in the Instruction email. The fee is
              payable at the time of the Company being engaged to perform the Service. Please note
              that such fee will not be treated by RICS as client money and will not be subject to
              the protection of the RICS Client Money Protection Scheme.
            </li>
            <li>
              The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations
              2013 shall not apply to this contract which cannot be cancelled once the Property has
              been inspected by the Surveyor.
            </li>
            <li>
              Cancellation of the contract may be made at any time but must be before 2pm on the
              previous working day (Monday to Friday) before the property is due to be inspected by
              the Surveyor, for a full refund of any fee paid; cancellation thereafter will be
              subject to a deduction from any fee paid for the reasonable expenses plus VAT incurred
              by the Valuer and the Company and an additional administration charge of &pound;40.00
              inclusive of VAT.
            </li>
            <li>
              The Company also has the right to cancel this contract, if for reasons beyond its
              control, it is unable supply the service and is therefore unable to fulfil the
              contract. In this event the company will provide a full refund of any fees paid.
            </li>
            <li>
              If the Client has agreed to accept reimbursement of the fee paid, or the
              Company&rsquo;s fee account has not been settled within three months of the date of
              the invoice, then it is agreed that the Client has placed no reliance on the Valuation
              Report and thus the Client or any permitted assignees will not be entitled to pursue
              any action for alleged negligence, breach of contract or breach of duty. This does not
              limit the Company&rsquo;s entitlement to the agreed fee.
            </li>
            <li>
              All telephone or verbal discussions are prone to misinterpretation or misunderstanding
              by either party, especially in the interpretation of instructions, legal, technical or
              descriptive matters; as such no reliance may be placed by the Client upon any such
              discussions unless agreed outcomes are formally confirmed in writing by an authorised
              officer of the Company.
            </li>
            <li>
              The Valuation Report will be conveyed to the Client electronically and in the event
              that a printed hard copy report is required, the Client undertakes to arrange this at
              the Client&rsquo;s cost.
            </li>
            <li>
              <strong> Referral fees: The Company</strong> as a regulated firm and{' '}
              <strong> The Surveyor</strong> confirms that a payment has been or may be made, either
              individually or in part of a third-party commercial relationship. Both{' '}
              <strong> The Company</strong> and <strong> The Surveyor</strong> are satisfied that
              this does not constitute a conflict of interest.
            </li>
            <li>
              Failure to follow the requirements set down by these conditions will invalidate the
              Report and the Valuation.
            </li>
            <li>
              <strong> Dispute resolution:</strong> In the event that the Client has a complaint
              regarding the standard of The Service and/or The Valuation Report, a formal Complaint
              Handling Procedure will be followed. Copies of the Surveyor&apos;s Complaint Handling
              Procedure and The Company&rsquo;s Complaint Handling Procedure are available upon
              request. Using the Complaints Handling Procedures will not affect the Client&apos;s
              legal rights.
            </li>
            <li>
              <strong> Data Protection:</strong> The Company takes its responsibility under the
              General Data Protection Regulation very seriously. Our Privacy Policy details how we
              collect, use, maintain and disclose personal data. A copy of our Privacy Policy is
              available upon request.
            </li>
            <li>
              <strong> Intellectual Property:</strong> All intellectual property rights arising out
              of the Valuation Service shall be owned by the Company, excluding the Customer
              Information.
            </li>
          </ol>
        </li>
        <li>
          <strong> Building Insurance Reinstatement Cost Provided as an Additional Service</strong>
          <br />
          <strong> The Surveyor</strong> can provide a building insurance reinstatement cost as an
          additional service to the Valuation service, subject to an additional fee.
          <br />
          <br />
          The reinstatement cost is not related to the capital value of the building and property.
          It is the cost of rebuilding from scratch were the building to be damaged beyond repair.
          <br />
          <br />
          The reinstatement cost is the cost of rebuilding a property including demolition of the
          original building, temporary support of adjoining buildings where applicable, materials,
          labour, professional fees and Local Authority charges. It includes sums for boundaries,
          drainage and significant outbuildings. The reinstatement cost will be reported as a single
          figure, not as a detailed schedule of all costs.
          <br />
          <br />
          Specialist installations such as swimming pools are excluded.
          <br />
          <br />
          The Surveyor cannot provide reinstatement costs for Listed Buildings as this requires a
          detailed specialist assessment, which is beyond the scope of the Valuation service.
        </li>
      </ol>
      <br />
      <br />
      <p className="text-lg">
        <strong> Definitions:</strong>
      </p>
      <p>
        <strong> The Company:</strong> Houzecheck Limited with company number 11223619 and
        registered office at 30-40 St. Albans Road, Watford, Hertfordshire, WD17 1RN.
        <br />
        <br />
        <strong> The Surveyor:</strong> {name} of {companyName}. <br />
        <br />
        <strong> Property:</strong> An interest in a self-contained domestic dwelling as defined by
        The Town and Country Planning (Use Classes) Order 1987 (as amended) being classified as Use
        Class C3 which may also include small HMOs within Use Class C4.
        <br />
        <br />
      </p>
      <p>
        <strong> Valuation Report:</strong> The report undertaken by the Surveyor, the format of
        which will follow a template that complies with VPS 3 of the current RICS Regulations, and
        will set out the terms and conditions on which the Surveyor has performed the report.
      </p>
      <br />
      <br />
      <p>
        <strong> Valuation:</strong> Unless otherwise stated in the report the valuation will be for
        the interest in the property for its existing tenure and title, use and form.
      </p>
      <br />
      <br />
      <p>
        <strong> Valuation Date:</strong> Shall be the Inspection Date unless otherwise stipulated
        in the report.
      </p>
      <br />
      <br />
      <p>
        <strong> Service:</strong> The service provided by the Company in arranging for the Surveyor
        to provide the Client with a Valuation Report.
      </p>
      <br />
      <br />
      <p>
        <strong> Market Value (MV) is:</strong> The estimated amount for which an asset or liability
        should exchange on the valuation date between a willing buyer and a willing seller in an
        arm&rsquo;s length transaction after proper marketing and where the parties had each acted
        knowledgeably, prudently and without compulsion.
      </p>
      <br />
      <br />
      <p>
        <strong>
          Statutory definition of Market Value (capital gains tax, inheritance tax and stamp duty
          land tax).
        </strong>{' '}
        Summary definition derived from legislation: &apos;The price which the property might
        reasonably be expected to fetch if sold in the open market at that time, but that price
        shall not be assumed to be reduced on the grounds that the whole property is to be placed on
        the market at one and the same time.&apos; (Source: section 272 Taxation and Chargeable
        Gains Act 1992. Section 160 Inheritance Tax Act 1984, Section 118 Finance Act 2003.)
      </p>
      <br />
      <br />
      <p>
        <strong> Market Rent (MR) is:</strong> The estimated amount for which an interest in real
        property should be leased on the valuation date between a willing lessor and a willing
        lessee on appropriate lease terms in an arm&rsquo;s length transaction, after proper
        marketing and where the parties had each acted knowledgeably, prudently and without
        compulsion. <br />
        Our assumptions of what are &lsquo;appropriate lease terms&rsquo; for this property will be
        set out in our report.
      </p>
    </div>
  );
}

function ValuationTnc2({ name, companyName }) {
  return (
    <div className="text-justify">
      <div className="page">
        <p className="text-center text-xl">
          <strong> Terms and Conditions of Engagement for Valuations</strong>
        </p>
        <br />
        <br />
        <p>
          We refer to your instructions received requiring The Surveyor to carry out and to prepare
          and deliver a Valuation of Residential Property Report (Valuation Report) on the above
          property. We accept the instructions only on the basis that the scope, purpose, and
          conditions of the report are as set out in these Terms and Conditions for the Valuation
          Report. The Terms and Conditions are attached to and form part of the Valuation Report.
        </p>
        <p>
          The Terms and Conditions of Engagement should be referred to and used in conjunction with
          the Instruction Email, setting out the client&rsquo;s name and address, instruction type,
          reason for instruction, subject property address and fee/price agreed. The Surveyor
          details are included in the Instruction email and these Terms as per below.
        </p>
        <p>
          The Valuation Report service requires disclosure of any previous business relationship we
          may have with you, your property, or your selling agent. If appropriate, disclosure has
          been made in accordance with Clause 1d of the Terms and Conditions. The scope and extent
          of the inspection is detailed in Clause 2a to 2h inclusive of the Terms and Conditions.
          The Valuation Report is prepared by a suitably qualified Surveyor, in an objective way
          having regard to the condition of the Property on the day of the inspection, and who is:
        </p>
        <p>
          a) a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the
          membership grades: AssocRICS, MRICS, or FRICS; and
        </p>
        <p>b) a member of the RICS Valuer Registration Scheme (VRS).</p>
        <p>
          We are required by RICS to seek formal confirmation from you that you are satisfied that
          the Valuation Report Terms and Conditions meet your requirements, and this will be deemed
          satisfied by you by making payment for this service, as stated in the Instruction Job
          Details email.
        </p>
        <p>
          Complaints: Houzecheck operates a Complaints Handling Procedure in accordance with RICS
          guidelines. A copy is available upon request.
        </p>
        <p>
          RICS Regulation and Monitoring: Houzecheck and the Surveyor/Valuer are registered with the
          RICS Valuer Registration scheme. Please note that our files may be subject to monitoring
          under the Institution&rsquo;s conduct and disciplinary regulations.
        </p>
        <p>1. Purpose of the Report</p>
        <p>
          a) The Client wishes the Company to arrange for a Surveyor to provide an opinion of the
          value of the Freehold or Leasehold or Commonhold interest in the Property as specified by
          the Client
        </p>
        <p>
          b) The Surveyor shall provide to the Client a report based upon an inspection of the
          property by a named individual who shall be: 1. a member of the Royal Institution of
          Chartered Surveyors (RICS) holding one of the membership grades: AssocRICS,
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 1
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          MRICS, or FRICS; and 2. a member of the RICS Valuer Registration Scheme (VRS), setting out
          the Valuer&rsquo;s opinion of the relevant interest in the property. The Valuation and
          Report will be prepared in accordance with the RICS Valuation Global Standards 2022,
          effective 31st January 2022, and the RICS Valuation Global Standard 2017 UK National
          Supplement, effective 14th January 2019, known as the Red Book.
        </p>
        <p>
          c) Confidentiality: The Surveyor&apos;s valuation is provided to the named addressee/s
          alone and solely for the purpose of the instruction to which it relates. The valuation may
          not, without the Surveyor&rsquo;s written consent, be used or relied upon by any other or
          third party, even if that third party pays all or part of the fees, or is permitted to see
          a copy of the Valuation Report. If the Surveyor provides written consent to a third party
          relying on the valuation, any such third party is deemed to have accepted the terms of our
          engagement and the Surveyor&apos;s engagement and an additional fee may be payable.
          Neither the whole nor any part of the report or any reference to it may be included in any
          published document, circular or statement nor published in any way without the
          Surveyor&rsquo;s written approval of the form and context in which it may appear.
        </p>
        <p>
          d) The Surveyor shall have no known interest in the Property or any other conflict that
          will prevent the Valuation Service from being undertaken in an independent, objective, and
          unbiased way.
        </p>
        <p>
          e) The Value reported unless otherwise specifically agreed in writing, shall be in
          accordance with one or more of the following Valuation bases: Market Value (MV) or Market
          Rent (MR), or a statutory basis of value as detailed at the end of these conditions. (See
          &ldquo;Definitions&rdquo;.) The value shall be provided in pounds sterling.
        </p>
        <p>
          f) The Valuation Approach shall be the Comparable Basis of Approach unless at the sole
          discretion of the Valuer and subject to the interest, bearing income, an Investment
          Methodology may be adopted which will be stated in the Valuation Report.
        </p>
        <p>
          g) The Reinstatement Cost figure (If requested in the client instruction) for the
          permanent buildings is provided for insurance purposes and is not directly related to the
          market value of the property. The reinstatement cost will be calculated by reference to
          the indices and guidance published by the Building Costs Information Service (BCIS). The
          figure stated will only include Value Added Tax on professional fees (not on building
          costs) and will not take into account other potential or consequential losses such as
          costs of demolition, site clearance, provision of services, alternative accommodation,
          personal items or any other contents or contingent costs.
        </p>
        <p>
          h) Flats &ndash; Building Insurance will be for the subject flat only and it will be
          assumed that the Client&apos;s legal adviser will confirm that the Freeholder or
          Management company includes the flat in a block buildings insurance policy.
        </p>
        <p>
          i) Access to the property shall be the responsibility of the Client who should inform the
          Valuer of any delay or any special arrangements to be followed together with contact
          information for the occupier, estate agent or other key holder.
        </p>
        <p>
          j) The Client will be informed of the inspection date and time of inspection and the
          report will be available normally within five working days of the inspection or otherwise
          as
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 2
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          agreed with the Client and is subject to all necessary information being made available to
          the Surveyor or subject to further discovery. Any anticipated delay in gaining access or
          delay in the report will be notified to the Client.
        </p>
        <p>
          k) Where specifically requested, the report will also provide an estimated price that
          could hypothetically be achieved given a specific restriction that the property must
          achieve a sale and exchange contracts within 4 weeks (28 days) of the date that the report
          is provided. This estimated price is not a valuation, and any relationship to Market Value
          is coincidental. It is not described or used as a basis of value rather it is a figure
          that might be seen as a reflection of worth to the instructing client as at the date that
          the report is provided given the particular circumstances.
        </p>
        <p>2. Reporting Assumptions and Limitations of Inspection</p>
        <p>
          a) The Surveyor will make reasonable assumptions which will be specified in the Valuation
          Report. However, if these assumptions are found to be incorrect please advise us
          immediately as the valuation may require to be amended to take account of any newly
          disclosed, amended or discovered facts.
        </p>
        <p>
          b) The Surveyor shall, unless otherwise expressly agreed, rely upon information from the
          Client or the Client&rsquo;s legal or other professional advisers. It is the
          responsibility of the Client to ensure this information is accurate and to promptly advise
          the Valuer if it is not.
        </p>
        <p>
          c) The Client has requested a report on the value of the Property only. The Report is not
          a more thorough RICS Level 2 Report, Level 3 Report or other detailed survey report. The
          Surveyor will draw attention to the general condition of the property and any visible
          major defects but only where these may have an e&ucirc;�ect upon the Valuation.
        </p>
        <p>
          d) External Cladding Panels and Fire Safety &ndash; Composite external panels which
          incorporate insulating material between inner and outer external wall faces such as
          aluminium composite material (ACM) panels have been extensively used in recent years as
          external cladding. Some panel cladding systems incorporate combustible insulation
          materials. The type of insulation and any fire checking of the panel system cannot be
          identified from a visual inspection. Where the existence of such panels has been reported,
          the type of insulation and installation is outside the scope of The Survey Report and the
          Valuation and Valuation Report. Other elements of the Property and building will also have
          a bearing on overall fire safety, including, fire doors, fire stops in wall cavities,
          escape routes, alarms and fire suppression systems. Such matters are outside the scope of
          the Valuation and/Valuation Report. For buildings of more than two storeys it is essential
          that the Fire Safety Certificate, (EWS1) is supplied. Where the nature of the cladding is
          considered to be suspect, the Surveyor will report a Zero Value until the form EWS1 in
          Compliance is supplied. For buildings of two storey height or less the Surveyor will make
          the assumption that the cladding system is satisfactory. It is the responsibility of The
          Client to have any materials tested
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 3
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          and if found to be not in compliance, the Valuation may then require to be adjusted. The
          Client should make all necessary enquiries prior to any legal or financial commitment.
        </p>
        <p>
          e) Asbestos and other hazardous materials: The construction of buildings, fixtures,
          fittings and plant or equipment may contain hazardous materials. Such materials are
          impossible to detect without specific tests and are beyond the scope of the Valuation
          Report. No guarantee is given that such materials are not incorporated in any part of the
          property or fixtures.
        </p>
        <p>
          f) The Inspection will take into account gardens, grounds and permanent outbuildings, but
          will not include constructions or equipment with a specific or leisure purpose including
          sauna, swimming pools or tennis courts and associated services and changing rooms. The
          Inspection will include the immediate gardens that belong to the property, but the
          Inspection specifically excludes any additional land such as pasture or amenity land.
        </p>
        <p>
          g) Measurement &ndash; The Surveyor may measure the property during the inspection or take
          measurements or areas from plans provided by the client or obtain such details from
          specialised database information available to the Surveyor or from publicly available
          information at the sole discretion of the Surveyor. Outbuildings will not be measured on
          site unless they are considered by the Surveyor to contribute significantly to the
          reported capital value or reinstatement cost (if reported).
        </p>
        <p>
          h) The Following Additional Assumptions will be made by the Surveyor in respect of the
          Property:
        </p>
        <p>
          i. the ground is not affected by the existence of noxious or toxic substances or gases and
          has not been used for landfill or mineral extraction or other forms of contamination and
          does not contain gypsum deposits;
        </p>
        <p>
          ii. no high alumina cement concrete or calcium chloride additive or other potentially
          deleterious material including &ldquo;Mundic material&rdquo; was used in the construction
          of the property or has since been incorporated;
        </p>
        <p>
          iii. the Property is not susceptible to radon gas pollution, invasive or protected
          species, flood risk, high voltage equipment, cables or coastal erosion unless specified in
          the report;
        </p>
        <p>
          iv. the Property is not subject to any unusual or especially onerous restrictions,
          encumbrances or outgoings, easements or way leaves or rights of way over the property and
          that good title can be shown;
        </p>
        <p>
          v. the Property and its value are unaffected by any matters which would be revealed by
          local or environmental searches, and replies to the usual enquiries, or by any statutory
          notice, and that neither the property, nor its condition, nor its use, nor its intended
          use, is or will be unlawful;
        </p>
        <p>vi. the inspection of those parts which have not been included in the Inspection in</p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 4
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          accordance with these Terms and Conditions would neither reveal material defects nor cause
          the Valuer to alter the valuation materially;
        </p>
        <p>
          vii. it is assumed that unless otherwise stated, roads, sewers and services outside the
          curtilage of the property are the responsibility of the Local Authority or other statutory
          body. It is further assumed, unless otherwise stated, that all services including drainage
          are connected and access and roads are unfettered and in the case of the public highway
          maintained at the expense of the Local and Highways Authorities;
        </p>
        <p>
          viii. the Valuation is based on the assumption that the Property is being valued with
          vacant possession and, unless otherwise stated, for owner occupation; if the property is
          tenanted the client is under a duty to inform the valuer;
        </p>
        <p>
          ix. that further investigation into Planning Permission, Building Regulation, Permitted
          Development Rights, extensions and alterations, hazardous materials, onerous restrictions
          or the like will not reveal anything sufficiently adverse as to materially affect the
          Valuation;
        </p>
        <p>
          x. in the case of Leasehold Tenure the Valuer will assume that there remains a minimum of
          85 years on the lease and the Ground Rent and any Service Charges are reasonable unless
          otherwise detailed in the report.
        </p>
        <p>
          xi. there are no archaeological remains, Scheduled Ancient Monument or liability to
          Chancel Repair and the property is not at risk of flooding or likely to suffer damage from
          unstable ground conditions.
        </p>
        <p>
          xii. any other assumptions will be clearly stated in the report; however, the Valuer shall
          be under no duty to verify these assumptions and the Client should ensure the assumptions
          are correct. If any of these assumptions are incorrect then these should be discussed with
          the Valuer before any financial or legal commitment is made as the Valuer&apos;s opinion
          of value may change.
        </p>
        <p>
          xiii. If the Valuer has not been provided, or cannot obtain, an up-to-date EPC rating for
          this property, for the purposes of the Energy Act 2011, its valuation will be based on the
          assumption that the subject property will meet the minimum requirements laid down by
          legislation and that there will be no adverse impact on value, rental ability and
          marketability.
        </p>
        <p>
          xiv. In the case of a newly constructed property, it has been built under a recognised
          builder&rsquo;s warranty or insurance scheme, or has been supervised by a professional
          consultant capable of fully completing the UK Finance Professional Consultant Certificate.
        </p>
        <p>
          xv. There are no ongoing insurance claims or neighbour disputes and the property is
          insurable under normal terms.
        </p>
        <p>
          i) Leasehold Properties: Where the dwelling is leasehold, data including unexpired lease
          term, ground rent and
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 5
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          any further relevant information should be considered and reflected in the valuation based
          upon data that can be sourced on the day of inspection from either the client, vendor or
          selling agent as applicable following reasonable investigation. However, the surveyor
          clearly cannot give any absolute assurance that, if verbally provided, such data is
          reliable &ndash; verification will be a matter for your legal representatives as
          appropriate.
        </p>
        <p>
          Where the dwelling is leasehold, and it is not possible to inspect the lease or details
          have not been provided, the following assumptions will be made, unless instructed to the
          contrary:
        </p>
        <p>
          i) The unexpired term of the lease is assumed to be 85 years, and no action is being taken
          by any eligible party with a view to acquiring the freehold or extending the lease term.
          (RICS Valuation - Global Standards 2022: UK national supplement, UK VPGA 11, Valuation for
          residential mortgage purposes, UK Valuation Practice Guidance Applications (UK VPGAs) RICS
          Valuation Global Standards 2022: UK national supplement).
        </p>
        <p>ii) There are no exceptionally onerous covenants upon the leaseholder.</p>
        <p>
          iii) The lease cannot be determined, except on the grounds of a serious breach of covenant
          in the existing lease agreement.
        </p>
        <p>
          iv) If there are separate freeholders, head and/or other subhead leaseholders, the terms
          and conditions of all the leases are in the same form and contain the same terms and
          conditions.
        </p>
        <p>v) The lease terms are mutually enforceable against all parties concerned.</p>
        <p>
          vi) There are no breaches of covenant or disputes between the various interests concerned.
        </p>
        <p>
          vii) The leases of all the properties in the building/ development are materially the
          same.
        </p>
        <p>
          viii) The ground rent stated, or assumed, is not subject to unreasonable review and is
          payable throughout the unexpired lease term.
        </p>
        <p>
          ix) In the case of blocks of flats or maisonettes of over six dwellings, the freeholder
          manages the property directly, or there is an appropriate management structure in place.
        </p>
        <p>
          x) There is a duty holder, as defined in the Control of Asbestos Regulations 2012, and
          there are in place an asbestos register and an effective management plan, which does not
          require any immediate expenditure, pose a significant risk to health, or breach Health and
          Safety Executive (HSE) requirements.
        </p>
        <p>
          xi) Where the subject property forms part of a mixed residential or commercially used
          block or development, there will be no significant changes in the existing
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 6
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>pattern of use.</p>
        <p>
          xii) Where the property forms part of a development containing separate blocks of
          dwellings, the lease terms of the property apply only to the block. There will be no
          requirement to contribute towards costs relating to other parts of the development, other
          than in respect of common roads, paths, communal grounds and services.
        </p>
        <p>
          xiii) Where the property forms part of a larger development whose ownership has since been
          divided, all necessary rights and reservations have been reserved.
        </p>
        <p>
          xiv) There are no unusual restrictions on assignment or subletting of the property for
          residential purposes.
        </p>
        <p>
          xv) There are no outstanding claims or litigation concerning the lease of the subject
          property or any others within the same development.
        </p>
        <p>
          xvi) Where the property benefits from additional facilities within the development, the
          lease makes adequate provisions for the occupier to continue to enjoy them without
          exceptional restriction, for the facilities to be maintained adequately and for there
          being no charges over and above the service charge for such use and maintenance.
        </p>
        <p>
          j) Flats and Maisonettes: Where the proposed security is part of a building comprising
          flats or maisonettes, the following assumptions will also be made, unless instructed to
          the contrary: UK VPGA 11 Valuation for residential mortgage purposes UK Valuation Practice
          Guidance Applications (UK VPGAs).
        </p>
        <p>
          i) The costs of repairs and maintenance to the building and grounds are shared equitably
          between the flats and maisonettes.
        </p>
        <p>
          ii) There are suitable, enforceable covenants between all leaseholds, or through the
          landlord or the owner.
        </p>
        <p>iii) There are no onerous liabilities outstanding.</p>
        <p>
          iv) There are no substantial defects, or other matters requiring expenditure (in excess of
          the current amount or assumed amount of service charge payable on an annual basis),
          expected to result in charges to the leaseholder or owner of the subject property during
          the next five years, that are equivalent to 10% or more of the reported market value.
        </p>
        <p>
          For the avoidance of doubt, the surveyor will not be under a duty to purchase data from
          third parties or to read the lease documentation. If further information is provided to
          the surveyor after the valuation has been completed and submitted, the valuer may
          reconsider the valuation and if necessary, issue an amended report. But again, for the
          avoidance of doubt, the valuer will not be under any obligation to read the lease document
          and would only be expected to base a
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 7
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          revised valuation on further information provided to the valuer by your legal
          representatives.
        </p>
        <p>
          In respect of insurance, the following assumptions will be made, unless instructed to the
          contrary:
        </p>
        <p>
          (1) the property can be insured under all-risks cover for the current reinstatement cost
          and is available on normal terms,
        </p>
        <p>(2) there are no outstanding claims or disputes,</p>
        <p>
          (3) where individuals in a block make separate insurance arrangements, the leases make
          provision for mutual enforceability of insurance and repairing obligations and,
        </p>
        <p>
          (4) any landlord responsible for insurance is required to rebuild the property with such
          alterations as may be necessary to comply with current Building Regulations and planning
          requirements.
        </p>
        <p>3. Limitation of Liability</p>
        <p>
          a) the Company and/or the Surveyor shall under no circumstances whatsoever be liable to
          the Client, whether in contract, tort (including negligence), breach of statutory duty, or
          otherwise, for any loss of profit or any indirect or consequential loss, of any failure by
          the client or any agent of the client: promptly to provide information or other material
          reasonably requested, or where that material is inaccurate or incomplete, or to follow our
          advice or recommendations.
        </p>
        <p>
          b) the Client acknowledges and agrees that the sole obligation of the Company is to
          arrange for the Surveyor to provide the Client with the Valuation Report. The Client
          hereby accepts that any claim for losses arising out of any matter in connection with the
          Valuation Report or any acts or omissions of the Surveyor must be brought against the
          Surveyor. The Client acknowledges, agrees and accepts that the Company shall have no
          liability whatsoever for or in relation to any such losses.
        </p>
        <p>
          c) none of the Company&rsquo;s employees, officers, partners, affiliates or consultants
          individually has a contract with the Client or owes the Client or the prospective
          purchaser or vendor or any other party a duty of care or personal responsibility. The
          Client agrees not to bring any claim against any such individuals personally. To the
          extent that any part of this notification is a restriction of liability within the meaning
          of Section 1 of the Unfair Contract Terms Act 1977, it does not apply to death or personal
          injury resulting from negligence or fraudulent misrepresentation.
        </p>
        <p>
          d) the Company&rsquo;s and Surveyors&rsquo; entire financial liability to the Client is
          hereby limited to an amount which is equivalent to twenty times the fee the Client has
          paid to the Company for the Valuation Service, and such amount shall be inclusive of legal
          costs, professional fees and disbursements, further investigations and testing and any
          other collateral costs incurred by the Client.
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 8
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          e) these Terms of Engagement do not include any warranties, conditions and other terms
          except as stated herein and as required by law. The Valuation will be provided after
          diligent consideration and research but property values are subject to fluctuation and the
          valuation process is inexact and thus the Surveyor&rsquo;s opinion is subject to a degree
          of tolerance depending on the property and the availability of comparable evidence.
          Valuations are provided to assist the Client in making a prudent decision: they are not
          provided as any form of guarantee or warranty of value.
        </p>
        <p>
          f) where loss is suffered by the Client for which the Company, the Surveyor and/or any
          other third party are jointly responsible, any recoverable loss by the Client from the
          Company will be proportionate to the Company&rsquo;s relative contribution to the loss
          suffered and subject to the provisions of this clause 3.
        </p>
        <p>
          g) where the Client is composed of more than one entity, then each such entity&apos;s
          liability shall be joint and several.
        </p>
        <p>
          h) the Client acknowledges that the Surveyor shall have no liability for any inaccuracy in
          the Valuation Report or service provision where this is due to an inaccuracy in the
          Information provided by the client or third parties.
        </p>
        <p>
          i) the terms implied by Sections 3 to 5 of the Supply of Goods and Services Act 1982 are,
          to the fullest extent permitted by law, excluded from this Contract and the Valuation
          Report.
        </p>
        <p>j) this Clause 3 shall survive termination of this contract.</p>
        <p>
          k) the Client&rsquo;s failure to follow the requirements set out in these Terms and
          Conditions will invalidate the Valuation and service provision.
        </p>
        <p>
          l) nothing in these conditions shall limit or exclude the Company&rsquo;s liability for:
        </p>
        <p>
          i. Death or personal injury caused by its negligence, or the negligence of its officers,
          employees, agents, subcontractors or consultants;
        </p>
        <p>ii. Fraud or fraudulent misrepresentation;</p>
        <p>4. General Terms</p>
        <p>
          a) In the event of one of the Terms and Conditions herein being held to be unenforceable,
          the remainder of the contract is not affected.
        </p>
        <p>
          b) The Client shall pay the Company in respect of the fee including VAT, to be agreed
          between the Client and the Company and detailed in the Instruction email. The fee is
          payable at the time of the Company being engaged to perform the Service. Please note that
          such fee will not be treated by RICS as client money and will not be subject to the
          protection of the RICS Client Money Protection Scheme.
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 9
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          c) If the Client has agreed to accept reimbursement of the fee paid, or the
          Company&rsquo;s fee account has not been settled within three months of the date of the
          invoice, then it is agreed that the Client has placed no reliance on the Valuation Report
          and thus the Client or any permitted assignees will not be entitled to pursue any action
          for alleged negligence, breach of contract or breach of duty. This does not limit the
          Company&rsquo;s entitlement to the agreed fee.
        </p>
        <p>
          d) All telephone or verbal discussions are prone to misinterpretation or misunderstanding
          by either party, especially in the interpretation of instructions, legal, technical or
          descriptive matters; as such no reliance may be placed by the Client upon any such
          discussions unless agreed outcomes are formally confirmed in writing by an authorised
          officer of the Company.
        </p>
        <p>
          e) The Valuation Report will be conveyed to the Client electronically and in the event
          that a printed hard copy report is required, the Client undertakes to arrange this at the
          Client&rsquo;s cost.
        </p>
        <p>
          f) Referral fees: The Company as a regulated firm and The Surveyor confirms that a payment
          has been or may be made, either individually or in part of a third-party commercial
          relationship. Both The Company and The Surveyor are satisfied that this does not
          constitute a conflict of interest.
        </p>
        <p>
          j) Failure to follow the requirements set down by these conditions will invalidate the
          Report and the Valuation.
        </p>
        <p>
          h) Dispute resolution: In the event that the Client has a complaint regarding the standard
          of The Service and/or The Valuation Report, a formal Complaint Handling Procedure will be
          followed. Copies of the Surveyor&apos;s Complaint Handling Procedure and The
          Company&rsquo;s Complaint Handling Procedure are available upon request. Using the
          Complaints Handling Procedures will not affect the Client&apos;s legal rights.
        </p>
        <p>
          i) Data Protection: The Company takes its responsibility under the General Data Protection
          Regulation very seriously. Our Privacy Policy details how we collect, use, maintain and
          disclose personal data. A copy of our Privacy Policy is available upon request.
        </p>
        <p>
          j) Intellectual Property: All intellectual property rights arising out of the Valuation
          Service shall be owned by the Company, excluding the Customer Information.
        </p>
        <p>5 Cancellation, rescheduling and no shows</p>
        <p>
          Cancellation: The Client can cancel this contract by notifying The Company by email or
          over the telephone. The Client is liable to pay the non-refundable Booking Fee and, one of
          the following cancellation fees depending upon when the notice of cancellation is
          received. Where notice of cancellation is received from The Client:
        </p>
        <p>a) Before 2pm on the day before the scheduled appointment: a cancellation fee of</p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 10
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>&pound;25.00 including VAT is payable to cover administrative costs</p>
        <p>
          b) After 2pm on the day before the appointment, but prior to inspection taking place: 25%
          of the agreed fee, subject to a minimum fee applying of &pound;75.00 including VAT
        </p>
        <p>
          c) Following the surveyor attending the subject property, but before the inspection is
          undertaken or report completed: 50% of the agreed fee. And note:
        </p>
        <p>
          The Company can cancel the contract if The Surveyor, after arriving at the property,
          concludes that it is of a type of property that he/she has insufficient specialist
          knowledge to be able to provide the Survey Report satisfactorily. In case of this
          cancellation by The Surveyor, any money paid by The Client for the Service and The Survey
          Report will be refunded in full minus the Booking Fee.
        </p>
        <p>
          The Company can cancel the contract if The Surveyor finds that The Subject Property is
          demonstrably different from that described by The Client at the time of providing
          instructions for the service. In case of this cancellation by The Company and/or The
          Surveyor, any money paid by the Client for the service and The Survey Report will be
          refunded, less a cancellation fee of &pound;25.00 including VAT to cover administrative
          costs. In the event of The Surveyor having already attended The Subject Property, a refund
          of monies paid by The Client for the Service and The Survey Report will be reduced by 50%
          of the paid fee and the non-refundable Booking Fee.
        </p>
        <p>
          The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013
          and/or the Consumer Rights Act 2015 shall not apply to this contract which cannot be
          cancelled once The Subject property has been inspected by The Surveyor. Once we have
          inspected The Subject Property or provided you with The Survey Report you will lose the
          right to cancel during the 14-day cooling off period.
        </p>
        <p>
          The Company also has the right to cancel this contract, if, for reasons beyond its
          control, it is unable to fulfil the contract. In this event The Company will provide a
          full refund of any fees paid.
        </p>
        <p>
          No-Shows: In circumstances where an appointment visit to access The Subject Property does
          not proceed due to factors beyond the control of The Company or The Surveyor, and/or where
          the third party appointee is not present or available.
        </p>
        <p>
          Where The Surveyor fails to get access to the property for any reason, the Client is
          liable to pay the non-refundable Booking Fee and, one of the following:
        </p>
        <p>
          a) An amount representing 50% of the agreed fee including VAT will be payable to The
          Company by The Client.
        </p>
        <p>
          b) Where The Client proceeds with rescheduling this appointment with The Company, a fee of
          &pound;75.00 including VAT will be payable by The Client to The Company to cover time and
          travel expenses incurred. Should the rescheduled appointment be cancelled, The Client will
          revert to be liable to pay 50% of the
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 11
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>agreed fee including VAT.</p>
        <p>
          General Rescheduling: In circumstances where an appointment visit needs to be rescheduled
          by The Client.
        </p>
        <p>
          The Client can reschedule their appointment visit by notifying The Company by email or
          over the telephone. Where notice of rescheduling is received from The Client:
        </p>
        <p>
          a) Before 2pm on the day before the scheduled appointment: no fee. b) On the day of
          inspection, but before the inspection is undertaken or report
        </p>
        <p>completed: &pound;75.00 including VAT for time and travel expenses incurred.</p>
        <p>6. Building Insurance Reinstatement Cost Provided as an Additional Service:</p>
        <p>
          The Surveyor can provide a building insurance reinstatement cost as an additional service
          to the Valuation service, subject to an additional fee.
        </p>
        <p>
          The reinstatement cost is not related to the capital value of the building and property.
          It is the cost of rebuilding from scratch were the building to be damaged beyond repair.
        </p>
        <p>
          The reinstatement cost is the cost of rebuilding a property including demolition of the
          original building, temporary support of adjoining buildings where applicable, materials,
          labour, professional fees and Local Authority charges. It includes sums for boundaries,
          drainage and significant outbuildings. The reinstatement cost will be reported as a single
          figure, not as a detailed schedule of all costs.
        </p>
        <p>Specialist installations such as swimming pools are excluded.</p>
        <p>
          The Surveyor cannot provide reinstatement costs for Listed Buildings as this requires a
          detailed specialist assessment, which is beyond the scope of the Valuation service.
        </p>
        <p>7. Definitions:</p>
        <p>
          The Company: Houzecheck Limited with company number 11223619 and registered office at The
          Quarters, 30-40 St Albans Road, Watford, Hertfordshire WD17 1RN.
        </p>
        <p>
          The Surveyor: {name} of {companyName}
        </p>
        <p>
          Property: An interest in a self-contained domestic dwelling as defined by The Town and
          Country Planning (Use Classes) Order 1987 (as amended) being classified as Use Class C3
          which may also include small HMOs within Use Class C4.
        </p>
        <p>
          Valuation Report: The report undertaken by the Surveyor, the format of which will follow a
          template that complies with VPS 3 of the current RICS Regulations, and will set out the
          terms and conditions on which the Surveyor has performed the report.
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 12
        </p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          Valuation: Unless otherwise stated in the report the valuation will be for the interest in
          the property for its existing tenure and title, use and form.
        </p>
        <p>
          Valuation Date: Shall be the Inspection Date unless otherwise stipulated in the report.
        </p>
        <p>
          Service: The service provided by the Company in arranging for the Surveyor to provide the
          Client with a Valuation Report.
        </p>
        <p>
          Market Value (MV): The estimated amount for which an asset or liability should exchange on
          the valuation date between a willing buyer and a willing seller in an arm&rsquo;s length
          transaction after proper marketing and where the parties had each acted knowledgeably,
          prudently and without compulsion.
        </p>
        <p>
          Statutory definition of Market Value (capital gains tax, inheritance tax and stamp duty
          land tax). Summary definition derived from legislation: &apos;The price which the property
          might reasonably be expected to fetch if sold in the open market at that time, but that
          price shall not be assumed to be reduced on the grounds that the whole property is to be
          placed on the market at one and the same time.&apos; (Source: section 272 Taxation and
          Chargeable Gains Act 1992. Section 160 Inheritance Tax Act 1984, Section 118 Finance Act
          2003.)
        </p>
        <p>
          Market Rent (MR): The estimated amount for which an interest in real property should be
          leased on the valuation date between a willing lessor and a willing lessee on appropriate
          lease terms in an arm&rsquo;s length transaction, after proper marketing and where the
          parties had each acted knowledgeably, prudently and without compulsion. Our assumptions of
          what are &lsquo;appropriate lease terms&rsquo; for this property will be set out in our
          report.
        </p>
        <p>
          Houzecheck Limited Company Number: 11223619 VAT Number: 307 9976 56 RICS Number: 814499
          Telephone: 0330 113 1133 Email: hello@houzecheck.com v16.0 17th May 2023 13
        </p>
        <p />
      </div>
    </div>
  );
}

function SurveyTnc2({ name, companyName }) {
  return (
    <div className="text-justify">
      <div className="page">
        <p className="text-xl text-center">
          <strong>SURVEYOR TERMS AND CONDITIONS OF ENGAGEMENT</strong>
        </p>
        <br />
        <br />
        <p />
        <p>Confirmation of Appointment</p>
        <p>
          We refer to your instruction received requiring The Surveyor to carry out and to prepare
          and deliver a Survey Report on the Subject Property. The Survey Report will be either a
          RICS Home Survey Level 2 Report (formerly known as an RICS HomeBuyer Report) or a RICS
          Home Survey Level 3 Report (formerly known as an RICS Building Survey) or a bespoke report
          as set out in the Instruction Email. The type of report will be specified in the separate
          Instruction Email for which you will have confirmed acceptance of the Terms of Engagement.
          We accept the instructions only on the basis that the scope, purpose, and conditions of
          the report are as set out in the Instruction Email and the Terms and Conditions for the
          Survey Report.
        </p>
        <p>The Service</p>
        <p>
          These Terms and Conditions of Engagement should be referred to and used in conjunction
          with the Instruction Email, setting out the Client name and Subject Property address,
          instruction type, reason for instruction and fee/price agreed. The Surveyor details are
          included in the Instruction email and these Terms as per below.
        </p>
        <p>
          The Survey Report requires disclosure of any previous business relationship we may have
          with you, your property, or your selling agent. If appropriate, disclosure has been made.
          The scope and extent of the inspection is detailed in the Description of Service,
          inclusive of the Terms and Conditions. The Survey Report will be prepared by a suitably
          qualified Surveyor in an objective way regarding the condition of the Property on the date
          of the inspection, and who is a member of the Royal Institution of Chartered Surveyors
          (RICS) holding one of the membership grades: AssocRICS, MRICS, or FRICS.
        </p>
        <p>
          We are required by RICS to seek formal confirmation from you that you are satisfied that
          the Survey Report Terms and Conditions meet your requirements, and these will be deemed as
          accepted by you upon making payment for this Service or services, as stated in the
          Instruction email by way of timed and date-verified email confirmation.
        </p>
        <p>
          Complaints: The Company operates a Complaints Handling Procedure in accordance with RICS
          guidelines. A copy is available upon request.
        </p>
        <p>
          Any reference to &lsquo;valuation&rsquo; or &lsquo;valuations&rsquo; in these Terms and
          Conditions relate to the Valuation element of an instructed Service and Survey Report
          where an optional additional valuation service has been selected by The Client and has
          agreed to be undertaken by The
        </p>
        <p>1</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>Surveyor, for which an additional fee has been agreed with The Client.</p>
        <p>
          The Survey Report does not include: a valuation buildings insurance reinstatement cost
          estimates
        </p>
        <p>
          These services can be provided as an additional service and are subject to additional
          terms and conditions and fees.
        </p>
        <p>Definition</p>
        <p>The Company/we/us/our: {companyName}</p>
        <p>
          The Surveyor: {name} of {companyName}
        </p>
        <p>Houzecheck: Our technology service provider.</p>
        <p>
          The Client/you/your: The person or persons detailed in the Instruction email providing
          instructions for the Service and Survey Report to be provided for their own use. In this
          context, The Client and the customer have the same meaning.
        </p>
        <p>
          Instruction Email: The type of report will be specified in the separate Instruction Email
          for which you will have confirmed acceptance of the Terms of Engagement. This is the email
          communication which we sent to you via Houzecheck, to set out the details of the Service
          and Survey Report to be undertaken. We accept the instructions only on the basis that the
          scope, purpose, and conditions of the report are as set out in the Instruction Email and
          the Terms and Conditions for the Survey Report.
        </p>
        <p>
          Property: An interest in a self-contained domestic dwelling as defined by The Town and
          Country Planning (Use Classes) Order 1987 (as amended) being classified as Use Class C3
          which may also include small HMOs within Use Class C4. In the context of the Service and
          the Property to be inspected, property is also referred to and defined as The Subject
          Property as specified in the Instruction Email.
        </p>
        <p>
          Subject Property: The Property interest which is physically inspected in performing the
          Service and carrying out the Survey Report which is detailed in the Instruction Email.
        </p>
        <p>
          Survey Report: The report undertaken by the Surveyor, the format of which will follow a
          template as set out in the attached documents &ldquo;Description of the RICS Home Survey
          &ndash; Level 2 (survey only)&rdquo;;
        </p>
        <p>2</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          &ldquo;Description of the RICS Home Survey &ndash; Level 2 (survey and valuation)&rdquo;;
          or &ldquo;Description of the RICS Home Survey &ndash; Level 3&rdquo;, as applicable. There
          is no set template for bespoke reports.
        </p>
        <p>
          Description of Service: The Royal Institution of Chartered Surveyors (RICS) publication
          documents which describe the extent of service for the RICS Home Survey Level 2 Report
          service and the RICS Home Survey Level 3 Report service.
        </p>
        <p>
          Valuation: Unless otherwise stated in the report, the valuation, where provided as an
          additional service, will be for the interest in The Subject Property, of its existing
          tenure and title, use and form. The Valuation will, as an additional service, therefore
          form part of the Survey Report.
        </p>
        <p>
          Valuation Date: The Valuation Date shall be the Inspection Date of The Subject Property
          unless otherwise stipulated in the report.
        </p>
        <p>
          The Service: The Service provided by the Company to provide The Client with a Survey
          Report which may include Valuation advice as an additional service.
        </p>
        <p>
          Market Value (MV): The estimated amount for which an asset or liability should exchange on
          the valuation date between a willing buyer and a willing seller in an arm&rsquo;s length
          transaction after proper marketing and where the parties had each acted knowledgeably,
          prudently and without compulsion.
        </p>
        <p>
          Statutory Definition of Market Value (capital gains tax, inheritance tax and stamp duty
          land tax). Summary definition derived from legislation: &lsquo;The price which the
          property might reasonably be expected to fetch if sold in the open market at that time,
          but that price shall not be assumed to be reduced on the grounds that the whole property
          is to be placed on the market at one and the same time.&rsquo; (Source: section 272
          Taxation and Chargeable Gains Act 1992. Section 160 Inheritance Tax Act 1984, Section 118
          Finance Act 2003).
        </p>
        <p>
          Market Rent (MR): The estimated amount for which an interest in real property should be
          leased on the valuation date between a willing lessor and a willing lessee on appropriate
          lease terms in an arm&rsquo;s length transaction, after proper marketing and where the
          parties had each acted knowledgeably, prudently and without compulsion.
        </p>
        <p>
          Our assumptions of what are &lsquo;appropriate lease terms&rsquo; for this property will
          be set out in our report.
        </p>
        <p>
          RICS Monitoring: The Surveyor is regulated by the Royal Institution of Chartered
          Surveyors, (RICS).
        </p>
        <p>3</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          Please note that our files may be subject to monitoring under the Institution&rsquo;s
          conduct and disciplinary regulations and will be provided to RICS on request.
        </p>
        <p>A. GENERAL TERMS</p>
        <p>
          1) The Client wishes The Company to provide the Survey Report being an opinion of
          condition of The Subject Property interest as specified by the Client.
        </p>
        <p>
          2) Subject to additional and agreed instructions to the effect, The Surveyor shall provide
          to The Client an opinion of value within The Survey Report based upon an inspection of The
          Subject Property by The Surveyor who shall be: a member of the Royal Institution of
          Chartered Surveyors (RICS), also a member of RICS Valuer Registration Scheme (VRS); and
          setting out the opinion of value of the relevant interest in the Property
        </p>
        <p>
          3) Any Valuation within the Survey Report will be prepared in accordance with the RICS
          Valuation &ndash; Global Standards effective 31 January 2022, the RICS Valuation &ndash;
          Global Standard 2017: UK National Supplement effective 14 January 2019, and relevant UK
          VPGAs.
        </p>
        <p>
          4) Introduction: These Terms of Engagement set out the conditions upon which we accept
          instructions, and will apply in all cases unless any other arrangement is confirmed in
          writing by The Company.
        </p>
        <p>
          5) Appointment: The Client appoints The Surveyor to provide The Survey Report, exercising
          reasonable skill and care.
        </p>
        <p>
          6) Performance Standards: The Surveyor confirms that the survey will be carried out in
          accordance with the best practice guidelines for RICS professionals carrying out a RICS
          Home Survey Level 2 Report service and the RICS Home Survey Level 3 Report service or
          other bespoke report.
        </p>
        <p>
          7) Liability and Duty of Care. The Surveyor owes to the Client a duty to act with
          reasonable skill and care in providing The Survey Report and complying with the
          Client&rsquo;s instructions where those instructions do not conflict with these terms or
          applicable laws and professional rules.
        </p>
        <p>
          The Company and The Surveyor have no liability for the consequences, including delay in or
          failure to provide The Service or The Survey Report, of any failure by The Client or any
          agent of The Client: promptly to provide information or other material reasonably
          requested, or where that material is inaccurate or incomplete, or to follow our advice or
          recommendations.
        </p>
        <p>
          We confirm that we hold professional indemnity insurance in respect of The Survey Report
          to be provided.
        </p>
        <p>8) The Surveyor. The Survey Report is to be provided by an AssocRICS, MRICS or</p>
        <p>4</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          FRICS qualified member of the Royal Institution of Chartered Surveyors, who has the
          skills, knowledge and experience to survey and report on the Subject Property.
        </p>
        <p>
          9) Report Delivery. Post inspection, We aim to deliver the report within 5 working days.
          In an exceptional scenario, the report may take longer. The report will be sent to the
          Client&rsquo;s email address (or other agreed email address as applicable). The Company
          and The Surveyor cannot be held liable for any loss arising due to the delay in Report
          delivery.
        </p>
        <p>
          10) The Client&rsquo;s Obligations. The Client shall pay us through Houzecheck in respect
          of the fee including VAT as detailed in the Instruction Email. The fee is payable at the
          time of The Company being engaged to perform The Service. Please note that such fee will
          not be treated by RICS as Client money and will not be subject to the protection of the
          RICS Client Money Protection Scheme.
        </p>
        <p>
          The Client will be responsible for the timely provision of all information and reasonable
          cooperation, including that from third parties engaged directly by the Client, required by
          the Company and the Surveyor to assist in the performance of The Service and delivery of
          The Survey Report. We will assume that the information provided is complete and correct.
        </p>
        <p>5</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>B. TERMS &amp; CONDITIONS RELATING TO RICS HOME SURVEYS</p>
        <p>1) Scope of the Inspection</p>
        <p>
          a) Generally, The Surveyor will consider his or her advice carefully, but is not required
          to advise on any matter the significance of which in relation to The Subject Property is
          not apparent at the time of inspection from the inspection itself.
        </p>
        <p>
          b) The Surveyor will inspect diligently, but is not required to undertake any action which
          would risk damage to the Property or injury to themselves.
        </p>
        <p>c) The Surveyor will not undertake any structural or other similar calculations.</p>
        <p>
          i) Areas not Inspected The Surveyor will identify any areas of The Subject Property which
          could not be adequately accessed or inspected during the inspection (relative to the RICS
          Home Survey Level 2 Report service and the RICS Home Survey Level 3 Report service
          standard scope of inspection specification), and as such these details are referred to in
          The Survey Report.
        </p>
        <p>
          In the event that The Surveyor is requested to re-visit The Subject Property to inspect
          areas that The Surveyor was unable to inspect during the original visit, due to reasons
          beyond The Surveyor&rsquo;s control, an additional fee will be payable.
        </p>
        <p>
          ii) Flats or Maisonettes Unless otherwise agreed, The Surveyor will inspect only the
          subject flat and garage (if any), the related internal and external common parts and the
          structure of the building or particular block in which the subject flat is situated. Other
          flats will not be inspected. The Surveyor will state in the report the limits of access
          and/or visibility in relation to the common parts and structure. The Surveyor will state
          whether he or she has seen a copy of the lease and, if not, the assumptions as to
          preparing obligations on which he or she is working.
        </p>
        <p>
          iii) Environmental and Other Issues (1) Particular noise and disturbance affecting The
          Subject Property will
        </p>
        <p>
          only be noted if it is significant at the time of the inspection or if specific
          investigation has been agreed between The Surveyor and The Client and confirmed in
          writing.
        </p>
        <p>
          (2) The Surveyor will report on any obvious health and safety hazards to the extent that
          they are apparent from elements of The Subject Property considered as part of the
          inspection.
        </p>
        <p>
          2) Ground Conditions The Surveyor will not be required to comment upon the possible
          existence of noxious substances, landfill or mineral extraction, or other forms of
          contamination.
        </p>
        <p>6</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>3) Consents, Approvals and Searches</p>
        <p>
          a) The Surveyor will be entitled to assume that The Subject Property is not subject to any
          unusual or onerous restrictions, obligations or covenants which apply to The Subject
          Property or affect the reasonable enjoyment of The Subject Property.
        </p>
        <p>
          b) The Surveyor will be entitled to assume that all planning, building regulations and
          other consents required in relation to the Property have been obtained. The Surveyor will
          not verify whether such consents have been obtained. Any enquiries should be made by the
          Client or the Client&apos;s legal advisers. Drawings and specifications will not be
          inspected by the Surveyor unless otherwise previously agreed.
        </p>
        <p>
          c) The Surveyor will be entitled to assume that The Subject Property is unaffected by any
          matters which would be revealed by a Local Search and replies to the usual enquiries, or
          by a Statutory Notice, and that neither The Subject Property, nor its condition, its use
          or its intended use, is or will be unlawful.
        </p>
        <p>
          4) Content of the Survey Report The content of The Survey Report will be as per the RICS
          standard template for RICS Home Survey Level 2 Report service and the RICS Home Survey
          Level 3 Report service. The description of the service document &ldquo;Description of the
          RICS Home Survey &ndash; Level 2 (survey only)&rdquo;; &ldquo;Description of the RICS Home
          Survey &ndash; Level 2 (survey and valuation)&rdquo;; or &ldquo;Description of the RICS
          Home Survey &ndash; Level 3&rdquo; have been provided to you with our confirmation of
          instructions by electronic mail in The Instruction Email. For bespoke reports, the content
          of the report will be as discussed and agreed with the Surveyor.
        </p>
        <p>
          5) a) Before the Inspection This period forms an important part of the relationship
          between the Surveyor and the Client. The commissioned Surveyor will use reasonable
          endeavours to contact the Client to understand particular concerns about the property and
          explain (where necessary) the extent and/or limitations of the inspection and report.
        </p>
        <p>
          b) After the inspection The commissioned Surveyor will use reasonable endeavours to
          contact the Client after the inspection to discuss the major points and issues found. The
          content of this conversation will not in any way override the content of the report. The
          report will take precedence in all cases.
        </p>
        <p>
          6) Limitations Where advice or opinions are required outside the reasonable professional
          skill and expertise of the Surveyor, he or she will advise you on the need for further
          specialist involvement where we consider this to be necessary for the completion of your
          instruction(s).
        </p>
        <p>7</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          7) Insurance Rebuilding Cost Assessment The Surveyor will provide an insurance rebuilding
          cost assessment only if this is agreed at the time of taking instructions. The
          Reinstatement Cost figure (If requested in the Client instruction) for the permanent
          buildings is provided for insurance purposes and is not directly related to the market
          value of the property. The reinstatement cost will be calculated by reference to the
          indices and guidance published by the Building Costs Information Service (BCIS). The
          figure stated will only include Value Added Tax on professional fees (not on building
          costs) and will not take into account other potential or consequential losses such as
          costs of demolition, site clearance, provision of services, alternative accommodation,
          personal items or any other contents or contingent costs.
        </p>
        <p>
          8) Force Majeure Whilst every reasonable effort will be made to carry out the inspection
          at the date/time agreed, we cannot be held liable for any losses caused by matters outside
          our control, such as, but not exclusively: Surveyor illness; traffic/vehicle
          delay/breakdown; extreme weather conditions; or vendor unavailability.
        </p>
        <p>
          9) Miscellaneous a) Unless expressly provided, no term in the agreement between the
          Surveyor and
        </p>
        <p>
          the Client is enforceable under the Contracts (Rights of Third Parties) Act 1999 by any
          person other than the Surveyor or the Client.
        </p>
        <p>
          b) The Surveyor shall, unless otherwise expressly agreed, rely upon information provided
          to the Company or the Surveyor by the Client or the Client&rsquo;s legal or other
          professional advisers relating to relevant matters.
        </p>
        <p>
          c) Any document resulting from a survey or other inspection of a building will not purport
          to express an opinion about or advise upon the condition of un-inspected or concealed
          parts and should not be taken as making any implied representation or statements about
          such parts.
        </p>
        <p>
          d) Where the Client has instructed the Surveyor to make investigations which cause damage
          to the Subject Property on the basis that the Client has obtained the owner&rsquo;s
          consent, the Client will indemnify the Surveyor against any loss or costs or liabilities
          arising.
        </p>
        <p>
          e) The Client may only rely upon the Surveyor&rsquo;s advice and report for purposes
          described in the Instruction Email, particulars of which have been communicated to the
          Surveyor in writing, and if the Client wishes to rely upon such advice and The Report for
          any other purpose, The Client may only do so with specific written consent of The Company
          and The Surveyor. These Terms and Conditions are based on the model Terms and Conditions
          of Engagement contained in the RICS guidance note.
        </p>
        <p>f) External Cladding Panels and Fire Safety &ndash; Composite external panels which</p>
        <p>8</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          incorporate insulating material between inner and outer external wall faces such as
          aluminium composite material (ACM) panels have been extensively used in recent years as
          external cladding. Some panel cladding systems incorporate combustible insulation
          materials. The type of insulation and any fire checking of the panel system cannot be
          identified from a visual inspection. Where the existence of such panels has been reported,
          the type of insulation and installation is outside the scope of The Survey Report. Other
          elements of the Property and building will also have a bearing on overall fire safety,
          including, fire doors, fire stops in wall cavities, escape routes, alarms and fire
          suppression systems. Such matters are outside the scope of The Survey Report. For
          buildings of more than two storeys it is essential that the Fire Safety Certificate,
          (EWS1), is supplied. For buildings of two storey height or less the Surveyor will make the
          assumption that the cladding system is satisfactory. It is the responsibility of The
          Client to have any materials tested and if found to be not in compliance, The Client
          should make all necessary enquiries prior to any legal or financial commitment.
        </p>
        <p>
          g) Asbestos and other hazardous materials: The construction of buildings, fixtures,
          fittings and plant or equipment may contain hazardous materials. Such materials are
          impossible to detect without specific tests and are beyond the scope of The Survey Report.
          No guarantee is given that such materials are not incorporated in any part of the property
          or fixtures. The service does not include an asbestos inspection and it falls outside The
          Control of Asbestos Regulations 2012.
        </p>
        <p>
          10) Limitation of Liability RICS recommends the use of liability caps to members to manage
          the risk in valuation and survey work. Our aggregate liability arising out of or in
          connection with this service, whether arising from negligence, breach of contract or any
          cause whatsoever, shall in no event exceed 15% of the Market Value of The Subject Property
          at the date of inspection, or the purchase price in relation to this report (whichever is
          the lesser). We will not be responsible for the first &pound;2,000.00 of any claim. This
          clause shall not exclude liability for death or personal injury caused by our negligence.
        </p>
        <p>
          The Survey Report is provided for The Clients use only, and we cannot accept
          responsibility if it is used or relied upon by anyone else, any other or third parties,
          unless this is expressly agreed by The Company and The Surveyor, in writing beforehand.
          None of the employees of The Company, partners or consultants, individually, has a
          contract with you, The Client, or owes you a duty of care or personal responsibility. You
          agree that you will not bring any claim against any such individuals personally in
          connection with the Services. For avoidance of doubt, the liability caps set out here also
          apply to valuations provided as an additional service.
        </p>
        <p>
          a) The Company and/or The Surveyor shall under no circumstances whatsoever be liable to
          the Client, whether in contract, tort (including negligence), breach of statutory duty, or
          otherwise, for loss of profit or any indirect or consequential loss, of any failure by the
          Client or any agent of the Client: promptly to provide information or other material
          reasonably requested, or where that material is inaccurate or incomplete, or to follow our
          advice or recommendations.
        </p>
        <p>
          b) None of the Company&rsquo;s employees, officers, partners, affiliates or consultants
        </p>
        <p>9</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          individually has a contract with the Client or owes the Client or the prospective
          purchaser or vendor or any other party a duty of care or personal responsibility. The
          Client agrees not to bring any claim against any such individuals personally. To the
          extent that any part of this notification is a restriction of liability within the meaning
          of Section 1 of the Unfair Contract Terms Act 1977, it does not apply to death or personal
          injury resulting from negligence or fraudulent misrepresentation.
        </p>
        <p>
          c) These Terms of Engagement do not include any warranties, conditions and other terms
          except as stated herein and as required by law. The Survey Report and any associated
          valuation will be provided after diligent consideration and research, but property values
          are subject to fluctuation and the valuation process is inexact and thus the
          Surveyor&rsquo;s opinion is subject to a degree of tolerance depending on the property and
          the availability of comparable evidence. Valuations are provided to assist the Client in
          making a prudent decision; they are not provided as any form of guarantee or warranty of
          value.
        </p>
        <p>
          d) Where loss is suffered by the Client for which the Company, the Surveyor and/or any
          other third party are jointly responsible, any recoverable loss by the Client from the
          Company will be proportionate to the Company&rsquo;s relative contribution to the loss
          suffered and subject to the provisions of this clause 10.
        </p>
        <p>
          e) Where the Client is composed of more than one entity, then each such entity&apos;s
          liability shall be joint and several.
        </p>
        <p>
          f) The Client acknowledges that the Surveyor shall have no liability for any inaccuracy in
          the Survey Report or service provision where this is due to an inaccuracy in the
          Information provided by the Client or third parties.
        </p>
        <p>
          g) The terms implied by Sections 3 to 5 of the Supply of Goods and Services Act 1982 are,
          to the fullest extent permitted by law, excluded from this Contract and the Survey Report.
        </p>
        <p>h) This Clause 10 shall survive termination of this contract.</p>
        <p>
          i) The Client&rsquo;s failure to follow the requirements set out in these Terms and
          Conditions will invalidate the Survey Report and service provision.
        </p>
        <p>
          j) Nothing in these conditions shall limit or exclude the Company&rsquo;s liability for:
        </p>
        <p>
          i) Death or personal injury caused by its negligence, or the negligence of its officers,
          employees, agents, subcontractors or consultants;
        </p>
        <p>ii) Fraud or fraudulent misrepresentation.</p>
        <p>
          k) If the Client has agreed to accept reimbursement of the fee paid, or the
          Company&rsquo;s fee account has not been settled within two months of the date of the
          invoice, then it is agreed that the Client has placed no reliance on the Survey Report
          (including a valuation produced therein) and thus the Client or any permitted assignees
          will not be entitled to pursue any action for alleged
        </p>
        <p>10</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          negligence, breach of contract or breach of duty. This does not limit the Company&rsquo;s
          entitlement to the agreed fee.
        </p>
        <p>
          l) All telephone or verbal discussions are prone to misinterpretation or misunderstanding
          by either party, especially in the interpretation of instructions, legal, technical or
          descriptive matters; as such no reliance may be placed by the Client upon any such
          discussions unless agreed outcomes are formally confirmed in writing by an authorised
          officer of the Company.
        </p>
        <p>C. VALUATIONS PROVIDED AS AN ADDITIONAL SERVICE</p>
        <p>
          Where a valuation is to be provided as an additional service, as confirmed with The Client
          in the Instruction Email, the following terms and conditions will also apply:
        </p>
        <p>1) Purpose of the Report</p>
        <p>
          a) The Client wishes the Surveyor to provide within the Survey Report an opinion of the
          value of the Freehold or Leasehold or Commonhold interest in The Subject Property as
          specified by the Client.
        </p>
        <p>
          b) The Surveyor shall provide to the Client an opinion of value with the Survey Report (as
          per 1a above) based upon an inspection of the property by a named individual who shall be:
        </p>
        <p>
          i) a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the
          membership grades: AssocRICS, MRICS, or FRICS; and
        </p>
        <p>
          ii) a member of the RICS Valuer Registration Scheme (VRS), setting out the Valuer&rsquo;s
          opinion of the relevant interest in the property.
        </p>
        <p>
          The Valuation within the Survey Report will be prepared in accordance with the RICS
          Valuation &ndash; Global Standards effective 31 January 2022, the RICS Valuation &ndash;
          Global Standard 2017: UK National Supplement effective 14 January 2019, and relevant UK
          VPGAs.
        </p>
        <p>
          c) Confidentiality: The Surveyor&rsquo;s valuation is provided to the named addressee/s
          alone and solely for the purpose of the instruction to which it relates. The valuation may
          not, without The Company&rsquo;s and Surveyor&rsquo;s written consent, be used or relied
          upon by any other or third party, even if that third party pays all or part of the fees,
          or is permitted to see a copy of the Survey Report. If The Company and The Surveyor
          provides written consent to a third party relying on the valuation, any such third party
          is deemed to have accepted the terms of The Company&rsquo;s engagement and the
          Surveyor&rsquo;s engagement, and an additional fee may be payable. Neither the whole nor
          any part of the Survey Report or any reference to it may be included in any published
          document, circular or statement nor published in any way without The Company&rsquo;s and
          The Surveyor&rsquo;s written approval of the form and context in which it may appear.
        </p>
        <p>11</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          d) The Surveyor shall have no known interest in The Subject Property or any other conflict
          that will prevent the Valuation from being undertaken in an independent, objective, and
          unbiased way.
        </p>
        <p>
          e) The Market Value reported unless otherwise specifically agreed in writing, shall be in
          accordance with one or more of the following Valuation bases: Market Value (MV) or Market
          Rent (MR) detailed at the beginning of these conditions. (See &ldquo;Definitions&rdquo;.)
          The value shall be provided in pounds sterling.
        </p>
        <p>
          f) The Valuation Methodology shall be the Comparable Basis of Methodology unless at the
          sole discretion of the Surveyor/Valuer and subject to the interest, bearing income, an
          Investment Methodology may be adopted which will be stated in the Valuation Report.
        </p>
        <p>
          g) Flats &ndash; Building Insurance will be for the subject flat only and it will be
          assumed that the Client&rsquo;s legal adviser will confirm that the Freeholder or
          Management company includes the flat in a block buildings insurance policy.
        </p>
        <p>
          h) Access to the property shall be the responsibility of the Client who should inform the
          Surveyor of any delay or any special arrangements to be followed together with contact
          information for the occupier, estate agent or other key holder.
        </p>
        <p>
          i) The Client will be informed of the inspection date and time and the report will be
          available normally within five working days of the inspection or otherwise as agreed with
          the Client and is subject to all necessary information being made available to the
          Surveyor or subject to further discovery. Any anticipated delay in gaining access or delay
          in the report will be notified to the Client.
        </p>
        <p>
          2) Reporting Assumptions and Limitations relating to provision of the Valuation within the
          Survey Report
        </p>
        <p>
          a) The Surveyor will make reasonable assumptions which will be specified in the Survey
          Report. However, if these assumptions are found to be incorrect please advise us
          immediately as the valuation may require to be amended to take account of any newly
          disclosed, amended or discovered facts.
        </p>
        <p>
          b) The Surveyor shall, unless otherwise expressly agreed, rely upon information from the
          Client or the Client&rsquo;s legal or other professional advisers. It is the
          responsibility of the Client to ensure this information is accurate and to promptly advise
          the Surveyor if it is not.
        </p>
        <p>
          c) External Cladding Panels and Fire Safety &ndash; Composite external panels which
          incorporate insulating material between inner and outer external wall faces such as
          aluminium composite material (ACM) panels have been extensively used in recent years as
          external cladding. Some panel cladding systems incorporate
        </p>
        <p>12</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          combustible insulation materials. The type of insulation and any fire checking of the
          panel system cannot be identified from a visual inspection. Where the existence of such
          panels has been reported, the type of insulation and installation is outside the scope of
          The Survey Report and the Valuation. Other elements of the Property and building will also
          have a bearing on overall fire safety, including, fire doors, fire stops in wall cavities,
          escape routes, alarms and fire suppression systems. Such matters are outside the scope of
          the Valuation. For buildings of more than two storeys it is essential that the Fire Safety
          Certificate, (EWS1), is supplied. Where the nature of the cladding is considered to be
          suspect, the Surveyor will report a Zero Value until the form EWS1 in Compliance is
          supplied. For buildings of two storey height or less the Surveyor will make the assumption
          that the cladding system is satisfactory. It is the responsibility of The Client to have
          any materials tested and if found to be not in compliance, the Valuation may then require
          to be adjusted. The Client should make all necessary enquiries prior to any legal or
          financial commitment.
        </p>
        <p>
          d) Asbestos and other hazardous materials: The construction of buildings, fixtures,
          fittings and plant or equipment may contain hazardous materials. Such materials are
          impossible to detect without specific tests and are beyond the scope of the Survey Report.
          No guarantee is given that such materials are not incorporated in any part of the property
          or fixtures. The service does not include an asbestos inspection and it falls outside The
          Control of Asbestos Regulations 2012.
        </p>
        <p>
          e) The Inspection will take into account gardens, grounds and permanent outbuildings, but
          will not include constructions or equipment with a specific or leisure purpose, including
          sauna, swimming pools or tennis courts and associated services and changing rooms. The
          Inspection will include the immediate gardens that belong to the property, but the
          Inspection specifically excludes any additional land such as pasture or amenity land.
        </p>
        <p>
          f) Measurement &ndash; the Surveyor may measure the property during the inspection or take
          measurements or areas from plans provided by the Client or obtain such details from
          specialised database information available to the Surveyor or from publicly available
          information at the sole discretion of the Surveyor. Outbuildings will not be measured on
          site unless they are considered by the Surveyor to contribute significantly to the
          reported capital value or reinstatement cost (if reported).
        </p>
        <p>
          g) The Following Additional Assumptions will be made by the Surveyor in respect of the
          Property:
        </p>
        <p>
          i) the ground is not affected by the existence of noxious or toxic substances or gases and
          has not been used for landfill or mineral extraction or other forms of contamination, and
          does not contain gypsum deposits;
        </p>
        <p>
          ii) no high alumina cement concrete or calcium chloride additive or other potentially
          deleterious material including &lsquo;Mundic material&rsquo; was used in the construction
          of the property or has since been incorporated;
        </p>
        <p>13</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          iii) the Property is not susceptible to radon gas pollution, invasive or protected
          species, flood risk, high voltage equipment, cables or coastal erosion, unless specified
          in the report;
        </p>
        <p>
          iv) the Property is not subject to any unusual or especially onerous restrictions,
          encumbrances or outgoings, easements or way leaves or rights of way over the property and
          that good title can be shown;
        </p>
        <p>
          v) the Property and its value are unaffected by any matters which would be revealed by
          local or environmental searches, and replies to the usual enquiries, or by any statutory
          notice, and that neither the property, nor its condition, nor its use, nor its intended
          use, is or will be unlawful;
        </p>
        <p>
          vi) the inspection of those parts which have not been included in the Inspection in
          accordance with these Terms and Conditions would neither reveal material defects nor cause
          the Surveyor to alter the valuation materially;
        </p>
        <p>
          vii) it is assumed that unless otherwise stated, roads, sewers and services outside the
          curtilage of the Property are the responsibility of the Local Authority or other statutory
          body. It is further assumed, unless otherwise stated, that all services including drainage
          are connected and access and roads are unfettered, and in the case of the public highway,
          maintained at the expense of the Local and Highways Authorities;
        </p>
        <p>
          viii) the Valuation is based on the assumption that the Property is being valued with
          vacant possession and, unless otherwise stated, for owner occupation; if the property is
          tenanted the Client is under a duty to inform the Surveyor;
        </p>
        <p>
          ix) that further investigation into Planning Permission, Building Regulations, Permitted
          Development Rights, extensions and alterations, hazardous materials, onerous restrictions
          or the like will not reveal anything sufficiently adverse as to materially affect the
          Valuation;
        </p>
        <p>
          x) in the case of Leasehold Tenure the Surveyor will assume that there remains a minimum
          of 85 years on the lease, and the Ground Rent and any Service Charges are reasonable
          unless otherwise detailed in the report;
        </p>
        <p>
          xi) there are no archaeological remains, Scheduled Ancient Monument or liability to
          Chancel Repair and the property is not at risk of flooding or likely to suffer damage from
          unstable ground conditions;
        </p>
        <p>
          xii) any other assumptions will be clearly stated in the report; however, the Surveyor
          shall be under no duty to verify these assumptions and the Client should ensure the
          assumptions are correct. If any of these assumptions are incorrect then these should be
          discussed with the Valuer before any financial or legal commitment is made as the
          Valuer&apos;s opinion of value may change;
        </p>
        <p>xiii) if the Surveyor has not been provided, or cannot obtain, an up-to-date EPC</p>
        <p>14</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          rating for this property, for the purposes of the Energy Act 2011, its valuation will be
          based on the assumption that the subject property will meet the minimum requirements laid
          down by legislation and that there will be no adverse impact on value, rental ability and
          marketability;
        </p>
        <p>
          xiv) in the case of a newly constructed property, it has been built under a recognised
          builder&rsquo;s warranty or insurance scheme, or has been supervised by a professional
          consultant capable of fully completing the UK Finance Professional Consultant Certificate;
        </p>
        <p>
          xv) there are no ongoing insurance claims or neighbour disputes and the property is
          insurable under normal terms.
        </p>
        <p>
          h) Leasehold Properties Where The Subject Property is leasehold, data including unexpired
          lease term, ground rent and any further relevant information should be considered and
          reflected in the Valuation based upon data that can be sourced on the day of inspection or
          beforehand from either the vendor or the selling agent as applicable following reasonable
          investigation. However, the Surveyor clearly cannot give any absolute assurance that, if
          verbally provided, such data is reliable &ndash; verification will be a matter for your
          legal representatives as appropriate.
        </p>
        <p>
          Where The Subject Property is leasehold, and it is not possible to inspect the lease or
          details have not been provided, the following assumptions will be made, unless instructed
          to the contrary:
        </p>
        <p>
          i) The unexpired term of the lease is assumed to be 85 years, and no action is being taken
          by any eligible party with a view to acquiring the freehold or extending the lease term.
          RICS Valuation &ndash; Global Standards 2022: UK national supplement UK VPGA 11 Valuation
          for residential mortgage purposes UK Valuation Practice Guidance Applications (UK VPGAs)
          RICS Valuation &ndash; Global Standards 2017: UK national supplement.
        </p>
        <p>ii) There are no exceptionally onerous covenants upon the leaseholder.</p>
        <p>
          iii) The lease cannot be determined, except on the grounds of a serious breach of covenant
          in the existing lease agreement.
        </p>
        <p>
          iv) If there are separate freeholders, head and/or other subhead leaseholders, the terms
          and conditions of all the leases are in the same form and contain the same terms and
          conditions.
        </p>
        <p>v) The lease terms are mutually enforceable against all parties concerned.</p>
        <p>
          vi) There are no breaches of covenant or disputes between the various interests concerned.
        </p>
        <p>
          vii) The leases of all the properties in the building/development are materially the same.
        </p>
        <p>15</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>
          viii) The ground rent stated, or assumed, is not subject to unreasonable review and is
          payable throughout the unexpired lease term.
        </p>
        <p>
          ix) In the case of blocks of flats or maisonettes of over six dwellings, the freeholder
          manages the property directly, or there is an appropriate management structure in place.
        </p>
        <p>
          x) There is a duty holder, as defined in the Control of Asbestos Regulations 2012, and
          there are in place an asbestos register and an effective management plan, which does not
          require any immediate expenditure, pose a significant risk to health, or breach Health and
          Safety Executive (HSE) requirements.
        </p>
        <p>
          xi) Where The Subject Property forms part of a mixed residential or commercially used
          block or development, there will be no significant changes in the existing pattern of use.
        </p>
        <p>
          xii) Where The Subject Property forms part of a development containing separate blocks of
          dwellings, the lease terms of the property apply only to the block. There will be no
          requirement to contribute towards costs relating to other parts of the development, other
          than in respect of common roads, paths, communal grounds and services.
        </p>
        <p>
          xiii) Where The Subject Property forms part of a larger development whose ownership has
          since been divided, all necessary rights and reservations have been reserved.
        </p>
        <p>
          xiv) There are no unusual restrictions on assignment or subletting of the property for
          residential purposes.
        </p>
        <p>
          xv) There are no outstanding claims or litigation concerning the lease of the subject
          property or any others within the same development.
        </p>
        <p>
          xvi) Where The Subject Property benefits from additional facilities within the
          development, the lease makes adequate provisions for the occupier to continue to enjoy
          them without exceptional restriction, for the facilities to be maintained adequately and
          for there being no charges over and above the service charge for such use and maintenance.
        </p>
        <p>
          For the avoidance of doubt, the Surveyor will not be under a duty to purchase data from
          third parties or to read the lease documentation. If further information is provided to
          the Surveyor after the valuation has been completed and submitted, the Surveyor may
          reconsider the valuation and, if necessary, issue an amended report. But again, for the
          avoidance of doubt, the Surveyor will not be under any obligation to read the lease
          document and would only be expected to base a revised valuation on further information
          provided to the Surveyor by your legal representatives.
        </p>
        <p>16</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>i) Flats and Maisonettes</p>
        <p>
          Where The Subject Property is part of a building comprising flats or maisonettes, the
          following assumptions will also be made, unless instructed to the contrary: UK VPGA 11
          Valuation for residential mortgage purposes, UK Valuation Practice Guidance Applications
          (UK VPGAs).
        </p>
        <p>
          i) The costs of repairs and maintenance to the building and grounds are shared equitably
          between the flats and maisonettes.
        </p>
        <p>
          ii) There are suitable, enforceable covenants between all leaseholds, or through the
          landlord or the owner.
        </p>
        <p>iii) There are no onerous liabilities outstanding.</p>
        <p>
          iv) There are no substantial defects, or other matters requiring expenditure (in excess of
          the current amount or assumed amount of service charge payable on an annual basis),
          expected to result in charges to the leaseholder or owner of the subject property during
          the next five years, that are equivalent to 10% or more of the reported market value.
        </p>
        <p>
          In respect of insurance, the following assumptions will be made, unless instructed to the
          contrary:
        </p>
        <p>
          i) the property can be insured under all-risks cover for the current reinstatement cost
          and is available on normal terms;
        </p>
        <p>ii) there are no outstanding claims or disputes;</p>
        <p>
          iii) where individuals in a block make separate insurance arrangements, the leases make
          provision for mutual enforceability of insurance and repairing obligations and;
        </p>
        <p>
          iv) any landlord responsible for insurance is required to rebuild the property with such
          alterations as may be necessary to comply with current Building Regulations and planning
          requirements.
        </p>
        <p>D. COST ESTIMATES PROVIDED AS AN ADDITIONAL SERVICE</p>
        <p>
          We can provide cost estimates as an additional service to the Level 3 building survey
          service, subject to an additional fee.
        </p>
        <p>
          These are indicative costs only and the purpose of providing them is to give initial
          guidance to help you in your purchasing decision. Actual costs may vary depending on
          economic factors at the time.
        </p>
        <p>17</p>
        <p />
      </div>
      <div className="page">
        <p />
        <p>Costs estimates may also be provided in bands as follows:</p>
        <p>
          Band A - &pound;1,000 to &pound;2,000 Band B - &pound;2,000 to &pound;5,000 Band C -
          &pound;5,000 to &pound;10,000 Band D &ndash; 0ver &pound;10,000
        </p>
        <p>
          Items below &pound;1,000 will be deemed as not significant in the context of making a
          decision to purchase the property.
        </p>
        <p>
          You must obtain your own cost estimates in writing from suitable building specialists
          and/or contractors before exchange of contracts so that you are aware of all costs before
          you make a legal commitment to purchase. Cost estimates must include VAT and any
          applicable professional fees. Should you exchange contracts before obtaining estimates
          from such contractors then you will proceed at your own risk. The Surveyor and Houzecheck
          Ltd will not be liable for any variation in costs between The Surveyor&rsquo;s estimates
          and the actual costs provided by contractors or ultimately by way of expenditure in
          addressing the issues highlighted by the cost estimates provided.
        </p>
        <p>E. BUILDINGS INSURANCE REINSTATMENT COST PROVIDED AS AN ADDITIONAL SERVICE</p>
        <p>
          The Surveyor can provide a building insurance reinstatement cost as an additional service
          to the Level 2 homebuyer service, subject to an additional fee.
        </p>
        <p>
          The reinstatement cost is the cost of rebuilding a property including demolition of the
          original building, temporary support of adjoining buildings where applicable, materials,
          labour, professional fees and Local Authority charges. It includes sums for boundaries,
          drainage and significant outbuildings. The reinstatement cost will be reported as a single
          figure, not as a detailed schedule of all costs.
        </p>
        <p>Specialist installations such as swimming pools are excluded.</p>
        <p>
          The Surveyor cannot provide reinstatement costs for Listed Buildings as this requires a
          detailed specialist assessment, which is beyond the scope of the Level 2 service.
        </p>
        <p>18</p>
        <p />
      </div>
    </div>
  );
}

function HouzecheckTnc({ name, companyName }) {
  return (
    <div className="text-justify">
      <p className="text-center text-xl">
        <strong> Terms and Conditions of Engagement for Valuations</strong>
      </p>
      <br />
      <br />
    </div>
  );
}

export default TnC;
