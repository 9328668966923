import React from 'react';

// containers
import Input from '../Input';
import Dropdown from '../Dropdown';
import RadioButton from '../RadioButton';
import TextArea from '../TextArea';

function FormControl(props) {
  const { controlType, ...rest } = props;
  switch (controlType) {
    case 'input':
      return <Input {...rest} />;
    case 'textarea':
      return <TextArea {...rest} />;
    case 'dropdown':
      return <Dropdown {...rest} />;
    case 'radio':
      return <RadioButton {...rest} />;
    default:
      return null;
  }
}

export default FormControl;
