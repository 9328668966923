import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { LeadReducer } from '../features/lead-slice';
import rootSaga from '../sagas/lead-saga';

const sagaMiddleware = createSagaMiddleware();
const customizedMiddleware = {
  serializableCheck: false
};
const store = configureStore({
  reducer: { lead: LeadReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(customizedMiddleware).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export default store;
