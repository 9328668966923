import React from 'react';
import { Field, ErrorMessage } from 'formik';

import TextError from '../TextError';
import Label from '../Label';

function Input({ label, name, addressOpts, ...rest }) {
  return (
    <div className="md:w-1/2 md:pr-4 my-3">
      <Label name={name} text={label} />
      {addressOpts ? (
        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue } = form;
            return (
              <input
                className="bg-primary-50 block w-full p-3 border border-primary-200 outline-primary-200 rounded-xl mt-2"
                id={name}
                {...field}
                {...rest}
                onChange={(e) => {
                  setFieldValue(name, e.target.value);
                  setFieldValue(addressOpts, '');
                }}
              />
            );
          }}
        </Field>
      ) : (
        <Field
          className="bg-primary-50 block w-full p-3 border border-primary-200 outline-primary-200 rounded-xl mt-2"
          id={name}
          name={name}
          {...rest}
        />
      )}
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Input;
