import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { updateLeadAction } from '../../redux/actions/lead-actions';
import { updateErr } from '../../redux/features/lead-slice';
import Button from '../Button';

function ErrComp({ children, onCloseFn, type, yesBtnHandler, btnOne = 'Okay', btnTwo }) {
  const leadData = useSelector((state) => state.lead);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const onCloseHandler = () => {
    if (onCloseFn) {
      onCloseFn();
    } else if (yesBtnHandler) {
      yesBtnHandler();
      dispatch(updateErr(null));
    } else {
      dispatch(updateErr(null));
      // navigate('');
    }
  };
  return (
    <div className="md:px-8 px-4 flex flex-col justify-center h-full">
      {/* <img
        src="/images/err_sad.png"
        alt=""
        width={150}
        height={150}
        className="p-4 bg-primary-50 rounded-full mx-auto"
      /> */}
      {children}
      <div className="md:flex text-center my-10 justify-around w-100">
        {type === 1 && (
          <Button
            submit
            btnType="defaultOutlineBtn"
            customClass="mt-5"
            onClickHandler={() =>
              dispatch(
                updateLeadAction({
                  ...leadData,
                  currentStep: 8,
                  errorType: null,
                  productData: {
                    ...leadData.productData,
                    currentStep: 8
                  }
                })
              )
            }>
            {btnTwo}
          </Button>
        )}
        <Button
          submit
          btnType="defaultFilledBtn"
          customClass="mt-5 ml-3"
          onClickHandler={onCloseHandler}>
          {btnOne}
        </Button>
      </div>
    </div>
  );
}

export default ErrComp;
