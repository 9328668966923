/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

const ALPHABETS = 'abcdefghijklmnopqrstuvwxyz'.split('');
const TABLE_SEQ_TYPES = {
  number: 'number',
  alphabet_down: 'alphabet_down',
  roman: 'roman',
  number2: 'number2',
  alphabet_up: 'alphabet_up'
};
const TABLE_SEQ_TYPES_ARR = Object.values(TABLE_SEQ_TYPES);

function HCTNCPAGE() {
  const toRoman = (num) => {
    const romanNumerals = [
      ['M', 1000],
      ['CM', 900],
      ['D', 500],
      ['CD', 400],
      ['C', 100],
      ['XC', 90],
      ['L', 50],
      ['XL', 40],
      ['X', 10],
      ['IX', 9],
      ['V', 5],
      ['IV', 4],
      ['I', 1]
    ];
    let result = '';

    for (const [roman, value] of romanNumerals) {
      while (num >= value) {
        result += roman;
        num -= value;
      }
    }
    return result;
  };

  const getFormattedSeqNum = (index, type) => {
    switch (type) {
      case TABLE_SEQ_TYPES.alphabet_up:
        return `${ALPHABETS[index].toUpperCase()}.`;
      case TABLE_SEQ_TYPES.number:
        return `${index + 1})`;
      case TABLE_SEQ_TYPES.alphabet_down:
        return `${ALPHABETS[index]})`;
      case TABLE_SEQ_TYPES.roman:
        return `${toRoman(index + 1)})`;
      case TABLE_SEQ_TYPES.number2:
        return `(${index + 1})`;
      default:
        return null;
    }
  };

  const renderUl = (data) => (
    <ul className="list-disc">
      {data.map((text) => (
        <li className="ml-3 whitespace-pre-wrap text-sm font-thin" key={text}>
          {text}
        </li>
      ))}
    </ul>
  );

  const renderContentList1 = (data) =>
    data.map(({ title, desc }) => (
      <div className="whitespace-pre-wrap text-justify text-sm" key={title}>
        <p className="!p-0 font-bold">{title}</p>
        <p className="!p-0 font-thin">{desc}</p>
      </div>
    ));

  const renderContentList2 = (data = [], type = TABLE_SEQ_TYPES.alphabet_up, level = 0) => {
    let html = '';

    data.forEach((obj, idx) => {
      const hasNestedData = obj.data && obj.data.length > 0;
      const hasNestedData2 = obj.data2 && obj.data2.length > 0;

      const formattedSeqNum = getFormattedSeqNum(idx, type);

      html += `<div style="display: flex; white-space: pre-wrap;"><div style="font-size: 14px; font-weight: 700; min-width: 45px ">${formattedSeqNum}</div>`;

      const textIndent = level === 0 ? 0 : 45;
      const fontWeight =
        ([TABLE_SEQ_TYPES.alphabet_down, TABLE_SEQ_TYPES.number2].includes(type) ||
          !hasNestedData) &&
        !obj.str2
          ? 100
          : 700;

      html += `<div>`;
      html += `<p style="font-size: 14px; text-align: justify; font-weight: ${fontWeight};">${obj.str}</p>`;

      if (obj.str2) {
        html += `<p style="font-size: 14px; text-align: justify; font-weight: 100;">${obj.str2}</p>`;
      }

      html += '</div></div>';

      if (hasNestedData) {
        html += `<div style="margin-left: ${textIndent}px; test="yes"">${renderContentList2(
          obj.data,
          TABLE_SEQ_TYPES_ARR[level + 1],
          level + 1
        )}</div>`;
      }

      if (obj.str3) {
        html += `<p style="margin-left: 45px; font-size: 14px; text-align: justify; font-weight: 100; white-space: pre-wrap;">${obj.str3}</p>`;
      }

      if (hasNestedData2) {
        html += `<div style="margin-left: ${textIndent}px;">${renderContentList2(
          obj.data2,
          TABLE_SEQ_TYPES_ARR[level + 1],
          level + 1
        )}</div>`;
      }

      if (obj.str_footer) {
        html += `<p style="margin-left: 45px; font-size: 14px; text-align: justify; font-weight: 100; white-space: pre-wrap;">${obj.str_footer}</p>`;
      }
    });

    return html;
  };

  const html = renderContentList2(
    [
      {
        str: 'Purpose of the Report\n\n',
        data: [
          {
            str: 'The Client wishes The Surveyor to provide an opinion of the value of the Freehold or Leasehold or Commonhold interest in the Property as specified by the Client\n\n'
          },
          {
            str: 'The Surveyor shall provide to the Client a report based upon an inspection of the property by a named individual who shall be: 1. a member of the Royal Institution of Chartered Surveyors (RICS) holding one of the membership grades: AssocRICS, MRICS, or FRICS; and 2. a member of the RICS Valuer Registration Scheme (VRS), setting out the Valuer’s opinion of the relevant interest in the property. The Valuation and Report will be prepared in accordance with the RICS Valuation Global Standards 2022, effective 31st January 2022, and the RICS Valuation Global Standard 2017 UK National Supplement, effective 14th January 2019, known as the Red Book.\n\n'
          },
          {
            str: "Confidentiality: The Surveyor's valuation is provided to the named addressee/s alone and solely for the purpose of the instruction to which it relates. The valuation may not, without the Surveyor’s written consent, be used or relied upon by any other or third party, even if that third party pays all or part of the fees, or is permitted to see a copy of the Valuation Report. If the Surveyor provides written consent to a third party relying on the valuation, any such third party is deemed to have accepted these terms of engagement and an additional fee may be payable. Neither the whole nor any part of the report or any reference to it may be included in any published document, circular or statement nor published in any way without the Surveyor’s written approval of the form and context in which it may appear.\n\n"
          },
          {
            str: 'The Surveyor shall have no known interest in the Property or any other conflict that will prevent the Valuation Service from being undertaken in an independent, objective, and unbiased way.\n\n'
          },
          {
            str: 'The Value reported unless otherwise specifically agreed in writing, shall be in accordance with one or more of the following Valuation bases: Market Value (MV) or Market Rent (MR), or a statutory basis of value as detailed at the end of these conditions. (See “Definitions”.) The value shall be provided in pounds sterling.\n\n'
          },
          {
            str: 'The Valuation Approach shall be the Comparable Basis of Approach unless at the sole discretion of the Valuer and subject to the interest, bearing income, an Investment Methodology may be adopted which will be stated in the Valuation Report.\n\n'
          },
          {
            str: 'The Reinstatement Cost figure (If requested in the client instruction) for the permanent buildings is provided for insurance purposes and is not directly related to the market value of the property. The reinstatement cost will be calculated by reference to the indices and guidance published by the Building Costs Information Service (BCIS). The figure stated will only include Value Added Tax on professional fees (not on building costs) and will not take into account other potential or consequential losses such as costs of demolition, site clearance, provision of services, alternative accommodation, personal items or any other contents or contingent costs.\n\n'
          },
          {
            str: "Flats – Building Insurance will be for the subject flat only and it will be assumed that the Client's legal adviser will confirm that the Freeholder or Management company includes the flat in a block buildings insurance policy.\n\n"
          },
          {
            str: 'Access to the property shall be the responsibility of the Client who should inform the Valuer of any delay or any special arrangements to be followed together with contact information for the occupier, estate agent or other key holder.\n\n'
          },
          {
            str: 'The Client will be informed of the inspection date and time of inspection and the report will be available normally within five working days of the inspection or otherwise as agreed with the Client and is subject to all necessary information being made available to the Surveyor or subject to further discovery. Any anticipated delay in gaining access or delay in the report will be notified to the Client.\n\n\n'
          },
          {
            str: 'Where specifically requested, the report will also provide an estimated price that could hypothetically be achieved given a specific restriction that the property must achieve a sale and exchange contracts within 4 weeks (28 days) of the date that the report is provided. This estimated price is not a valuation, and any relationship to Market Value is coincidental. It is not described or used as a basis of value rather it is a figure that might be seen as a reflection of worth to the instructing client as at the date that the report is provided given the particular circumstances.\n\n\n\n'
          }
        ]
      },
      {
        str: 'Reporting Assumptions and Limitations of Inspection\n\n\n',
        data: [
          {
            str: 'The Surveyor will make reasonable assumptions which will be specified in the Valuation Report. However, if these assumptions are found to be incorrect please advise us immediately as the valuation may require to be amended to take account of any newly disclosed, amended or discovered facts.\n\n'
          },
          {
            str: 'The Surveyor shall, unless otherwise expressly agreed, rely upon information from the Client or the Client’s legal or other professional advisers. It is the responsibility of the Client to ensure this information is accurate and to promptly advise the Valuer if it is not.\n\n'
          },
          {
            str: 'The Client has requested a report on the value of the Property only. The Report is not a more thorough RICS Level 2 Report, Level 3 Report or other detailed survey report.  The Surveyor will draw attention to the general condition of the property and any visible major defects but only where these may have an effect upon the Valuation.\n\n'
          },
          {
            str: 'External Cladding Panels and Fire Safety – Composite external panels which incorporate insulating material between inner and outer external wall faces such as aluminium composite material (ACM) panels have been extensively used in recent years as external cladding. Some panel cladding systems incorporate combustible insulation materials. The type of insulation and any fire checking of the panel system cannot be identified from a visual inspection. Where the existence of such panels has been reported, the type of insulation and installation is outside the scope of The Survey Report and the Valuation and Valuation Report. Other elements of the Property and building will also have a bearing on overall fire safety, including, fire doors, fire stops in wall cavities, escape routes, alarms and fire suppression systems. Such matters are outside the scope of the Valuation and/Valuation Report. For buildings of more than two storeys it is essential that the Fire Safety Certificate, (EWS1) is supplied. Where the nature of the cladding is considered to be suspect, the Surveyor will report a Zero Value until the form EWS1 in Compliance is supplied. For buildings of two storey height or less the Surveyor will make the assumption that the cladding system is satisfactory. It is the responsibility of The Client to have any materials tested and if found to be not in compliance, the Valuation may then require to be adjusted. The Client should make all necessary enquiries prior to any legal or financial commitment.\n\n'
          },
          {
            str: 'Asbestos and other hazardous materials: The construction of buildings, fixtures, fittings and plant or equipment may contain hazardous materials. Such materials are impossible to detect without specific tests and are beyond the scope of the Valuation Report. No guarantee is given that such materials are not incorporated in any part of the property or fixtures.\n\n'
          },
          {
            str: 'The Inspection will take into account gardens, grounds and permanent outbuildings, but will not include constructions or equipment with a specific or leisure purpose including sauna, swimming pools or tennis courts and associated services and changing rooms. The Inspection will include the immediate gardens that belong to the property, but the Inspection specifically excludes any additional land such as pasture or amenity land.\n\n'
          },
          {
            str: 'Measurement – The Surveyor may measure the property during the inspection or take measurements or areas from plans provided by the client or obtain such details from specialised database information available to the Surveyor or from publicly available information at the sole discretion of the Surveyor. Outbuildings will not be measured on site unless they are considered by the Surveyor to contribute significantly to the reported capital value or reinstatement cost (if reported).\n\n'
          },
          {
            str: 'The Following Additional Assumptions will be made by the Surveyor in respect of the Property:\n\n',
            data: [
              {
                str: 'the ground is not affected by the existence of noxious or toxic substances or gases and has not been used for landfill or mineral extraction or other forms of contamination and does not contain gypsum deposits;\n\n'
              },
              {
                str: 'no high alumina cement concrete or calcium chloride additive or other potentially deleterious material including “Mundic material” was used in the construction of the property or has since been incorporated;\n\n'
              },
              {
                str: 'the Property is not susceptible to radon gas pollution, invasive or protected species, flood risk, high voltage equipment, cables or coastal erosion unless specified in the report;\n\n'
              },
              {
                str: 'the Property is not subject to any unusual or especially onerous restrictions, encumbrances or outgoings, easements or way leaves or rights of way over the property and that good title can be shown;\n\n'
              },
              {
                str: 'the Property and its value are unaffected by any matters which would be revealed by local or environmental searches, and replies to the usual enquiries, or by any statutory notice, and that neither the property, nor its condition, nor its use, nor its intended use, is or will be unlawful;\n\n'
              },
              {
                str: 'the inspection of those parts which have not been included in the Inspection in accordance with these Terms and Conditions would neither reveal material defects nor cause the Valuer to alter the valuation materially;\n\n'
              },
              {
                str: 'it is assumed that unless otherwise stated, roads, sewers and services outside the curtilage of the property are the responsibility of the Local Authority or other statutory body. It is further assumed, unless otherwise stated, that all services including drainage are connected and access and roads are unfettered and in the case of the public highway maintained at the expense of the Local and Highways Authorities;\n\n'
              },
              {
                str: 'the Valuation is based on the assumption that the Property is being valued with vacant possession and, unless otherwise stated, for owner occupation; if the property is tenanted the client is under a duty to inform the valuer;\n\n'
              },
              {
                str: 'that further investigation into Planning Permission, Building Regulation, Permitted Development Rights, extensions and alterations, hazardous materials, onerous restrictions or the like will not reveal anything sufficiently adverse as to materially affect the Valuation;\n\n'
              },
              {
                str: 'in the case of Leasehold Tenure the Valuer will assume that there remains a minimum of 85 years on the lease and the Ground Rent and any Service Charges are reasonable unless otherwise detailed in the report.\n\n'
              },
              {
                str: 'there are no archaeological remains, Scheduled Ancient Monument or liability to Chancel Repair and the property is not at risk of flooding or likely to suffer damage from unstable ground conditions.\n\n'
              },
              {
                str: "any other assumptions will be clearly stated in the report; however, the Valuer shall be under no duty to verify these assumptions and the Client should ensure the assumptions are correct. If any of these assumptions are incorrect then these should be discussed with the Valuer before any financial or legal commitment is made as the Valuer's opinion of value may change.\n\n"
              },
              {
                str: 'If the Valuer has not been provided, or cannot obtain, an up-to-date EPC rating for this property, for the purposes of the Energy Act 2011, its valuation will be based on the assumption that the subject property will meet the minimum requirements laid down by legislation and that there will be no adverse impact on value, rental ability and marketability.\n\n'
              },
              {
                str: 'In the case of a newly constructed property, it has been built under a recognised builder’s warranty or insurance scheme, or has been supervised by a professional consultant capable of fully completing the UK Finance Professional Consultant Certificate.\n\n'
              },
              {
                str: 'There are no ongoing insurance claims or neighbour disputes and the property is insurable under normal terms.\n\n'
              }
            ]
          },
          {
            str: 'Leasehold Properties:\nWhere the dwelling is leasehold, data including unexpired lease term, ground rent and any further relevant information should be considered and reflected in the valuation based upon data that can be sourced on the day of inspection from either the client, vendor or selling agent as applicable following reasonable investigation. However, the surveyor clearly cannot give any absolute assurance that, if verbally provided, such data is reliable – verification will be a matter for your legal representatives as appropriate.\n\nWhere the dwelling is leasehold, and it is not possible to inspect the lease or details have not been provided, the following assumptions will be made, unless instructed to the contrary:\n\n',
            data: [
              {
                str: 'The unexpired term of the lease is assumed to be 85 years, and no action is being taken by any eligible party with a view to acquiring the freehold or extending the lease term. (RICS Valuation - Global Standards 2022: UK national supplement, UK VPGA 11, Valuation for residential mortgage purposes, UK Valuation Practice Guidance Applications (UK VPGAs) RICS Valuation Global Standards 2022: UK national supplement).\n\n'
              },
              {
                str: 'There are no exceptionally onerous covenants upon the leaseholder.\n\n'
              },
              {
                str: 'The lease cannot be determined, except on the grounds of a serious breach of covenant in the existing lease agreement.\n\n'
              },
              {
                str: 'If there are separate freeholders, head and/or other subhead leaseholders, the terms and conditions of all the leases are in the same form and contain the same terms and conditions.\n\n'
              },
              {
                str: 'The lease terms are mutually enforceable against all parties concerned.\n\n'
              },
              {
                str: 'There are no breaches of covenant or disputes between the various interests concerned.\n\n'
              },
              {
                str: 'The leases of all the properties in the building/ development are materially the same.\n\n'
              },
              {
                str: 'The ground rent stated, or assumed, is not subject to unreasonable review and is payable throughout the unexpired lease term.\n\n'
              },
              {
                str: 'In the case of blocks of flats or maisonettes of over six dwellings, the freeholder manages the property directly, or there is an appropriate management structure in place.\n\n'
              },
              {
                str: 'There is a duty holder, as defined in the Control of Asbestos Regulations 2012, and there are in place an asbestos register and an effective management plan, which does not require any immediate expenditure, pose a significant risk to health, or breach Health and Safety Executive (HSE) requirements.\n\n'
              },
              {
                str: 'Where the subject property forms part of a mixed residential or commercially used block or development, there will be no significant changes in the existing pattern of use.\n\n'
              },
              {
                str: 'Where the property forms part of a development containing separate blocks of dwellings, the lease terms of the property apply only to the block. There will be no requirement to contribute towards costs relating to other parts of the development, other than in respect of common roads, paths, communal grounds and services.\n\n'
              },
              {
                str: 'Where the property forms part of a larger development whose ownership has since been divided, all necessary rights and reservations have been reserved.\n\n'
              },
              {
                str: 'There are no unusual restrictions on assignment or subletting of the property for residential purposes.\n\n'
              },
              {
                str: 'There are no outstanding claims or litigation concerning the lease of the subject property or any others within the same development.\n\n'
              },
              {
                str: 'Where the property benefits from additional facilities within the development, the lease makes adequate provisions for the occupier to continue to enjoy them without exceptional restriction, for the facilities to be maintained adequately and for there being no charges over and above the service charge for such use and maintenance.\n\n'
              }
            ]
          },
          {
            str: 'Flats and Maisonettes:\nWhere the proposed security is part of a building comprising flats or maisonettes, the following assumptions will also be made, unless instructed to the contrary: UK VPGA 11 Valuation for residential mortgage purposes UK Valuation Practice Guidance Applications (UK VPGAs).\n\n',
            data: [
              {
                str: 'The costs of repairs and maintenance to the building and grounds are shared equitably between the flats and maisonettes.\n\n'
              },
              {
                str: 'There are suitable, enforceable covenants between all leaseholds, or through the landlord or the owner.\n\n'
              },
              { str: 'There are no onerous liabilities outstanding.\n\n' },
              {
                str: 'There are no substantial defects, or other matters requiring expenditure (in excess of the current amount or assumed amount of service charge payable on an annual basis), expected to result in charges to the leaseholder or owner of the subject property during the next five years, that are equivalent to 10% or more of the reported market value.\n\nFor the avoidance of doubt, the surveyor will not be under a duty to purchase data from third parties or to read the lease documentation. If further information is provided to the surveyor after the valuation has been completed and submitted, the valuer may reconsider the valuation and if necessary, issue an amended report. But again, for the avoidance of doubt, the valuer will not be under any obligation to read the lease document and would only be expected to base a revised valuation on further information provided to the valuer by your legal representatives.\n\nIn respect of insurance, the following assumptions will be made, unless instructed to the contrary:\n\n',
                data: [
                  {
                    str: 'the property can be insured under all-risks cover for the current reinstatement cost and is available on normal terms,\n\n\n'
                  },
                  { str: 'there are no outstanding claims or disputes,\n\n' },
                  {
                    str: 'where individuals in a block make separate insurance arrangements, the leases make provision for mutual enforceability of insurance and repairing obligations and,\n\n'
                  },
                  {
                    str: 'any landlord responsible for insurance is required to rebuild the property with such alterations as may be necessary to comply with current Building Regulations and planning requirements.\n\n'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        str: 'Limitation of Liability\n\n',
        data: [
          {
            str: 'the Company and/or the Surveyor shall under no circumstances whatsoever be liable to the Client, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit or any indirect or consequential loss, of any failure by the client or any agent of the client: promptly to provide information or other material reasonably requested, or where that material is inaccurate or incomplete, or to follow our advice or recommendations.\n\n'
          },
          {
            str: 'none of the Company’s employees, officers, partners, affiliates or consultants individually has a contract with the Client or owes the Client or the prospective purchaser or vendor or any other party a duty of care or personal responsibility. The Client agrees not to bring any claim against any such individuals personally. To the extent that any part of this notification is a restriction of liability within the meaning of Section 1 of the Unfair Contract Terms Act 1977, it does not apply to death or personal injury resulting from negligence or fraudulent misrepresentation.\n\n'
          },
          {
            str: 'the Company’s and Surveyors’ entire financial liability to the Client is hereby limited to an amount which is equivalent to twenty times the fee the Client has paid to the Surveyor through Houzecheck for the Valuation Service, and such amount shall be inclusive of legal costs, professional fees and disbursements, further investigations and testing and any other collateral costs incurred by the Client.\n\n'
          },
          {
            str: 'these Terms of Engagement do not include any warranties, conditions and other terms except as stated herein and as required by law. The Valuation will be provided after diligent consideration and research but property values are subject to fluctuation and the valuation process is inexact and thus the Surveyor’s opinion is subject to a degree of tolerance depending on the property and the availability of comparable evidence. Valuations are provided to assist the Client in making a prudent decision: they are not provided as any form of guarantee or warranty of value.\n\n\n'
          },
          {
            str: 'where loss is suffered by the Client for which the Company, the Surveyor and/or any other third party are jointly responsible, any recoverable loss by the Client from the Company will be proportionate to the Company’s relative contribution to the loss suffered and subject to the provisions of this clause 3.\n\n'
          },
          {
            str: "where the Client is composed of more than one entity, then each such entity's liability shall be joint and several.\n\n"
          },
          {
            str: 'the Client acknowledges that the Surveyor shall have no liability for any inaccuracy in the Valuation Report or service provision where this is due to an inaccuracy in the Information provided by the client or third parties.\n\n'
          },
          {
            str: 'the terms implied by Sections 3 to 5 of the Supply of Goods and Services Act 1982 are, to the fullest extent permitted by law, excluded from this Contract and the Valuation Report.\n\n'
          },
          {
            str: 'this Clause 3 shall survive termination of this contract.\n\n'
          },
          {
            str: 'the Client’s failure to follow the requirements set out in these Terms and Conditions will invalidate the Valuation and service provision.\n\n'
          },
          {
            str: 'nothing in these conditions shall limit or exclude the Company’s liability for:\n\n',
            data: [
              {
                str: 'Death or personal injury caused by its negligence, or the negligence of its officers, employees, agents, subcontractors or consultants;\n\n',
                italic: true
              },
              {
                str: 'Fraud or fraudulent misrepresentation;\n\n',
                italic: true
              }
            ]
          }
        ]
      },
      {
        str: 'General Terms\n\n',
        data: [
          {
            str: 'In the event of one of the Terms and Conditions herein being held to be unenforceable, the remainder of the contract is not affected.\n\n'
          },
          {
            str: 'The Client shall pay the Company through Houzecheck in respect of the fee including VAT, to be agreed between the Client and the Company and detailed in the Instruction email. The fee is payable at the time of the Company being engaged to perform the Service. Please note that such fee will not be treated by RICS as client money and will not be subject to the protection of the RICS Client Money Protection Scheme.\n\n\n'
          },
          {
            str: 'If the Client has agreed to accept reimbursement of the fee paid, or the Company’s fee account has not been settled within three months of the date of the invoice, then it is agreed that the Client has placed no reliance on the Valuation Report and thus the Client or any permitted assignees will not be entitled to pursue any action for alleged negligence, breach of contract or breach of duty. This does not limit the Company’s entitlement to the agreed fee.\n\n'
          },
          {
            str: 'All telephone or verbal discussions are prone to misinterpretation or misunderstanding by either party, especially in the interpretation of instructions, legal, technical or descriptive matters; as such no reliance may be placed by the Client upon any such discussions unless agreed outcomes are formally confirmed in writing by an authorised officer of the Company.\n\n'
          },
          {
            str: 'The Valuation Report will be conveyed to the Client electronically and in the event that a printed hard copy report is required, the Client undertakes to arrange this at the Client’s cost.\n\n'
          },
          {
            str: 'Referral fees: The Company as a regulated firm and The Surveyor confirms that a payment has been or may be made, either individually or in part of a third-party commercial relationship. Both The Company and The Surveyor are satisfied that this does not constitute a conflict of interest.\n\n'
          },
          {
            str: 'Failure to follow the requirements set down by these conditions will invalidate the Report and the Valuation.\n\n'
          },
          {
            str: "Dispute resolution: In the event that the Client has a complaint regarding the standard of The Service and/or The Valuation Report, a formal Complaint Handling Procedure will be followed. Copies of the Company's Complaint Handling Procedure are available upon request. Using the Complaints Handling Procedures will not affect the Client's legal rights.\n\n"
          },
          {
            str: 'Data Protection: The Company takes its responsibility under the General Data Protection Regulation very seriously. Our Privacy Policy details how we collect, use, maintain and disclose personal data. A copy of our Privacy Policy is available upon request.\n\n'
          },
          {
            str: 'Intellectual Property: All intellectual property rights arising out of the Valuation Service shall be owned by the Company, excluding the Customer Information.\n\n'
          }
        ]
      },
      {
        str: 'Building Insurance Reinstatement Cost Provided as an Additional Service:\n\n',
        str2: 'The Surveyor can provide a building insurance reinstatement cost as an additional service to the Valuation service, subject to an additional fee.\n\nThe reinstatement cost is not related to the capital value of the building and property. It is the cost of rebuilding from scratch were the building to be damaged beyond repair.\n\nThe reinstatement cost is the cost of rebuilding a property including demolition of the original building, temporary support of adjoining buildings where applicable, materials, labour, professional fees and Local Authority charges. It includes sums for boundaries, drainage and significant outbuildings. The reinstatement cost will be reported as a single figure, not as a detailed schedule of all costs.\n\nSpecialist installations such as swimming pools are excluded.\n\nThe Surveyor cannot provide reinstatement costs for Listed Buildings as this requires a detailed specialist assessment, which is beyond the scope of the Valuation service.\n\n\n'
      },
      {
        str: 'Definitions:\n\n'
      }
    ],
    TABLE_SEQ_TYPES.number
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold text-center">
        TERMS AND CONDITIONS RELATING TO OUR SERVICE
      </h1>
      <br />
      <h1 className="text-2xl font-bold text-center">PLEASE READ THESE TERMS CAREFULLY</h1>
      <br />
      <h1 className="text-lg text-center">
        IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT BOOK A VALUATION OR A SURVEY.
      </h1>
      <br />
      <h1 className="text-lg text-center">
        WHEN YOU PAY FOR THE SURVEY OR VALUATION YOU ARE AGREEING TO THESE TERMS AND CONDITIONS
      </h1>
      <br />
      <br />
      {renderContentList1([
        {
          title: 'WHO WE ARE AND WHAT THIS AGREEMENT DOES\n\n',
          desc: 'We are Houzecheck Limited, a company registered in England and Wales with company number 11223619. Our registered office is at The Quarters, 30-40 St Albans Road, Watford, Hertfordshire WD17 1RN. These terms apply to the use of our service to book a third-party Surveyor to carry out a Survey or a Valuation of a Property as permitted in these terms. We also manage the payment process on behalf of the Surveyor.\n\n'
        },
        {
          title: 'CONTACT US\n\n',
          desc: 'To contact us please email us on team@houzecheck.com or call us on 0330 113 1133 (Sales) or 0203 936 9033 (Customer Services).\n\n'
        },
        {
          title: 'YOUR PRIVACY\n\n',
          desc: 'Under data protection legislation, we are required to provide you with certain information including who we are, how we process your personal data and for what purposes and your rights in relation to your personal data and how to exercise them. This information is provided online at https://www.houzecheck.com/terms and it is important that you read that information.\n\nWe share customer data with a small number of carefully selected partners. We will give you the option to opt out of this upon delivery of your report. Alternatively, you can email us at: team@houzecheck.com.\n\n'
        },
        {
          title: 'SOME DEFINTIONS\n\n',
          desc: 'In these terms and conditions, we use the following definitions:\n\n'
        },
        {
          title: 'We / us / our:',
          desc: `Houzecheck Limited\n\n`
        },
        {
          title: 'You / your:',
          desc: `the person who requests a Survey or Valuation.\n\n`
        },
        {
          title: 'Fees:',
          desc: 'the fees payable for use of our service and the charges for the Survey or Valuation.\n\n'
        },
        {
          title: 'Instruction email:',
          desc: 'our email confirmation and acceptance of your Request\n\n'
        },
        {
          title: 'Property:',
          desc: 'an interest in a self-contained domestic dwelling as defined by The Town and Country Planning (Use Classes) Order 1987 (as amended) being classified as Use Class C3 which may also include small HMOs within Use Class C4 in the United Kingdom in respect of which a Survey is requested or which is to be Valued\n\n'
        },
        {
          title: 'Regulations:',
          desc: 'The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013\n\n'
        },
        {
          title: 'Request:',
          desc: 'your application for a Survey and/or Valuation which is submitted by contacting us or by completing the form on our Website\n\n'
        },
        {
          title: 'Survey:',
          desc: 'the service provided by the Surveyor as set out in the instruction email. A few Survey options are RICS Home Survey – Level 2 (survey only), RICS Home Survey – Level 2 (survey and valuation) and RICS Home Survey – Level 3. For full list of Surveys please visit our website www.houzecheck.com\n\n'
        },
        {
          title: 'Surveyor:',
          desc: 'the Surveyor whose name and details are set out in the Instruction email\n\n'
        },
        {
          title: 'Survey Report:',
          desc: 'the report prepared by the Surveyor following an inspection of the Property.\n\n'
        },
        {
          title: 'Valuation:',
          desc: 'the valuation of a Property by a Surveyor prepared in accordance with the RICS Valuation – Global Standards effective 31 January 2022, the RICS Valuation – Global Standard 2017: UK National Supplement effective 14 January 2019, and relevant UK Valuation Practical Guidance Applications and Valued shall be interpreted accordingly\n\n'
        },
        {
          title: 'Website:',
          desc: 'https://www.houzecheck.com\n\n\n'
        },
        {
          title: 'HOW WE WORK\n\n'
        }
      ])}
      <div
        dangerouslySetInnerHTML={{
          __html: renderContentList2(
            [
              {
                str: 'We do not provide or carry out Surveys or Valuations. Your enquiry will be referred to one of our panel of suitably qualified Surveyors in your area who are:\n\n',
                str_footer:
                  'The Surveyors are independent third-party contractors who are not employed by us. The Surveyor will enter into a contract with you directly for provision of the Survey or Valuation and we are not a party to that contract.\n\n',
                data2: [
                  {
                    str: 'members of the Royal Institution of Chartered Surveyors (RICS) holding one of the membership grades: AssocRICS, MRICS, or FRICS; and/or\n\n'
                  },
                  {
                    str: 'members of the RICS Valuer Registration Scheme (VRS).\n\n'
                  }
                ]
              },
              {
                str: 'When you request a Survey or Valuation you must provide the information requested by us. If you fail to do so we reserve the right to reject your request. Your Request will only be accepted when we send you an Instruction email which sets out your name and subject property address, instruction type, reason for instruction, subject property address, and fee/price agreed. The Survey and Surveyor’s details are included in the Instruction email.\n\n'
              },
              {
                str: 'We reserve the right to reject your Request for any reason and we will let you know by email – this could be because there is no Surveyor on our panel who can carry out the Survey or Valuation due to the type of Property or its location.\n\n'
              },
              {
                str: 'We make changes to our services from time to time. This may happen because there is a change in the law or regulations which affect our services.\n\n'
              },
              {
                str: 'The Surveyors on our panel only cover Properties in the United Kingdom.\n\n\n'
              }
            ],
            TABLE_SEQ_TYPES.number
          )
        }}
      />

      {renderContentList1([
        {
          title: 'FEES AND PAYMENT\n\n'
        }
      ])}
      <div
        dangerouslySetInnerHTML={{
          __html: renderContentList2(
            [
              {
                str: 'When you make a Request you agree to pay the relevant Fees. We invoice for the Fees payable to the Surveyor. We will pay the Surveyor on your behalf.\n\n'
              },
              {
                str: 'You will be charged for the Survey or Valuation when you book the Survey or Valuation appointment.\n\n'
              },
              {
                str: 'All Fees are inclusive of VAT unless we specifically state that this is not the case.\n\n\n'
              }
            ],
            TABLE_SEQ_TYPES.number
          )
        }}
      />
      {renderContentList1([
        {
          title: 'CHANGES AND CANCELLATIONS\n\n'
        }
      ])}
      <div
        dangerouslySetInnerHTML={{
          __html: renderContentList2(
            [
              {
                str: 'If you are a consumer you have a legal right under the Regulations to change your mind about your booking within 14 days of the date we send you the Instruction email. However, you lose the right to cancel your booking if the Survey or Valuation is completed within the 14-day cooling off period.\n\n'
              },
              {
                str: 'If you change your mind and wish to cancel the Survey or Valuation relying on your rights under the Distance Selling Regulations, please email us at team@houzecheck.com. If you do this we will refund the Fees paid within 14 days of you telling us you want to cancel. If you cancel your booking during the 14-day cooling off period, we will deduct £50 for the booking service provided by Houzecheck. If the Surveyor has carried out part of the Survey or Valuation then we will retain part of the Fees paid to cover the services actually provided the Surveyor. No refund is payable if the property has been inspected or report completed.\n\n'
              },
              {
                str: 'The refund will be made by the same method you used to pay us and we do not charge a fee to make the refund.\n\n'
              },
              {
                str: 'After the 14-day cooling off period has expired you can cancel your Survey or Valuation by emailing or calling us using the details set out above.\n\n'
              },
              {
                str: 'If you cancel your Survey or Valuation at any time when the 14-day cooling off period does not apply one of the following cancellation fees will be deducted from the Fees depending upon when the notice of cancellation is received. Where notice of cancellation received:\n\n',
                data2: [
                  {
                    str: 'Before 2pm on the day before scheduled appointment: a cancellation fee of £35.00 including VAT to cover administrative costs.\n\n'
                  },
                  {
                    str: 'After 2pm on the day before scheduled appointment, but prior to inspection taking place: 25% of the Fees, subject to a minimum fee applying of £75.00 including VAT.\n\n'
                  },
                  {
                    str: 'Following the Surveyor attending the Property but is unable to gain access in order to undertake the inspection: 50% of the Fees.\n\n'
                  }
                ]
              },
              {
                str: 'Subject to the permitted deductions set out in paragraph 5 above we will (on behalf of the Surveyor) refund the Fee using the same method that you used for the original payment.\n\n'
              },
              {
                str: 'The Surveyor can cancel their appointment to carry out the Survey or Valuation if, after arriving at the Property, they conclude that it is of a type of property they have insufficient specialist knowledge to be able to provide the Survey Report satisfactorily. If this happens, we will (on behalf of the Surveyor) refund any Fees paid by you in full.\n\n'
              },
              {
                str: 'The Surveyor can cancel their appointment to carry out the Survey or Valuation if the Surveyor finds that the Property is demonstrably different from that described by you in the Request. In any such case we will (on behalf of the Surveyor) refund any Fees paid by you less our booking fee of £50 including VAT to cover administrative costs. If the Surveyor has already attended the Property, the refund will be reduced by 50% of the Fees.\n\n\n'
              }
            ],
            TABLE_SEQ_TYPES.number
          )
        }}
      />

      {renderContentList1([
        {
          title: 'OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU\n\n'
        }
      ])}
      <div
        dangerouslySetInnerHTML={{
          __html: renderContentList2(
            [
              {
                str: 'Whether you are a consumer or a business user:\n\n',
                data2: [
                  {
                    str: 'We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.\n\n'
                  }
                ]
              },
              {
                str: 'If you are a business user:\n\n',
                data2: [
                  {
                    str: 'We exclude all implied conditions, warranties, representations, or other terms that may apply to the Website or any content on it.\n\n'
                  },
                  {
                    str: 'We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:\n\n',
                    data2: [
                      {
                        str: 'use of, or inability to use, the Website; or\n\n'
                      },
                      {
                        str: 'use of or reliance on any content displayed on the Website.\n\n'
                      }
                    ]
                  },
                  {
                    str: 'In particular, we will not be liable for:\n\n',
                    data2: [
                      {
                        str: 'loss of profits, sales, business, or revenue;\n\n'
                      },
                      {
                        str: 'business interruption;\n\n'
                      },
                      {
                        str: 'loss of anticipated savings;\n\n'
                      },
                      {
                        str: 'loss of business opportunity, goodwill or reputation; or\n\n'
                      },
                      {
                        str: 'any indirect or consequential loss or damage.\n\n'
                      }
                    ]
                  }
                ]
              },
              {
                str: 'If you are a consumer user:\n\nPlease note that we only provide our service for domestic and private use. You agree not to use our service for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.\n\n\n'
              }
            ],
            TABLE_SEQ_TYPES.number
          )
        }}
      />
      {renderContentList1([
        {
          title:
            'YOUR RESPONSIBILITY FOR ANY DAMAGE ARISING AS A RESULT OF YOUR USE OF THE SERVICES\n\n',
          desc: 'If you are in breach of these terms you agree that you will be liable for any loss or damage suffered by us where such loss or damage has been caused by your intentional, reckless or grossly negligent actions.\n\n'
        },
        {
          title: 'Do not rely on information on the Website\n\n'
        }
      ])}
      <div
        dangerouslySetInnerHTML={{
          __html: renderContentList2([
            {
              str: 'The content on the Website is provided for general information only. It is not intended to amount to advice on which you should rely.\n\n'
            },
            {
              str: 'Although we make reasonable efforts to update the information on the Website, we make no representations, warranties or guarantees, whether express or implied, that the content on the Website is accurate, complete, or up to date.\n\n\n'
            }
          ])
        }}
      />
      {renderContentList1([
        {
          title: 'GENERAL\n\n'
        }
      ])}
      <div
        dangerouslySetInnerHTML={{
          __html: renderContentList2([
            {
              str: 'If you would like to discuss our services with us or you wish to make a complaint please contact us by email at team@houzecheck.com or by calling us on 0203 936 9033. A copy of our Complaint Handling Procedure is available on request.\n\n'
            },
            {
              str: 'If we have to contact you we will do so by phone call, SMS or email using the contact details provided to us.\n\n'
            },
            {
              str: 'You must be 18 or over to accept these terms and use our services.\n\n'
            },
            {
              str: 'We are not responsible for events outside of our or Surveyor’s control. If provision of Surveyor’s services is delayed by an event outside of Surveyor’s control, then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract with us and receive a refund for any Survey or Valuation services you have paid for but not received.\n\n'
            },
            {
              str: 'We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.\n\n'
            },
            {
              str: 'You may only transfer your rights or your obligations under these terms to another person if we agree in writing.\n\n'
            },
            {
              str: 'This Agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement.\n\n'
            },
            {
              str: 'If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.\n\n'
            },
            {
              str: 'Even if we delay in enforcing this contract, we can still enforce it later.\n\n'
            },
            {
              str: 'These terms are governed by English law and you can bring legal proceedings in respect of the products in the English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the products in either the Northern Irish or the English courts.\n\n\n'
            }
          ])
        }}
      />
    </div>
  );
}

export default HCTNCPAGE;
