/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../components/Container';
import FormControl from '../../components/FormControl';
import './QuotePage.css';
import {
  getQuoteAction,
  handlePaymentAction,
  updateLeadAction,
  updateStepAction
} from '../../redux/actions/lead-actions';
import { updateLead } from '../../redux/features/lead-slice';
import Accordian from '../../components/Accordian';
import FormSubmitButtons from '../../components/FormSubmitButtons';
import { allConstantVals, QuotePageQnAs, monthFullText, commonFieldErr } from '../../Constants';
import TextError from '../../components/TextError';
import CautionComponent from '../../components/CautionComponent';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import TnC from '../../components/TnC';
import FieldsErr from '../../components/FieldsErr';
// import { getQuoteApi } from '../../apis';

function QuotePage() {
  const {
    valuationJobType,
    surveyJobType,
    level2Name,
    level3Name,
    stage_payment,
    stage_quote_no_slots,
    stage_quote
  } = allConstantVals;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead);
  // const {
  //   quoteData: { schedule = [], productPriceDetails, dateSelected, timeSelected, product }
  // } = leadData;
  const {
    productData: { quoteData }
  } = leadData;
  const schedule = quoteData?.schedule || [];
  const availableDates = schedule.map((val, i) => ({
    key: `key${i}`,
    btnTitle: `${val.date} ${val.month}`,
    value: `${val.day}, ${val.date} ${monthFullText(val.month)} ${val.year}`,
    slots: val.slots,
    surveyor: val.surveyor,
    company: val.company
  }));

  const [selectedDate, setSelectedDate] = useState({ slots: [] });
  const [availableTime, setAvailableTime] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [hcModalOpen, setHcModalOpen] = useState(false);
  const { refId } = useParams();
  const redirectPage = (route) => {
    if (refId) {
      navigate(`/${refId}${route}`);
    } else {
      navigate(route);
    }
  };
  useEffect(() => {
    // console.log('hello');
    // const localLeadData = localStorage.getItem('leadData');
    // const localLeadDataParsed = JSON.parse(localLeadData);
    // if (localLeadData) {
    //   dispatch(updateLead(localLeadDataParsed));
    dispatch(getQuoteAction(leadData));
    // } else {
    //   navigate('/');
    // }
  }, [leadData.productData?.valuation]);
  useEffect(() => {
    if (leadData.productData?.currentStep === 1) {
      redirectPage('');
    }
    if (
      leadData.productData?.currentStep === 2 ||
      leadData.productData?.currentStep === 3 ||
      leadData.productData?.currentStep === 4 ||
      leadData.productData?.currentStep === 5
    ) {
      navigate('product');
    } else if (leadData.productData?.currentStep === 7) {
      navigate('payment');
    } else if (leadData.productData?.currentStep === 8) {
      navigate('confirmation');
    }
    // else if (leadData.currentStep === 9) {
    //   navigate('/access-details');
    // } else if (leadData.currentStep === 10) {
    //   navigate('/report-details');
    // }
  }, [leadData.productData?.currentStep]);

  useEffect(() => {
    const selectedTime = selectedDate?.slots.map((val, i) => ({
      key: `key${i}`,
      btnTitle: `${val.start_time} - ${val.end_time}`,
      value: val.slot_id
    }));
    setAvailableTime(selectedTime);
  }, [selectedDate]);

  const initialValues = {
    // selected_date: dateSelected || '',
    // selected_time: timeSelected || '',
    selected_date: '',
    selected_time: quoteData?.timeSelected || '',
    tnc: false,
    surveyor_consent: false
  };
  const validationSchema = Yup.object({
    selected_date:
      availableDates.length !== 0 ? Yup.string().required(commonFieldErr) : Yup.string(),
    // leadData?.productData?.job_type === valuationJobType.value
    //   ? Yup.string().required(commonFieldErr)
    //   : Yup.string(),
    selected_time: Yup.string(),
    tnc: Yup.bool().oneOf([true], 'You must accept the terms & conditions.'),
    surveyor_consent: Yup.bool().oneOf([true], 'Please provide your consent to proceed.')
  });
  const fieldLabels = {
    selected_date: 'Please select your preferred appointment date'
  };
  const backBtnHandler = () => {
    if (leadData.productData.job_type === valuationJobType.value) {
      dispatch(
        updateLead({
          ...leadData,
          productData: { ...leadData.productData, valuation: 'no', currentStep: 3 }
        })
      );
    } else {
      dispatch(
        updateLead({
          ...leadData,
          productData: { ...leadData.productData, valuation: 'no', currentStep: 5 }
        })
      );
    }
  };
  const updateCallBackStatus = () => {
    dispatch(
      updateLeadAction({
        ...leadData,
        currentStep: 8,
        productData: {
          ...leadData.productData,
          web_stage: 'Quote - Callback',
          currentStep: 8,
          callbackStatus: true
        }
        // appointment: availableDates.length !== 0,
      })
    );
  };
  const onSubmit = (values) => {
    // dispatch(createLeadAction(values));
    const updatedLeadData = {
      ...leadData,
      productData: {
        ...leadData.productData,
        web_stage: availableDates.length !== 0 ? stage_payment.value : stage_quote_no_slots.value,
        currentStep: availableDates.length !== 0 ? 7 : 8,
        quoteData: {
          ...leadData.productData?.quoteData,
          dateSelected: values.selected_date,
          // leadData?.productData?.job_type === valuationJobType.value
          //   ? values.selected_date
          //   : availableDates[0]?.value,
          timeSelected: availableTime[0]?.value
          // leadData?.productData?.job_type === valuationJobType.value
          //   ? availableTime[0]?.value
          //   : availableDates[0]?.slots[0]?.slot_id
        },
        appointment: availableDates.length !== 0
      }
    };
    console.log(updatedLeadData, '.....');
    // dispatch(updateLead(updatedLead));
    // dispatch(handlePaymentAction(updatedLeadData));
    dispatch(updateLeadAction(updatedLeadData));
  };
  const updateQuote = (val) => {
    dispatch(updateLead({ ...leadData, productData: { ...leadData.productData, valuation: val } }));
  };
  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, errors, touched }) => (
          <Form>
            <p className="font-bold text-3xl md:text-4xl mt-3">Price and Availability</p>
            {/* <p>
              We’re offering {productPriceDetails?.discountPercentage}% Limited time discount for
              all new surveys. Order your survey now to claim the offer. No coupon code required
            </p> */}
            <div className="border-b-2 border-primary-200 mt-3" />
            <div className="text-xl md:text-2xl font-bold my-3">{quoteData?.product?.name}</div>
            <div className="flex">
              <p className="w-8/12 text-right">Cost</p>
              <p className="w-4/12 font-bold text-right">
                £{quoteData?.productPriceDetails?.baseAmt}
              </p>
              {/* <p>
                {JSON.stringify(product)}
                {leadData.currentStep}
              </p> */}
            </div>
            {/* <div className="flex">
              <div className="flex w-8/12 justify-end">
                <Field type="checkbox" name="tnc" />
                <p className="text-sm pl-3">
                  I agree to the terms and conditions stated{' '}
                  <a className="text-primary-400" href="¬">
                    here
                  </a>
                </p>
              </div>
              <p className="w-4/12 font-bold text-right">£{productPriceDetails?.baseAmt}</p>
            </div> */}
            <div className="flex mt-3">
              <p className="w-8/12 text-right">VAT @ 20%</p>
              <p className="w-4/12 font-bold text-right">
                £{quoteData?.productPriceDetails?.vatAmt}
              </p>
            </div>
            {!!quoteData?.productPriceDetails?.discountPercentage && (
              <div className="flex mt-3 text-green-600">
                <p className="w-8/12 text-right">{quoteData?.offer}</p>
                <p className="w-4/12 font-bold text-right">
                  -£{quoteData?.productPriceDetails?.discountAmt}
                </p>
              </div>
            )}
            <div className="border-b border-primary-200 my-2" />
            <div className="flex">
              <p className="w-8/12 font-bold text-right text-xl">Total Cost</p>
              <p className="w-4/12 font-bold text-right  text-xl">
                £{quoteData?.productPriceDetails?.finalPrice}
              </p>
            </div>
            {leadData?.productData?.job_type === surveyJobType.value &&
              leadData?.productData?.survey_type === level2Name.value && (
                <div className="flex justify-between items-center mt-5">
                  <p className="mb-0">
                    Would you like to get market valuation for an additional fee?
                  </p>
                  <div className="flex">
                    <Button
                      btnType={
                        leadData.productData?.valuation === 'no'
                          ? 'defaultFilledBtn'
                          : 'defaultOutlineBtn'
                      }
                      customClass="!py-2 text-primary-800 rounded-full !px-4 mr-3"
                      onClickHandler={() => updateQuote('no')}>
                      No
                    </Button>
                    <Button
                      btnType={
                        leadData.productData?.valuation === 'yes'
                          ? 'defaultFilledBtn'
                          : 'defaultOutlineBtn'
                      }
                      customClass="!py-2 text-primary-800 rounded-full !px-4 mr-3"
                      onClickHandler={() => updateQuote('yes')}>
                      Yes
                    </Button>
                  </div>
                </div>
              )}
            <CautionComponent text="Our experts will review the property, and if it has a non-standard construction or an usual aspect, such as being grade listed, then in these rare cases, the price may change." />
            <div className="bg-primary-100 px-4 md:pl-0 py-3 rounded-xl mt-8">
              <div className="md:flex justify-between items-center">
                <p className="bg-primary-500 p-3 text-center md:text-left text-white rounded-full md:rounded-l-none">
                  Great price unlocked
                </p>
                <p className="text-primary-500 text-center font-semibold text-lg mt-3 md:mt-0">
                  {/* {productPriceDetails?.discountPercentage}% off on all online orders */}
                  {quoteData?.productPriceDetails?.discountPercentage
                    ? `${quoteData?.offer}`
                    : `Rated Excellent on Trustpilot`}
                </p>
              </div>
              <p className="md:pl-4 text-justify mt-3">
                {/* Quality matters - that’s why we only work with highly experienced RICS surveyors.
                For the level of service, 90% of our customers find our prices very competitive. To
                discuss anything request a call back from one of our amazing property experts */}
                <strong>YOU’RE IN SAFE HANDS</strong>: Not all surveys are created equal, quality
                matters - that’s why we only work with highly experienced qualified RICS surveyors -
                we don’t use trainee surveyors.
                <br />
                <br />
                <strong>THE NEED FOR SPEED</strong>: Our cutting-edge technology helps us deliver a
                faster service without compromising quality. Reports are emailed within 5 working
                days - 80% are delivered even quicker!
                <br />
                <br />
                <strong>RELAX, WE’VE GOT THIS</strong>: Behind this fancy website, is a dedicated
                team of real-life people, who are here to answer your questions and basically make
                everything run smoothly.
              </p>
            </div>

            {/* <div className="flex justify-between items-center mt-5">
              <p className="mb-0">Would you like to receive a callback from our Property Expert?</p>
              <div className="flex">
                <Button
                  btnType={
                    leadData?.callbackStatus === false ? 'defaultFilledBtn' : 'defaultOutlineBtn'
                  }
                  customClass="!py-2 text-primary-800 rounded-full !px-4 mr-3"
                  onClickHandler={() => updateCallBackStatus(false)}>
                  No
                </Button>
                <Button
                  btnType={
                    leadData?.callbackStatus === true ? 'defaultFilledBtn' : 'defaultOutlineBtn'
                  }
                  customClass="!py-2 text-primary-800 rounded-full !px-4 mr-3"
                  onClickHandler={() => updateCallBackStatus(true)}>
                  Yes
                </Button>
              </div>
            </div> */}
            {/* <>
              <p className="mt-8 font-bold">Do you like to add any addtional product?</p>
              <div className="md:flex justify-between items-center border border-primary-600 rounded-2xl mt-3 p-5">
                <div className="md:w-2/12 p-3">
                  <img className=" mx-auto" src="/images/addtional_prod.png" alt="" />
                </div>
                <div className="md:w-8/12 md:mx-3">
                  <p className="font-semibold mb-2">Product one</p>
                  <p className="text-justify">
                    We’re offering 10% Limited time discournt for all new surveys. Order your survey
                    now to claim the offer. No coupon code required
                  </p>
                </div>

                <div className="md:w-2/12 font-semibold flex md:block justify-between mt-3 md:mt-0 md:self-start md:text-right">
                  <button
                    type="button"
                    className={`${
                      leadData.valuation ? 'text-red-500' : 'text-primary-500'
                    } md:mb-6 md:mt-2`}
                    onClick={updateQuote}>
                    {leadData.valuation ? (
                      <strong>Remove from Quote</strong>
                    ) : (
                      <strong>Add to Quote</strong>
                    )}
                  </button>
                  <p>£99.00</p>
                </div>
              </div>
            </> */}
            <div className="border-b-2 border-primary-200 mt-12 mb-5" />
            {availableDates.length !== 0 && (
              <p className="text-2xl font-bold">Secure your surveyor appointment date now!</p>
            )}
            {/* <p>Choose the date and time you’d like </p> */}
            {/* <p className="mt-5 font-bold">Select date</p> */}

            <FormControl
              controlType="radio"
              label=""
              name="selected_date"
              optionVals={availableDates}
              radioType="type4"
              onClickHandler={(val) => setSelectedDate(val)}
            />
            {leadData?.productData?.job_type === valuationJobType.value &&
              availableDates.length !== 0 && (
                <p className="mt-5">
                  If these dates do not work for you, please request a callback as sometimes we can
                  work some magic!
                  <br />
                  <br />
                  Our customer service team will coordinate with you to arrange access to the
                  property and confirm the appointment time band.
                  {/* <br />
                  <br />
                  The report will be sent to you as soon as possible, but may take up to five
                  working days */}
                  <br />
                </p>
              )}
            {leadData?.productData?.job_type === surveyJobType.value &&
              availableDates.length !== 0 && (
                <p className="mt-5">
                  If these dates do not work for you, please request a callback as sometimes we can
                  work some magic!
                  <br />
                  <br />
                  Our customer service team will coordinate with you, the estate agent and the
                  vendor to arrange access to the property
                  <br />
                  {/* <br />
                  Our surveyor will aim to call you prior to the survey to understand if you have
                  any specific concerns. We aim to deliver the report within 5 working days, usually
                  the report is sent much sooner. <br /> */}
                </p>
              )}
            {availableDates.length === 0 && (
              <p className="mt-5 font-semibold">
                We have very limited availability in this postcode. One of our team members will
                call you to try book you a Surveyor as soon as possible.
                <br />
              </p>
            )}
            {/* {availableTime.length > 0 && (
              <FormControl
                controlType="dropdown"
                label="Select time"
                name="selected_time"
                optionVals={availableTime}
                dropdownType="type1"
              />
            )} */}
            {/* <div className="border-b-2 border-primary-200 mt-8 mb-5" /> */}
            {/* <CautionComponent text="Don't worry, one of our team will arrange access to the property for you" /> */}
            <div className="border-b-2 border-primary-200 mt-8 mb-5" />
            {availableDates.length !== 0 && (
              <p className="my-5 font-semibold">
                The quotation and availability has been given on the basis of the answers you have
                provided. The price may change if any of the details are found to be incorrect
              </p>
            )}
            <Accordian
              title="Details provided"
              accordianQnA={QuotePageQnAs(leadData)}
              step={2}
              toggleable={false}
            />

            {/* <Accordian
              title="Product detail"
              accordianQnA={surveyQnAs(leadData, sType2)}
              step={3}
            />
            {leadData.productData?.survey_type === level3Name.value && (
              <Accordian
                title="Property Details: Non-standard construction"
                accordianQnA={surveyQnAs(leadData, sType3)}
                step={3}
              />
            )}
            <Accordian
              title="Property Details: additional features"
              accordianQnA={surveyQnAs(leadData, sType4)}
              step={4}
            /> */}
            {((leadData?.productData?.job_type === valuationJobType.value &&
              values.selected_date) ||
              (leadData?.productData?.job_type === valuationJobType.value &&
                availableDates.length === 0)) && (
              <TncCheckbox setModalOpen={setModalOpen} setHcModalOpen={setHcModalOpen} />
            )}
            {((leadData?.productData?.job_type === surveyJobType.value && values.selected_date) ||
              (leadData?.productData?.job_type === surveyJobType.value &&
                availableDates.length === 0)) && (
              <TncCheckbox setModalOpen={setModalOpen} setHcModalOpen={setHcModalOpen} />
            )}
            <FieldsErr
              fieldLabels={fieldLabels}
              errors={{ selected_date: errors.selected_date }}
              touched={touched}
            />

            <div className="flex items-center justify-between my-6">
              <p>Any query, receive a call from one of our property experts</p>
              <Button btnType="defaultOutlineBtn" onClickHandler={updateCallBackStatus}>
                Request Callback
              </Button>
            </div>
            <FormSubmitButtons backBtnHandler={backBtnHandler} continueTxt="Go to Payment" />
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="md:px-8">
          <TnC
            jobType={leadData?.productData?.job_type}
            name={selectedDate.surveyor}
            companyName={selectedDate.company}
          />
          <div className="text-center py-5">
            <Button
              btnType="defaultOutlineBtn"
              customClass="mr-3"
              onClickHandler={() => setModalOpen(false)}>
              Thanks
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={hcModalOpen} onClose={() => setHcModalOpen(false)}>
        <div className="md:px-8">
          <TnC
            jobType="Houzecheck"
            name={
              leadData?.productData?.job_type === valuationJobType.value
                ? selectedDate.surveyor
                : availableDates[0]?.surveyor
            }
            companyName={
              leadData?.productData?.job_type === valuationJobType.value
                ? selectedDate.company
                : availableDates[0]?.company
            }
          />
          <div className="text-center py-5">
            <Button
              btnType="defaultOutlineBtn"
              customClass="mr-3"
              onClickHandler={() => setHcModalOpen(false)}>
              Thanks
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
}

function TncCheckbox({ setModalOpen, setHcModalOpen }) {
  return (
    <>
      <div className="flex items-center py-4">
        <Field type="checkbox" name="tnc" style={{ zoom: 2 }} />
        <p className="pl-3">
          I agree to the{' '}
          <button type="button" className="text-primary-400" onClick={() => setHcModalOpen(true)}>
            Houzecheck&apos;s T&Cs
          </button>
          {' and '}
          <button type="button" className="text-primary-400" onClick={() => setModalOpen(true)}>
            Surveyor&apos;s T&Cs
          </button>{' '}
          of engagement.
        </p>
      </div>
      <ErrorMessage component={TextError} name="tnc" />
      <div className="flex items-center py-4">
        <Field type="checkbox" name="surveyor_consent" style={{ zoom: 2 }} />
        <p className="pl-3">
          I consent to the sharing of my data with surveyors and other affiliates.
        </p>
      </div>
      <ErrorMessage component={TextError} name="surveyor_consent" />
    </>
  );
}

// eslint-disable-next-line no-lone-blocks
{
  /* <div className="px-8">
          <p className="text-secondary-800 font-bold text-xl text-center">
            Covid terms and conditions
          </p>

          <img
            src="/images/covid.png"
            alt=""
            width={96}
            height={96}
            className="p-4 bg-primary-50 rounded-full mx-auto"
          />
          <p className="text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. At eget est faucibus odio
            tincidunt curabitur felis feugiat mattis.
          </p>
          <div className="mx-auto text-center">
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <img src="/images/tick.png" className="w-6 mr-5" alt="" width={20} height={20} />
              <p>Lorem ipsum dolor sit amet, conetur adipiscing elit.</p>
            </div>
             <Button
              submit
              btnType="defaultOutlineBtn"
              customClass="mt-5"
              onClickHandler={() => goToReport(finalVal)}>
              Okay, Thanks
            </Button>
          </div>
        </div> */
}

export default QuotePage;
